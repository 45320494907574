import axios from "axios";
import { CreateOrganisationDto, EditOrganisationDto, EmailConfigDto, SmsConfigDto, IpConfigDto } from "shared";
import { injectable, Lifecycle, scoped } from "tsyringe";
import { getConstants } from "../../../config/constants";
import { getContainer } from "../../app-container";
import { ModDataService } from "../mod-data.service";

@injectable()
@scoped(Lifecycle.ContainerScoped)
export class OrganisationRequestsService {
  async createOrganisationDto(dto:CreateOrganisationDto) {
    await axios({
      method: "post",
      url: `${getConstants().SERVER_URL}/organisations`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()

  }

  async editOrganisationDto(dto:EditOrganisationDto,id:string) {
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/organisations/${id}`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()

  }

  async deleteOrganisationDto(id:string) {
    await axios({
      method: "delete",
      url: `${getConstants().SERVER_URL}/organisations/${id}`,
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()
  }
  async sendUpdateSmsConfig(dto:SmsConfigDto,organisationId:string) {
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/organisations/${organisationId}/smsConfig`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleOrganisationDataChanges(organisationId)

  }
  async sendUpdateEmailConfig(dto:EmailConfigDto,organisationId:string) {
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/organisations/${organisationId}/emailConfig`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleOrganisationDataChanges(organisationId)
  }


  async sendUpdateIPConfig(dto:IpConfigDto,organisationId:string) {
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/organisations/${organisationId}/ipConfig`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleOrganisationDataChanges(organisationId)
  }
}
