import { ReactComponent as EditIcon } from "bootstrap-icons/icons/pencil-fill.svg";
import { ReactComponent as DeleteIcon } from "bootstrap-icons/icons/trash-fill.svg";
import { ReactComponent as AddIcon } from "bootstrap-icons/icons/plus-square-fill.svg";
import { useLayoutEffect, useState } from "react";
import { Tooltip } from "bootstrap";
import { useTranslate } from "react-translate";
import { useModPanelData } from "../../hooks/useModPanelData";
import CreateIPModal from "../../modals/IpConfigTab/CreateIPModal";
import EditIPModal from "../../modals/IpConfigTab/EditIPModal";
import DeleteIPModal from "../../modals/IpConfigTab/DeleteIPModal";

const MAX_IP_NUMBER=100

const IpConfigTab: React.FC<{ organisationID: string | null }> = ({
  organisationID,
}) => {


  const {ipConfigData} = useModPanelData(organisationID)


  const primaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-primary-rgb");

  const dangerColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-danger-rgb");

  const [editingIP,setEditingIP] = useState<number|null>(null)
  const [deletingIP,setDeletingIP] = useState<number|null>(null)
  const [creatingIP,setCreatingIP] = useState<boolean>(false)


  useLayoutEffect(() => {
    const tooltipElements = Array.from(
      document.querySelectorAll(".IpConfigTooltip")
    );
    const tooltips = tooltipElements.map((el) => new Tooltip(el));
    return () => tooltips.forEach((tooltip) => tooltip.dispose());
  }, []);

  const t = useTranslate("user");

  return (
    <>
      <h4>{t("IP_CONFIG_HEADER")}</h4>
      <p className="ms-1">{t("IP_CONFIG_PARAGRAPH",{maxNumber:MAX_IP_NUMBER.toString()})}</p>
      <div style={{ maxWidth: 1100 }}>
        <table className="table table-striped mb-2">
          <thead>
            <tr>
              <th
                style={{ width: "1%", whiteSpace: "nowrap" }}
                className="ps-4"
              >
                #
              </th>
              <th className="ps-4" style={{ whiteSpace: "nowrap" }}>
                {t("IP_CONFIG_TABLE_IP")}
              </th>
              <th className="ps-4">{t("IP_CONFIG_TABLE_COMMENT")}</th>
              <th className="ps-4 pe-3" style={{ width: "1%" }}></th>
            </tr>
          </thead>
          <tbody>
            {ipConfigData.ipAddressList.map((item, i) => (
              <tr>
                <th
                  style={{ width: "1%", whiteSpace: "nowrap" }}
                  className="ps-4"
                >
                  {i + 1}
                </th>
                <td
                  className="ps-4"
                  style={{ width: "1%", whiteSpace: "nowrap" }}
                >
                  {item.ipAddress}
                </td>
                <td className="ps-4">{item.comment}</td>
                <td className="ps-4 pe-0" style={{ width: "1%" }}>
                  <div className="d-flex justify-content-end">
                    <div className="me-3">
                      <div
                        className="IpConfigTooltip"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={t("IP_CONFIG_MODAL_HEADER_EDIT")}
                      >
                        <a
                          className="text-decoration-none text-reset"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            // setModalData({
                            //   mode: "EDIT",
                            //   comment: item.comment,
                            //   phoneNumber: item.phoneNumber,
                            //   index: i,
                            // });
                            setEditingIP(i)
                          }}
                          // data-bs-toggle="modal"
                          // data-bs-target="#configItemSms"
                        >
                          <EditIcon style={{ cursor: "pointer" }} />
                        </a>
                      </div>
                    </div>
                    <div className="me-4">
                      <div
                        className="IpConfigTooltip"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={t("IP_CONFIG_MODAL_HEADER_DELETE")}
                      >
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            // setModalData({
                            //   mode: "DELETE",
                            //   comment: item.comment,
                            //   phoneNumber: item.phoneNumber,
                            //   index: i,
                            // });
                            setDeletingIP(i)
                          }}
                          // data-bs-toggle="modal"
                          // data-bs-target="#configItemSms"
                        >
                          <DeleteIcon
                            fill={`rgb(${dangerColor})`}
                            style={{ cursor: "pointer" }}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {ipConfigData.ipAddressList.length>=MAX_IP_NUMBER ? null :
        <div className="d-flex justify-content-end">
          <div className="pe-4" style={{ marginRight: -2 }}>
            <div
              className="IpConfigTooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={t("IP_CONFIG_MODAL_HEADER_CREATE")}
            >
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  // setModalData({ mode: "CREATE" });
                  setCreatingIP(true)
                }}
                // data-bs-toggle="modal"
                // data-bs-target="#configItemSms"
              >
                <AddIcon
                  style={{ cursor: "pointer" }}
                  fill={`rgb(${primaryColor})`}
                  height="20"
                  width="20"
                />
              </a>
            </div>
          </div>
        </div>
        }
      </div>
      <CreateIPModal organisationId={organisationID} show={creatingIP} onClose={()=>setCreatingIP(false)} /> 
      <EditIPModal organisationId={organisationID} index={editingIP} onClose={()=>setEditingIP(null)} /> 
      <DeleteIPModal organisationId={organisationID} index={deletingIP} onClose={()=>setDeletingIP(null)} /> 
    </>
  );
};

export default IpConfigTab;
