import { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { EditOrganisationDto } from "shared";
import ErrorDisplayFieldError from "../../../../../utils/forms/ErrorDisplayFieldError";
import { exampleOrganisations } from "../../tabs/Admin/OrganisationsTab";
import { useModDataSelector } from "../../../mod-data.provider";
import { container } from "tsyringe";
import { OrganisationRequestsService } from "../../../request-services/organisation-requests.service";

const resolver = classValidatorResolver(EditOrganisationDto);

const EditOrganisationModal: React.FC<{
  organisationId: string | null;
  onClose: () => void;
}> = ({ onClose, organisationId }) => {
  const t = useTranslate("mod");

  const organisations = useModDataSelector(state=>state.system?.organisations);

  const organisation = useMemo(()=>organisations?.find(org=>org.organisation._id===organisationId),[organisationId,organisations])
  const {
    register,
    handleSubmit,
    formState: { errors,isValid },
    setValue,
    trigger
  } = useForm<EditOrganisationDto>({ resolver, mode: "onBlur" });

  const submitRef = useRef<HTMLInputElement>(null);
  const onSave = useCallback(() => {
    if (!submitRef.current) {
      return;
    }
    submitRef.current.click();
  }, [submitRef]);

  useEffect(() => {
    if(!organisation){
      return
    }
    setValue("name", organisation?.organisation.name || "");
    setValue("owner", organisation?.owner.name || "");
    trigger()
  }, [organisation]);

  return (
    <DefaultFormModal
      title={t("ORGANISATION_TOOLTIP_EDIT")}
      show={!!organisation}
      onClose={onClose}
      onSave={onSave}
      saveDisabled={!isValid}
    >
      <>{t("EDIT_ORGANISATION_MODAL_PARAGRAPH", { orgName: organisation?.organisation.name || "" })}</>
      <form
        onSubmit={handleSubmit((data) => {
          container.resolve(OrganisationRequestsService).editOrganisationDto(data,organisation?.organisation._id||"").then(()=>{
            onClose()
          })
        })}
      >
        <input ref={submitRef} hidden type="submit" />
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <label htmlFor="edit_org_name" className="col-form-label">
                {t("EDIT_ORGANISATION_MODAL_LABEL_NAME")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="edit_org_name"
                {...register("name")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.name} />
        </div>
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <label htmlFor="edit_org_owner" className="col-form-label">
                {t("EDIT_ORGANISATION_MODAL_LABEL_OWNER")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="edit_org_owner"
                {...register("owner")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.owner} />
        </div>
      </form>
    </DefaultFormModal>
  );
};

export default EditOrganisationModal;
