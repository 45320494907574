import { BubbleDataPoint, Chart, ChartDataset, ChartTypeRegistry, ScatterDataPoint } from "chart.js";
import { isEqual } from "lodash";
import { getConstants } from "../../../../config/constants";

export const updateDatasetsProcedure = (chart:Chart,datasets: ChartDataset<keyof ChartTypeRegistry, (number | ScatterDataPoint | BubbleDataPoint | null)[]>[])=>{
    //@ts-ignore
    const chartDatasets = chart.config._config.data.datasets as ChartDataset<keyof ChartTypeRegistry, (number | ScatterDataPoint | BubbleDataPoint | null)[]>[];

    if(getConstants().LOCK_DATASETS){
        return
    }


    //splicing removed or changed datasets
    const datasetsIndexesToRemove:Array<number>=[];
    chartDatasets.forEach((prevDatasetInst,i)=>{
        const label = prevDatasetInst.label;
        const nextDatasetInst = datasets.find(dt=>dt.label===label);
        if(!nextDatasetInst){
            datasetsIndexesToRemove.push(i)
            return;
        }
        if(nextDatasetInst.data.length!==prevDatasetInst.data.length){
            datasetsIndexesToRemove.push(i)
            return;
        }

        /* different time ranges */
        //@ts-ignore
        if(nextDatasetInst.data[0].x!==prevDatasetInst.data[0].x||nextDatasetInst.data[nextDatasetInst.data.length-1].x!==prevDatasetInst.data[prevDatasetInst.data.length-1].x){
            datasetsIndexesToRemove.push(i)
            return
        }

        /* different dashes */
        //@ts-ignore
        if(nextDatasetInst.borderDash!==prevDatasetInst.borderDash){
            datasetsIndexesToRemove.push(i)
            return;
        }

        /* fill to different alarm threshold */

        //@ts-ignore
        if(!(isEqual(nextDatasetInst.fill,prevDatasetInst.fill))){
            datasetsIndexesToRemove.push(i)
            return
        }
    })
    datasetsIndexesToRemove.reverse().forEach((index)=>{
        chartDatasets.splice(index,1)
    })

    //pushing added or changed datasets
    datasets.forEach((nextDatasetInst)=>{
        const label = nextDatasetInst.label;
        if(chartDatasets.find(dt=>dt.label===label)){
            return
        }
        chartDatasets.push(nextDatasetInst)

    })

}