import React from "react";
import { TranslatorProvider } from "react-translate";
import translations from "../translations/translations";

const TranslationProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const { children } = props;

  return (
    <TranslatorProvider translations={translations.PL}>
      {children}
    </TranslatorProvider>
  );
};

export default TranslationProvider;
