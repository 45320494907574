import { DateTime } from "luxon";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useTranslate } from "react-translate";
import {
  getTimeRange,
  getTimeRangeType,
  moveRange,
} from "../chart-generating/utils/getTimeRange";
import { TimeRangeType, TimeScaleRange } from "../models/time-scale-state";
import { PutTimeScaleState } from "../store/putTimeScaleState";

import { ReactComponent as ArrowLeftIcon } from "bootstrap-icons/icons/caret-left-square-fill.svg";
import { ReactComponent as ArrowRightIcon } from "bootstrap-icons/icons/caret-right-square-fill.svg";
import {
  useMainChartDispatch,
  useMainChartSelector,
} from "../hooks/chart.context";
import DownloadChartButton from "./DownloadChartButton";
import { Tooltip } from "bootstrap";

const TimeScaleConfig: React.FC = () => {
  const chart = useMainChartSelector((state) => state.chart);

  const timeScaleState = useMainChartSelector((state) => state.timeScaleState);
  const { endTimestamp_s, startTimestamp_s } = timeScaleState;

  const [forcedCustomTimeRange, setForcedCustomTimeRange] = useState<boolean>(
    getTimeRangeType(timeScaleState) === "CUSTOM"
  );
  const timeRangeType = useMemo(
    () => (forcedCustomTimeRange ? "CUSTOM" : getTimeRangeType(timeScaleState)),
    [forcedCustomTimeRange, timeScaleState]
  );

  useEffect(() => {
    if (timeRangeType === "CUSTOM") {
      setForcedCustomTimeRange(true);
    }
  }, [timeRangeType, setForcedCustomTimeRange]);

  const showEndDate = useMemo<boolean>(
    () => timeRangeType !== "DAILY",
    [timeRangeType]
  );

  const dispatch = useMainChartDispatch();

  const PRIMARY_COLOR = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-primary-rgb");
  const primaryColorString = `rgb(${PRIMARY_COLOR})`;

  const applyTimeScaleRange = useCallback(
    (timeRange: TimeScaleRange) => {
      dispatch(PutTimeScaleState.callAction({ timeScaleState: timeRange }));
      let timestamp = performance.now();
      chart?.zoomScale(
        "x",
        {
          min: timeRange.startTimestamp_s * 1000,
          max: timeRange.endTimestamp_s * 1000,
        },
        "normal"
      );
    },
    [chart, dispatch]
  );

  const selectDailyTimeRange = useCallback(() => {
    setForcedCustomTimeRange(false);
    const dailyTimeRange = getTimeRange("DAILY", startTimestamp_s, "START");
    applyTimeScaleRange(dailyTimeRange);
  }, [setForcedCustomTimeRange, applyTimeScaleRange, startTimestamp_s]);

  const selectWeeklyTimeRange = useCallback(() => {
    setForcedCustomTimeRange(false);
    const weeklyTimeRange = getTimeRange("WEEKLY", startTimestamp_s, "START");
    applyTimeScaleRange(weeklyTimeRange);
  }, [setForcedCustomTimeRange, applyTimeScaleRange, startTimestamp_s]);
  const selectCustomTimeRange = useCallback(() => {
    setForcedCustomTimeRange(true);
  }, [setForcedCustomTimeRange]);

  const setDate = useCallback(
    (timeStamp_s: number, timestamp_ref: "START" | "END") => {
      const timeRange = getTimeRange(
        timeRangeType,
        timeStamp_s,
        timestamp_ref,
        timeScaleState
      );
      applyTimeScaleRange(timeRange);
    },
    [applyTimeScaleRange, timeRangeType, timeScaleState]
  );

  const moveRangePage = useCallback(
    (movePageNumber: number) => {
      const timeRange = moveRange(timeScaleState, movePageNumber);
      applyTimeScaleRange(timeRange);
    },
    [applyTimeScaleRange, timeScaleState]
  );

  const t = useTranslate("chart");

  useLayoutEffect(() => {
    const tooltipElements = Array.from(
      document.querySelectorAll(".timeButtonTooltip")
    );
    const tooltips = tooltipElements.map((el) => new Tooltip(el));
    return () => tooltips.forEach((tooltip) => tooltip.dispose());
  }, []);

  return (
    <div
      className="justify-content-center justify-content-lg-start"
      style={{ display: "flex", alignItems: "baseline", flexWrap: "wrap" }}
    >
      <ul
        className="nav nav-pills p-1"
        style={{ display: "flex", flexWrap: "nowrap" }}
      >
        <li className="nav-item">
          <a
            className={`nav-link ${timeRangeType === "DAILY" ? "active" : ""}`}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              selectDailyTimeRange();
            }}
          >
            {t("TIME_RANGE_DAILY")}
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${timeRangeType === "WEEKLY" ? "active" : ""}`}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              selectWeeklyTimeRange();
            }}
          >
            {t("TIME_RANGE_WEEKLY")}
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${timeRangeType === "CUSTOM" ? "active" : ""}`}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              selectCustomTimeRange();
            }}
          >
            {t("TIME_RANGE_CUSTOM")}
          </a>
        </li>
      </ul>
      <input
        className="form-control ms-3 my-1"
        type="date"
        style={{ width: 150 }}
        onChange={(e) => {
          const timestamp_s = DateTime.fromFormat(
            e.target.value,
            "yyyy-MM-dd"
          ).toSeconds();
          setDate(timestamp_s, "START");
        }}
        value={DateTime.fromSeconds(startTimestamp_s).toFormat("yyyy-MM-dd")}
      />
      <div
        style={{ justifySelf: "center" }}
        className={`me-2 ms-2 my-1 ${!showEndDate ? "opacity-0 pe-none" : ""}`}
      >
        -
      </div>
      <input
      tabIndex={!showEndDate ? -1 : undefined}
        className={`form-control  ${
          !showEndDate ? "opacity-0 pe-none my-1" : ""
        }`}
        type="date"
        style={{ width: 150 }}
        onChange={(e) => {
          const timestamp_s = DateTime.fromFormat(
            e.target.value,
            "yyyy-MM-dd"
          ).toSeconds();
          setDate(timestamp_s, "END");
        }}
        value={DateTime.fromSeconds(endTimestamp_s - 1).toFormat("yyyy-MM-dd")}
      />
      <div className="my-1" style={{ display: "flex" }}>
        <div
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={t("MOVE_BACKWARD")}
          className="ms-3 timeButtonTooltip"
          style={{
            alignSelf: "center",
            cursor: "pointer",
            justifySelf: "flex-end",
          }}
        >
          <a href="#" onClick={(e) =>{e.preventDefault(); moveRangePage(-1)}}>
          <ArrowLeftIcon
            onClick={() => moveRangePage(-1)}
            fill={primaryColorString}
            width="40"
            height="40"
          />
          </a>
        </div>
        <div
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title={t("MOVE_FORWARD")}
          className="ms-2 timeButtonTooltip"
          style={{
            alignSelf: "center",
            cursor: "pointer",
            justifySelf: "flex-end",
          }}
        >
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              moveRangePage(1);
            }}
          >
            <ArrowRightIcon fill={primaryColorString} width="40" height="40" />
          </a>
        </div>
        <DownloadChartButton />
      </div>
    </div>
  );
};

export default TimeScaleConfig;
