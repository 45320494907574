import "./AlarmItemsContainer.scss"

const AlarmItemsContainer: React.FC<{children:React.ReactNode}> = (props)=>{

    const {children} = props;

    return(
        <>
            <div style={{width:"100%",height:"100%"}}/>
            <div className="alarm-items-container pe-2" style={{position:"absolute",left:0,top:0,width:"100%",height:"100%"}}>
                {children}
            </div>
        </>
    )
}

export default AlarmItemsContainer