import { PdfGeneratorState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";
import { TimeScaleState } from "../../charts/models/time-scale-state";

type Payload = {
  timeScaleState: Partial<TimeScaleState>;
};

class UpdateTimeScaleStateClass extends ReducerActionClass<
  PdfGeneratorState,
  Payload
> {
  getActionName() {
    return "UPDATE_TIME_SCALE_STATE";
  }

  handleDispatch(
    prevState: PdfGeneratorState,
    payload: Payload
  ): PdfGeneratorState {
    const { timeScaleState: prevTimeScaleState } = prevState;
    const { timeScaleState: partTimeScaleState } = payload;

    const timeScaleState: TimeScaleState = {
      ...prevTimeScaleState,
      ...partTimeScaleState,
    };

    return {
      ...prevState,
      timeScaleState,
    };
  }
}

export const UpdateTimeScaleState = new UpdateTimeScaleStateClass();
