import { Redirect, Route } from "react-router-dom";
import AnimatedSwitch from "./AnimatedSwitch";
import AdminPage, { ADMIN_PAGE_PATH } from "./pages/admin";
import AlarmsPage, { ALARMS_PAGE_URL } from "./pages/alarms";
import DevicesPage, { DEVICES_PAGE_PATH } from "./pages/devices";
import DeviceItemPage, { DEVICE_ITEM_PAGE_PATH } from "./pages/devices/device-item-page";
import HomePage, { HOME_PAGE_PATH } from "./pages/home";
import ModPage, { MOD_PAGE_PATH } from "./pages/mod";
import ProfilePage, { PROFILE_PAGE_PATH } from "./pages/profile";
import RaportsPage, { RAPORTS_PAGE_PATH } from "./pages/reports";
import StatsPage, { STATS_PAGE_PATH } from "./pages/stats";

const Router: React.FC = () => {

  return (
    <AnimatedSwitch>
      <Route component={DevicesPage} exact path={DEVICES_PAGE_PATH} />
      <Route component={DeviceItemPage} exact path={DEVICE_ITEM_PAGE_PATH}/>
      <Route component={HomePage} exact path={HOME_PAGE_PATH} />
      <Route component={ProfilePage} exact path={PROFILE_PAGE_PATH} />
      <Route component={RaportsPage} exact path={RAPORTS_PAGE_PATH} />
      <Route component={StatsPage} exact path={STATS_PAGE_PATH} />
      <Route component={AlarmsPage} exact path={ALARMS_PAGE_URL} />
      <Route component={ModPage} exact path={MOD_PAGE_PATH} />
      <Route component={AdminPage} exact path={ADMIN_PAGE_PATH} />
      <Route>
        <Redirect to={HOME_PAGE_PATH} />
      </Route>
    </AnimatedSwitch>
  );
};

export default Router;
