import { AggregationRange } from "shared";

type Constants={

    MAX_CHART_RANGE_MINUTE:number,
    MAX_CHART_RANGE_HOUR:number,
    LOCK_DATASETS:boolean,
    DATA_EXPORT_AGGREGATION?:AggregationRange,
    SUBDIR:boolean,
    SUBDIR_NAME?:string,
    SERVER_URL:string,
}

const CONSTANTS:Constants={
    MAX_CHART_RANGE_MINUTE:86400,
    MAX_CHART_RANGE_HOUR:5356800,
    LOCK_DATASETS:false,
    DATA_EXPORT_AGGREGATION:"HOUR",
    SUBDIR:false,
    SUBDIR_NAME:"28102022",
    // SERVER_URL:"http://localhost:8000/api",
    SERVER_URL:"/api",
}

//@ts-ignore
window.CONSTANTS=CONSTANTS

export const getConstants=()=>{
    return CONSTANTS;
}