import { useLayoutEffect, useState } from "react";

export const useDeviceRes = (
  maxWidth: number=10000,
  minWidth: number=0
): { width?: number; height?: number } => {
  const [chartRes, setChartRes] = useState<{ width?: number; height?: number }>(
    {}
  );

  useLayoutEffect(() => {
    const resizeChartIfSmartphone = () => {
      const width = document.documentElement.clientWidth;
      const height = document.documentElement.clientHeight;

      if (width >= maxWidth || width < minWidth) {
        setChartRes({});
        return;
      }
      setChartRes({ width, height });
    };
    resizeChartIfSmartphone();

    window.addEventListener("resize", resizeChartIfSmartphone);
    return () => {
      window.removeEventListener("resize", resizeChartIfSmartphone);
    };
  }, []);

  return chartRes;
};
