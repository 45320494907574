import { ALARMS_CONFIG } from "../../config/config";
import { AlarmExceed } from "../../models/alarm-exceed.model";
import { SortMode } from "../../models/sort-mode.model";
import { getSortCompareFunction } from "./getSortCompareFunction";

export const calculateCurrentPageAfterFiltersChange = (
  requestedAlarmExceeds: Array<AlarmExceed>,
  prevRequestedAlarmExceeds: Array<AlarmExceed>,
  sortMode:SortMode,
  prevSortMode:SortMode,
  prevPage: number,
): number => {

    if(sortMode!==prevSortMode){
        return prevPage;
    }

    const prevFocusedElement = prevRequestedAlarmExceeds[prevPage*ALARMS_CONFIG.itemsOnPage]
    if(!prevFocusedElement){
        return 0
    }

    const sortCompareF = getSortCompareFunction(sortMode);
    let focusedElementIndex = requestedAlarmExceeds.findIndex((element)=>
        sortCompareF(element,prevFocusedElement)>=0
    )
    if(focusedElementIndex===-1){
        focusedElementIndex=requestedAlarmExceeds.length-1;
    }

    const page = Math.floor(focusedElementIndex/ALARMS_CONFIG.itemsOnPage)


    return page
};
