import { useState } from "react";
import { useTranslate } from "react-translate";
import { useModPanelData } from "../../hooks/useModPanelData";
import ChangeMissingMeasuresAlertConfig from "../../modals/SystemTab/ChangeMissingMeasuresAlertConfig";

const SystemTab: React.FC = () => {
  const t = useTranslate("mod");

  const { missingMeasuresAlertConfig } = useModPanelData(null);
  const { enabled = false, alertTimeThreshold_s = 0 } =
    missingMeasuresAlertConfig || {};

  const [configMissingMeasures, setConfigMissingMeasures] =
    useState<boolean>(false);

  return (
    <>
      <h4>{t("TAB_system")}</h4>
      <p>{t("SYSTEM_PARAGRAPH")}</p>
      <div style={{maxWidth:1100}}>
        <h5>{t("SYSTEM_MISSING_MEASURES_HEADER")}</h5>
        <p>{t("SYSTEM_MISSING_MEASURES_DESCRIPTION")}</p>
        <div style={{ padding: 5 }}>
          {t("SYSTEM_MISSING_MEASURES_ENABLED")}:
          {enabled
            ? t("SYSTEM_MISSING_MEASURES_ENABLED_YES")
            : t("SYSTEM_MISSING_MEASURES_ENABLED_NO")}
          <br />
          {!enabled ? (
            ""
          ) : (
            <>
              {t("SYSTEM_MISSING_MEASURES_THRESHOLD")}:
              {alertTimeThreshold_s} s
              <br/>
            </>
          )}
          <button
            className="btn btn-primary ms-1 mt-3"
            onClick={() => setConfigMissingMeasures(true)}
          >
            {t("SYSTEM_MISSING_MEASURES_EDIT")}
          </button>
        </div>
      </div>
      <ChangeMissingMeasuresAlertConfig
        show={configMissingMeasures}
        onClose={() => setConfigMissingMeasures(false)}
      />
    </>
  );
};

export default SystemTab;
