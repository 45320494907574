import "./LoginView.scss";
import { useState } from "react";
import { getContainer } from "../../app-container";
import { AuthService } from "../auth.service";
import { useTranslate } from "react-translate";

const LoginView: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const t = useTranslate("menu")
  //w scss:height

  return (
    <div className="login-container position-absolute d-flex flex-column flex-lg-row-reverse align-items-stretch">
      <div className="login-container-image bg-secondary"></div>
      <div className="login-container-form d-flex justify-content-center align-items-center ">
        <div
          className="position-relative px-2 py-5"
          style={{ maxWidth: "440px", flexGrow: 1 }}
        >
          <div>
            <h3 className="ms-1 mb-5">{t("LOGIN_HEADER")}</h3>
            <div style={{ width: "100%" }} className="form-floating mb-3">
              <input
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                className="form-control"
                style={{ height: 70 }}
                id="usernameInput"
              />
              <label htmlFor="usernameInput">{t("USERNAME")}</label>
            </div>
            <div style={{ width: "100%" }} className="form-floating mb-2">
              <input
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                style={{ height: 70 }}
                type="password"
                className="form-control"
                id="passwordInput"
              />
              <label htmlFor="passwordInput">{t("PASSWORD")}</label>
            </div>

            <div className="form-check mb-3 ms-1">
              <input
                checked={rememberMe}
                type="checkbox"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    //@ts-ignore
                    e.target.click();
                  }
                }}
                onClick={() => {
                  setRememberMe((val) => !val);
                }}
                className="form-check-input"
                value=""
                id="remembermeInput"
              />
              <label className="form-check-label" htmlFor="remembermeInput">
                {t("REMEMBER_ME")}
              </label>
            </div>

            <button
              style={{ width: "100%" }}
              className="btn btn-primary btn-lg"
              type="button"
              onClick={() => {
                getContainer()
                  .resolve<AuthService>("auth")
                  .loginLocal(username, password, rememberMe);
              }}
            >
              {t("LOGIN_BUTTON")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      Username:{" "}
      <input
        value={username}
        onChange={(e) => {
          setUsername(e.target.value);
        }}
      ></input>
      <br />
      Password:{" "}
      <input
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      ></input>
      <br />
      Remember me:{" "}
      <input
        checked={rememberMe}
        type="checkbox"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            //@ts-ignore
            e.target.click();
          }
        }}
        onClick={() => {
          setRememberMe((val) => !val);
        }}
      ></input>
      <br />
      <button
        onClick={() => {
          getContainer()
            .resolve<AuthService>("auth")
            .loginLocal(username, password, rememberMe);
        }}
      >
        Login
      </button>
    </div>
  );
};

export default LoginView;
