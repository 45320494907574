import TranslationProvider from "./TranslationProvider";
import RouterProvider from "./RouterProvider";
import { DataProviderProvider } from "../modules/data-provider/data-provider.provider";
import { AuthProvider } from "../modules/auth/auth.provider";
import { AlertProvider } from "../modules/alerts/alert.provider";
import { ModDataProvider } from "../modules/mod/mod-data.provider";
import { UserProvider } from "../modules/user/user.provider";

const MainProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const { children } = props;

  return (
    <AuthProvider>
      <UserProvider>
        <DataProviderProvider>
          <ModDataProvider>
            <RouterProvider>
              <TranslationProvider>
                <AlertProvider>{children}</AlertProvider>
              </TranslationProvider>
            </RouterProvider>
          </ModDataProvider>
        </DataProviderProvider>
      </UserProvider>
    </AuthProvider>
  );
};

export default MainProvider;
