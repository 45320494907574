export default{
    "PROFILE_HEADER":"Profil użytkownika",
    "USERNAME":"Nazwa użytkownika",
    "USER_ROLE":"Rola",
    "USER_ROLE_ADMIN":"Administrator systemu",
    "USER_ROLE_OWNER":"Właściciel",
    "USER_ROLE_MANAGER":"Menadżer",
    "USER_ROLE_MOD":"Moderator",
    "USER_ROLE_USER":"Użytkownik",
    "CHANGE_PASSWORD":"Zmień hasło",

    "NOTIFICATIONS_HEADER":"Powiadomienia",
    "NOTIFICATIONS_PARAGRAPH":"Wszystkie powiadomienia wyświetlane w systemie.",
    "NOTIFICATION_OPEN":"Otwórz",
    "NOTIFICATION_NEW":"Nowy",


    "CHANGE_PASSWORD_MODAL_HEADER":"Zmień hasło",
    "CHANGE_PASSWORD_MODAL_BODY":"Zmiana hasła użytkownika",
    "CHANGE_PASSWORD_MODAL_OLD_PASSWORD":"Stare hasło",
    "CHANGE_PASSWORD_MODAL_NEW_PASSWORD":"Nowe hasło",
    "CHANGE_PASSWORD_MODAL_NEW_PASSWORD_REPEAT":"Powtórz nowe hasło",
    "CHANGE_PASSWORD_MODAL_CLOSE":"Zamknij",
    "CHANGE_PASSWORD_MODAL_SAVE":"Zapisz",

    "SMS_CONFIG_HEADER":"Numery telefonów",
    "SMS_CONFIG_PARAGRAPH":"Numery telefonów na które będą wysyłane wiadomości SMS o przekroczonych progach alarmowych. Można przydzielić maksymalnie {{maxNumber}} numery.",
    "SMS_CONFIG_TABLE_PHONE_NUMBER":"Numer telefonu",
    "SMS_CONFIG_TABLE_COMMENT":"Komentarz",

    "SMS_CONFIG_MODAL_HEADER_EDIT":"Edytuj numer SMS",
    "SMS_CONFIG_MODAL_HEADER_CREATE":"Dodaj numer SMS",
    "SMS_CONFIG_MODAL_HEADER_DELETE":"Usuń numer SMS",

    "SMS_CONFIG_MODAL_PARAGRAPH_EDIT_1":"Edytuj numer telefonu (obecnie: ",
    "SMS_CONFIG_MODAL_PARAGRAPH_EDIT_2":"), na który będą wysyłane wiadomości o przekroczonych progach alarmowych.",
    "SMS_CONFIG_MODAL_PARAGRAPH_CREATE":"Dodaj nowy numer telefonu, na który będą wysyłane wiadomości o przekroczonych progach alarmowych.",
    "SMS_CONFIG_MODAL_PARAGRAPH_DELETE_1":"Usuń numer telefonu ",
    "SMS_CONFIG_MODAL_PARAGRAPH_DELETE_2":" z puli numerów, na które będą wysyłane wiadomości o przekroczonych progach alarmowych.",

    "SMS_CONFIG_MODAL_CLOSE":"Zamknij",
    "SMS_CONFIG_MODAL_EDIT":"Edytuj",
    "SMS_CONFIG_MODAL_CREATE":"Dodaj",
    "SMS_CONFIG_MODAL_DELETE":"Usuń",
    
    "EMAIL_CONFIG_HEADER":"Adresy Email",
    "EMAIL_CONFIG_PARAGRAPH":"Adresy Email na które będą wysyłane wiadomości o przekroczonych progach alarmowych. Można przydzielić maksymalnie {{maxNumber}} adresów.",
    "EMAIL_CONFIG_TABLE_EMAIL":"Adres Email",
    "EMAIL_CONFIG_TABLE_COMMENT":"Komentarz",
    
    "EMAIL_CONFIG_MODAL_HEADER_EDIT":"Edytuj adres email",
    "EMAIL_CONFIG_MODAL_HEADER_CREATE":"Dodaj adres email",
    "EMAIL_CONFIG_MODAL_HEADER_DELETE":"Usuń adres email",

    "EMAIL_CONFIG_MODAL_PARAGRAPH_EDIT_1":"Edytuj adres email (obecnie: ",
    "EMAIL_CONFIG_MODAL_PARAGRAPH_EDIT_2":"), na który będą wysyłane wiadomości o przekroczonych progach alarmowych.",
    "EMAIL_CONFIG_MODAL_PARAGRAPH_CREATE":"Dodaj nowy adres email, na który będą wysyłane wiadomości o przekroczonych progach alarmowych.",
    "EMAIL_CONFIG_MODAL_PARAGRAPH_DELETE_1":"Usuń adres email",
    "EMAIL_CONFIG_MODAL_PARAGRAPH_DELETE_2":" z puli adresów, na które będą wysyłane wiadomości o przekroczonych progach alarmowych.",

    
    "IP_CONFIG_HEADER":"Adresy IP",
    "IP_CONFIG_PARAGRAPH":"Adresy z których będzie można przeglądać dane organizacji. Jeśli żaden adres nie będzie przydzielony, można będzie korzystać z danych organizacji z dowolnego adresu IP. Można przydzielić maksymalnie {{maxNumber}} adresów.",
    "IP_CONFIG_TABLE_IP":"Adres IP",
    "IP_CONFIG_TABLE_COMMENT":"Komentarz",
    
    "IP_CONFIG_MODAL_HEADER_EDIT":"Edytuj adres IP",
    "IP_CONFIG_MODAL_HEADER_CREATE":"Dodaj adres IP",
    "IP_CONFIG_MODAL_HEADER_DELETE":"Usuń adres IP",

    "IP_CONFIG_MODAL_PARAGRAPH_EDIT_1":"Edytuj adres IP (obecnie: ",
    "IP_CONFIG_MODAL_PARAGRAPH_EDIT_2":"), z którego będzie można przeglądać dane organizacji. ",
    "IP_CONFIG_MODAL_PARAGRAPH_CREATE":"Dodaj nowy adres IP, z którego będzie można przeglądać dane organizacji. ",
    "IP_CONFIG_MODAL_PARAGRAPH_DELETE_1":"Usuń adres IP",
    "IP_CONFIG_MODAL_PARAGRAPH_DELETE_2":" z puli adresów, z którego będzie można przeglądać dane organizacji.",
}