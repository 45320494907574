import React from 'react';
import UserProfileView from '../../../modules/user/components/UserProfileView';

export const PROFILE_PAGE_PATH = "/profile"

const ProfilePage:React.FC = ()=>{

    return(
        <UserProfileView/>
    )

}

export default ProfilePage;