import { useMemo } from "react";
import { useUserSelector } from "../../user/user.provider";
import { useModPanelData } from "../components/hooks/useModPanelData";
import { useModDataSelector } from "../mod-data.provider";

const modPanelRoles = ["ADMIN", "MOD", "OWNER", "MANAGER"];
const modPanelLevel2Roles = ["ADMIN", "OWNER", "MANAGER"];

export const useCanAccessModPanel = () => {
  const user = useUserSelector((state) => state.user);
  const dataLoaded = useMemo(() => !!user, [user]);

  const selectedOrganisation = useModDataSelector(
    (state) => state.selectedOrganisation
  );

  const access = useMemo(
    () => !!user?.roles.find((role) => modPanelRoles.includes(role.role)),
    [user]
  );

  const modPanelLevel = useMemo(() => {
    if (!access) {
      return 0;
    }
    if (
      user?.roles.find(
        (role) =>
          (modPanelLevel2Roles.includes(role.role) &&
          //@ts-ignore
          role.target === selectedOrganisation) ||role.role==="ADMIN"
      )
    ) {
      return 2
    }
    return 1
  }, [access,user,selectedOrganisation]);

  return { dataLoaded, access,modPanelLevel };
};
