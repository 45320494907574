import { cloneDeep, isUndefined } from "lodash";
import { DateTime } from "luxon";
import {
  Alarm,
  AlarmExceedStatistic,
  AlarmType,
  Device,
  DeviceGroup,
  Measure,
  RunningHistory,
  RunningLocation,
} from "shared";
import { inject, injectable } from "tsyringe";
import { DataType } from "../charts/models/chart-description.model";
import { TimeScaleRange } from "../charts/models/time-scale-state";
import { DataProviderService } from "../data-provider/data-provider.service";
import devices from "../../translations/pl/devices";

@injectable()
export class DevicesService {
  constructor(
    @inject("data-provider") private dataProviderService: DataProviderService
  ) {}

  getTimeRangeRunningHistory(
    runningHistory: RunningHistory,
    timeScaleRange: TimeScaleRange
  ): Array<{
    timeStart_s?: Date;
    timeEnd_s?: Date;
    location?: RunningLocation;
  }> {
    const timeRangeRH: Array<{
      timeStart_s?: Date;
      timeEnd_s?: Date;
      location?: RunningLocation;
    }> = runningHistory
      .filter(
        (rhi) =>
          DateTime.fromJSDate(rhi.timeStart_s, { zone: "utc" }).toSeconds() <
            timeScaleRange.endTimestamp_s &&
          (rhi.timeEnd_s || DateTime.now().toSeconds()) >
            timeScaleRange.startTimestamp_s
      )
      .map((rhi) => cloneDeep(rhi));

    timeRangeRH.forEach((tri) => {
      if (
        tri.timeStart_s &&
        DateTime.fromJSDate(tri.timeStart_s, { zone: "utc" }).toSeconds() <
          timeScaleRange.startTimestamp_s
      ) {
        delete tri.timeStart_s;
      }
      if (
        tri.timeEnd_s &&
        DateTime.fromJSDate(tri.timeEnd_s, { zone: "utc" }).toSeconds() >
          timeScaleRange.endTimestamp_s
      ) {
        delete tri.timeEnd_s;
      }
    });

    return timeRangeRH;
  }
  findAlarmExceedStatistic(
    alarmExceedsStatistics: Array<AlarmExceedStatistic>,
    time: "WEEKLY" | "MONTHLY",
    dataType: DataType,
    alarmType: AlarmType
  ): AlarmExceedStatistic | undefined {
    const { alarms } = this.dataProviderService.getStore().getState();

    const matchingAlarms = alarmExceedsStatistics.filter((aes) => {
      const alarm = alarms.find((alarm) => alarm._id === aes.alarm);
      const alarmDataType = this.dataProviderService.getDataType(
        alarm?.dtAlias || ""
      );
      if (alarm?.type !== alarmType) {
        return false;
      }
      if (alarmDataType !== dataType) {
        return false;
      }
      const expectedTimeDuration =
        time === "WEEKLY" ? 60 * 60 * 24 * 7 : 60 * 60 * 24 * 30;
      const timeDuration =
        DateTime.fromJSDate(aes.timeRangeEnd_s, { zone: "utc" }).toSeconds() -
        DateTime.fromJSDate(aes.timeRangeStart_s, { zone: "utc" }).toSeconds();
      if (timeDuration !== expectedTimeDuration) {
        return false;
      }
      return true;
    });

    if (matchingAlarms.length === 0) {
      return undefined;
    }

    /* when there is more than 1 matching alarm, app should display the most restrictives */
    return matchingAlarms.sort(
      (alarmA, alarmB) => alarmB.exceededPercentage - alarmA.exceededPercentage
    )[0];
  }

  getDeviceAlarms(
    device: Device,
    alarms: Array<Alarm>,
    deviceGroups: Array<DeviceGroup>
  ): Array<Alarm> {
    console.log(device,deviceGroups,alarms)
    const devicesGroups = deviceGroups.filter((group) =>
      device.groupIds.includes(group._id)
    );

    const deviceAlarms: Array<Alarm> = [];

    const alarmIds = devicesGroups.reduce((arr: Array<string>, group) => {
      group.alarms.forEach((alarmId) => {
        if (arr.includes(alarmId)) {
          return;
        }
        arr.push(alarmId);
      });

      return arr;
    }, []);

    //@ts-ignore
    return alarmIds.map(
      (alarmId) => alarms.find((alarm) => alarm._id === alarmId)
    ).filter(alarm=>!isUndefined(alarm));
  }

  getDeviceGroups(
    deviceGroups: Array<DeviceGroup>,
    devices: Array<{
      device: Device;
      lastMeasure?: Measure | undefined;
    }>
  ): Array<DeviceGroup | false> {
    const displayDeviceGroups: Array<DeviceGroup | false> = deviceGroups.filter(
      (dgroup) => dgroup.display
    );
    if (this.getGroupsDevices(deviceGroups, devices, false).length !== 0) {
      displayDeviceGroups.push(false);
    }
    return displayDeviceGroups;
  }

  getDevicesLatestLocation(runningHistory: RunningHistory): string {
    const now = DateTime.now().toSeconds();

    return (
      runningHistory.find(
        (item) =>
          DateTime.fromJSDate(item.timeStart_s).toSeconds() <= now &&
          (!item.timeEnd_s ||
            DateTime.fromJSDate(item.timeEnd_s).toSeconds() >= now)
      )?.location?.locationString || ""
    );
  }

  getGroupsDevices(
    deviceGroups: Array<DeviceGroup>,
    devices: Array<{
      device: Device;
      lastMeasure?: Measure | undefined;
    }>,
    group: string | false
  ): Array<{
    device: Device;
    lastMeasure?: Measure | undefined;
  }> {
    if (group !== false) {
      return devices.filter((dev) => dev.device.groupIds.includes(group));
    } else {
      const displayDeviceGroups = deviceGroups.filter(
        (dgroup) => dgroup.display
      );
      return devices.filter(
        (dev) =>
          !displayDeviceGroups.find((dgroup) =>
            dev.device.groupIds.includes(dgroup._id)
          )
      );
    }
  }
}
