import {
  CartesianScaleTypeRegistry,
  ScaleOptionsByType,
} from "chart.js";
import { _DeepPartialObject } from "chart.js/types/utils";
import { ChartState } from "../store";
import { DataType } from "../models/chart-description.model";
import { getTotalRequestedDataTypesArray } from "./utils/getTotalRequestedDataTypesArray";
import { getAtmosphericPressureScale } from "./yScales/getAtmosphericPressureScale";
import { getCO2Scale } from "./yScales/getCO2Scale";
import { getCOScale } from "./yScales/getCOScale";
import { getHumidityScale } from "./yScales/getHumidityScale";
import { getTemperatureScale } from "./yScales/getTemperatureScale";

const scalesMerging: { [mergedDataType: string]: DataType } = {
  CO_LEVEL: "CO2_LEVEL",
  "POWER_SUPPLY":"HUMIDITY"
};

export const getMergedScaleDataType = (dataType: DataType) =>
  dataType in scalesMerging ? scalesMerging[dataType] : dataType;

export type IGetScale = (
  chartState: ChartState,
  t: any
) => ScaleOptionsByType<"radialLinear" | keyof CartesianScaleTypeRegistry>;

export const getYScales = (
  chartState: ChartState,
  t: any
): _DeepPartialObject<{
  [key: string]: ScaleOptionsByType<
    "radialLinear" | keyof CartesianScaleTypeRegistry
  >;
}> => {
  const scales: _DeepPartialObject<{
    [key: string]: ScaleOptionsByType<
      "radialLinear" | keyof CartesianScaleTypeRegistry
    >;
  }> = {};

  const { totalRequestedDataTypes,scalesConfig } = chartState;
  const totalRequestedDataTypesArray = getTotalRequestedDataTypesArray(
    totalRequestedDataTypes
  );

  totalRequestedDataTypesArray.forEach((dataType) => {
    const scaleDataType = getMergedScaleDataType(dataType);
    let id = `y${scaleDataType}`;
    if(!Object.keys(scalesConfig).includes(scaleDataType)){
      return
    }

    if (scaleDataType === "CO2_LEVEL") {
      scales[id] = getCO2Scale(chartState, t);
    } else if (scaleDataType === "CO_LEVEL") {
      scales[id] = getCOScale(chartState, t);
    } else if (scaleDataType === "TEMPERATURE") {
      scales[id] = getTemperatureScale(chartState, t);
    } else if (scaleDataType === "ATMOSPHERIC_PRESSURE") {
      scales[id] = getAtmosphericPressureScale(chartState, t);
    } else if (scaleDataType === "HUMIDITY") {
      scales[id] = getHumidityScale(chartState, t);
    } else {
      throw Error("unknown data type");
    }
  });
  return scales;
};
