import { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import ErrorDisplayFieldError from "../../../../../utils/forms/ErrorDisplayFieldError";
import { CreateDeviceGroupDto } from "shared";
import { isNull } from "lodash";
import { DeviceGroupRequestsService } from "../../../request-services/device-group-requests.service";
import { container } from "tsyringe";

const resolver = classValidatorResolver(CreateDeviceGroupDto);

const CreateAlarmSetModal: React.FC<{
  show: boolean;
  onClose: () => void;
  organisationId: string | null 
}> = ({ onClose, show,organisationId }) => {
  const t = useTranslate("mod");

  const {
    register,
    handleSubmit,
    formState: { errors,isValid },
  } = useForm<CreateDeviceGroupDto>({ resolver, mode: "onBlur",defaultValues:{display:false} });

  const submitRef = useRef<HTMLInputElement>(null);
  const onSave = useCallback(() => {
    if (!submitRef.current) {
      return;
    }
    submitRef.current.click();
  }, [submitRef]);

  return (
    <DefaultFormModal
      title={t("ASET_TOOLTIP_ADD")}
      show={show}
      onClose={onClose}
      onSave={onSave}
      submitType="add"
      saveDisabled={!isValid}
    >
      <>{t("CREATE_ASET_MODAL_PARAGRAPH")}</>
      <form
        onSubmit={handleSubmit((data) => {
            if(isNull(organisationId)){
              /* add global device group */
              container.resolve(DeviceGroupRequestsService).createGlobalDeviceGroup(data).then(()=>{
                onClose()
              })

            }else{
              container.resolve(DeviceGroupRequestsService).createDeviceGroup(data,organisationId).then(()=>{
                onClose()
              })
            }

        })}
      >
        <input ref={submitRef} hidden type="submit" />
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <label htmlFor="create_aset_name" className="col-form-label">
                {t("EDIT_ASET_MODAL_LABEL_NAME")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="create_aset_name"
                {...register("name")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.name} />
        </div>
      </form>
    </DefaultFormModal>
  );
};

export default CreateAlarmSetModal;
