export type SortMode =
  | "DEVICE_NAME"
  | "DEVICE_NAME_REVERTED"
  | "VALUE_DESCENDING"
  | "DATE_DESCENDING"
  | "DATE_ASCENDING";

export const AllSortModes:Array<SortMode> = [
  "DATE_DESCENDING",
  "DATE_ASCENDING",
  "DEVICE_NAME",
  "DEVICE_NAME_REVERTED",
  "VALUE_DESCENDING"
]