import { ChartState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";
import { TimeScaleState } from "../models/time-scale-state";
import { checkMeasuresGroupingCorrectProcedure } from "./utils/checkMeasuresGroupingCorrectProcedure";

type Payload = {
  timeScaleState: TimeScaleState;
};

class PutTimeScaleStateClass extends ReducerActionClass<ChartState, Payload> {
  getActionName() {
    return "PUT_TIME_SCALE_STATE";
  }

  handleDispatch(prevState: ChartState, payload: Payload) {
    const { timeScaleState } = payload;

    const state = {
      ...prevState,
      timeScaleState,
    }

    checkMeasuresGroupingCorrectProcedure(state);

    return state;
  }
}

export const PutTimeScaleState = new PutTimeScaleStateClass();
