import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Nav } from "react-bootstrap";
import { animated, useSpring } from "react-spring";
import { useTranslate } from "react-translate";
import { useHistory } from "react-router-dom";

import SideMenuContent from "./MenuContent";

import { HOME_PAGE_PATH } from "../pages/home";

import { ReactComponent as ExpandIcon } from "bootstrap-icons/icons/justify.svg";
import { useUserSelector } from "../../modules/user/user.provider";
//@ts-ignore
import logoWoText from "../../assets/logo-bez-napisu-25.png"
//@ts-ignore
import logoText from "../../assets/logo-25.png"
//@ts-ignore
import logoGigText from "../../assets/gig-logo-25.png"
//@ts-ignore
import logoGigWoText from "../../assets/gig-logo-bez-napisu-25.png"

const CONTAINER_HORIZONTAL_PADDING_TOTAL = 16;

const SideMenuDesktopContent: React.FC<{
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}> = (props) => {
  const { expanded, setExpanded } = props;
  const t = useTranslate("menu");
  const history = useHistory();

  return (
    <>
    <ul className="nav nav-pills flex-column mb-auto">
      <li className="nav-item py-1">
      <Nav.Link
        role="none"
        href={HOME_PAGE_PATH}
        onClick={(e) => {
          e.preventDefault();
          history.push(HOME_PAGE_PATH);
        }}
      >
        <div style={{height:25,position:"relative"}}>
        <div style={{position:"absolute"}}>
        <img src={expanded ? logoText : logoWoText} height={25} />
        </div>
        </div>
      </Nav.Link>
      </li>
      <li className="nav-item py-1">
      <Nav.Link
      style={{backgroundColor:"white"}}
        role="none"
        // href="https://gig.eu/pl"
        // target="_blank"
      >
        <div style={{height:25,position:"relative"}}>
        <div style={{position:"absolute",top:-1,left:-12.5}}>
        <img src={expanded ? logoGigText : logoGigWoText} height={25} />
        </div>
        </div>
      </Nav.Link>
      </li>
      </ul>
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item py-1">
          <Nav.Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setExpanded((val) => !val);
            }}
          >
            <span className="text-white">
              <ExpandIcon
                style={{ display: "inline-block", verticalAlign: "middle" }}
                width="25"
                height="25"
              />
              <span
                style={{
                  verticalAlign: "bottom",
                  display: expanded ? "inline-block" : "none",
                }}
                className="ms-2 me-4"
              >
                {t("COLLAPSE_MENU")}
              </span>
            </span>
          </Nav.Link>
        </li>
      </ul>
      <hr />
      <SideMenuContent expanded={expanded} />
    </>
  );
};

const SideMenuDesktop = () => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number | undefined>(undefined);
  const [styles, api] = useSpring(() => ({ height: "100%", width: "auto" }));

  const roles = useUserSelector(state=>state.user?.roles)

  useLayoutEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const width = containerRef.current.clientWidth;
    //@ts-ignore
    containerRef.current.inert=true;
    setWidth(width);
  }, [expanded, containerRef,roles]);

  useEffect(() => {
    if (!width) {
      return;
    }
    //@ts-ignore
    api.start({ width: width + 1 });
  }, [width]);

  return (
    <>
      <div
        ref={containerRef}
        className="p-2 bg-dark text-white"
        style={{
          height: "100%",
          opacity: 0,
          position: "absolute",
          pointerEvents: "none",
        }}
      >
        <SideMenuDesktopContent expanded={expanded} setExpanded={setExpanded} />
      </div>
      <animated.div
        className="p-2 bg-dark text-white"
        style={{ ...styles, overflow: "hidden" }}
      >
        <div
          style={{
            height: "100%",
            width: !width
              ? undefined
              : width - CONTAINER_HORIZONTAL_PADDING_TOTAL + 1,
          }}
        >
          <SideMenuDesktopContent
            expanded={expanded}
            setExpanded={setExpanded}
          />
        </div>
      </animated.div>
    </>
  );
};

export default SideMenuDesktop;
