import axios from "axios";
import { AlarmDto, AlarmGlobalDto, SmsConfigDto } from "shared";
import { injectable, Lifecycle, scoped } from "tsyringe";
import { getConstants } from "../../../config/constants";
import { getContainer } from "../../app-container";
import { ModDataService } from "../mod-data.service";

@injectable()
@scoped(Lifecycle.ContainerScoped)
export class AlarmRequestsService {
  async createGlobalAlarm(dto:AlarmGlobalDto) {
    await axios({
      method: "post",
      url: `${getConstants().SERVER_URL}/alarms/system`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()
  }
  async editGlobalAlarm(dto:AlarmGlobalDto,id:string) {
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/alarms/system/${id}`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()
  }
  async deleteGlobalAlarm(id:string) {
    await axios({
      method: "delete",
      url: `${getConstants().SERVER_URL}/alarms/system/${id}`,
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()
  }

  async editGlobalAlarmGroups(dto:{deviceGroupsToAdd:Array<string>,deviceGroupsToRemove:Array<string>},id:string) {
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/alarms/system/${id}/alarmSets`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()
  }

  async createAlarm(dto:AlarmGlobalDto,orgId:string) {
    await axios({
      method: "post",
      url: `${getConstants().SERVER_URL}/alarms/organisations/${orgId}`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleOrganisationDataChanges(orgId)
  }
  async editAlarm(dto:AlarmGlobalDto,id:string,orgId:string) {
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/alarms/organisations/${orgId}/${id}`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleOrganisationDataChanges(orgId)
  }
  async deleteAlarm(id:string,orgId:string) {
    await axios({
      method: "delete",
      url: `${getConstants().SERVER_URL}/alarms/organisations/${orgId}/${id}`,
    });

    await getContainer().resolve<ModDataService>("mod-data").handleOrganisationDataChanges(orgId)
  }

  async editAlarmGroups(dto:{deviceGroupsToAdd:Array<string>,deviceGroupsToRemove:Array<string>},id:string,orgId:string) {
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/alarms/organisations/${orgId}/${id}/alarmSets`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleOrganisationDataChanges(orgId)
  }

}
