import { CoreScaleOptions, Scale, Tick } from "chart.js";
import Decimal from "decimal.js";
import { ScaleConfig } from "../../models/scale-config.model";
import { getGridVisibility } from "../utils/getGridVisibility";
export const TEMPERATURE_DISPLAY_DEFAULT_DECIMAL_POINTS = 1;

//@ts-ignore
export const getAtmosphericPressureScale: IGetScale = (chartState,t) => {

  let displayGrid = getGridVisibility(chartState,"ATMOSPHERIC_PRESSURE");
  let ticksCallback: (
    this: Scale<CoreScaleOptions>,
    tickValue: string | number,
    index: number,
    ticks: Tick[]
  ) => string | number | string[] | number[] | null | undefined = (val) =>
    `${new Decimal(+val).toFixed(1)}${t("ATMOSPHERIC_PRESSURE_UNIT")}`;

  const scaleConfig = chartState.scalesConfig["ATMOSPHERIC_PRESSURE"];

  let min = scaleConfig.auto ? undefined : scaleConfig.min;
  let max = scaleConfig.auto ? undefined : scaleConfig.max;

  let id = `yATMOSPHERIC_PRESSURE`;
  return {
    //@ts-ignore
    type: "linear",
    id,
    //@ts-ignore
    min,
    //@ts-ignore
    max,
    grid: {
      display: displayGrid,
    },
    ticks: {
      callback: ticksCallback,
    },
  };
};

export const getDefaultAtmosphericPressureScaleConfig = ():ScaleConfig=>({
    auto:true,
    min:950,
    max:1050
})