import axios from "axios";
import { CreateDeviceGroupDto, EditDeviceGroupDto } from "shared";
import { injectable, Lifecycle, scoped } from "tsyringe";
import { getConstants } from "../../../config/constants";
import { getContainer } from "../../app-container";
import { ModDataService } from "../mod-data.service";

@injectable()
@scoped(Lifecycle.ContainerScoped)
export class DeviceGroupRequestsService {
  async createGlobalDeviceGroup(dto:CreateDeviceGroupDto) {
    await axios({
      method: "post",
      url: `${getConstants().SERVER_URL}/device-groups/system`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()

  }
  async editGlobalDeviceGroup(dto:EditDeviceGroupDto,id:string) {
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/device-groups/system/${id}`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()

  }
  async deleteGlobalDeviceGroup(id:string) {
    await axios({
      method: "delete",
      url: `${getConstants().SERVER_URL}/device-groups/system/${id}`,
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()

  }
  async createDeviceGroup(dto:CreateDeviceGroupDto,organisationId:string) {
    await axios({
      method: "post",
      url: `${getConstants().SERVER_URL}/device-groups/organisations/${organisationId}`,
      data:dto
    });
    await getContainer().resolve<ModDataService>("mod-data").handleOrganisationDataChanges(organisationId)


  }
  async editDeviceGroup(dto:EditDeviceGroupDto,id:string,organisationId:string) {
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/device-groups/organisations/${organisationId}/${id}`,
      data:dto
    });
    await getContainer().resolve<ModDataService>("mod-data").handleOrganisationDataChanges(organisationId)


  }
  async deleteDeviceGroup(id:string,organisationId:string) {
    await axios({
      method: "delete",
      url: `${getConstants().SERVER_URL}/device-groups/organisations/${organisationId}/${id}`,
    });

    await getContainer().resolve<ModDataService>("mod-data").handleOrganisationDataChanges(organisationId)

  }
}
