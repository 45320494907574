import { createContext, useMemo } from "react";
import { createDispatchHook, createSelectorHook, Provider } from "react-redux";
import { Action, Dispatch } from "redux";
import { getContainer } from "../app-container";
import { UserState } from "./store";
import { UserService } from "./user.service";

const UserContext = createContext({})

export const useUserDispatch: <
AppDispatch extends Dispatch<Action<UserState>> = Dispatch<
  Action<UserState>
>
>() => AppDispatch = createDispatchHook(
//@ts-ignore
UserContext
);

export const useUserSelector: <S>(
    selector: (state: UserState) => S
    //@ts-ignore
  ) => S = createSelectorHook(UserContext);


export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => {
    const store = useMemo(
      () => getContainer().resolve<UserService>("user").getStore(),
      []
    );
  
    return (
      <Provider context={UserContext as any} store={store}>
        <>{children}</>
      </Provider>
    );
  };
  