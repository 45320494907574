import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { isNull } from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslate } from "react-translate";
import {
  EmailConfigDto,
  EmailItemDto,
  IpAddressItemDto,
  IpConfigDto,
  SmsConfigDto,
  SmsItemDto,
} from "shared";
import { container } from "tsyringe";
import ErrorDisplayFieldError from "../../../../../utils/forms/ErrorDisplayFieldError";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { OrganisationRequestsService } from "../../../request-services/organisation-requests.service";
import { SystemRequestsService } from "../../../request-services/system-requests.service";
import { useModPanelData } from "../../hooks/useModPanelData";

const resolver = classValidatorResolver(IpAddressItemDto);

const CreateIPModal: React.FC<{
  show: boolean;
  onClose: () => void;
  organisationId: string | null;
}> = ({ onClose, show, organisationId }) => {
  const t = useTranslate("user");
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm<IpAddressItemDto>({ resolver, mode: "onBlur" });
  const { ipConfigData } = useModPanelData(organisationId);

  const submitRef = useRef<HTMLInputElement>(null);
  const onSave = useCallback(() => {
    if (!submitRef.current) {
      return;
    }
    submitRef.current.click();
  }, [submitRef]);

  useEffect(() => {
    setValue("comment", "");
    setValue("ipAddress", "");
  }, [show]);

  return (
    <DefaultFormModal
      title={t("IP_CONFIG_MODAL_HEADER_CREATE")}
      show={show}
      onClose={onClose}
      onSave={onSave}
      saveDisabled={!isValid}
      submitType="add"
    >
      <>{t("IP_CONFIG_MODAL_PARAGRAPH_CREATE")}</>
      <form
        onSubmit={handleSubmit((data) => {
          const { comment, ipAddress } = data;

          const dto: IpConfigDto = {
            ipRestrictionEnabled: true,
            ipAddressList: [...(ipConfigData?.ipAddressList||[])],
          };

          dto.ipAddressList.push({ ipAddress, comment });

          if (isNull(organisationId)) {
          } else {
            container
              .resolve(OrganisationRequestsService)
              .sendUpdateIPConfig(dto, organisationId)
              .then(() => {
                onClose();
              });
          }
        })}
      >
        <input ref={submitRef} hidden type="submit" />
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <label htmlFor="create_ip_address" className="col-form-label">
                {t("IP_CONFIG_TABLE_IP")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="create_ip_address"
                {...register("ipAddress")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.ipAddress} />
        </div>
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <label htmlFor="create_ip_comment" className="col-form-label">
                {t("IP_CONFIG_TABLE_COMMENT")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="create_ip_comment"
                {...register("comment")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.comment} />
        </div>
      </form>
    </DefaultFormModal>
  );
};

export default CreateIPModal;
