import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import {
  AlarmDto,
  AlarmGlobalDto,
  AllAlarmTypes,
  AllDataTypeAliases,
  DataTypeAlias,
} from "shared";
import ErrorDisplayFieldError from "../../../../../utils/forms/ErrorDisplayFieldError";
import { isNull } from "lodash";
import { container } from "tsyringe";
import { AlarmRequestsService } from "../../../request-services/alarm-requests.service";

const resolver = classValidatorResolver(AlarmDto);
const resolverGlobal = classValidatorResolver(AlarmGlobalDto);

const defaultValues: AlarmDto = {
  name: "",
  dtAlias: "CO2",
  type: "WARNING",
  threshold: 0,
  email:false,
  sms:false,
  exceedBelowThreshold:false
};

const CreateAlarmModal: React.FC<{
  show: boolean;
  onClose: () => void;
  organisationId: string | null;
}> = ({ onClose, show, organisationId }) => {
  const t = useTranslate("mod");
  const tChart = useTranslate("chart");
  const tAlarms = useTranslate("alarms");

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<AlarmGlobalDto>({
    resolver: isNull(organisationId) ? resolverGlobal : resolver,
    mode: "onBlur",
    defaultValues,
  });

  const [selectedDt, setSelectedDt] = useState<DataTypeAlias>(
    defaultValues.dtAlias
  );

  useEffect(() => {
    const sub = watch((value) => {
      setSelectedDt((curr) => value.dtAlias || curr);
    });
    return () => sub.unsubscribe();
  }, [watch, setSelectedDt]);

  const submitRef = useRef<HTMLInputElement>(null);
  const onSave = useCallback(() => {
    if (!submitRef.current) {
      return;
    }
    submitRef.current.click();
  }, [submitRef]);

  return (
    <DefaultFormModal
      title={t("ALARMS_TOOLTIP_NEW")}
      show={show}
      onClose={onClose}
      onSave={onSave}
      submitType="add"
      saveDisabled={!isValid}
    >
      <>{t("CREATE_ALARM_MODAL_PARAGRAPH")}</>
      <form
        onSubmit={handleSubmit((data) => {
          if(isNull(organisationId)){
            /* create global alarm */
            container.resolve(AlarmRequestsService).createGlobalAlarm(data).then(()=>{
              onClose()
            })
          }else{

            container.resolve(AlarmRequestsService).createAlarm(data,organisationId).then(()=>{
              onClose()
            })
          }
        })}
      >
        <input ref={submitRef} hidden type="submit" />
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <label htmlFor="create_alarm_name" className="col-form-label">
                {t("EDIT_ALARM_MODAL_LABEL_NAME")}
              </label>
            </div>{" "}
            <div style={{ width: 220 }}>
              <input
                type="text"
                className="form-control"
                id="create_alarm_name"
                {...register("name")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.name} />
        </div>

        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <label htmlFor="create_alarm_type" className="col-form-label">
                {t("EDIT_ALARM_MODAL_LABEL_TYPE")}
              </label>
            </div>
            <div style={{ width: 220 }}>
              <select
                className="form-select"
                id="create_alarm_type"
                {...register("type")}
              >
                {AllAlarmTypes.map((type) => (
                  <option key={type} value={type}>
                    {tAlarms(`FILTER_ALARM_TYPE_${type}`)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <label htmlFor="create_alarm_dtAlias" className="col-form-label">
                {t("EDIT_ALARM_MODAL_LABEL_DTALIAS")}
              </label>
            </div>
            <div style={{ width: 220 }}>
              <select
                className="form-select"
                id="create_alarm_dtAlias"
                {...register("dtAlias")}
              >
                {AllDataTypeAliases.map((dta) => (
                  <option key={dta} value={dta}>
                    {tChart(`${dta}_LABEL`)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <label
                htmlFor="create_alarm_threshold"
                className="col-form-label"
              >
                {t("EDIT_ALARM_MODAL_LABEL_THRESHOLD")}
              </label>
            </div>
            <div style={{ display: "flex", alignItems: "center", width: 220 }}>
              <div>
                <input
                  type="number"
                  className="form-control"
                  id="create_alarm_threshold"
                  {...register("threshold", { valueAsNumber: true })}
                />
              </div>
              <div className="ms-1">{tChart(`${selectedDt}_UNIT`)}</div>
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.threshold} />
        </div>

        <div className="mb-0 mt-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="create-alarm-email"
                  {...register("email")}
                />
                <label className="form-check-label" htmlFor="create-alarm-email">
                  {t("EDIT_ALARM_MODAL_LABEL_EMAIL")}
                </label>
              </div>
            </div>
            <div className="mb-0 mt-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="create-alarm-sms"
                  {...register("sms")}
                />
                <label className="form-check-label" htmlFor="create-alarm-sms">
                  {t("EDIT_ALARM_MODAL_LABEL_SMS")}
                </label>
              </div>
            </div>
            <div className="mb-0 mt-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="create-alarm-ebt"
                  {...register("exceedBelowThreshold")}
                />
                <label className="form-check-label" htmlFor="create-alarm-ebt">
                  {t("EDIT_ALARM_MODAL_LABEL_EXCEED_BELOW_THRESHOLD")}
                </label>
              </div>
            </div>
        {!isNull(organisationId) ? (
          ""
        ) : (
          <>
            <div className="mb-3 mt-2">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="create-alarm-hidden"
                  {...register("hidden")}
                />
                <label
                  className="form-check-label"
                  htmlFor="create-alarm-hidden"
                >
                  {t("EDIT_ALARM_MODAL_LABEL_HIDDEN")}
                </label>
              </div>
            </div>
          </>
        )}
      </form>
    </DefaultFormModal>
  );
};

export default CreateAlarmModal;
