import { DateTime } from "luxon";
import { useEffect, useMemo } from "react";
import { createStore, Store } from "redux";
import { getContainer } from "../../app-container";
import { useSaveStateInSearchParams } from "../../charts/components/useSaveStateInSearchParams";
import { useDataProviderSelector } from "../../data-provider/data-provider.provider";
import { PdfGeneratorService } from "../pdf-generator.service";
import { PdfGeneratorReducer, PdfGeneratorState } from "../store";

const { endTimestamp_s, startTimestamp_s } = getContainer()
  .resolve<PdfGeneratorService>("pdf-generator")
  .getDefaultReportsTimeScaleState();

const defaultValue: PdfGeneratorState = {
  requestedDataTypes: ["CO_LEVEL", "CO2_LEVEL"],
  requestedAlarms: {},
  downloadable: true,
  requestedDevice: undefined,
  timeScaleState: {
    startTimestamp_s,
    endTimestamp_s,
  },
};

export const useCreatePdfGeneratorStore = () => {
  const devices = useDataProviderSelector((state) => state.devices);
  const dataLoaded = useDataProviderSelector((state) => state.dataLoaded);

  const { handleValueChange, initialValue, valueIndex } =
    useSaveStateInSearchParams(
      {
        ...defaultValue,
        requestedDevice: devices[0]?.device._id,
      } as PdfGeneratorState,
      "RREQ",
      dataLoaded
    );


  const store = useMemo<Store>(() => {
    const reducer = new PdfGeneratorReducer(initialValue);
    //@ts-ignore
    return createStore(reducer.changeState.bind(reducer));
  }, [valueIndex]);

  useEffect(() => {
    if (!store) {
      return;
    }

    const unsubscribe = store.subscribe(() => {
      const state = store.getState() as PdfGeneratorState;
      handleValueChange(state);
    });

    return () => {
      unsubscribe();
    };
  }, [handleValueChange, store]);

  return store;
};
