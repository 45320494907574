import {
  createContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createDispatchHook, createSelectorHook, Provider } from "react-redux";
import { useTranslate } from "react-translate";
import { Action, createStore, Dispatch, Store } from "redux";
import { PdfGeneratorState } from "../store";
import PdfGeneratorStandard from "./PdfGeneratorStandard";
import { useCreatePdfGeneratorStore } from "./useCreatePdfGeneratorStore";
import { Tab } from "bootstrap";
import DataExportView from "./DataExportView";
import DataExporterProvider, {
  useCreateDataExporterStore,
} from "../../charts/submodules/data-exporter/data-exporter.provider";
import { useSaveStateInSearchParams } from "../../charts/components/useSaveStateInSearchParams";
import {
  DataExporterReducer,
  DataExporterState,
} from "../../charts/submodules/data-exporter/store";

export const PdfGeneratorContext = createContext({});

type PdfTab = "pdfTabStandard" | "pdfTabExport";

export const usePdfGeneratorDispatch: <
  AppDispatch extends Dispatch<Action<PdfGeneratorState>> = Dispatch<
    Action<PdfGeneratorState>
  >
>() => AppDispatch = createDispatchHook(
  //@ts-ignore
  PdfGeneratorContext
);
export const usePdfGeneratorSelector: <S>(
  selector: (state: PdfGeneratorState) => S
  //@ts-ignore
) => S = createSelectorHook(PdfGeneratorContext);

const PdfGenerator: React.FC = () => {
  const store = useCreatePdfGeneratorStore();
  const t = useTranslate("pdf-generator");

  const {
    valueIndex: deValueIndex,
    initialValue: deInitialValue,
    handleValueChange: deHandleValueChange,
  } = useSaveStateInSearchParams(DataExporterReducer.initialState, "DEXV");

  const dataExporterStore = useMemo(() => {
    const reducer = new DataExporterReducer(deInitialValue);

    return createStore(
      //@ts-ignore
      reducer.changeState.bind(reducer)
    ) as Store<DataExporterState>;
  }, [deValueIndex]);

  useEffect(() => {
    if (!dataExporterStore) {
      return;
    }

    const unsubscribe = dataExporterStore.subscribe(() => {
      const state = dataExporterStore.getState() as DataExporterState;
      deHandleValueChange(state);
    });

    return () => {
      unsubscribe();
    };
  }, [deHandleValueChange, dataExporterStore]);

  const primaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-primary-rgb");
  const primaryColorString = `rgb(${primaryColor})`;

  const {
    initialValue: initialTab,
    handleValueChange: handleTabChangeInSP,
    valueIndex: tabValueIndex,
  } = useSaveStateInSearchParams<PdfTab>("pdfTabStandard", "TAB");

  const [activeTab, setActiveTab] = useState<PdfTab>(initialTab);
  const tabRef = useRef<HTMLUListElement>(null);

  useLayoutEffect(() => {
    if (!tabRef.current) {
      return;
    }

    const handleTabChange = (e: Tab.Events.show) => {
      //@ts-ignore
      const targetId = e.target.id as string;
      const tabName = targetId.split("-")[0] as PdfTab;
      setActiveTab(tabName);
      handleTabChangeInSP(tabName);
    };

    const tabEl = tabRef.current;
    //@ts-ignore
    tabEl.addEventListener("show.bs.tab", handleTabChange);
    return () => {
      //@ts-ignore
      tabEl.removeEventListener("show.bs.tab", handleTabChange);
    };
  }, [tabRef, setActiveTab, handleTabChangeInSP]);

  return (
    <div
      className="px-xl-5 px-2"
      style={{ maxWidth: 900, margin: "50px auto" }}
    >
      <div className="mb-5 text-center text-lg-start">
        <h1>{t("PDF_GENERATOR_HEADER")}</h1>
        <h5 className="ms-1">{t("PDF_GENERATOR_PARAGRAPH")}</h5>
      </div>

      <ul
        style={{ fontSize: "1.1rem" }}
        className="nav nav-tabs"
        role="tablist"
        ref={tabRef}
      >
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link bg-light ${
              initialTab === "pdfTabStandard" ? "active" : ""
            }`}
            aria-current="page"
            id="pdfTabStandard-tab"
            data-bs-toggle="tab"
            data-bs-target="#pdfTabStandard"
            type="button"
            role="tab"
            aria-controls="pdfTabStandard"
            aria-selected="true"
            style={{
              borderWidth: 3,
            }}
          >
            {t("TAB_STANDARD")}
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link bg-light ${
              initialTab === "pdfTabExport" ? "active" : ""
            }`}
            aria-current="page"
            id="pdfTabExport-tab"
            data-bs-toggle="tab"
            data-bs-target="#pdfTabExport"
            type="button"
            role="tab"
            aria-controls="pdfTabExport"
            aria-selected="false"
            style={{
              borderWidth: 3,
            }}
          >
            {t("TAB_EXPORT")}
          </button>
        </li>
      </ul>

      <div className="d-flex justify-content-center justify-content-lg-start ps-0 ps-lg-4">
        <div
          style={{ maxWidth: 500, flexGrow: 1 }}
          className="ms-lg-5  mt-4 ms-1"
        >
          <div className="tab-content">
            <div
              className={`tab-pane fade ${
                initialTab === "pdfTabStandard" ? "show active" : ""
              }`}
              id="pdfTabStandard"
            >
              <Provider context={PdfGeneratorContext as any} store={store}>
                <PdfGeneratorStandard />
              </Provider>
            </div>
            <div
              className={`tab-pane fade ${
                initialTab === "pdfTabExport" ? "show active" : ""
              }`}
              id="pdfTabExport"
            >
              <DataExporterProvider store={dataExporterStore}>
                <DataExportView />
              </DataExporterProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfGenerator;
