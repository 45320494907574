import { AuthState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";

type Payload={
    loggedIn: boolean;
    accessToken?:string;
    rememberMe?:boolean;
}

class SetAuthDataClass extends ReducerActionClass<AuthState,Payload>{
    getActionName(): string {
        return  "SET_AUTH_DATA"
    }
    handleDispatch(prevState: AuthState, payload: Payload): AuthState {

        console.log("Token debug: SetAuthData",prevState)

        return{
            ...prevState,
            ...payload
        }
    }
}

export const SetAuthData = new SetAuthDataClass()