import AnimationsSwitch from "./AnimationsSwitch";
import ChartControllsContent from "./ChartControllsContent";

const ChartControllsMobile: React.FC = ()=>{


    return <div style={{position:"relative"}}>
        {/* <div style={{color:"white"}}>
            <div className="ps-3 pt-3">
        <AnimationsSwitch/>
        </div>
        <hr/>
        </div> */}
        <div style={{position:"relative"}}>
        <ChartControllsContent/>
        </div>
    </div>;
}

export default ChartControllsMobile;