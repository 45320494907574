const TabContainer: React.FC<{ children: React.ReactNode }> = (props) => {
  const { children } = props;

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="mt-5 m-2" style={{ maxWidth: 700, flexGrow: 1 }}>
        {children}
      </div>
    </div>
  );
};

export default TabContainer;
