import { useTranslate } from "react-translate";
import { ReactComponent as PasswordIcon } from "bootstrap-icons/icons/key-fill.svg";
import { ReactComponent as EditIcon } from "bootstrap-icons/icons/pencil-fill.svg";
import { ReactComponent as DeleteIcon } from "bootstrap-icons/icons/trash-fill.svg";
import { ReactComponent as AddIcon } from "bootstrap-icons/icons/plus-square-fill.svg";
import { Tooltip } from "bootstrap";
import { useLayoutEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import ResetPasswordModal from "../../modals/UsersTab/ResetPasswordModal";
import EditOrganisationModal from "../../modals/OrganisationTab/EditOrganisationModal";
import CreateOrganisationModal from "../../modals/OrganisationTab/CreateOrganisationModal";
import DeleteOrganisationModal from "../../modals/OrganisationTab/DeleteOrganisationModal";
import { useModDataSelector } from "../../../mod-data.provider";

export const exampleOrganisations = [{
  _id:"0",
  name:"Organizacja1",
  owner:"Alex",
},{
  _id:"1",
  name:"Organizacja1",
  owner:"Boromir",
},{
  _id:"2",
  name:"Organizacja3",
  owner:"Czesław",
},{
  _id:"3",
  name:"Organizacja4",
  owner:"Daria",
}]


const OrganisationsTab: React.FC = () => {
  const t = useTranslate("mod");

  const organisations = useModDataSelector(state=>state.system?.organisations)


  const primaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-primary-rgb");

  const dangerColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-danger-rgb");




  const columns = useMemo(
    () => [
      {
        Header: t("ORGANISATION_COLUMN_NAME"),
        accessor: "name",
      },
      {
        Header: t("ORGANISATION_COLUMN_OWNER_NAME"),
        accessor: "owner",
      },
      {
        Header: t("ORGANISATION_COLUMN_OWNER_LOGIN"),
        accessor: "login",
      },
      {
        Header: t("ORGANISATION_COLUMN_DEVICE_NUMBER"),
        accessor: "device_number",
      },
    ],
    [t]
  );

  const data = useMemo(
    () =>
    (organisations||[]).map(org=>({
      _id:org.organisation._id,
      userId:org.owner._id,
      name:org.organisation.name,
      owner:org.owner.name,
      login:org.owner.username,
      device_number:org.organisation.device_number,
      })),
    [organisations]
  );

  useLayoutEffect(() => {
    const tooltipElements = Array.from(
      document.querySelectorAll(".OrganisationTabTooltip")
    );
    const tooltips = tooltipElements.map((el) => new Tooltip(el));
    return () => tooltips.forEach((tooltip) => tooltip.dispose());
  }, [data]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      //@ts-ignore
      columns,
      data,
    });

    const [editingOrganisation,setEditingOrganisation] = useState<string|null>(null)
    const [deletingOrganisation,setDeletingOrganisation] = useState<string|null>(null)
    const [creatingOrganisation,setCreatingOrganisation] = useState<boolean>(false)
    const [resetingPassword,setResetingPassword] = useState<{userId:string,orgId:string}|null>(null)


  return (
    <>
      <h4>{t("TAB_organisations")}</h4>
      <p>{t("ORGANISATION_PARAGRAPH")}</p>
      <div style={{ maxWidth: 1100 }}>
        <table {...getTableProps()} className="table table-striped mb-2">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
                <th />
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                  <td className="ps-4 pe-0" style={{ width: "1%" }}>
                    <div className="d-flex justify-content-end">
                      <div className="me-3">
                        <div
                          className="OrganisationTabTooltip"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={t("ORGANISATION_TOOLTIP_EDIT")}
                        >
                          <a
                            className="text-decoration-none text-reset"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setEditingOrganisation(row.original._id)
                            }}
                          >
                            <EditIcon style={{ cursor: "pointer" }} />
                          </a>
                        </div>
                      </div>
                      <div className="me-3">
                        <div
                          className="OrganisationTabTooltip"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={t("ORGANISATION_TOOLTIP_RESET_OWNER_PASSWORD")}
                        >
                          <a
                            className="text-decoration-none text-reset"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setResetingPassword({userId:row.original.userId,orgId:row.original._id})
                            }}
                          >
                            <PasswordIcon style={{ cursor: "pointer" }} />
                          </a>
                        </div>
                      </div>
                      <div className="me-4">
                        <div
                          className="OrganisationTabTooltip"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={t("ORGANISATION_TOOLTIP_DELETE")}
                        >
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setDeletingOrganisation(row.original._id)
                            }}
                            // data-bs-toggle="modal"
                            // data-bs-target="#configItemSms"
                          >
                            <DeleteIcon
                              fill={`rgb(${dangerColor})`}
                              style={{ cursor: "pointer" }}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="d-flex justify-content-end">
          <div className="pe-4" style={{ marginRight: -2 }}>
            <div
              className="OrganisationTabTooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={t("ORGANISATION_TOOLTIP_CREATE")}
            >
              <a
                href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setCreatingOrganisation(true)
                  }}
              >
                <AddIcon
                  style={{ cursor: "pointer" }}
                  fill={`rgb(${primaryColor})`}
                  height="20"
                  width="20"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <ResetPasswordModal systemRequest={true} userId={resetingPassword?.userId||null} organisationId={resetingPassword?.orgId||""} onClose={()=>setResetingPassword(null)} />
      <EditOrganisationModal organisationId={editingOrganisation} onClose={()=>setEditingOrganisation(null)}/>
      <CreateOrganisationModal show={creatingOrganisation} onClose={()=>setCreatingOrganisation(false)}/>
      <DeleteOrganisationModal organisationId={deletingOrganisation} onClose={()=>setDeletingOrganisation(null)}/>
    </>
  );
};

export default OrganisationsTab;
