import { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { AllUserOrganisationRoles, CreateUserDto, ResetPasswordDto } from "shared";
import ErrorDisplayFieldError from "../../../../../utils/forms/ErrorDisplayFieldError";
import {generate as generatePassword} from "generate-password-browser";
import { container } from "tsyringe";
import { UserRequestsService } from "../../../request-services/user-requests.service";

const resolver = classValidatorResolver(CreateUserDto);

const CreateUserModal: React.FC<{
  show: boolean;
  onClose: () => void;

  organisationId:string
}> = ({ onClose, show,organisationId }) => {
  const t = useTranslate("mod");
  const tUser = useTranslate("user")

  const {
    register,
    handleSubmit,
    formState: { errors,isValid },
    setValue
  } = useForm<CreateUserDto>({ resolver, mode: "onBlur" });

  const submitRef = useRef<HTMLInputElement>(null);
  const onSave = useCallback(() => {
    if (!submitRef.current) {
      return;
    }
    submitRef.current.click();
  }, [submitRef]);

  useEffect(()=>{
    setValue("password",generatePassword({length:12,numbers:true,symbols:true,strict:true}))
  },[show])

  return (
    <DefaultFormModal
      title={t("USERS_TOOLTIP_ADD_USER")}
      show={show}
      onClose={onClose}
      onSave={onSave}
      submitType="add"
      saveDisabled={!isValid}
    >
      <>{t("CREATE_USER_MODAL_PARAGRAPH")}</>
      <form
        onSubmit={handleSubmit((data) => {
          container.resolve(UserRequestsService).create(data,organisationId).then(()=>[
            onClose()
          ])
        })}
      >
        <input ref={submitRef} hidden type="submit" />
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <label htmlFor="user_name" className="col-form-label">
                {t("CREATE_USER_MODAL_LABEL_NAME")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="user_name"
                {...register("name")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.name} />
        </div>
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <label htmlFor="user_password" className="col-form-label">
                {t("CREATE_USER_MODAL_LABEL_PASSWORD")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="user_password"
                {...register("password")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.password} />
        </div>
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <label htmlFor="user_role" className="col-form-label">
                {t("CREATE_USER_MODAL_LABEL_ROLE")}
              </label>
            </div>
            <div>
              <select
                className="form-select"
                id="user_role"
                {...register("organisationRole")}
              >
                {AllUserOrganisationRoles.filter(role=>role!=="OWNER").map(role=>(
                  <option key={role} value={role}>{tUser(`USER_ROLE_${role}`)}</option>
                ))}
                </select>
            </div>
          </div>
        </div>
      </form>
    </DefaultFormModal>
  );
};

export default CreateUserModal;
