import { useMemo } from "react";
import { SwitchTransition, Transition } from "react-transition-group";
import { ALARMS_CONFIG } from "../config/config";
import AlarmItem from "./AlarmItem";
import AlarmItemsContainer from "./AlarmItemsContainer";
import AlarmsFilter from "./AlarmsFilter";
import AlarmsPagination from "./AlarmsPagination";
import { useAlarmsSelector } from "./AlarmsView";

const ANIMATION_DURATION_MS = 150;

const AlarmsList: React.FC = () => {
  // const alarmExceeds = useMemo(()=> new AlarmsService().getAlarmExceeds(),[])
  const page = useAlarmsSelector((state) => state.page);
  const requestedAlarmsExceeds = useAlarmsSelector(
    (state) => state.requestedAlarmExceeds
  );
  const alarmExceeds = useMemo(
    () =>
      requestedAlarmsExceeds.slice(
        page * ALARMS_CONFIG.itemsOnPage,
        (page + 1) * ALARMS_CONFIG.itemsOnPage
      ),
    [page, requestedAlarmsExceeds]
  );

  const defaultAnimatedStyle = {
    transition: `opacity ${ANIMATION_DURATION_MS}ms linear`,
    opacity: 0,
    height: "100%",
    width: "100%",
  };
  const transitionAnimatedStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: { opacity: 0 },
  };

  return (
    <div
      className="flex-md-row flex-column"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "stretch",
        flexWrap: "wrap",
      }}
    >
      <div
        className="order-1 order-md-0  mx-3"
        style={{ flexGrow: 1, position: "relative" }}
      >
        <SwitchTransition>
          <Transition
            key={alarmExceeds.reduce((k, curr) => `${k} ${curr.id}`, "")}
            timeout={{ enter: 1, exit: ANIMATION_DURATION_MS }}
          >
            {(state) => (
              <div
                style={{
                  ...defaultAnimatedStyle,
                  ...transitionAnimatedStyles[state],
                }}
              >
                <AlarmItemsContainer>
                  {alarmExceeds.map((alarmExceed) => (
                    <div className="mb-3" key={alarmExceed.id}>
                      <AlarmItem alarmExceed={alarmExceed} />
                    </div>
                  ))}
                  <div
                    className="d-md-none d-flex"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <div>
                      <AlarmsPagination />
                    </div>
                  </div>
                </AlarmItemsContainer>
              </div>
            )}
          </Transition>
        </SwitchTransition>
      </div>
      <div
        className="order-0 order-md-1"
        style={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "space-between",
          }}
        >
          <div>
            <AlarmsFilter />
          </div>
          <div className="d-md-block d-none">
            <AlarmsPagination />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlarmsList;
