import { useMemo } from "react";
import { useUserSelector } from "../../user/user.provider";

export const useCanAccessAdminPanel = () => {
  const user = useUserSelector((state) => state.user);
  const dataLoaded = useMemo(() => !!user, [user]);
  const access = useMemo(
    () => !!user?.roles.find((role) => role.role === "ADMIN"),
    [user]
  );
  return {dataLoaded,access}
};
