import { useCallback, useMemo, useRef } from "react";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { isNull } from "lodash";
import { container } from "tsyringe";
import { DeviceGroupRequestsService } from "../../../request-services/device-group-requests.service";
import { useModPanelData } from "../../hooks/useModPanelData";
import { EmailConfigDto, IpConfigDto, SmsConfigDto } from "shared";
import { OrganisationRequestsService } from "../../../request-services/organisation-requests.service";
import { SystemRequestsService } from "../../../request-services/system-requests.service";
import { NotificationsRequestsService } from "../../../request-services/notifications-requests.service";

const DeleteNotificationModal: React.FC<{
  onClose: () => void;
  notificationId:string|null
}> = ({ onClose, notificationId }) => {
  const t = useTranslate("mod");
  const { notifications } = useModPanelData(null);

  const notification = useMemo(()=>notifications.find(not=>not._id===notificationId),[notificationId,notifications])
  const onSave = useCallback(() => {
      container.resolve(NotificationsRequestsService).deleteNotification(notificationId!).then(()=>{
        onClose()
      });
  }, [notificationId]);

  return (
    <DefaultFormModal
      title={t("NOTIFICATIONS_TOOLTIP_DELETE")}
      show={!isNull(notificationId)}
      onClose={onClose}
      onSave={onSave}
      submitType="delete"
    >
      <>
        {t("DELETE_NOTIFICATION_MODAL_PARAGRAPH",{title:notification?.title||""})}
      </>
    </DefaultFormModal>
  );
};

export default DeleteNotificationModal;
