import { DependencyContainer, container, Lifecycle } from "tsyringe";
import { initServices } from "../../utils/depedency-injection/initServices";
import { ChartToImageService } from "./submodules/chart-to-image/chart-to-image.service";
import { JspdfCreatorService } from "./submodules/jspdf-creator/jspdf-creator.service";
import { PdfItemsGeneratorService } from "./submodules/pdf-items-generator/pdf-items-generator.service";

export const createPdfGeneratorContainer = (
  parentContainer: DependencyContainer = container
): DependencyContainer => {
  const childContainer = parentContainer.createChildContainer();
  childContainer.register(
    "chart-to-image",
    { useClass: ChartToImageService },
    { lifecycle: Lifecycle.ContainerScoped }
  ); /* will be moved to chart */

  childContainer.register(
    "pdf-creator",
    { useClass: JspdfCreatorService },
    { lifecycle: Lifecycle.ContainerScoped }
  );
  childContainer.register(
    "pdf-items-generator",
    { useClass: PdfItemsGeneratorService },
    { lifecycle: Lifecycle.ContainerScoped }
  );

  initServices(childContainer, [
    "chart-to-image",
    "pdf-creator",
    "pdf-items-generator",
  ]);

  return childContainer;
};
