import { InjectionToken, DependencyContainer } from "tsyringe";

export const initServices = (
  container: DependencyContainer,
  injectionTokens: Array<InjectionToken<object>>
) => {
  injectionTokens.forEach((serviceTag) => {
    const service = container.resolve(serviceTag) as object;
    if ("onInit" in service) {
      //@ts-ignore
      service.onInit();
    }
  });
};