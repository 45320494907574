import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslate } from "react-translate";
import { NotificationBlock } from "shared";
import { container } from "tsyringe";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { NotificationsRequestsService } from "../../../request-services/notifications-requests.service";
import { useModPanelData } from "../../hooks/useModPanelData";
import { useEditor } from "./useEditor";


const EditNotificationModal: React.FC<{
  onClose: () => void;
  notificationId:string|null
}> = ({ onClose, notificationId }) => {
  const t = useTranslate("mod");
  const { organisations,notifications } = useModPanelData(null);
  const notification = useMemo(()=>notifications.find(not=>not._id===notificationId),[notificationId,notifications])
  const blocks = useMemo(()=>notification?.blocks||[],[notification])
  const editorContainerRef = useRef<HTMLDivElement>(null);
  const { editor } = useEditor(editorContainerRef, blocks);

  const [showAllOrganisations, setShowAllOrganisations] =
    useState<boolean>(true);
  const [selectedOrganisations, setSelectedOrganisations] = useState<
    Array<string>
  >([]);

  useEffect(()=>{
      if(!notification){
        return
      }
      setShowAllOrganisations(notification.showAllOrganisations)
      setSelectedOrganisations(notification.organisations)
  },[notification])

  const onSave = useCallback(() => {
    editor?.save().then(({ blocks }) => {
      container.resolve(NotificationsRequestsService).editNotification({
        organisations: selectedOrganisations,
        showAllOrganisations,
        //@ts-ignore
        blocks,
      },notificationId).then(()=>{
        onClose()
      });;
    });
  }, [editor, showAllOrganisations, selectedOrganisations,notificationId]);

  return (
    <DefaultFormModal
      title={t("NOTIFICATIONS_TOOLTIP_EDIT")}
      show={!!notificationId}
      onClose={onClose}
      onSave={onSave}
      submitType="edit"
    >
      <>{t("EDIT_NOTIFICATION_MODAL_PARAGRAPH",{title:notification?.title||""})}</>

      <div className="mb-3 mt-4">
        <>
          <label className="col-sm-2 col-form-label">
            {t("NOTIFICATIONS_MODAL_LABEL_ORGANISATIONS")}
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="edit-notifications-org-all"
              checked={showAllOrganisations}
              onClick={() => {
                setShowAllOrganisations((curr) => !curr);
              }}
            />
            <label
              className="form-check-label"
              htmlFor="edit-notifications-org-all"
            >
              {t("NOTIFICATIONS_ORGANISATIONS_ALL")}
            </label>
          </div>
          {organisations.map((org) => {
            const id = org.organisation._id;
            return (
              <div key={id} className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id={`edit-notifications-org-${id}`}
                  checked={selectedOrganisations.includes(id)}
                  onClick={() => {
                    setSelectedOrganisations((curr) => {
                      if (curr.includes(id)) {
                        return curr.filter((item) => item !== id);
                      } else {
                        return [...curr, id];
                      }
                    });
                  }}
                  disabled={showAllOrganisations}
                />
                <label
                  className="form-check-label"
                  htmlFor={`edit-notifications-org-${id}`}
                >
                  {org.organisation.name}
                </label>
              </div>
            );
          })}
        </>
        <hr/>
        <div ref={editorContainerRef} />
      </div>
    </DefaultFormModal>
  );
};

export default EditNotificationModal;
