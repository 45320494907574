import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslate } from "react-translate";
import {MissingMeasuresAlertsConfigDto} from "shared"
import { container } from "tsyringe";
import ErrorDisplayFieldError from "../../../../../utils/forms/ErrorDisplayFieldError";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { SystemRequestsService } from "../../../request-services/system-requests.service";
import { useModPanelData } from "../../hooks/useModPanelData";

const resolver = classValidatorResolver(MissingMeasuresAlertsConfigDto)

const ChangeMissingMeasuresAlertConfig:React.FC<{
    show:boolean,
    onClose:()=>void
}>=({onClose,show})=>{
    const t = useTranslate("mod");
    const {missingMeasuresAlertConfig} = useModPanelData(null);

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        trigger,
        watch
      } = useForm<MissingMeasuresAlertsConfigDto>({ resolver, mode: "onBlur",defaultValues:{enabled:false,alertTimeThreshold_s:30*60} });
    
      const submitRef = useRef<HTMLInputElement>(null);

    const [enabled,setEnabled] = useState<boolean>(false)
      
    useEffect(()=>{
        const subscription = watch((formState)=>{
            setEnabled(!!formState.enabled)
        })

        return ()=>subscription.unsubscribe()
    },[watch])

    useEffect(()=>{
        if(!missingMeasuresAlertConfig){
            return
        }
        setValue("alertTimeThreshold_s",missingMeasuresAlertConfig.alertTimeThreshold_s)
        setValue("enabled",missingMeasuresAlertConfig.enabled)
        trigger();
    },[show])

    const onSave = useCallback(() => {
        if (!submitRef.current) {
          return;
        }
        submitRef.current.click();
      }, [submitRef]);

    return (
        <DefaultFormModal
          title={t("SYSTEM_MISSING_MEASURES_EDIT")}
          show={show}
          onClose={onClose}
          onSave={onSave}
          saveDisabled={!isValid}
        >
          <>{t("CHANGE_MMAC_MODAL_PARAGRAPH")}</>
          <form
            onSubmit={handleSubmit((data) => {
              container.resolve(SystemRequestsService).sendUpdateMissingMeasuresAlertsConfig(data).then(()=>{
                onClose()
              })
            })}
          >
            <input ref={submitRef} hidden type="submit" />

            <div className="mb-0 mt-4">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="change-mmac-enabled"
                  {...register("enabled")}
                />
                <label className="form-check-label" htmlFor="change-mmac-enabled">
                  {t("SYSTEM_MISSING_MEASURES_ENABLED")}
                </label>
              </div>
            </div>

            <div className="mb-3 mt-4">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <label htmlFor="change-mmac-threshold" className="col-form-label">
                    {t("SYSTEM_MISSING_MEASURES_THRESHOLD")}
                  </label>
                </div>
                <div className="d-flex" style={{alignItems:"center"}}>
                    <div>
                  <input
                    type="text"
                    disabled={!enabled}
                    className="form-control"
                    id="change-mmac-threshold"
                    {...register("alertTimeThreshold_s",{valueAsNumber:true})}
                  /> </div><div className="ms-1">s</div>
                </div>
              </div>
              <ErrorDisplayFieldError error={errors.alertTimeThreshold_s} />
            </div>
          </form>
        </DefaultFormModal>
      );

}

export default ChangeMissingMeasuresAlertConfig;