import { ChartData } from "../models/chart-data";
import { ChartRequests } from "../models/chart-requests.model";

import { ReactComponent as DownloadIcon } from "bootstrap-icons/icons/download.svg";
import { ReactComponent as SquareIcon } from "bootstrap-icons/icons/square-fill.svg";
import { useTranslate } from "react-translate";
import { Tooltip } from "bootstrap";
import { useCallback, useLayoutEffect, useMemo } from "react";
import { useMainChartSelector } from "../hooks/chart.context";
import { getContainer } from "../../app-container";
import { ChartsService } from "../charts.service";
import { PdfGeneratorService } from "../../pdf-generator/pdf-generator.service";

const DownloadChartButton: React.FC = () => {
  const PRIMARY_COLOR = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-primary-rgb");
  const primaryColorString = `rgb(${PRIMARY_COLOR})`;

  const t = useTranslate("chart");

  const chartState = useMainChartSelector((state) => state);

  const chartRequests = useMemo<ChartRequests>(
    () => ({
      measuresGrouping: chartState.measuresGrouping,
      requestedAlarms: chartState.requestedAlarms,
      requestedDataTypes: chartState.requestedDataTypes,
      requestedDevices: chartState.requestedDevices,
      timeScaleState: chartState.timeScaleState,
    }),
    [chartState]
  );

  const chartData = useMemo<ChartData>(
    () => ({
      alarmsDatasetsSetup: chartState.alarmsDatasetsSetup,
      dataTypesIncluded: chartState.dataTypesIncluded,
      measuresDatasetsSetup: chartState.measuresDatasetsSetup,
    }),
    [chartState]
  );

  const downloadChart = useCallback(() => {
    const chart2ImageService = getContainer()
      .resolve<PdfGeneratorService>("pdf-generator")
      .getChartToImageService();
    chart2ImageService.downloadChartImage(
      chartData,
      chartRequests,
      {
        showLegend: true,
      },
      { height: 720, width: 1280 }
    );
  }, [chartData, chartRequests]);

  useLayoutEffect(() => {
    const tooltipElements = Array.from(
      document.querySelectorAll(".downloadChartTooltip")
    );
    const tooltips = tooltipElements.map((el) => new Tooltip(el));
    return () => tooltips.forEach((tooltip) => tooltip.dispose());
  }, []);

  return (
    <div
      className="ms-2 downloadChartTooltip"
      data-bs-toggle="tooltip"
      data-bs-placement="top"
      title={t("DOWNLOAD_CHART")}
      style={{
        alignSelf: "center",
        cursor: "pointer",
        justifySelf: "flex-end",
        position: "relative",
      }}
    >
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          downloadChart();
        }}
      >
        <div style={{ position: "absolute", zIndex: 5, top: 6, left: 6 }}>
          <DownloadIcon className="text-light" width="28" height="28" />
        </div>
        <SquareIcon className="text-primary" width="40" height="40" />
      </a>
    </div>
  );
};

export default DownloadChartButton;
