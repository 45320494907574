import { AlertService } from "../../../modules/alerts/alert.service";
import { getContainer } from "../../../modules/app-container";
import translations from "../../../translations/translations";
import { NetworkErrorHandler } from "../error-handler.interface";

const unknownError: NetworkErrorHandler = () => {
  const alertService = getContainer().resolve<AlertService>("alert");
  alertService.pushMessage({
    theme: "danger",
    message: translations.PL.alert.UNKNOWN_ERROR,
  });
  return true;
};

export default unknownError;
