import clamp from "clamp-js";
import { DateTime } from "luxon";
import { useEffect, useMemo, useRef } from "react";
import { useTranslate } from "react-translate";
import { Notification } from "shared";
import { getContainer } from "../../../app-container";
import { UserService } from "../../user.service";

const NotificationCard: React.FC<{
  notification: Notification;
  onExpand: () => void;
}> = ({ notification, onExpand }) => {
  const { unseenNotifications } = useMemo(
    () => getContainer().resolve<UserService>("user").getUnseenNotifications(),
    []
  );
  const unseenNotificationsIds = useMemo(
    () => unseenNotifications.map((notif) => notif._id),
    [unseenNotifications]
  );

  const previewText =
    notification.blocks.find((block) => block.type === "paragraph")?.data
      .text || "";

  const paragraphRef = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    if (!paragraphRef.current) {
      return;
    }
    clamp(paragraphRef.current, { clamp: 2 });
  }, [paragraphRef]);

  const t = useTranslate("user");

  return (
    <div className="card border-white mb-3">
      <div className="card-body">
        <div className="d-flex">
        <h5 className="card-title justify-content-baseline">
          {notification.title}
          
        </h5>
        {unseenNotificationsIds.includes(notification._id) ? (
            <div className="ms-2"><span className="badge rounded-pill bg-info">{t("NOTIFICATION_NEW")}</span></div>
          ) : (
            ""
          )}
        </div>
        <h6 className="card-subtitle mb-2 text-muted">
          {DateTime.fromJSDate(notification.createdAt).toLocaleString(
            DateTime.DATETIME_SHORT
          )}
        </h6>
        <p
          ref={paragraphRef}
          className="card-text"
          dangerouslySetInnerHTML={{ __html: previewText }}
        />
        <div className="d-flex justify-content-end">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onExpand();
            }}
            className="card-link"
          >
            {t("NOTIFICATION_OPEN")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
