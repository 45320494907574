import { ChartState } from "..";
import { getConstants } from "../../../../config/constants";
import { getCorrectGroupingsForTimeRange } from "../../chart-generating/utils/getCorrectGroupingsForTimeRange";

export const checkMeasuresGroupingCorrectProcedure = (newState: ChartState): void => {
  const { timeScaleState } = newState;
  const correctGroupings = getCorrectGroupingsForTimeRange(timeScaleState);

  if (newState.measuresGrouping === "NONE") {
    if (!correctGroupings.includes("NONE")) {
      newState.measuresGrouping = "HOUR";
    }
  }
  if (newState.measuresGrouping === "HOUR") {
    if (!correctGroupings.includes("HOUR")) {
      newState.measuresGrouping = "DAILY";
    }
  }
};
