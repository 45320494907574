import { DateTime } from "luxon";
import { ReducerCollection } from "../../../utils/store/core";
import { getStartOfTheDay } from "../../charts/chart-generating/utils/getStartOfTheDay";
import { AlarmExceed } from "../models/alarm-exceed.model";
import { AlarmRequests } from "../models/alarm-requests.model";
import { SetPage } from "./setPage";
import { SetSortMode } from "./setSortMode";
import { SetTotalAlarmExceeds } from "./setTotalAlarmExceeds";
import { UpdateFiltersConfig } from "./updateFiltersConfig";
import { UpdateTimeScaleState } from "./updateTimeScaleState";

export type AlarmsState = AlarmRequests & {
  totalAlarmExceeds: Array<AlarmExceed>;
  requestedAlarmExceeds: Array<AlarmExceed>;
  page: number;
};

export class AlarmsReducer extends ReducerCollection<AlarmsState> {
  static initialState: AlarmsState = {
    page: 0,
    totalAlarmExceeds: [],
    requestedAlarmExceeds: [],
    sortMode: "DATE_DESCENDING",
    timeScaleState: {
      startTimestamp_s:
        getStartOfTheDay(DateTime.now().toSeconds()) - 30 * 24 * 60 * 60,
      endTimestamp_s: getStartOfTheDay(
        DateTime.now().toSeconds() + 24 * 60 * 60
      ),
    },
    filterAlarmType: undefined,
    filterDataType: undefined,
    filterDeviceName: undefined,
  };

  initState() {
    return AlarmsReducer.initialState;
  }

  getActionClassObjects() {
    return [
      SetPage,
      SetSortMode,
      SetTotalAlarmExceeds,
      UpdateFiltersConfig,
      UpdateTimeScaleState,
    ];
  }
}
