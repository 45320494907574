import { DataProviderState, OrganisationViewData } from ".";
import { ReducerActionClass } from "../../../utils/store/core";
import { Alarm, Device, DeviceGroup, Measure } from "shared";

type Payload =OrganisationViewData & {

  orgId:string
};

class PutDataClass extends ReducerActionClass<DataProviderState, Payload> {
  getActionName(): string {
    return "PUT_DATA";
  }

  handleDispatch(
    prevState: DataProviderState,
    payload: Payload
  ): DataProviderState {
    const { orgId,...orgData } = payload;
    return {
      ...prevState,
      ...orgData,
      orgId,
      dataLoaded:true
    };
  }
}

export const PutData = new PutDataClass();