import { useTranslate } from "react-translate";
import { ReactComponent as EditIcon } from "bootstrap-icons/icons/pencil-fill.svg";
import { ReactComponent as LocationIcon } from "bootstrap-icons/icons/geo-fill.svg";
import { ReactComponent as GroupIcon } from "bootstrap-icons/icons/collection-fill.svg";
import { ReactComponent as AlarmIcon } from "bootstrap-icons/icons/bell-fill.svg";
import { ReactComponent as AddIcon } from "bootstrap-icons/icons/plus-square-fill.svg";
import { ReactComponent as DeleteIcon } from "bootstrap-icons/icons/trash-fill.svg";
import { ReactComponent as MqttConfIcon } from "bootstrap-icons/icons/key-fill.svg";
import { ReactComponent as OrganisationIcon } from "bootstrap-icons/icons/building-fill.svg";
import { Tooltip } from "bootstrap";
import { useLayoutEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import EditDevicesGroupModal from "../../modals/DevicesTab/EditDevicesGroupModal";
import EditDeviceModal from "../../modals/DevicesTab/EditDeviceModal";
import EditDevicesAlarmSetsModal from "../../modals/DevicesTab/EditDevicesAlarmSetsModal";
import EditDevicesLocationsModal from "../../modals/DevicesTab/EditDevicesLocationsModal";
import { isNull } from "lodash";
import CreateDeviceModal from "../../modals/DevicesTab/CreateDeviceModal";
import DeleteDeviceModal from "../../modals/DevicesTab/DeleteDeviceModal";
import EditDeviceMqttConfigurationModal from "../../modals/DevicesTab/EditDeviceMqttConfigurationModal";
import EditDevicesOrganisationModal from "../../modals/DevicesTab/EditDevicesOrganisationModal";
import { Device, DeviceGroup, DeviceWMqttConfWOPassword } from "shared";
import { useModDataSelector } from "../../../mod-data.provider";
import { useModPanelData } from "../../hooks/useModPanelData";

const DevicesTab: React.FC<{ organisationId: string | null }> = ({
  organisationId,
}) => {
  const t = useTranslate("mod");

  const primaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-primary-rgb");

  const dangerColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-danger-rgb");

  const modState = useModDataSelector(state=>state)
  const {devices,deviceGroups} = useModPanelData(organisationId)

  const [editingDevice, setEditingDevice] = useState<string | null>(null);
  const [editingDevicesGroup, setEditingDevicesGroup] = useState<string | null>(
    null
  );
  const [editingDevicesAlarmSets, setEditingDevicesAlarmSets] = useState<
    string | null
  >(null);
  const [editingDevicesLocations, setEditingDevicesLocations] = useState<
    string | null
  >(null);

  const [editingDevicesMqttConfiguration, setEditingDevicesMqttConfiguration] =
    useState<string | null>(null);

  const [creatingDevice, setCreatingDevice] = useState<boolean>(false);

  const [deletingDevice, setDeletingDevice] = useState<string | null>(null);

  const [editingDevicesOrganisation, setEditingDevicesOrganisation] = useState<
    string | null
  >(null);

  const columns = useMemo(() => {
    const columns = [
      {
        Header: t("DEVICES_COLUMN_NAME"),
        accessor: "name",
      },
      {
        Header: t("DEVICES_COLUMN_SSN"),
        accessor: "ssn",
      },
      // {
      //   Header: t("DEVICES_COLUMN_LOCATION"),
      //   accessor: "location",
      // },
      {
        Header: t("DEVICES_COLUMN_GROUP"),
        accessor: "dgroup",
      },
      {
        Header: t("DEVICES_COLUMN_ALARM_SETS"),
        accessor: "alarm_sets",
      },
    ];

    if (isNull(organisationId)) {
      columns.splice(
        2,
        3,
        {
          Header: t("DEVICES_COLUMN_MQTT_USERNAME"),
          accessor: "mqtt_username",
        },
        {
          Header: t("DEVICES_COLUMN_ORGANISATION"),
          accessor: "organisation",
        },
        {
          Header: t("DEVICES_COLUMN_ALARM_SETS_GLOBAL"),
          accessor: "alarm_sets",
        }
      );
    }

    return columns;
  }, [t]);

  const data = useMemo(
    () =>
      devices.map((dev) => {
        const deviceGroupsAll = deviceGroups.filter((dgroup) =>
          dev.device.groupIds.includes(dgroup._id)
        );

        return {
          _id: dev.device._id,
          name: dev.device.name,
          ssn: dev.device.ssn,
          dgroup: deviceGroupsAll.find((grp) => grp.display)?.name,
          mqtt_username: dev.device.mqttConfiguration?.username,
          organisation: modState.system?.organisations.find(
            (org) => org.organisation._id === dev.device.organisation
          )?.organisation.name,
          alarm_sets: deviceGroupsAll
            .filter((grp) => !grp.display)
            .reduce((str: string, dgroup) => {
              if (str === "") {
                return dgroup.name;
              }

              return `${str}, ${dgroup.name}`;
            }, ""),
          location:
            dev.device.history.length === 0
              ? ""
              : dev.device.history[dev.device.history.length - 1].location
                  ?.locationString,
        };
      }),
    [devices, deviceGroups]
  );

  useLayoutEffect(() => {
    const tooltipElements = Array.from(
      document.querySelectorAll(".DevicesTabTooltip")
    );
    const tooltips = tooltipElements.map((el) => new Tooltip(el));
    return () => tooltips.forEach((tooltip) => tooltip.dispose());
  }, [data]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      //@ts-ignore
      columns,
      data,
    });

  return (
    <>
      <h4>{t("TAB_devices")}</h4>
      <p>{t("DEVICES_PARAGRAPH")}</p>
      <div>
        <table {...getTableProps()} className="table table-striped mb-2">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
                <th />
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                  <td className="ps-4 pe-0" style={{ width: "1%" }}>
                    <div className="d-flex justify-content-end">
                      {isNull(organisationId) ? null : (
                        <>
                          <div className="me-3">
                            <div
                              className="DevicesTabTooltip"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={t("DEVICES_TOOLTIP_EDIT_NAME")}
                            >
                              <a
                                className="text-decoration-none text-reset"
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setEditingDevice(row.original._id);
                                }}
                              >
                                <EditIcon style={{ cursor: "pointer" }} />
                              </a>
                            </div>
                          </div>
                          <div className="me-3">
                        <div
                          className="DevicesTabTooltip"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={t("DEVICES_TOOLTIP_EDIT_LOCATION")}
                        >
                          <a
                            className="text-decoration-none text-reset"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setEditingDevicesLocations(row.original._id)
                            }}
                          >
                            <LocationIcon style={{ cursor: "pointer" }} />
                          </a>
                        </div>
                      </div>
                          <div className="me-3">
                            <div
                              className="DevicesTabTooltip"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={t("DEVICES_TOOLTIP_EDIT_GROUP")}
                            >
                              <a
                                className="text-decoration-none text-reset"
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setEditingDevicesGroup(row.original._id);
                                }}
                              >
                                <GroupIcon style={{ cursor: "pointer" }} />
                              </a>
                            </div>
                          </div>
                        </>
                      )}
                      <div className={isNull(organisationId) ? "me-3" : "me-4"}>
                        <div
                          className="DevicesTabTooltip"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={t("DEVICES_TOOLTIP_EDIT_ALARMS")}
                        >
                          <a
                            className="text-decoration-none text-reset"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setEditingDevicesAlarmSets(row.original._id);
                            }}
                          >
                            <AlarmIcon style={{ cursor: "pointer" }} />
                          </a>
                        </div>
                      </div>
                      {!isNull(organisationId) ? null : (
                        <>
                          <div className="me-3">
                            <div
                              className="DevicesTabTooltip"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={t("DEVICES_TOOLTIP_EDIT_ORGANISATION")}
                            >
                              <a
                                className="text-decoration-none text-reset"
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setEditingDevicesOrganisation(
                                    row.original._id
                                  );
                                }}
                              >
                                <OrganisationIcon
                                  style={{ cursor: "pointer" }}
                                />
                              </a>
                            </div>
                          </div>
                          <div className="me-3">
                            <div
                              className="DevicesTabTooltip"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={t(
                                "DEVICES_TOOLTIP_EDIT_MQTT_CONFIGURATION"
                              )}
                            >
                              <a
                                className="text-decoration-none text-reset"
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setEditingDevicesMqttConfiguration(
                                    row.original._id
                                  );
                                }}
                              >
                                <MqttConfIcon style={{ cursor: "pointer" }} />
                              </a>
                            </div>
                          </div>

                          <div className="me-4">
                            <div
                              className="DevicesTabTooltip"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title={t("DEVICES_TOOLTIP_DELETE")}
                            >
                              <a
                                href="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setDeletingDevice(row.original._id);
                                }}
                              >
                                <DeleteIcon
                                  fill={`rgb(${dangerColor})`}
                                  style={{ cursor: "pointer" }}
                                />
                              </a>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {!isNull(organisationId) ? null : (
          <div className="d-flex justify-content-end">
            <div className="pe-4" style={{ marginRight: -2 }}>
              <div
                className="DevicesTabTooltip"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={t("DEVICES_TOOLTIP_CREATE")}
              >
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setCreatingDevice(true);
                  }}
                >
                  <AddIcon
                    style={{ cursor: "pointer" }}
                    fill={`rgb(${primaryColor})`}
                    height="20"
                    width="20"
                  />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      <EditDevicesGroupModal
      organisationId={organisationId||""}
        deviceId={editingDevicesGroup}
        onClose={() => setEditingDevicesGroup(null)}
      />
      <EditDeviceModal
      organisationId={organisationId||""}
        deviceId={editingDevice}
        onClose={() => setEditingDevice(null)}
      />
      <EditDevicesAlarmSetsModal
        organisationId={organisationId}
        deviceId={editingDevicesAlarmSets}
        onClose={() => setEditingDevicesAlarmSets(null)}
      />
      <EditDevicesLocationsModal organisationId={organisationId||""} deviceId={editingDevicesLocations} onClose={()=>setEditingDevicesLocations(null)} />

      <CreateDeviceModal
        show={creatingDevice}
        onClose={() => setCreatingDevice(false)}
      />
      <DeleteDeviceModal
        deviceId={deletingDevice}
        onClose={() => setDeletingDevice(null)}
      />
      <EditDeviceMqttConfigurationModal
        deviceId={editingDevicesMqttConfiguration}
        onClose={() => setEditingDevicesMqttConfiguration(null)}
      />
      <EditDevicesOrganisationModal
        deviceId={editingDevicesOrganisation}
        onClose={() => setEditingDevicesOrganisation(null)}
      />
    </>
  );
};

export default DevicesTab;
