import { isUndefined } from "lodash";
import { PdfGeneratorState } from "..";

export const checkIfStateIsDownloadable = (
  state: PdfGeneratorState
): boolean => {
  const { requestedDevice, requestedDataTypes } = state;

  if (isUndefined(requestedDevice)) {
    return false;
  }

  if (!requestedDataTypes.length) {
    return false;
  }

  return true;
};
