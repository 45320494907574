import { Modal } from "bootstrap";
import { useEffect, useRef, useState } from "react";

const DefaultModal: React.FC<{
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
}> = (props) => {
  const { onClose, show, children } = props;
  const modalRef = useRef<HTMLDivElement>(null);
  const [modal, setModal] = useState<Modal | null>(null);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    const modal = new Modal(modalRef.current);
    setModal(modal);
    return () => {
      modal.dispose();
    };
  }, [modalRef, setModal]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }

    modalRef.current.addEventListener("hide.bs.modal", onClose);
    return () => {
      modalRef.current?.removeEventListener("hide.bs.modal", onClose);
    };
  }, [modalRef, onClose]);

  useEffect(() => {
    if (!modalRef.current) {
      return;
    }
    const modal = Modal.getInstance(modalRef.current);
    if (!modal) {
      return;
    }

    if (show) {
      modal.show();
    } else {
      modal.hide();
    }
  }, [modal, show]);

  return (
    <div ref={modalRef} className="modal fade" tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog">{children}</div>
    </div>
  );
};

export default DefaultModal;
