import { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { EditDeviceGroupDto } from "shared";
import ErrorDisplayFieldError from "../../../../../utils/forms/ErrorDisplayFieldError";
import { triggerAsyncId } from "async_hooks";
import { isNull } from "lodash";
import { container } from "tsyringe";
import { DeviceGroupRequestsService } from "../../../request-services/device-group-requests.service";
import { useModPanelData } from "../../hooks/useModPanelData";

const resolver = classValidatorResolver(EditDeviceGroupDto);

const EditAlarmSetModal: React.FC<{
  groupId: string | null;
  onClose: () => void;
  organisationId: string | null;
}> = ({ onClose, groupId, organisationId }) => {
  const t = useTranslate("mod");
  
  const {deviceGroups: groups} = useModPanelData(organisationId);

  const group = useMemo(
    () => groups.find((group) => group._id === groupId),
    [groups, groupId]
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm<EditDeviceGroupDto>({ resolver, mode: "onBlur" });

  const submitRef = useRef<HTMLInputElement>(null);
  const onSave = useCallback(() => {
    if (!submitRef.current) {
      return;
    }
    submitRef.current.click();
  }, [submitRef]);

  useEffect(() => {
    if (!group) {
      return;
    }
    setValue("name", group?.name || "");
    trigger();
  }, [group]);

  return (
    <DefaultFormModal
      title={t("ASET_TOOLTIP_EDIT")}
      show={!!groupId}
      onClose={onClose}
      onSave={onSave}
      saveDisabled={!isValid}
    >
      <>{t("EDIT_ASET_MODAL_PARAGRAPH", { setName: group?.name || "" })}</>
      <form
        onSubmit={handleSubmit((data) => {
          if (isNull(organisationId)) {
            //edit global device group
            container.resolve(DeviceGroupRequestsService).editGlobalDeviceGroup(data,groupId!).then(()=>{
              onClose()
            })
          } else {
            //edit device group
            container.resolve(DeviceGroupRequestsService).editDeviceGroup(data,groupId!,organisationId).then(()=>{
              onClose()
            })
          }
        })}
      >
        <input ref={submitRef} hidden type="submit" />
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <label htmlFor="edit_aset_name" className="col-form-label">
                {t("EDIT_ASET_MODAL_LABEL_NAME")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="edit_aset_name"
                {...register("name")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.name} />
        </div>
      </form>
    </DefaultFormModal>
  );
};

export default EditAlarmSetModal;
