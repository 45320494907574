import MainProvider from "./providers/MainProvider";
import Skeleton from "./skeleton/Skeleton";

const App = () => {
  return (
    <div className="App">
      <MainProvider>
        <Skeleton />
      </MainProvider>
    </div>
  );
};

export default App;

