import { ChartState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";

type Payload = {
  alarmName: string;
  requested: boolean;
};

class SetRequestedAlarmsClass extends ReducerActionClass<ChartState, Payload> {
  getActionName() {
    return "SET_REQUESTED_ALARMS";
  }

  handleDispatch(prevState: ChartState, payload: Payload) {
    const { alarmName, requested } = payload;
    const { requestedAlarms: prevRequestedAlarms } = prevState;

    const requestedAlarms = [...prevRequestedAlarms];

    if (requested) {
      //add alarm if is not included
      if (!requestedAlarms.find((alarm) => alarm === alarmName)) {
        requestedAlarms.push(alarmName);
      }
    } else {
      //remove alarm if is included
      const index = requestedAlarms.findIndex((alarm) => alarm === alarmName);
      if (index !== -1) {
        requestedAlarms.splice(index, 1);
      }
    }
    return {
      ...prevState,
      requestedAlarms,
    };
  }
}

export const SetRequestedAlarm = new SetRequestedAlarmsClass();