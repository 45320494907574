import { Container, Nav, Navbar } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { animated, useSpring } from "react-spring";

import SideMenuContent from "./MenuContent";

import { HOME_PAGE_PATH } from "../pages/home";
import { useEffect, useState } from "react";

//@ts-ignore
import logoText from "../../assets/logo-25.png";
//@ts-ignore
import logoGigText from "../../assets/gig-logo-30.png";

const NavbarMobileContent: React.FC = () => {
  const history = useHistory();

  return (
    <Navbar collapseOnSelect expand={false} bg="dark" variant="dark">
      <Container>
        <Navbar.Brand>
          <Nav.Link
            href={HOME_PAGE_PATH}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push(HOME_PAGE_PATH);
            }}
          >
            <img src={logoText} height={25} />
            <img
              className="ms-3"
              src={logoGigText}
              height={30}
              style={{ cursor: "default" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <SideMenuContent expanded={true} />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const NavbarMobile: React.FC = () => {
  const [show, setShow] = useState<boolean>(true);
  const [dragPosition, setDragPosition] = useState<number | undefined>(
    undefined
  );
  const [styles, api] = useSpring(() => ({
    width: "100%",
    position: "fixed",
    top: "0%",
    zIndex: 1000,
  }));

  useEffect(() => {
    const onTouchMove = (e: TouchEvent) => {
      if (e.touches.length === 1) {
        setDragPosition((curr) => {
          const newVal = e.touches.item(0)!.screenY;
          if (curr && newVal > curr) {
            setShow(true);
          } else if (curr && newVal < curr) {
            setShow(false);
          }
          return newVal;
        });
      } else {
        setDragPosition(undefined);
      }
    };
    const onTouchEnd = () => {
      setDragPosition(undefined);
    };
    window.addEventListener("touchmove", onTouchMove);
    window.addEventListener("touchEnd", onTouchEnd);
    return () => {
      window.removeEventListener("touchmove", onTouchMove);
      window.removeEventListener("touchEnd", onTouchEnd);
    };
  }, [setDragPosition, setShow]);

  // useEffect(()=>{
  //   const intrv = setInterval(()=>{
  //     setShow(curr=>!curr);
  //   },5000)
  //   return ()=>{
  //     clearInterval(intrv);
  //   }
  // },[setShow])

  useEffect(() => {
    api.start({ top: show ? "0%" : "-100%" });
  }, [show]);

  return (
    <>
      <animated.div style={styles as any}>
        <NavbarMobileContent />
      </animated.div>

      <div style={{ opacity: 0, pointerEvents: "none", width: "100%" }}>
        <NavbarMobileContent />
      </div>
    </>
  );
};

export default NavbarMobile;

export const NavbarMobileNoFollow: React.FC = () => {
  return (
    <div style={{ width: "100%" }}>
      <NavbarMobileContent />
    </div>
  );
};
