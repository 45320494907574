import { injectable } from "tsyringe";
import { AlertConfig } from "./models/alert-config.model";

@injectable()
export class AlertService {
  private cbs: Array<(alert: AlertConfig) => void> = [];

  pushMessage(alertConfig: AlertConfig) {
    this.cbs.forEach((cb) => cb(alertConfig));
  }

  subscribeToMessages(cb: (alert: AlertConfig) => void) {
    this.cbs.push(cb);
    return () => {
      this.cbs = this.cbs.filter((currCb) => currCb !== cb);
    };
  }
}
