import { isString } from "lodash";
import { LoginInfo } from "shared";
import Cookies from "universal-cookie";


const JWT_RT_STATE_KEY = "JWT_RT_STATE";
const LOCAL_STORAGE_TRIGGER_KEY = "JWT_RT_TRIGGER";

const loggedOutState:LoginInfo={
  loginState:"LOGGED_OUT"
}

export const getLoginInfo = (): LoginInfo => {
    const loginCookie = getLoginCookie();
    return loginCookie || loggedOutState;
};

const getLoginCookie = ():LoginInfo|undefined =>{

  const cookies = new Cookies();
  const loginCookieValue = cookies.get(JWT_RT_STATE_KEY,{doNotParse:true})
  let loginCookie:LoginInfo|undefined;
  if(loginCookieValue){
    loginCookie = JSON.parse(loginCookieValue)
  }else{
    loginCookie=undefined;
  }

  return loginCookie;
}

const setLoginCookie = (loginInfo:LoginInfo)=>{
    const loginCookieValue = JSON.stringify(loginInfo);
    
    let expires = loginInfo.expires;

    if(isString(expires)&&expires.length>1){
      expires = new Date(loginInfo.expires as any as string);
    }

    const cookies = new Cookies();
    cookies.set(JWT_RT_STATE_KEY,loginCookieValue,{
      expires:expires || undefined,
      path:"/"
    })
}

const triggerOtherTabs = () => {
  
  const localStorageTriggerValue = window.localStorage.getItem(LOCAL_STORAGE_TRIGGER_KEY) as null|"0"|"1";
  let localStorageTriggerNewValue:"0"|"1"
  if(localStorageTriggerValue==="1"){
    localStorageTriggerNewValue="0"
  }else{
    localStorageTriggerNewValue="1"
  }
  window.localStorage.setItem(LOCAL_STORAGE_TRIGGER_KEY,localStorageTriggerNewValue);
};


export const setLoginInfo = (loginInfo?: LoginInfo) => {
  if(!loginInfo){
    loginInfo=loggedOutState
  }
  
  setLoginCookie(loginInfo)
  triggerOtherTabs()
  triggerStorageChangeSubscriber()
  
};




const getLoggedInState = (loginInfo:LoginInfo)=>{
  return loginInfo.loginState!=="LOGGED_OUT"
}

const cbFunctions:Array<()=>void> = [] 

const triggerStorageChangeSubscriber = ()=>{
  cbFunctions.forEach(cbF=>cbF())
}

export const registerLoggedInListener = (
  loggedInCb: (rememberMe: boolean) => void,
  loggedOutCb: () => void
) => {
  
  let prevLoggedInState = getLoggedInState(getLoginInfo());

  const checkLoginInfoChanged = () => {
    const loginInfo = getLoginInfo();
    const loggedInState = getLoggedInState(loginInfo)
    if (prevLoggedInState === loggedInState) {
      return;
    }
    prevLoggedInState = loggedInState;
    if (loggedInState) {
      const rememberMe = loginInfo.loginState === "LOGGED_IN_REMEMBERME";
      loggedInCb(rememberMe);
    } else {
      loggedOutCb();
    }
  }
  cbFunctions.push(checkLoginInfoChanged);
  window.addEventListener("storage", checkLoginInfoChanged);
};
