export default{
    "FIELD_GROUP":"Grupa",
    "FIELD_DEVICE":"Urządzenie",
    "FIELD_SINCE":"Od",
    "FIELD_UNTIL":"Do",
    "FIELD_DATA_TYPES":"Dane Pomiarowe",
    "FIELD_FORMAT":"Format",
    "FIELD_DEVICES":"Urządzenia",
    "FIELD_AESTATISTICS":"Statystyki przekroczeń alarmów",
    "FIELD_SHOW_IN_TABLE":"Zaznacz w tabeli",
    "FIELD_SHOW_ON_CHARTS":"Zaznacz na wykresie",
    "FIELD_SHOW_IN_STATS_SECTION":"Pokaż w sekcji statystyk",

    "PDF_GENERATOR_HEADER":"Generowanie raportu",
    "PDF_GENERATOR_PARAGRAPH":"Wygeneruj raport przy użyciu danych dostępnych w systemie. Wybierz urządzenie i zaznacz odpowiednie opcje.",
    "DOWNLOAD_REPORT":"Pobierz raport",
    "EXPORT_DATA":"Eksportuj dane",
    "OPEN_SCHEMA_JSON":"Otwórz JSON Schema",
    "OPEN_SCHEMA_XML":"Otwórz XML Schema Definition",

    "TAB_STANDARD":"Generuj raport",
    "TAB_EXPORT":"Eksportuj dane",

    "ADD_DEVICE_TOOLTIP":"Dodaj urządzenia",
    "REMOVE_DEVICE_TOOLTIP":"Usuń zaznaczone urządzenia",
    "ADD_DEVICES_IN_GROUP_ALL":"Wszystkie",
    "ADD_DEVICES_ALL":"Wszystkie"
}