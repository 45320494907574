import { useLayoutEffect, useRef } from "react";
import { GenerateChartConfig } from "../../../charts/chart-generating/generateChartData";
import { useChart } from "../../../charts/hooks/useChart";
import { ChartData } from "../../../charts/models/chart-data";
import { ChartRequests } from "../../../charts/models/chart-requests.model";

const ChartPdfComponent: React.FC<{
  chartData: ChartData;
  chartRequests: ChartRequests;
  chartCanvasId: string;
  onRender: () => void;
  generateChartConfig?:Partial<GenerateChartConfig>
  width?:number
  height?:number
}> = (props) => {
  const { chartData, chartRequests, chartCanvasId, onRender,generateChartConfig,width,height } = props;

  const chartRef = useRef<HTMLCanvasElement>(null);

  const { chart } = useChart(chartRef, false, chartRequests,undefined,undefined,generateChartConfig,undefined,undefined,chartData);

  useLayoutEffect(() => {
    if (!chart) {
      return;
    }

    onRender();
  }, [chart]);

  return (
    <div
      style={{ width, height, zIndex: 100, backgroundColor: "red" }}
    >
      <canvas id={chartCanvasId} ref={chartRef} />
    </div>
  );
};

export default ChartPdfComponent;
