import IconTile from "./IconTile";
import { ReactComponent as DevicesIcon } from "bootstrap-icons/icons/robot.svg";
import { DEVICES_PAGE_PATH } from "../../../../skeleton/pages/devices";
import { useTranslate } from "react-translate";

const DevicesTile: React.FC = () => {
  const t = useTranslate("menu");
  return (
    <IconTile
      url={DEVICES_PAGE_PATH}
      title={t("PAGE_DEVICES")}
      Icon={<DevicesIcon width="100%" height="100%" />}
    />
  );
};

export default DevicesTile;
