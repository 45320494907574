  import { DateTime } from "luxon";
import { Alarm, AlarmType, Device } from "shared";
import { inject, injectable } from "tsyringe";
import { DataType } from "../charts/models/chart-description.model";
import { DataProviderService } from "../data-provider/data-provider.service";
import { AlarmExceed } from "./models/alarm-exceed.model";

@injectable()
export class AlarmsService {
  constructor(
    @inject("data-provider") private dataProviderService: DataProviderService
  ) {}

  getAlarmItem(dataType: DataType, alarmType: AlarmType, device: Device) {
    const alarms = this.dataProviderService.getStore().getState().alarms;

    return alarms.find(
      (alarm) =>
        this.dataProviderService.getDataType(alarm.dtAlias) &&
        alarm.type === alarmType
    );
  }

  async getAlarmExceeds(
    timestampStart_s: number,
    timestampEnd_s: number
  ): Promise<Array<AlarmExceed>> {
    const store = this.dataProviderService.getStore();
    const { alarms, devices } = store.getState();

    const now = DateTime.now().toSeconds();

    const alarmExceedsRecords = await this.dataProviderService.getAlarmExceeds(
      DateTime.fromSeconds(timestampStart_s,{zone:"utc"}).toJSDate(),
      DateTime.fromSeconds(timestampEnd_s,{zone:"utc"}).toJSDate(),
    );
    return alarmExceedsRecords.map((aeRecord) => {
      const device = devices.find(
        (dev) => dev.device._id === aeRecord.dev
      )?.device

      return {
        id: aeRecord._id,
        startTimestamp_s: DateTime.fromJSDate(aeRecord.sDTime,{zone:"utc"}).toSeconds(),
        endTimestamp_s: (aeRecord.eDTime&&DateTime.fromJSDate(aeRecord.eDTime,{zone:"utc"}).toSeconds()) || now,
        alarmName: aeRecord.alarm.name,
        alarmType: aeRecord.alarm.type,
        deviceName: device?.name || "",
        maxValue: aeRecord.maxVal,
        dataType: this.dataProviderService.getDataType(aeRecord.alarm.dtAlias),
        maxValueLast24h:aeRecord.maxValLast24h.val
      };
    });
  }

  getHighestAlarmExceeded(
    device: Device,
    dataType: DataType,
    value: number,
    timestamp_s: number
  ): AlarmType | null {
    const alarms = this.dataProviderService.getStore().getState().alarms;
    return alarms
      .filter(
        (alarm) =>
          this.dataProviderService.getDataType(alarm.dtAlias) === dataType
      )
      .reduce((currMaxAlarm: AlarmType | null, alarm) => {
        if (alarm.threshold >= value) {
          return currMaxAlarm;
        }

        if (alarm.type === "CRITICAL" || currMaxAlarm === "CRITICAL") {
          return "CRITICAL";
        }
        if (alarm.type === "WARNING" || currMaxAlarm === "WARNING") {
          return "WARNING";
        }

        return null;
      }, null);
  }

  getAlarmsDataTypes(alarms: Array<Alarm>) {
    return Object.values(alarms).reduce((arr: Array<DataType>, alarm) => {
      const dataType = this.dataProviderService.getDataType(alarm.dtAlias);

      if (!arr.includes(dataType)) {
        arr.push(dataType);
      }

      return arr;
    }, []);
  }
}
