import { TabsArray } from "./useTabView";

const TabControlls = <TabT extends string>({
  items,
  initialTab,
  t
}: {
  items: TabsArray<TabT>;
  initialTab:TabT;
  t:any
}) => {
  return (
    <>
      {items.map((tab) => (
        <li className="nav-item" role="presentation" key={tab.name}>
          <button
            className={`nav-link bg-light ${
              initialTab === tab.name ? "active" : ""
            }`}
            aria-current="page"
            id={`${tab.name}-tab`}
            data-bs-toggle="tab"
            data-bs-target={`#${tab.name}`}
            type="button"
            role="tab"
            aria-controls={tab.name}
            aria-selected="true"
            style={{
              borderWidth: 3,
            }}
          >
            {t(`TAB_${tab.name}`)}
          </button>
        </li>
      ))}
    </>
  );
};

export default TabControlls;
