import { isUndefined } from "lodash";
import { DataType } from "../models/chart-description.model";
import { DeviceRequest } from "../models/device-request.model";
import { MeasuresGrouping } from "../models/measure-dataset-setup.model";
import { TimeScaleState } from "../models/time-scale-state";
import { DevicesTableTimeGroups, TableTimeGroups } from "./TableView";

export const getRequestedTableTimeGroups = (
  devicesTimeTableGroups: DevicesTableTimeGroups,
  requestedDevices: {
    [deviceName: string]: DeviceRequest;
  },
  requestedDataTypes: Array<DataType>,
  measuresGrouping: MeasuresGrouping,
  timeScaleState: TimeScaleState
): {
  requestedTableTimeGroups: DevicesTableTimeGroups;
  requestedTimestampGroups_s: Array<number>;
} => {
  const requestedTableTimeGroups: DevicesTableTimeGroups = {};
  const requestedTimestampGroups_s: Array<number> = [];

  const { startTimestamp_s, endTimestamp_s } = timeScaleState;

  Object.keys(devicesTimeTableGroups).forEach((deviceName) => {
    if (!Object.keys(requestedDevices).includes(deviceName)) {
      return;
    }
    requestedTableTimeGroups[deviceName] = {};
    Object.keys(devicesTimeTableGroups[deviceName]).forEach((dataType) => {
      const deviceRequestedDataTypes = isUndefined(
        requestedDevices[deviceName].requestedDataTypes
      )
        ? requestedDataTypes
        : requestedDevices[deviceName].requestedDataTypes!;

      //@ts-ignore
      if (!deviceRequestedDataTypes.includes(dataType)) {
        return;
      }

      requestedTableTimeGroups[deviceName][dataType] = {};

      Object.keys(devicesTimeTableGroups[deviceName][dataType]).forEach(
        (grouping) => {
          if (measuresGrouping !== grouping) {
            return;
          }

          const tableTimeGroups =
            devicesTimeTableGroups[deviceName][dataType][grouping];
          const filteredTableTimeGroups: TableTimeGroups = {};

          Object.keys(tableTimeGroups).forEach((timestampGroup_s_string: string) => {
            const timestampGroup_s = +timestampGroup_s_string;
            if (
              timestampGroup_s < startTimestamp_s ||
              timestampGroup_s >= endTimestamp_s
            ) {
              return;
            }
            filteredTableTimeGroups[timestampGroup_s] =
              tableTimeGroups[timestampGroup_s];
            if (!requestedTimestampGroups_s.includes(timestampGroup_s)) {
              requestedTimestampGroups_s.push(timestampGroup_s);
            }
          });

          requestedTableTimeGroups[deviceName][dataType][
            grouping
          ] = filteredTableTimeGroups;
        }
      );
    });
  });

  return { requestedTableTimeGroups, requestedTimestampGroups_s };
};
