import { useDataProviderSelector } from "../../../data-provider/data-provider.provider";

import { ReactComponent as TableIcon } from "bootstrap-icons/icons/table.svg";
import { ReactComponent as GridIcon } from "bootstrap-icons/icons/grid-fill.svg";
import { DevicesViewOption } from "./DevicesView";
import { useMemo } from "react";
import { useTranslate } from "react-translate";
import { getContainer } from "../../../app-container";
import { DevicesService } from "../../devices.service";
import { isNull, isUndefined } from "lodash";

type Props = {
  selectedGroupId: string | null;
  setSelectedGroupId: (group: string | null) => void;
  selectedView: DevicesViewOption;
  setSelectedView: (view: DevicesViewOption) => void;
};

const DevicesViewSwitch: React.FC<Props> = ({
  selectedGroupId,
  selectedView,
  setSelectedGroupId,
  setSelectedView,
}) => {
  const t = useTranslate("devices");
  const {deviceGroups,devices} = useDataProviderSelector((state) => state);
  const deviceGroupsDisplay = useMemo(()=>
  getContainer().resolve<DevicesService>("devices").getDeviceGroups(deviceGroups,devices)
  ,[deviceGroups,devices])
  const selectedGroup = useMemo(
    () => deviceGroupsDisplay.find((group) => group===false ? selectedGroupId==="false" : group._id === selectedGroupId),
    [deviceGroupsDisplay, selectedGroupId]
  );


  return (
    <div
      className="mx-3"
      style={{ display: "flex", justifyContent: "flex-end" }}
    >
      <div className="bg-white dropdown mx-2">
        <button
          type="button"
          className="btn btn-lg btn-outline-primary dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {isUndefined(selectedGroup) ? t("ALL_GROUPS_OPTION") : selectedGroup===false ? t("REST_OPTION") : selectedGroup.name}
        </button>{" "}
        <ul className="dropdown-menu">
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setSelectedGroupId(null);
              }}
            >
              {t("ALL_GROUPS_OPTION")}
            </a>
          </li>
          {deviceGroupsDisplay.map((group) => (
            <li key={group===false ? "false" : group._id}>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedGroupId(group===false ? "false"  : group._id);
                }}
              >
                {group===false ? t("REST_OPTION") : group.name}
              </a>
            </li>
          ))}
        </ul>
      </div>{" "}
      <div className="bg-white dropdown mx-2">
        <button
          type="button"
          className="btn btn-lg btn-outline-primary dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {/* <GridIcon/> */}
          {selectedView === DevicesViewOption.GRID ? (
            <GridIcon width="25" height="25" />
          ) : (
            <TableIcon width="25" height="25" />
          )}
        </button>{" "}
        <ul className="dropdown-menu dropdown-menu-end">
          <li>
            <a
              className="dropdown-item "
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setSelectedView(DevicesViewOption.GRID);
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <GridIcon className="me-2" />
                <div>{t("GRID_VIEW_OPTION")}</div>
              </div>
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setSelectedView(DevicesViewOption.TABLE);
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <TableIcon className="me-2" />
                <div>{t("TABLE_VIEW_OPTION")}</div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DevicesViewSwitch;
