import { inject, injectable } from "tsyringe";
import { ChartData } from "../../../charts/models/chart-data";
import { ChartRequests } from "../../../charts/models/chart-requests.model";
import { ChartToImageService } from "../chart-to-image/chart-to-image.service";
import { MeasuresGrouping } from "../../../charts/models/measure-dataset-setup.model";
import translations from "../../../../translations/translations";
import { DataType } from "../../../charts/models/chart-description.model";
import { DateTime } from "luxon";
import {
  PdfRequestDataType,
  PdfRequestsDownloadable,
} from "../../models/pdf-requests.model";
import { DataProviderService } from "../../../data-provider/data-provider.service";
import { getCorrectGroupingsForTimeRange } from "../../../charts/chart-generating/utils/getCorrectGroupingsForTimeRange";
import { PdfItemArray } from "../../models/pdf-item-array.model";
import { ChartsService } from "../../../charts/charts.service";
import { getContainer } from "../../../app-container";
import { DevicesService } from "../../../devices/devices.service";
import { AlarmExceedStatistic } from "shared";

/* translations should be imported from translations service */
const translation = translations.PL;
const tCharts = translation.chart as { [key: string]: string };

@injectable()
export class PdfItemsGeneratorService {
  constructor(
    @inject("chart-to-image") private chartToImageService: ChartToImageService,
    @inject("charts") private chartsService: ChartsService,
    @inject("data-provider") private dataProviderService: DataProviderService
  ) {}

  async getChartItems(
    chartData: ChartData,
    pdfRequest: PdfRequestsDownloadable
  ): Promise<PdfItemArray> {
    const pdfItems: PdfItemArray = [];

    const device = this.dataProviderService
      .getStore()
      .getState()
      .devices.find((dev) => dev.device._id === pdfRequest.requestedDevice);

    pdfItems.push({
      type: "text",
      title: "Wyniki pomiarów",
      subtitle: `${device?.device.name}`,
      body: `Tabela pomiarów, ${DateTime.fromSeconds(
        pdfRequest.timeScaleState.startTimestamp_s
      ).toLocaleString(DateTime.DATE_MED)} - ${DateTime.fromSeconds(
        pdfRequest.timeScaleState.endTimestamp_s
      ).toLocaleString(DateTime.DATE_MED)}`,
    });

    const { alarms, devices, deviceGroups } = this.dataProviderService
      .getStore()
      .getState();
    const deviceAlarms = getContainer()
      .resolve<DevicesService>("devices")
      .getDeviceAlarms(
        devices.find((dev) => dev.device._id === pdfRequest.requestedDevice)!
          .device,
        alarms,
        deviceGroups
      );

    const alarmExceededBelow: { [dataType: string]: boolean } = {};
    const alarmThreshold: { [dataType: string]: number } = {};
    Object.entries(chartData.alarmsDatasetsSetup).forEach(
      ([alarmName, dataset]) => {
        if (
          !Object.entries(pdfRequest.requestedAlarms).find(
            ([pdfReqAlarmName, pdfReqDataset]) =>
              alarmName === pdfReqAlarmName &&
              pdfReqDataset.table &&
              deviceAlarms.find((alarm) => alarm.name === alarmName)
          )
        ) {
          return;
        }
        alarmExceededBelow[dataset.dataType] = dataset.exceedBelowThreshold;
        alarmThreshold[dataset.dataType] = dataset.value;
      }
    );
    pdfItems.push({
      type: "table",
      ...this.chartsService
        .getChartTableService()
        .getItemTableData(
          chartData,
          this.getChartRequests(
            pdfRequest,
            { measuresGrouping: "DAILY" },
            "table"
          ),
          { alarmExceededBelow, alarmThreshold }
        ),
    });

    await pdfRequest.requestedDataTypes.reduce(
      async (ready: Promise<boolean>, dt) => {
        await ready;

        const { dataUrl: chartDataUrl, resolution } =
          await this.chartToImageService.getChartImage(
            chartData,
            this.getChartRequests(
              pdfRequest,
              {
                measuresGrouping: getCorrectGroupingsForTimeRange(
                  pdfRequest.timeScaleState
                )[0],
                dataTypes: this.getChartDataTypes(dt),
              },
              "chart"
            )
          );
        pdfItems.push([
          {
            type: "text",
            body: tCharts[`${dt}_LABEL`],
          },
          {
            type: "image",
            resolution,
            dataUrl: chartDataUrl,
          },
        ]);

        return true;
      },
      true as any as Promise<boolean>
    );

    const alarmIdsStats = alarms.filter(alarm=>Object.entries(pdfRequest.requestedAlarms).find(([pdfReqAlarmName,pdfReqDataset])=>
    alarm.name === pdfReqAlarmName &&
    pdfReqDataset.aeStatistics &&
    deviceAlarms.find((devAlarm) => devAlarm.name === alarm.name))).map(alarm=>alarm._id)

    const aeStatisticsObj = await this.dataProviderService.getAeStatistics(
      [pdfRequest.requestedDevice],
      alarmIdsStats,
      DateTime.fromSeconds(
        pdfRequest.timeScaleState.startTimestamp_s
      ).toJSDate(),

      DateTime.fromSeconds(pdfRequest.timeScaleState.endTimestamp_s).toJSDate()
    );

    const aeStatistics:Array<AlarmExceedStatistic> =[]
console.log(aeStatisticsObj)
    Object.values(aeStatisticsObj[pdfRequest.requestedDevice]||{}).forEach((aes)=>{
      aeStatistics.push(aes);
    })

    pdfItems.push({  
      type: "table",
      ...this.chartsService
        .getChartTableService().getItemTableAEStatistics(aeStatistics)
    })

    return pdfItems;
  }

  private getChartDataTypes(pdfDataType: PdfRequestDataType): Array<DataType> {
    if (pdfDataType === "CO2+ATMOSPHERIC_PRESSURE") {
      return ["CO2_LEVEL", "ATMOSPHERIC_PRESSURE"];
    }
    return [pdfDataType];
  }

  private getChartRequests(
    pdfRequests: PdfRequestsDownloadable,
    additionalInfo: {
      measuresGrouping: MeasuresGrouping;
      dataTypes?: Array<DataType>;
    },
    type: "table" | "chart"
  ): ChartRequests {
    const { measuresGrouping, dataTypes } = additionalInfo;
    const {
      requestedAlarms,
      requestedDataTypes: allRequestedDataTypes,
      timeScaleState,
      requestedDevice,
    } = pdfRequests;

    const { alarms, devices, deviceGroups } = this.dataProviderService
      .getStore()
      .getState();
    const deviceAlarms = getContainer()
      .resolve<DevicesService>("devices")
      .getDeviceAlarms(
        devices.find((dev) => dev.device._id === requestedDevice)!.device,
        alarms,
        deviceGroups
      );

    let requestedDataTypes: Array<DataType>;
    if (dataTypes) {
      //@ts-ignore
      // requestedDataTypes = allRequestedDataTypes.filter((dt) =>
      // //@ts-ignore

      //   dataTypes.includes(dt)
      // );
      requestedDataTypes = dataTypes;
    } else {
      //@ts-ignore
      requestedDataTypes = allRequestedDataTypes;
    }

    const requestedAlarmsChart: Array<string> = [];
    Object.entries(requestedAlarms).forEach(
      ([alarmName, alarmRequest = {}]) => {
        if (
          alarmRequest[type] &&
          deviceAlarms.find((alarm) => alarm.name === alarmName)
        ) {
          requestedAlarmsChart.push(alarmName);
        }
      }
    );

    return {
      measuresGrouping,
      requestedAlarms: requestedAlarmsChart,
      requestedDataTypes,
      timeScaleState,
      requestedDevices: {
        [requestedDevice]: { enabled: true, color: "0,0,0" },
      },
    };
  }
}
