import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import { DEVICES_PAGE_PATH } from "../../../../skeleton/pages/devices";
import { getColorForDeviceGroup } from "../../../charts/chart-generating/utils/getColor";
import DeviceItemHeader from "./Header";
import { Tab } from "bootstrap";
import { useTranslate } from "react-translate";
import InformationTab from "./Tabs/InformationTab";
import TechnicalDataTab from "./Tabs/TechnicalDataTab";
import HistoryTab from "./Tabs/HistoryTab";
import { useDataProviderSelector } from "../../../data-provider/data-provider.provider";
import AlarmExceedsStatisticsTab from "./Tabs/AlarmExceedsStatisticsTab";
import { ChartRequests } from "../../../charts/models/chart-requests.model";
import { DataType } from "../../../charts/models/chart-description.model";
import { getContainer } from "../../../app-container";
import { ChartsService } from "../../../charts/charts.service";
import { isUndefined } from "lodash";
import { useSaveStateInSearchParams } from "../../../charts/components/useSaveStateInSearchParams";

const initialRequestedDataTypes: Array<DataType> = ["CO2_LEVEL", "CO_LEVEL"];

type TabName = "INFORMATION" | "TECHNICAL_DATA" | "HISTORY"|"ALARM_EXCEEDS_STATISTICS";

const DeviceItemView: React.FC<{ deviceName: string }> = (props) => {
  const { deviceName } = props;

  const dataLoaded = useDataProviderSelector(state=>state.dataLoaded)
  const devices = useDataProviderSelector(state=>state.devices)
  const deviceObj = useMemo(()=>devices.find(dev=>dev.device.name===deviceName),[deviceName,devices])
  const device = useMemo(()=>deviceObj?.device,[deviceObj])
  const lastMeasure = useMemo(()=>deviceObj?.lastMeasure,[deviceObj])
  
  const connected = useMemo(()=>device?.connected,[device])

  const PRIMARY_COLOR = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-primary-rgb");

  const WARNING_COLOR = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-warning-rgb");

  const deviceColor=useMemo(()=>connected ? PRIMARY_COLOR : WARNING_COLOR,[connected])
  // const deviceColor = getColorForDeviceGroup(device?.groupIds?.[0]);
  const colorString = `rgb(${deviceColor})`

  const {initialValue: initialValueTab,handleValueChange: handleValueChangeTab,valueIndex: valueIndexTab} = useSaveStateInSearchParams<TabName>("INFORMATION","TAB")

  const [activeTab,setActiveTab] = useState<TabName>(initialValueTab);

  useEffect(()=>{
    handleValueChangeTab(activeTab)
  },[activeTab])
  useEffect(()=>{
    setActiveTab(initialValueTab)
  },[valueIndexTab])

  const tabRef = useRef<HTMLUListElement>(null);
  const t = useTranslate("devices")

  const chartsService = getContainer().resolve<ChartsService>("charts");


  const {handleValueChange: handleValueChangeChart,initialValue: initialValueChart,valueIndex: valueIndexChart} = useSaveStateInSearchParams<ChartRequests>({
    requestedAlarms: [],
    requestedDataTypes:initialRequestedDataTypes,
    requestedDevices: { [!isUndefined(device) ? device._id :""]: { color: deviceColor, enabled: true } },
    timeScaleState:chartsService.getDefaultTimeScaleState(),
    measuresGrouping: "NONE",
  },"CREQ")
  const [chartRequests, setChartRequests] = useState<ChartRequests>(initialValueChart);
  useEffect(()=>{
    handleValueChangeChart(chartRequests)
  },[chartRequests])
  useEffect(()=>{
    setChartRequests(initialValueChart)
  },[valueIndexChart])



  const [chartRequestIndex,setChartRequestIndex] = useState<number>(0)



  useLayoutEffect(() => {
    if (!tabRef.current) {
      return;
    }

    const handleTabChange = (e: Tab.Events.show) => {
      //@ts-ignore
      const targetId = e.target.id as string;
      const tabName = targetId.split("-")[0] as TabName;
      setActiveTab(tabName)
    };

    const tabEl = tabRef.current;
    //@ts-ignore
    tabEl.addEventListener("show.bs.tab", handleTabChange);
    return () => {
      //@ts-ignore
      tabEl.removeEventListener("show.bs.tab", handleTabChange);
    };
  }, [tabRef,setActiveTab]);

  if(!dataLoaded){
    return <></>
  }

  if (!device) {
    return <Redirect to={DEVICES_PAGE_PATH} />;
  }
  return (
    <div className="px-xl-5 px-1" style={{ maxWidth: 1380, margin: "50px auto" }}>
      <div className="mb-5">
        <DeviceItemHeader
        chartRequestIndex={chartRequestIndex+valueIndexChart}
        chartRequests={chartRequests}
        setChartRequests={setChartRequests}
        setChartRequestIndex={setChartRequestIndex}
        deviceId={device._id}
          deviceName={device.name}
          deviceColor={deviceColor}
          lastMeasure={lastMeasure!}
          deviceSerialNumber={device.ssn}
          deviceRunningHistory={device.history}
          connected={!!connected}
        />
      </div>
      <ul ref={tabRef} style={{fontSize:"1.1rem"}} className="nav nav-tabs mx-5" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link bg-light ${
              initialValueTab === "INFORMATION" ? "active" : ""
            }`}
            id="INFORMATION-tab"
            data-bs-toggle="tab"
            data-bs-target="#INFORMATION"
            type="button"
            role="tab"
            aria-controls="INFORMATION"
            aria-selected="true"
            style={{color:activeTab!=="INFORMATION" ? colorString : undefined,borderWidth:3}}
          >
            {t("INFORMATION_TAB")}
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link bg-light ${
              initialValueTab === "TECHNICAL_DATA" ? "active" : ""
            }`}
            id="TECHNICAL_DATA-tab"
            data-bs-toggle="tab"
            data-bs-target="#TECHNICAL_DATA"
            type="button"
            role="tab"
            aria-controls="TECHNICAL_DATA"
            aria-selected="false"
            style={{color:activeTab!=="TECHNICAL_DATA" ? colorString : undefined,borderWidth:3}}
          >
            {t("TECHNICAL_DATA_TAB")}
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link bg-light ${
              initialValueTab === "HISTORY" ? "active" : ""
            }`}
            id="HISTORY-tab"
            data-bs-toggle="tab"
            data-bs-target="#HISTORY"
            type="button"
            role="tab"
            aria-controls="HISTORY"
            aria-selected="false"
            
            style={{color:activeTab!=="HISTORY" ? colorString : undefined,borderWidth:3}}
          >
            {t("HISTORY_TAB")}
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link bg-light ${
              initialValueTab === "ALARM_EXCEEDS_STATISTICS" ? "active" : ""
            }`}
            id="ALARM_EXCEEDS_STATISTICS-tab"
            data-bs-toggle="tab"
            data-bs-target="#ALARM_EXCEEDS_STATISTICS"
            type="button"
            role="tab"
            aria-controls="ALARM_EXCEEDS_STATISTICS"
            aria-selected="false"
            
            style={{color:activeTab!=="ALARM_EXCEEDS_STATISTICS" ? colorString : undefined,borderWidth:3}}
          >
            {t("ALARM_EXCEEDS_STATISTICS_TAB")}
          </button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          className={`tab-pane fade${
            initialValueTab === "INFORMATION" ? "show active" : ""
          }`}
          id="INFORMATION"
          role="tabpanel"
          aria-labelledby="INFORMATION-tab"
        >
          <InformationTab deviceObj={deviceObj} />
        </div>
        <div
          className={`tab-pane fade${
            initialValueTab === "TECHNICAL_DATA" ? "show active" : ""
          }`}
          id="TECHNICAL_DATA"
          role="tabpanel"
          aria-labelledby="TECHNICAL_DATA-tab"
        >
          <TechnicalDataTab/>
        </div>
        <div
          className={`tab-pane fade${
            initialValueTab === "HISTORY" ? "show active" : ""
          }`}
          id="HISTORY"
          role="tabpanel"
          aria-labelledby="HISTORY-tab"
        >
          <HistoryTab deviceId={device._id}/>
        </div>
        <div
          className={`tab-pane fade${
            initialValueTab === "ALARM_EXCEEDS_STATISTICS" ? "show active" : ""
          }`}
          id="ALARM_EXCEEDS_STATISTICS"
          role="tabpanel"
          aria-labelledby="ALARM_EXCEEDS_STATISTICS-tab"
        >
          <AlarmExceedsStatisticsTab setChartRequests={(cr)=>{setChartRequests(cr);setChartRequestIndex(curr=>curr+1)}} deviceId={device._id}/>
        </div>
      </div>
    </div>
  );
};

export default DeviceItemView;
