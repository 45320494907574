import React, { createContext, useMemo } from "react";
import context from "react-bootstrap/esm/AccordionContext";
import { createSelectorHook, Provider } from "react-redux";
import { getContainer } from "../app-container";
import { AuthService } from "./auth.service";
import { AuthState } from "./store";

export const AuthContext = createContext({});

export const useAuthSelector: <S>(
  selector: (state: AuthState) => S
//   @ts-ignore
) => S = createSelectorHook(AuthContext);

export const AuthProvider:React.FC<{children:React.ReactNode}> = ({children})=>{
    const store = useMemo(()=>getContainer().resolve<AuthService>("auth").getStore(),[])

    return(
        <Provider context={AuthContext as any} store={store}>
          {children}
        </Provider>
    )
}