import download from "downloadjs";
import { inject, injectable } from "tsyringe";
import { js2xml } from "xml-js";
import { getConstants } from "../../../../config/constants";
import { DataProviderService } from "../../../data-provider/data-provider.service";
import { DevicesService } from "../../../devices/devices.service";
import { ChartRequests } from "../../models/chart-requests.model";
import { DeviceRequest } from "../../models/device-request.model";
import { TimeScaleRange, TimeScaleState } from "../../models/time-scale-state";
import { ChartDataProviderService } from "../data-provider/chart-data-provider.service";
import { ChartsTableService } from "../table/table.service";
import {
  DataExporterRequests,
  DataExportFormat,
} from "./models/data-exporter-requests.model";
import { stringify as csvStringify } from "csv-stringify/browser/esm/sync";
import { Device, DeviceGroup, Measure, AggregationRange, AllDataTypeAliases, DataTypeAlias } from "shared";
import { DateTime } from "luxon";
import { v3 } from "uuid";
import { isNull, isUndefined } from "lodash";

type AggregationInfo = {
  aggregation: boolean;
  aggregationTime_s?: number;
};

const exportedValueOrder:Array<DataTypeAlias> = ["CO2","CO","TMP","AMP","HMD","PWS"]

@injectable()
export class ChartDataExporterService {
  constructor(
    @inject("data-provider") private dataProviderService: DataProviderService,
    @inject("chart-data-provider")
    private chartDataProviderService: ChartDataProviderService,
    @inject("devices") private devicesService: DevicesService,
    @inject("charts-table") private chartsTableService: ChartsTableService
  ) {}

  async exportData(requests: DataExporterRequests) {
    const { devices: requestedDevicesIds, format, timeScaleState } = requests;
    const { deviceGroups, devices } = this.dataProviderService
      .getStore()
      .getState();

    const aggregation = getConstants().DATA_EXPORT_AGGREGATION;

    const measures = await this.dataProviderService.getMeasures(
      requestedDevicesIds,
      DateTime.fromSeconds(timeScaleState.startTimestamp_s, {
        zone: "utc",
      }).toJSDate(),
      DateTime.fromSeconds(timeScaleState.endTimestamp_s, {
        zone: "utc",
      }).toJSDate(),
      aggregation
    );

    const requestedDevices = devices
      .filter((dev) => requestedDevicesIds.includes(dev.device._id))
      .map((dev) => dev.device);
    const requestedGroups = deviceGroups.filter((group) =>
      requestedDevices.find((dev) => dev.groupIds.includes(group._id))
    );

    const aggregationInfo = this.getAggregationInfo(aggregation);

    if (format === DataExportFormat.JSON) {
      return await this.downloadJSONData(
        requests,
        measures,
        devices.map((dev) => dev.device),
        deviceGroups,
        aggregationInfo
      );
    } else if (format === DataExportFormat.XML) {
      this.downloadXMLData(
        requests,
        measures,
        requestedDevices,
        requestedGroups,
        aggregationInfo
      );
    } else if (format === DataExportFormat.CSV) {
      this.downloadCSVData(measures, requestedDevices, timeScaleState);
    } else {
      throw Error("Incorrect export format");
    }
  }

  checkIfRequestsAreDownloadable(requests: DataExporterRequests): boolean {
    const { devices, format, timeScaleState } = requests;

    if (devices.length === 0) {
      return false;
    }

    if (timeScaleState.startTimestamp_s >= timeScaleState.endTimestamp_s) {
      return false;
    }

    return true;
  }

  private async downloadJSONData(
    requests: DataExporterRequests,
    data: Array<Measure>,
    devices: Array<Device>,
    deviceGroups: Array<DeviceGroup>,
    aggregationInfo: AggregationInfo
  ) {
    const exportObject = this.getExportObject(requests,data,devices,deviceGroups,aggregationInfo)

    const json = JSON.stringify(exportObject);
    download(json, "data-export.json", "application/json");
  }

  private downloadXMLData(
    requests: DataExporterRequests,
    data: Array<Measure>,
    devices: Array<Device>,
    deviceGroups: Array<DeviceGroup>,
    aggregationInfo: AggregationInfo
  ) {
    const exportObject = this.getExportObject(requests,data,devices,deviceGroups,aggregationInfo,true)

    const xml = js2xml(exportObject, { compact: true });
    download(xml, "data-export.xml", "application/xml");
  }

  private getExportObject(
    requests: DataExporterRequests,
    data: Array<Measure>,
    devices: Array<Device>,
    deviceGroups: Array<DeviceGroup>,
    aggregationInfo: AggregationInfo,
    namingSingular: boolean = false
  ) {
    const devicesExportObject = devices.map((dev) =>
      this.getExportedDeviceData(dev, requests.timeScaleState)
    );

    const measuresExportObject = data.map((measure) =>
      this.getExportedMeasure(measure)
    );
    const groupsExportObject = deviceGroups.map((dgroup) =>
      this.getExportedDeviceGroupData(dgroup)
    );

    let exportObject: any;

    if (namingSingular) {
      exportObject = {
        data: {
          timeRange: requests.timeScaleState,
          aggregationInfo,
          device: devicesExportObject,
          deviceGroup: groupsExportObject,
          measure: measuresExportObject,
        },
      };
    } else {
      exportObject = {
        timeRange: requests.timeScaleState,
        aggregationInfo,
        devices: devicesExportObject,
        deviceGroups:groupsExportObject,
        measures: measuresExportObject,
      };
    }
    return exportObject;
  }

  private async downloadCSVData(
    data: Array<Measure>,
    devices: Array<Device>,
    timeScaleState: TimeScaleState
  ) {
    const chartData =
      await this.chartDataProviderService.getChartDataWithMeasures(
        data,
        this.chartDataProviderService.getMeasuresGrouping(
          getConstants().DATA_EXPORT_AGGREGATION
        )
      );

    const requestedDevices: {
      [deviceName: string]: DeviceRequest;
    } = {};
    Object.keys(chartData.measuresDatasetsSetup).forEach((devName) => {
      requestedDevices[devName] = {
        enabled: true,
      };
    });

    const requestedDataTypesSorted = [...chartData.dataTypesIncluded].sort((dtA,dtB)=>{

      const dataTypeAliasA = this.dataProviderService.getDataTypeAlias(dtA)
      const dataTypeAliasB = this.dataProviderService.getDataTypeAlias(dtB)

      return exportedValueOrder.findIndex(dta=>dta===dataTypeAliasA) - exportedValueOrder.findIndex(dta=>dta===dataTypeAliasB)
    })

    const chartRequests: ChartRequests = {
      measuresGrouping: this.chartDataProviderService.getMeasuresGrouping(
        getConstants().DATA_EXPORT_AGGREGATION
      ),
      requestedAlarms: [],
      requestedDataTypes: requestedDataTypesSorted,
      requestedDevices,
      timeScaleState,
    };

    const tableItem = this.chartsTableService.getItemTableData(
      chartData,
      chartRequests,
      { singleRowHeader: true }
    );

    console.log(tableItem.head,tableItem.body,"DEBUG:CSV")

    const doubleArrayTable: Array<Array<string>> = [
      tableItem.head[0],
      ...tableItem.body,
    ].map((row) =>
      row.map((cell) => (typeof cell === "object" ? cell.content : cell))
    );

    const csv = csvStringify(doubleArrayTable);
    download(csv, "data-export.csv", "text/csv");
  }

  private getExportedDeviceData(
    device: Device,
    timeScaleRange: TimeScaleRange
  ) {
    const { groupIds, history, _id, name } = device;

    const rangeHistory = this.devicesService
      .getTimeRangeRunningHistory(history, timeScaleRange)
      .map((item) => {
        if (item.timeStart_s) {
          //@ts-ignore
          item.timeStart_s = DateTime.fromJSDate(item.timeStart_s).toSeconds();
        }
        if (item.timeEnd_s) {
          //@ts-ignore
          item.timeEnd_s = DateTime.fromJSDate(item.timeEnd_s).toSeconds();
        }
        return item;
      });

    return {
      id: _id,
      groupIds,
      name,
      history: rangeHistory,
    };
  }

  private getExportedDeviceGroupData(group: DeviceGroup) {
    const { _id, name } = group;
    return {
      id: _id,
      name,
    };
  }

  private getExportedMeasure(measure: Measure) {
    const { v, dtime, dev } = measure;

    const orderedNFilteredV: { [dataType: string]: number } = {};

    exportedValueOrder.forEach(dtA=>{

      const value = v[dtA]
      if(isNull(value)||isUndefined(value)){
        return
      }
      orderedNFilteredV[dtA]=value
    })

    return {
      dtime: DateTime.fromJSDate(dtime).toSeconds(),
      dev,
      v: orderedNFilteredV,
    };
  }

  private getAggregationInfo(aggregation?: AggregationRange): AggregationInfo {
    if (!aggregation) {
      return {
        aggregation: false,
      };
    }
    if (aggregation === "HOUR") {
      return {
        aggregation: true,
        aggregationTime_s: 60 * 60,
      };
    }
    if (aggregation === "DAILY") {
      return {
        aggregation: true,
        aggregationTime_s: 60 * 60 * 24,
      };
    }
    throw Error("Not handled aggregation");
  }
}
