import React, { InputHTMLAttributes, useEffect, useRef } from "react";

const IndeterminateInput: React.FC<
  {
    children?: React.ReactNode;
    indeterminate: boolean;
  } & InputHTMLAttributes<any>
> = ({ children, indeterminate, ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    const setIndeterminate = () => {
      if (inputRef.current) {
        inputRef.current.indeterminate = indeterminate;
      }
    };
    setIndeterminate()
    inputRef.current.addEventListener("click", setIndeterminate);
    return ()=>inputRef.current?.removeEventListener("click",setIndeterminate)
  }, [inputRef, indeterminate]);

  return (
    <input ref={inputRef} {...props}>
      {children}
    </input>
  );
};

export default IndeterminateInput;
