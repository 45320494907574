import { useTranslate } from "react-translate";
import TabContainer from "./res/TabContainer";
import { useMemo } from "react";
import { DateTime } from "luxon";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { RunningHistory } from "shared";

const HistoryTab: React.FC<{ deviceId: string }> = (props) => {
  const t = useTranslate("devices");
  const { deviceId } = props;

  const devices = useDataProviderSelector(state=>state.devices);

  const history = useMemo<RunningHistory | undefined>(
    () => devices.find((dev) => dev.device._id === deviceId)?.device.history,
    [deviceId]
  );

  const historyReverse = useMemo<RunningHistory | undefined>(()=>history&&[...history].reverse(),[history])

  if (!historyReverse) {
    console.warn("Wrong device id");
    return null;
  }

  return (
    <TabContainer>
      <h3 className="mb-3">{t("HISTORY_TAB")}</h3>

      <ul className="list-group list-group-flush">
        {historyReverse.map((item, i, arr) => {

            const timeStartString = DateTime.fromJSDate(item.timeStart_s,{zone:"utc"}).toLocaleString(DateTime.DATETIME_SHORT)
            const timeEndString = item.timeEnd_s ? DateTime.fromJSDate(item.timeEnd_s,{zone:"utc"}).toLocaleString(DateTime.DATETIME_SHORT) : t("TIME_END_NOW")

          return (
            <li
              className="list-group-item  bg-light bg-gradient"
              aria-current="true"
              key={i}
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">#{arr.length - i}</h5>
      <small className="text-muted">{item.location?.locationString}</small>
              </div>
          <p className="mb-1">{t("TIME_START_LABEL")}: {timeStartString}<br/>{t("TIME_END_LABEL")}: {timeEndString}</p>
            </li>
          );
        })}
      </ul>
    </TabContainer>
  );
};

export default HistoryTab;
