import { AlertService } from "../../../modules/alerts/alert.service";
import { getContainer } from "../../../modules/app-container";
import translations from "../../../translations/translations";
import { NetworkErrorHandler } from "../error-handler.interface";

const passwordError: NetworkErrorHandler = (error) => {

    if((error?.response?.data as any)?.title!=="Wrong password"){
        return false
    }


  const alertService = getContainer().resolve<AlertService>("alert");
  alertService.pushMessage({
    theme: "danger",
    message: translations.PL.menu.INCORRECT_PASSWORD,
  });
  return true;
};

export default passwordError;
