import { DateTime } from "luxon";
import { User } from "shared";
import { UserState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";

type Payload = {};

class UpdateNotificationRefreshTimeClass extends ReducerActionClass<
  UserState,
  Payload
> {
  getActionName(): string {
    return "UPDATE_NOTIFICATION_REFRESH_TIME";
  }
  handleDispatch(prevState: UserState): UserState {
    const { user: prevUser } = prevState;

    if (!prevUser) {
      return prevState;
    }

    const user: User = {
      ...prevUser,
      notificationRefreshTime: DateTime.now().toJSDate(),
    };
    return {
      ...prevState,
      user
    };
  }
}
export const UpdateNotificationRefreshTime = new UpdateNotificationRefreshTimeClass()