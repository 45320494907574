import { useMemo } from "react";
import { useTranslate } from "react-translate";
import TabControlls from "../../../utils/tabs/TabControlls";
import TabSwitch from "../../../utils/tabs/TabSwitch";
import { TabsArray, useTabView } from "../../../utils/tabs/useTabView";
import OrganisationsTab from "./tabs/Admin/OrganisationsTab";
import AlarmSetsTab from "./tabs/Both/AlarmSetsTab";
import AlarmsTab from "./tabs/Both/AlarmsTab";
import DevicesTab from "./tabs/Both/DevicesTab";
import SmsConfigTab from "./tabs/Both/SmsConfigTab";
import EmailConfigTab from "./tabs/Both/EmailConfigTab";
import NotificationsTab from "./tabs/Admin/NotificationsTab";
import SystemTab from "./tabs/Admin/SystemTab";

type AdminPanelTab = "system" | "organisations" | "sms" | "devices" | "alarm_sets_global" | "alarms_global"|"email"|"notifications" ;

const AdminPanel: React.FC = () => {
  const t = useTranslate("mod");

  const adminPanelTabs = useMemo<Array<{name:AdminPanelTab,Component:React.FC}>>(()=>([
    { name: "system", Component: () => <SystemTab/> },
    { name: "organisations", Component: () => <OrganisationsTab/> },
    { name: "sms", Component: () => <SmsConfigTab organisationID={null}/> },
    { name: "email", Component: () => <EmailConfigTab organisationID={null}/> },
    { name: "devices", Component: () => <DevicesTab organisationId={null}/> },
    { name: "alarm_sets_global", Component: () => <AlarmSetsTab organisationId={null} /> },
    { name: "alarms_global", Component: () => <AlarmsTab organisationId={null} /> },
    { name: "notifications", Component: () => <NotificationsTab  /> },
  ]),[])

  const { tabRef, initialTab } = useTabView(adminPanelTabs[0].name, "TAB");

  return (
    <div className="px-xl-5 px-2" style={{ margin: "50px auto" }}>
      <div className="mb-5 text-center text-lg-start">
        <h1>{t("ADMIN_PANEL_HEADER")}</h1>
        <h5 className="ms-1">{t("ADMIN_PANEL_PARAGRAPH")}</h5>
      </div>

      <ul
        style={{ fontSize: "1.1rem" }}
        className="nav nav-tabs"
        role="tablist"
        ref={tabRef}
      >
        <TabControlls items={adminPanelTabs} t={t} initialTab={initialTab} />
      </ul>
      <div className="d-flex justify-content-center justify-content-lg-start ps-0 ps-lg-4">
        <div
          style={{  flexGrow: 1 }}
          className="ms-lg-5  mt-4 ms-1"
        >
          <div className="tab-content">
            <TabSwitch initialTab={initialTab} items={adminPanelTabs}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
