import { useCallback, useMemo, useRef } from "react";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { container } from "tsyringe";
import { DeviceGroupRequestsService } from "../../../request-services/device-group-requests.service";
import { useModPanelData } from "../../hooks/useModPanelData";

const DeleteDeviceGroupModal: React.FC<{
  groupId: string | null;
  onClose: () => void;
  organisationId:string

}> = ({ onClose, groupId,organisationId }) => {
  const t = useTranslate("mod");
  const {deviceGroups: groups} = useModPanelData(organisationId);
  const group = useMemo(
    () => groups.find((group) => group._id === groupId),
    [groups, groupId]
  );


  const onSave = useCallback(() => {
    container.resolve(DeviceGroupRequestsService).deleteDeviceGroup(groupId!,organisationId).then(()=>{
      onClose()
    })
  }, [organisationId,groupId]);

  return (
    <DefaultFormModal
      title={t("DGROUP_TOOLTIP_DELETE")}
      show={!!groupId}
      onClose={onClose}
      onSave={onSave}
      submitType="delete"
    >
      <>{t("DELETE_DGROUP_MODAL_PARAGRAPH", { groupName: group?.name || "" })}</>
    </DefaultFormModal>
  );
};

export default DeleteDeviceGroupModal;
