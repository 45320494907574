import { Nav } from "react-bootstrap";
import { useTranslate } from "react-translate";
import { matchPath, useHistory, useLocation } from "react-router-dom";

import { DEVICES_PAGE_PATH } from "../pages/devices";
import { STATS_PAGE_PATH } from "../pages/stats";
import { RAPORTS_PAGE_PATH } from "../pages/reports";
import { PROFILE_PAGE_PATH } from "../pages/profile";
import { ALARMS_PAGE_URL } from "../pages/alarms";

import { ReactComponent as DevicesIcon } from "bootstrap-icons/icons/robot.svg";
import { ReactComponent as StatsIcon } from "bootstrap-icons/icons/graph-up.svg";
import { ReactComponent as RaportsIcon } from "bootstrap-icons/icons/clipboard-data.svg";
import { ReactComponent as ProfileIcon } from "bootstrap-icons/icons/person-fill.svg";
import { ReactComponent as AlarmsIcon } from "bootstrap-icons/icons/bell-fill.svg";
import { ReactComponent as ModIcon } from "bootstrap-icons/icons/clipboard-check-fill.svg";
import { ReactComponent as AdminIcon } from "bootstrap-icons/icons/toggles.svg";
import { ReactComponent as LogoutIcon } from "bootstrap-icons/icons/box-arrow-left.svg";
import { MOD_PAGE_PATH } from "../pages/mod";
import { ADMIN_PAGE_PATH } from "../pages/admin";
import { AuthService } from "../../modules/auth/auth.service";
import { getContainer } from "../../modules/app-container";
import { useMemo } from "react";
import { useUserSelector } from "../../modules/user/user.provider";
import { useCanAccessModPanel } from "../../modules/mod/hooks/useCanAccessModPanel";
import { useCanAccessAdminPanel } from "../../modules/mod/hooks/useCanAccessAdminPanel";
import { useDataProviderSelector } from "../../modules/data-provider/data-provider.provider";
import { UserService } from "../../modules/user/user.service";
// import { ReactComponent as AlarmsIcon } from "bootstrap-icons/icons/exclamation-triangle-fill.svg";

const SideMenuContent: React.FC<{ expanded: boolean }> = ({ expanded }) => {
  const t = useTranslate("menu");
  const history = useHistory();
  const location = useLocation();

  const {access: modPanelAccess} = useCanAccessModPanel()
  const {access: adminPanelAccess} = useCanAccessAdminPanel()

  const userData = useUserSelector(state=>state);
  const appData = useDataProviderSelector(state=>state);
  const {unseenNotifications} = useMemo(()=>getContainer().resolve<UserService>("user").getUnseenNotifications(),[userData,appData])


  const menuItems = useMemo(() => {
    const menuItems = [
      
      {
        Icon: DevicesIcon,
        alias: "DEVICES",
        path: DEVICES_PAGE_PATH,
      },
      {
        Icon: StatsIcon,
        alias: "STATS",
        path: STATS_PAGE_PATH,
      },
      {
        Icon: RaportsIcon,
        alias: "RAPORTS",
        path: RAPORTS_PAGE_PATH,
      },
      {
        Icon: AlarmsIcon,
        alias: "ALARMS",
        path: ALARMS_PAGE_URL,
      },
      {
        Icon: ProfileIcon,
        alias: "PROFILE",
        path: PROFILE_PAGE_PATH,
        badge:unseenNotifications.length===0 ? undefined : unseenNotifications.length.toString()
      },
    ];

    if(modPanelAccess){
      menuItems.push(
        {
          Icon: ModIcon,
          alias: "MOD_PANEL",
          path: MOD_PAGE_PATH,
        },)
    }

    if(adminPanelAccess){
      menuItems.push(

        {
          Icon: AdminIcon,
          alias: "ADMIN_PANEL",
          path: ADMIN_PAGE_PATH,
        },
      )
    }

    return menuItems;
  }, [modPanelAccess,adminPanelAccess,unseenNotifications]);

  return (
    <ul className="nav nav-pills flex-column mb-auto">
      {menuItems.map((Item, i) => {
        const matching = matchPath(location.pathname, Item.path);
        const active = !!matching;

        return (
          <li key={i} className="nav-item py-1">
            <Nav.Link
              className={active ? "active" : ""}
              href={Item.path}
              onClick={(e) => {
                e.preventDefault();
                history.push(Item.path);
              }}
            >
              <span className="text-white">
                <Item.Icon
                  style={{ display: "inline-block", verticalAlign: "middle" }}
                  width="25"
                  height="25"
                />
                <span
                  style={{
                    verticalAlign: "bottom",
                    display: expanded ? "inline-block" : "none",
                  }}
                  className="ms-2 me-4"
                >
                  <div className="d-flex justify-content-start">
                  <div>{t(`PAGE_${Item.alias}`)}</div>{!Item.badge ? "" : <div style={{position:"relative",top:-2}}><span className=" ms-2 badge rounded-pill bg-info">{Item.badge}</span></div>}
                  </div>
                </span>
              </span>
            </Nav.Link>
          </li>
        );
      })}

      <li className="nav-item py-1">
        <Nav.Link
          href="#"
          onClick={(e) => {
            e.preventDefault();
            getContainer().resolve<AuthService>("auth").logout();
          }}
        >
          <span className="text-white">
            <LogoutIcon
              style={{ display: "inline-block", verticalAlign: "middle" }}
              width="25"
              height="25"
            />
            <span
              style={{
                verticalAlign: "bottom",
                display: expanded ? "inline-block" : "none",
              }}
              className="ms-2 me-4"
            >
              {t(`LOGOUT`)}
            </span>
          </span>
        </Nav.Link>
      </li>
    </ul>
  );
};

export default SideMenuContent;
