import { ChartState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";
import { DataType } from "../models/chart-description.model";
import { ScaleConfig } from "../models/scale-config.model";

type Payload = {
    dataType:DataType,
    partialScaleConfig:Partial<ScaleConfig>
}

class PutScaleConfigClass extends ReducerActionClass<ChartState,Payload>{

    getActionName(){
        return "PUT_SCALE_CONFIG"
    }

    handleDispatch(prevState:ChartState,payload:Payload):ChartState{

        const {scalesConfig: prevScalesConfig} = prevState;
        const {dataType,partialScaleConfig} = payload;

        const prevScaleConfig = prevScalesConfig[dataType];
        const scaleConfig = {...prevScaleConfig,...partialScaleConfig}

        const scalesConfig = {...prevScalesConfig,[dataType]:scaleConfig};

        return{
            ...prevState,
            scalesConfig
        }

    }

}

export const PutScaleConfig = new PutScaleConfigClass()