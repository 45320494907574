import axios from "axios";
import { isUndefined } from "lodash";
import { DateTime } from "luxon";
import { createStore, Store } from "redux";
import { User } from "shared";
import { inject, injectable } from "tsyringe";
import { getConstants } from "../../config/constants";
import { getContainer } from "../app-container";
import { AuthService } from "../auth/auth.service";
import { AuthState } from "../auth/store";
import { DataProviderService } from "../data-provider/data-provider.service";
import { UserReducer, UserState } from "./store";
import { PutUserData } from "./store/putUserData";
import { UpdateNotificationRefreshTime } from "./store/updateNotificationRefreshTime";

@injectable()
export class UserService {
  private store: Store<UserState>;

  constructor(@inject("auth") private authService: AuthService) {
    const reducer = new UserReducer();
    //@ts-ignore
    this.store = createStore(reducer.changeState.bind(reducer));
    this.subscribeToAuth();
  }

  getStore() {
    return this.store;
  }

  updateNotificationRefreshTime() {
    axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/users/notificationRefreshTime`,
    });
    this.store.dispatch(UpdateNotificationRefreshTime.callAction({}));
  }

  getUnseenNotifications() {
    const { notifications } = getContainer()
      .resolve<DataProviderService>("data-provider")
      .getStore()
      .getState();

    const unseenNotifications =notifications.filter(
      (notification) =>
        DateTime.fromJSDate(notification.createdAt).toSeconds() >
        DateTime.fromJSDate(
          this.getStore().getState().user?.notificationRefreshTime ||
            new Date(0)
        ).toSeconds()
    );

    return {unseenNotifications}
  }

  private subscribeToAuth() {
    const authStore = this.authService.getStore();
    const selectLoggedIn = (state: AuthState) =>
      !isUndefined(state.accessToken) && state.loggedIn;
    let prevLoggedIn = false;
    authStore.subscribe(() => {
      const loggedIn = selectLoggedIn(authStore.getState());
      if (loggedIn === prevLoggedIn) {
        return;
      }
      prevLoggedIn = loggedIn;
      if (loggedIn) {
        this.updateUserInfo();
      } else {
        this.removeUserInfo();
      }
    });
  }

  private removeUserInfo() {
    this.store.dispatch(
      PutUserData.callAction({ organisationNames: {}, user: null })
    );
  }

  private async updateUserInfo() {
    const data = (await (
      await axios({ method: "get", url: `${getConstants().SERVER_URL}/users` })
    ).data) as { user: User; organisationNames: { [orgId: string]: string } };
    const { organisationNames, user } = data;
    this.store.dispatch(PutUserData.callAction({ organisationNames, user }));
  }
}
