import { ModDataState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";

type Payload = {}

class RemoveDataClass extends ReducerActionClass<ModDataState,Payload>{
    getActionName(): string {
        return "REMOVE_DATA";
    }
    handleDispatch(_prevState: ModDataState, _payload: Payload): ModDataState {
        return {organisations:{}}
    }

}
export const RemoveData = new RemoveDataClass()