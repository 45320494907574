import React from 'react';
import AlarmsView from '../../../modules/alarms/components/AlarmsView';

export const ALARMS_PAGE_URL = "/alarms"

export const ALARMS_REQUESTS_SEARCH_PARAM = "AREQ"

const AlarmsPage:React.FC = ()=>{

    return(
        <AlarmsView/>
    )

}

export default AlarmsPage;