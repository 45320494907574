import { FieldError } from "react-hook-form";
import { useTranslate } from "react-translate";

const ErrorDisplay: React.FC<{ paramsObj:any,type:string,errorExist:boolean }> = ({
  paramsObj,type,errorExist
}) => {
  const t = useTranslate("forms");


    // return <>{error ? t(`ERROR_${error.type}`, paramsObj) : ""}</>;
  return (
    <>
      {errorExist ? (
        <div style={{display:"flex",justifyContent:"flex-end"}}>
        <div className="text-danger">
          {t(`ERROR_${type}`, paramsObj)}
        </div>
        </div>
      ) : (
        null
      )}
    </>
  );
};

export default ErrorDisplay;
