import { User } from "shared"
import { ReducerActionClass, ReducerCollection } from "../../../utils/store/core"
import { PutUserData } from "./putUserData"
import { UpdateNotificationRefreshTime } from "./updateNotificationRefreshTime"

export type UserState = {
    user:User|null,
    organisationNames:{[orgId:string]:string}
}

export class UserReducer extends ReducerCollection<UserState>{

    static initialState: UserState = {
        user:null,
        organisationNames:{}
    }

    protected initState(): UserState {
        return UserReducer.initialState;
    }
    protected getActionClassObjects(): ReducerActionClass<UserState, any>[] {
        return [PutUserData,UpdateNotificationRefreshTime]
    }
}