const TabList: React.FC<{items:Array<{key:string,value:string}>}>=(props)=>{
    const {items} = props;
    return(
        
      <ul
      className="list-group list-group-flush"
      style={{ fontSize: "1.15rem" }}
    >
      {items.map((item, i) => (
        <li key={i} className="list-group-item bg-light bg-gradient">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>{item.key}</div>

            <div>{item.value}</div>
          </div>
        </li>
      ))}
    </ul>
    )
}

export default TabList