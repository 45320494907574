import { isNull } from "lodash";
import { DateTime } from "luxon";
import { MeasureDatasetSetup } from "../models/measure-dataset-setup.model";
import { DevicesTableTimeGroups, TableTimeGroups } from "./TableView";

const NO_GROUPING_GROUP_LENGTH_S = 120 /* 2 minutes */

export const getDeviceTableTimeGroups = (measuresDatasetsSetup: {
  [deviceName: string]: {
    [dataType: string]: Array<MeasureDatasetSetup>;
  };
}): DevicesTableTimeGroups => {

    const devicesTableTimeGroups:DevicesTableTimeGroups = {}

    Object.keys(measuresDatasetsSetup).forEach(deviceName=>{

        const deviceDatasets = measuresDatasetsSetup[deviceName]; 
        devicesTableTimeGroups[deviceName]={}
        Object.keys(deviceDatasets).forEach(dataType=>{
            const dataTypeDatasets = deviceDatasets[dataType];
            devicesTableTimeGroups[deviceName][dataType] = {}
            dataTypeDatasets.forEach(dataset=>{
                const grouping = dataset.grouping;
                const timeTableGroups:TableTimeGroups = {}
                dataset.measures.forEach(measure=>{
                    const {timestamp_s} = measure
                    /* need implement grouping logic here */
                    if(isNull(measure.value)){
                        return
                    }
                    let timestamp_group:number;
                    if(grouping==="NONE"){
                        timestamp_group = DateTime.fromSeconds(timestamp_s).startOf("hour").toSeconds()
                        while(timestamp_group+NO_GROUPING_GROUP_LENGTH_S<timestamp_s){
                            timestamp_group+=NO_GROUPING_GROUP_LENGTH_S
                        }
                    }else{
                        timestamp_group=timestamp_s;
                    }

                    if(!(timestamp_group in timeTableGroups)){
                        timeTableGroups[timestamp_group]=[];
                    }
                    timeTableGroups[timestamp_group].push(measure);
                })
                devicesTableTimeGroups[deviceName][dataType][grouping] = timeTableGroups

            })
        })

    })

    return devicesTableTimeGroups;
};
