import { useTranslate } from "react-translate";
import { ReactComponent as DeleteIcon } from "bootstrap-icons/icons/trash-fill.svg";
import { ReactComponent as AddIcon } from "bootstrap-icons/icons/plus-square-fill.svg";
import { ReactComponent as SquareIcon } from "bootstrap-icons/icons/square-fill.svg";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Dropdown, Tooltip } from "bootstrap";
import {
  useDataExporterDispatch,
  useDataExporterSelector,
} from "../../charts/submodules/data-exporter/data-exporter.provider";
import { DataExportFormat } from "../../charts/submodules/data-exporter/models/data-exporter-requests.model";
import { UpdateDataExporterRequests } from "../../charts/submodules/data-exporter/store/updateDataExporterRequests";
import { DateTime } from "luxon";
import { getTimeRange } from "../../charts/chart-generating/utils/getTimeRange";
import { useDataProviderSelector } from "../../data-provider/data-provider.provider";
import { getDeviceDisplayGroupId } from "../../devices/utils/getDeviceDisplayGroup";
import { getDevicesInGroup, getDevicesInGroupShort } from "../../devices/utils/getDevicesInGroup";
import { UpdateExportedDevices } from "../../charts/submodules/data-exporter/store/updateExportedDevices";
import { getContainer } from "../../app-container";
import { ChartsService } from "../../charts/charts.service";

const DataExportView: React.FC = () => {
  const t = useTranslate("pdf-generator");

  const dataExporterService = getContainer()
    .resolve<ChartsService>("charts")
    .getChartDataExporterService();
  const format = useDataExporterSelector((state) => state.format);
  const timeScaleState = useDataExporterSelector(
    (state) => state.timeScaleState
  );
  const { startTimestamp_s, endTimestamp_s } = timeScaleState;
  const downloadable = useDataExporterSelector((state) => state.downloadable);
  const dispatch = useDataExporterDispatch();

  const requestedDevices = useDataExporterSelector((state) => state.devices);
  const devicesObj = useDataProviderSelector((state) => state.devices);
  const devices = useMemo(()=>devicesObj.map(dev=>dev.device),[devicesObj])

  const deviceGroups = useDataProviderSelector((state) => state.deviceGroups);
  const notAddedGroups = useMemo(
    () =>
      deviceGroups.filter((group) =>
        devices.find(
          (device) =>
            getDeviceDisplayGroupId(device) ===
              group._id && !requestedDevices.includes(device._id)
        )
      ),
    [deviceGroups, requestedDevices, devices]
  );
  const devicesSelectRef = useRef<HTMLSelectElement>(null);
  const allDevicesAdded = useMemo(
    () => devices.length === requestedDevices.length,
    [devices, requestedDevices]
  );

  const removeDevices = useCallback(() => {
    if (!devicesSelectRef.current) {
      return;
    }
    const deviceIds = Array.from(devicesSelectRef.current!.options)
      .filter((opt) => opt.selected)
      .map((opt) => opt.value);

    dispatch(UpdateExportedDevices.callAction({ devicesToRemove: deviceIds }));

    devicesSelectRef.current.value = "";
  }, [devicesSelectRef, dispatch]);

  const primaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-primary-rgb");

  const secondaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-secondary-rgb");

  const dangerColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-danger-rgb");

  const lightColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-light-rgb");

  useLayoutEffect(() => {
    const tooltipElements = Array.from(
      document.querySelectorAll(".dataExportTooltip")
    );
    const tooltips = tooltipElements.map((el) => new Tooltip(el));
    return () => tooltips.forEach((tooltip) => tooltip.dispose());
  }, []);

  const addDeviceIconDropdownRef = useRef<HTMLAnchorElement>(null);
  const hideAddDeviceIconDropdown = useCallback(() => {
    if (!addDeviceIconDropdownRef.current) {
      return;
    }

    const dropdown = new Dropdown(addDeviceIconDropdownRef.current);
    dropdown.hide();
  }, [addDeviceIconDropdownRef]);

  const downloadData = useCallback(
    () =>
      dataExporterService.exportData({
        format,
        timeScaleState,
        devices: requestedDevices,
      }),
    [format, timeScaleState, requestedDevices]
  );

  return (
    <>
      <div className="mb-3">
        <label htmlFor="formGroupExampleInput" className="form-label">
          {t("FIELD_FORMAT")}
        </label>
        <select
          value={format}
          onChange={(e) => {
            const format = e.target.value as DataExportFormat;
            dispatch(
              UpdateDataExporterRequests.callAction({
                dataExporterRequests: { format },
              })
            );
          }}
          className="form-select mb-3"
        >
          {Object.values(DataExportFormat).map((format) => (
            <option>{format}</option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <div>
          <label htmlFor="formGroupExampleInput" className="form-label">
            {t("FIELD_DEVICES")}
          </label>
          <select
            className="form-select"
            multiple
            aria-label="multiple select example"
            size={5}
            ref={devicesSelectRef}
          >
            {requestedDevices.map((devId) => {
              const device = devices.find(
                (dev) => dev._id === devId
              );
              return <option value={device?._id}>{device?.name}</option>;
            })}
          </select>
        </div>
        <div className="d-flex justify-content-end">
          <div className=" m-1 mb-0" style={{ position: "relative" }}>
            <a
              className="btn-link"
              ref={addDeviceIconDropdownRef}
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              tabIndex={0}
              onKeyDown={(e)=>{
                if(e.nativeEvent.key==="Enter"){
                  //@ts-ignore
                  e.target.click()
                }
              }}        
            >
              <div
                className="dataExportTooltip"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={t("ADD_DEVICE_TOOLTIP")}
              >
                <AddIcon
                  style={{ cursor: allDevicesAdded ? undefined : "pointer" }}
                  fill={`rgb(${
                    allDevicesAdded ? secondaryColor : primaryColor
                  })`}
                  height="25"
                  width="25"
                />
              </div>
            </a>

            <ul
              className={`dropdown-menu dropdown-menu-end ${
                allDevicesAdded ? "d-none" : ""
              }`}
            >
              <li className="dropend">
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      UpdateExportedDevices.callAction({
                        devicesToAdd: devices
                          .filter(
                            (device) => !requestedDevices.includes(device._id)
                          )
                          .map((dev) => dev._id),
                      })
                    );
                    hideAddDeviceIconDropdown();
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <div>{t("ADD_DEVICES_ALL")}</div>
                  </div>
                </a>
              </li>
              {notAddedGroups.map((group) => {
                const devicesInGroup = getDevicesInGroupShort(
                  devices,
                  group._id
                );
                const notAddedDevicesInGroup = devicesInGroup.filter(
                  (device) => !requestedDevices.includes(device._id)
                );

                return (
                  <li className="dropend" key={group._id}>
                    <a
                      data-bs-toggle="dropdown"
                      className="dropdown-item"
                      href="#"
                    >
                      <div className="d-flex justify-content-between">
                        <div>{group.name}</div>
                        <div className="dropdown-toggle ms-3"></div>
                      </div>
                    </a>

                    <ul className="dropdown-menu">
                      <li>
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(
                              UpdateExportedDevices.callAction({
                                devicesToAdd: notAddedDevicesInGroup.map(
                                  (dev) => dev._id
                                ),
                              })
                            );
                            hideAddDeviceIconDropdown();
                          }}
                          className="dropdown-item"
                          href="#"
                        >
                          {t("ADD_DEVICES_IN_GROUP_ALL")}
                        </a>
                      </li>
                      {notAddedDevicesInGroup.map((device) => (
                        <li key={device._id}>
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch(
                                UpdateExportedDevices.callAction({
                                  devicesToAdd: [device._id],
                                })
                              );
                              hideAddDeviceIconDropdown();
                            }}
                            className="dropdown-item"
                            href="#"
                          >
                            {device.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
          <div
            tabIndex={0}
            onKeyDown={(e)=>{
              if(e.nativeEvent.key==="Enter"){
                //@ts-ignore
                e.target.click()
              }
            }}
            style={{ position: "relative", cursor: "pointer" }}
            className="dataExportTooltip m-1 mb-0"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={t("REMOVE_DEVICE_TOOLTIP")}
            onClick={removeDevices}
          >
            <div style={{ position: "absolute", zIndex: 1, top: 1, left: 6 }}>
              <DeleteIcon fill={`rgb(${lightColor})`} height="13" width="13" />
            </div>
            <SquareIcon fill={`rgb(${dangerColor})`} width="25" height="25" />
          </div>
        </div>
      </div>
      <div className="mb-3">
        <label htmlFor="formGroupExampleInput" className="form-label">
          {t("FIELD_SINCE")}
        </label>
        <input
          value={DateTime.fromSeconds(startTimestamp_s).toFormat("yyyy-MM-dd")}
          onChange={(e) => {
            const timestamp_s = DateTime.fromFormat(
              e.target.value,
              "yyyy-MM-dd"
            ).toSeconds();
            const newTimeScaleState = getTimeRange(
              "CUSTOM",
              timestamp_s,
              "START",
              timeScaleState
            );
            dispatch(
              UpdateDataExporterRequests.callAction({
                dataExporterRequests: {
                  timeScaleState: newTimeScaleState,
                },
              })
            );
          }}
          className="form-control"
          type="date"
          placeholder="Default input"
        />
      </div>{" "}
      <div className="mb-3">
        <label htmlFor="formGroupExampleInput" className="form-label">
          {t("FIELD_UNTIL")}
        </label>
        <input
          value={DateTime.fromSeconds(endTimestamp_s - 1).toFormat(
            "yyyy-MM-dd"
          )}
          onChange={(e) => {
            const timestamp_s = DateTime.fromFormat(
              e.target.value,
              "yyyy-MM-dd"
            ).toSeconds();

            const newTimeScaleState = getTimeRange(
              "CUSTOM",
              timestamp_s,
              "END",
              timeScaleState
            );

            dispatch(
              UpdateDataExporterRequests.callAction({
                dataExporterRequests: {
                  timeScaleState: newTimeScaleState,
                },
              })
            );
          }}
          className="form-control"
          type="date"
          placeholder="Default input"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div>
          {format === DataExportFormat.JSON ? (
            <a target="_blank" href="/schemas/data-export.schema.json" tabIndex={-1}>
              <button
                type="button"
                className="btn btn-primary mt-3 me-1"
              >
                {t("OPEN_SCHEMA_JSON")}
              </button>
            </a>
          ) : format === DataExportFormat.XML ? (
            <a target="_blank" href="/schemas/data-export.xsd" tabIndex={-1}>
              <button
                type="button"
                className="btn btn-primary mt-3 me-1"
              >
                {t("OPEN_SCHEMA_XML")}
              </button>
            </a>
          ) : null}
        </div>
        <div className="ms-2">
          <button
            disabled={!downloadable}
            type="button"
            className="btn btn-primary mt-3 me-1"
            onClick={downloadData}
          >
            {t("EXPORT_DATA")}
          </button>
        </div>
      </div>
    </>
  );
};

export default DataExportView;
