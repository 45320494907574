import axios from "axios";
import { ChangeRoleDto, CreateUserDto, ResetPasswordDto, SmsConfigDto } from "shared";
import { injectable, Lifecycle, scoped } from "tsyringe";
import { getConstants } from "../../../config/constants";
import { getContainer } from "../../app-container";
import { ModDataService } from "../mod-data.service";

@injectable()
@scoped(Lifecycle.ContainerScoped)
export class UserRequestsService {
  async create(dto:CreateUserDto,orgId:string) {
    await axios({
      method: "post",
      url: `${getConstants().SERVER_URL}/users/organisations/${orgId}`,
      data:dto
    });
    await getContainer().resolve<ModDataService>("mod-data").handleOrganisationDataChanges(orgId)

  }

  async delete(userId:string,orgId:string){
    
    await axios({
      method: "delete",
      url: `${getConstants().SERVER_URL}/users/organisations/${orgId}/${userId}`,
    });

    await getContainer().resolve<ModDataService>("mod-data").handleOrganisationDataChanges(orgId)
  }

  async changeRoles(dto:ChangeRoleDto,userId:string,orgId:string){
    
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/users/organisations/${orgId}/${userId}/roles`,
      data:dto
    });
    await getContainer().resolve<ModDataService>("mod-data").handleOrganisationDataChanges(orgId)

  }


  async resetPassword(dto:ResetPasswordDto,userId:string,orgId:string,systemRequest:boolean=false){
    
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/users/organisations/${orgId}/${userId}/password`,
      data:dto
    });
    if(systemRequest){
    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()
    }else{
    await getContainer().resolve<ModDataService>("mod-data").handleOrganisationDataChanges(orgId)
    }
  }
}
