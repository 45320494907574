import { createContext } from "react";
import { createDispatchHook, createSelectorHook, Provider } from "react-redux";
import { useTranslate } from "react-translate";
import { Action, Dispatch } from "redux";
import { AlarmsState } from "../store";
import AlarmsList from "./AlarmsList";
import { useCreateAlarmsStore } from "./useCreateAlarmsStore";

export const AlarmsContext = createContext({});

export const useAlarmsDispatch: <AppDispatch extends Dispatch<
  Action<AlarmsState>
> = Dispatch<Action<AlarmsState>>>() => AppDispatch = createDispatchHook(
  // @ts-ignore
  AlarmsContext
);
export const useAlarmsSelector: <S>(
  selector: (state: AlarmsState) => S
  // @ts-ignore
) => S = createSelectorHook(AlarmsContext);

const AlarmsView: React.FC = () => {
  const t = useTranslate("alarms");

  const store = useCreateAlarmsStore();

  return (
    <>
      <Provider context={AlarmsContext as any} store={store}>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "stretch",
          }}
        >
          <div
            className="px-xl-5 px-2"
            style={{
              maxWidth: 1380,
              margin: "50px auto",
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            <div className="mb-5 text-center text-lg-start">
              <h1>{t("ALARMS_HEADER")}</h1>
              <h5 className="ms-1">{t("ALARMS_PARAGRAPH")}</h5>
            </div>
            <div style={{ flexGrow: 1, position: "relative" }}>
              
              <AlarmsList />
            </div>
          </div>
        </div>
      </Provider>
    </>
  );
};

export default AlarmsView;
