import { Chart } from "chart.js";
import { ChartState } from "../../store";
import { getYScales } from "../get-y-scales";

export const updateYScalesProcedure = (
  chart: Chart,
  chartState: ChartState,
  t:any
) => {
  const yScales = getYScales(chartState,t);

  //@ts-ignore
  const xScale = chart.config._config.options.scales.x;

  //@ts-ignore
  chart.config._config.options.scales = { x: xScale, ...yScales };

};
