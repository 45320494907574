import { identity, isNull, isUndefined, sortBy } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSortBy, useTable } from "react-table";
import type { Column } from "react-table";
import { useDataProviderSelector } from "../../../data-provider/data-provider.provider";
import { getDevicesInGroup } from "../../utils/getDevicesInGroup";
import { getDeviceDisplayGroupId } from "../../utils/getDeviceDisplayGroup";
import { getContainer } from "../../../app-container";
import { DevicesService } from "../../devices.service";
import Decimal from "decimal.js";
import {
  displayDataTypeNumber,
  getDisplayDefaultDecimalPoints,
} from "../../../charts/chart-generating/utils/getDisplayDefaultDecimalPoints";
import { useTranslate } from "react-translate";
import { useSaveStateInSearchParams } from "../../../charts/components/useSaveStateInSearchParams";
import {
  AllDataTypes,
  DataType,
} from "../../../charts/models/chart-description.model";
import DevicesTableColumnSelect from "./DevicesTableColumnSelect";
import { generatePath, useHistory } from "react-router";
import { DEVICE_ITEM_PAGE_PATH } from "../../../../skeleton/pages/devices/device-item-page";
import { AlarmsService } from "../../../alarms/alarms.service";
import AlarmExceedsStatisticDisplay from "../components/AlarmExceedsStatisticDisplay";
import { AESTimeRange, AlarmType, AllAlarmTypes } from "shared";
import { DateTime } from "luxon";

type Props = {
  selectedGroupId: string | null;
};

export const callForAllAesUnits = (
  f: (timeRange: AESTimeRange, alarmType: AlarmType, dataType: DataType) => void
) => {
  Object.values(AESTimeRange).forEach((aesTimeRange) =>
    AllAlarmTypes.forEach((alarmType) =>
      (["CO2_LEVEL", "CO_LEVEL"] as Array<DataType>).forEach((dataType) =>
        f(aesTimeRange, alarmType, dataType)
      )
    )
  );
};

export enum DevicesTableColumnName {
  "NAME" = "NAME",
  "SERIAL_NUMBER" = "SERIAL_NUMBER",
  "GROUP" = "GROUP",
  "LOCATION" = "LOCATION",
  "RSSI" = "RSSI",
  "CONNECTED" = "CONNECTED",
  "LAST_MEASURE" = "LAST_MEASURE",
  "CO2_LEVEL" = "CO2_LEVEL",
  "CO_LEVEL" = "CO_LEVEL",
  "TEMPERATURE" = "TEMPERATURE",
  "ATMOSPHERIC_PRESSURE" = "ATMOSPHERIC_PRESSURE",
  "HUMIDITY" = "HUMIDITY",
  "POWER_SUPPLY" = "POWER_SUPPLY",
  "aes-WEEKLY-WARNING-CO2_LEVEL" = "aes-WEEKLY-WARNING-CO2_LEVEL",
  "aes-WEEKLY-WARNING-CO_LEVEL" = "aes-WEEKLY-WARNING-CO_LEVEL",
  "aes-MONTHLY-WARNING-CO2_LEVEL" = "aes-MONTHLY-WARNING-CO2_LEVEL",
  "aes-MONTHLY-WARNING-CO_LEVEL" = "aes-MONTHLY-WARNING-CO_LEVEL",
  "aes-WEEKLY-CRITICAL-CO2_LEVEL" = "aes-WEEKLY-CRITICAL-CO2_LEVEL",
  "aes-WEEKLY-CRITICAL-CO_LEVEL" = "aes-WEEKLY-CRITICAL-CO_LEVEL",
  "aes-MONTHLY-CRITICAL-CO2_LEVEL" = "aes-MONTHLY-CRITICAL-CO2_LEVEL",
  "aes-MONTHLY-CRITICAL-CO_LEVEL" = "aes-MONTHLY-CRITICAL-CO_LEVEL",
}

const DEFAULT_VISIBLE_COLUMNS: Array<DevicesTableColumnName> = [
  DevicesTableColumnName.NAME,
  DevicesTableColumnName.GROUP,
  DevicesTableColumnName.LOCATION,
  DevicesTableColumnName.CO2_LEVEL,
  DevicesTableColumnName.CO_LEVEL,
  DevicesTableColumnName.TEMPERATURE,
  DevicesTableColumnName.ATMOSPHERIC_PRESSURE,
  DevicesTableColumnName.HUMIDITY,
  DevicesTableColumnName.POWER_SUPPLY,
  DevicesTableColumnName["aes-WEEKLY-WARNING-CO2_LEVEL"],
  DevicesTableColumnName["aes-WEEKLY-WARNING-CO_LEVEL"],
];

const DevicesTable: React.FC<Props> = ({ selectedGroupId }) => {
  const devicesService = getContainer().resolve<DevicesService>("devices");
  const groups = useDataProviderSelector((state) => state.deviceGroups);
  const devices = useDataProviderSelector((state) => state.devices);
  const tCharts = useTranslate("chart");
  const t = useTranslate("devices");
  const history = useHistory();
  const alarmService = getContainer().resolve<AlarmsService>("alarms");

  const devicesInGroup = useMemo(
    () =>
      isNull(selectedGroupId)
        ? devices
        : getContainer()
            .resolve<DevicesService>("devices")
            .getGroupsDevices(
              groups,
              devices,
              selectedGroupId === "false" ? false : selectedGroupId
            ),
    [selectedGroupId, devices, groups]
  );

  const {
    handleValueChange: handleValueChangeColumns,
    initialValue: initialValueColumns,
    valueIndex: valueIndexColumns,
  } = useSaveStateInSearchParams(DEFAULT_VISIBLE_COLUMNS, "SHOW_COLUMNS");
  const [requestedColumns, setRequestedColumns] =
    useState<Array<DevicesTableColumnName>>(initialValueColumns);
  useEffect(() => {
    handleValueChangeColumns(requestedColumns);
  }, [requestedColumns]);
  useEffect(() => {
    setRequestedColumns(initialValueColumns);
  }, [valueIndexColumns]);

  const columns = useMemo<Array<Column>>(() => {
    const columns: Array<Column> = [];

    (
      [
        "NAME",
        "SERIAL_NUMBER",
        "GROUP",
        "LOCATION",
      ] as Array<DevicesTableColumnName>
    ).forEach((columnName) => {
      if (
        !(columnName === DevicesTableColumnName.NAME) &&
        !requestedColumns.includes(DevicesTableColumnName[columnName])
      ) {
        return;
      }
      columns.push({
        Header: t(`COLUMN_DEVICE_${columnName}`),
        accessor: columnName,
      });
    });
    if(requestedColumns.includes(DevicesTableColumnName.CONNECTED)){
      
      columns.push({
        Header: t(`COLUMN_DEVICE_${DevicesTableColumnName.CONNECTED}`),
        accessor: DevicesTableColumnName.CONNECTED,
        Cell:(obj)=><>{obj.value ? t("COLUMN_DEVICE_CONNECTED_YES") : t("COLUMN_DEVICE_CONNECTED_NO")}</>
      });
    }
    if(requestedColumns.includes(DevicesTableColumnName.RSSI)){
      
      columns.push({
        Header: t(`COLUMN_DEVICE_${DevicesTableColumnName.RSSI}`),
        accessor: DevicesTableColumnName.RSSI,
        //@ts-ignore
        Cell:(obj)=><>{obj.row.original[DevicesTableColumnName.CONNECTED] ? obj.value : ""}</>
      });
    }
    if(requestedColumns.includes(DevicesTableColumnName.LAST_MEASURE)){
      
      columns.push({
        Header: t(`COLUMN_DEVICE_${DevicesTableColumnName.LAST_MEASURE}`),
        accessor: DevicesTableColumnName.LAST_MEASURE,
        Cell:(obj)=><>{obj.value ? DateTime.fromJSDate(obj.value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : ""}</>,
        //@ts-ignore
        sortType:(rowA,rowB,column)=>(rowA.original[column] ? DateTime.fromJSDate(rowA.original[column]) : 0) - (rowB.original[column] ? DateTime.fromJSDate(rowB.original[column]) : 0)
      });
    }


    AllDataTypes.forEach((dataType) => {
      if (!requestedColumns.includes(DevicesTableColumnName[dataType])) {
        return;
      }
      columns.push({
        Header: tCharts(`${dataType}_LABEL`),
        accessor: dataType,
        Cell: (obj) =>
          isUndefined(obj.value) ? (
            <></>
          ) : (
            <>{`${displayDataTypeNumber(obj.value, dataType)} ${tCharts(
              `${dataType}_UNIT`
            )}`}</>
          ),
      });
    });

    callForAllAesUnits((timeRange, alarmType, dataType) => {
      const columnName =
        `aes-${timeRange}-${alarmType}-${dataType}` as DevicesTableColumnName;

      if (!requestedColumns.includes(DevicesTableColumnName[columnName])) {
        return;
      }

      columns.push({
        Header: `${tCharts(`${dataType}_LABEL`)} - ${t(
          `EXCEEDED_${alarmType}_LEVEL`
        )} ${t(`EXCEEDED_${timeRange}_RANGE`)}`,
        accessor: columnName,
        Cell: (obj) => {
          //@ts-ignore
          const deviceName = obj.row.original[DevicesTableColumnName.NAME];
          const device = devices.find((dev) => dev.device.name === deviceName)!;
          const alarm = alarmService.getAlarmItem(
            dataType,
            alarmType,
            device.device
          )!;
          const aes = devicesService.findAlarmExceedStatistic(
            device.device.statistics.alarmExceedsStatistics,
            timeRange,
            dataType,
            alarmType
          )!;

          if (!aes) {
            return <></>;
          }

          return (
            <>
              <AlarmExceedsStatisticDisplay
                alarm={alarm}
                device={device.device}
                alarmExceedStatistic={aes}
              />
            </>
          );
        },
        //@ts-ignore
        sortType: (rowA, rowB, column) =>
          rowA.original[column] - rowB.original[column],
      });
    });

    return columns;
  }, [t, requestedColumns, devices]);

  const items = useMemo(
    () =>
      devicesInGroup.map((device) => {
        const group = groups.find(
          (group) => group.display && device.device.groupIds.includes(group._id)
        );

        const aesFields: { [key: string]: number | undefined } = {};

        callForAllAesUnits(
          (timeRange, alarmType, dataType) =>
            (aesFields[`aes-${timeRange}-${alarmType}-${dataType}`] =
              devicesService.findAlarmExceedStatistic(
                device.device.statistics.alarmExceedsStatistics,
                timeRange,
                dataType,
                alarmType
              )?.exceededPercentage)
        );

        return {
          NAME: device.device.name,
          SERIAL_NUMBER: device.device.ssn,
          GROUP: group?.name || "",
          LOCATION: getContainer()
            .resolve<DevicesService>("devices")
            .getDevicesLatestLocation(device.device.history),
          CO2_LEVEL: device.lastMeasure?.v.CO2,
          CO_LEVEL: device.lastMeasure?.v.CO,
          TEMPERATURE: device.lastMeasure?.v.TMP,
          ATMOSPHERIC_PRESSURE: device.lastMeasure?.v.AMP,
          HUMIDITY: device.lastMeasure?.v.HMD,
          POWER_SUPPLY: device.lastMeasure?.v.PWS,
          [DevicesTableColumnName.CONNECTED]:!!device.device.connected,
          [DevicesTableColumnName.RSSI]:device.device.rssi,
          [DevicesTableColumnName.LAST_MEASURE]:device.lastMeasure?.dtime,
          ...aesFields,
        };
      }),
    [devicesInGroup, groups]
  );

  const {
    initialValue: initialValueSort,
    handleValueChange: handleValueChangeSort,
    valueIndex: valueIndexSort,
  } = useSaveStateInSearchParams({ id: "NAME", desc: "FALSE" }, "SORT");

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    //@ts-ignore
    setSortBy,
  } = useTable(
    {
      //@ts-ignore
      columns,
      data: items,
      //@ts-ignore
      disableSortRemove: true,
      //@ts-ignore
      disableMultiSort: true,
      initialState: {
        //@ts-ignore
        sortBy: [
          { id: initialValueSort.id, desc: initialValueSort.desc === "TRUE" },
        ],
      },
    },
    useSortBy
  );

  useEffect(() => {
    headerGroups[0].headers?.forEach((column) => {
      //@ts-ignore
      const sortedAsc: boolean = column.isSorted;
      if (sortedAsc) {
        //@ts-ignore
        const sortedDesc: boolean = column.isSortedDesc;
        const newSortBy = {
          id: column.id as DevicesTableColumnName,
          desc: sortedDesc ? "TRUE" : "FALSE",
        };
        handleValueChangeSort(newSortBy);
      }
    });
  }, [rows]);

  useEffect(() => {
    setSortBy([
      { id: initialValueSort.id, desc: initialValueSort.desc === "TRUE" },
    ]);
  }, [valueIndexSort]);

  return (
    <>
      <DevicesTableColumnSelect
        requestedColumns={requestedColumns}
        setRequestedColumns={setRequestedColumns}
      />
      <div style={{ position: "relative" }}>
        <table
          // style={{ tableLayout: "fixed" }}
          className="table table-striped table-responsive"
          {...getTableProps()}
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr
                  className="bg-light bg-gradient"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        className="text-center bg-light bg-gradient"
                        {...column.getHeaderProps(
                          /* @ts-ignore */
                          column.getSortByToggleProps({ title: undefined })
                        )}
                        style={{
                          position: "sticky",
                          top: 0,
                          zIndex: column.id === "NAME" ? 3 : 2,
                          cursor: "pointer",
                          left: column.id === "NAME" ? 0 : undefined,
                        }}
                      >
                        {/* <th {...column.getHeaderProps()}> */}
                        {
                          // Render the header
                          column.render("Header")
                        }

                        {/* Add a sort direction indicator */}
                        {
                          // <span>
                          //   {
                          //     //@ts-ignore
                          //     column.isSorted
                          //       ? //@ts-ignore
                          //         column.isSortedDesc
                          //         ? " 🔼"
                          //         : " 🔽"
                          //       : ""
                          //   }
                          // </span>
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        const columnId = cell.column.id;

                        //@ts-ignore
                        const deviceName = cell.row.original.NAME;

                        const uri = generatePath(DEVICE_ITEM_PAGE_PATH, {
                          deviceName,
                        });

                        if (columnId === "NAME") {
                          return (
                            <td
                              style={{
                                width: 1,
                                position: "sticky",
                                left: 0,
                              }}
                              className="bg-light bg-gradient"
                              {...cell.getCellProps()}
                            >
                              <a
                                className="text-reset text-decoration-none"
                                href={uri}
                                onClick={(e) => {
                                  e.preventDefault();
                                  history.push(uri);
                                }}
                              >
                                {
                                  // Render the cell contents
                                  cell.render("Cell")
                                }
                              </a>
                            </td>
                          );
                        }

                        return (
                          <td
                            className="text-center"
                            style={{
                              width: 1,
                            }}
                            {...cell.getCellProps()}
                          >
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DevicesTable;
