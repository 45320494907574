import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { RunningHistory } from "shared";
import { ReactComponent as DeleteIcon } from "bootstrap-icons/icons/trash-fill.svg";
import { useModPanelData } from "../../hooks/useModPanelData";
import { validateSync } from "class-validator";
import { plainToClass } from "class-transformer";
import { isUndefined } from "lodash";
import { DateTime } from "luxon";
import { getContainer } from "../../../../app-container";
import { DeviceRequestsService } from "../../../request-services/device-requests.service";

const EditDevicesLocationsModal: React.FC<{
  deviceId: string | null;
  onClose: () => void;
  organisationId: string;
}> = ({ onClose, deviceId, organisationId }) => {
  const t = useTranslate("mod");
  const { devices } = useModPanelData(organisationId);
  const device = useMemo(
    () => devices.find((dev) => dev.device._id === deviceId)?.device,
    [deviceId, devices]
  );

  const [newRunningHistory, setNewRunningHistory] = useState<RunningHistory>(
    []
  );

  const onSave = useCallback(() => {
    
    getContainer().resolve(DeviceRequestsService).editDevicesRunningHistory(newRunningHistory,deviceId!,organisationId).then(()=>{
      onClose()
    })
  }, [newRunningHistory,deviceId,organisationId]);

  useEffect(() => {
    setNewRunningHistory(device?.history || []);
  }, [device]);


  const updateTime = useCallback(
    (i: number, field: "start" | "end", value: Date | undefined) => {
      setNewRunningHistory((curr) => {
        const newObj = { ...curr[i] };
        if (field === "start") {
          if (!isUndefined(value)) {
            newObj.timeStart_s = value;
          }
        } else {
          newObj.timeEnd_s = value;
        }
        const newVal = [...curr];
        newVal.splice(i, 1, newObj);
        return newVal;
      });
    },
    [setNewRunningHistory]
  );
  const updateLocation = useCallback(
    (i: number, locationString: string) => {
      setNewRunningHistory((curr) => {
        const newObj = { ...curr[i] };
        newObj.location!.locationString = locationString;
        const newVal = [...curr];
        newVal.splice(i, 1, newObj);
        return newVal;
      });
    },
    [setNewRunningHistory]
  );
  return (
    <DefaultFormModal
      title={t("DEVICES_TOOLTIP_EDIT_LOCATION")}
      show={!!deviceId}
      onClose={onClose}
      onSave={onSave}
    >
      <>
        {t("EDIT_DEVICES_LOCATIONS_MODAL_PARAGRAPH", {
          deviceName: device?.name || "",
        })}
      </>

      {newRunningHistory.map((rhi, i) => (
        <div className="my-4 mx-2">
          <div className="mb-0 mt-4">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h5>
                  {t("EDIT_DEVICES_LOCATIONS_LOCATION_HEADER", {
                    i: (i + 1).toString(),
                  })}
                </h5>
              </div>
              <div className="text-danger" style={{ cursor: "pointer" }}>
                {/* <button type="button" className="btn-close btn-close-danger" aria-label="Close"></button> */}

                <div
                  className="DevicesTabTooltip"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={t("EDIT_DEVICES_LOCATIONS_DELETE_LOCATION_TOOLTIP")}
                >
                  <DeleteIcon
                    height="20"
                    width="20"
                    onClick={() => {
                      setNewRunningHistory((curr) =>
                        [...curr].filter((_item, j) => i !== j)
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-0 mt-3">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div>
                  <label htmlFor="device_name" className="col-form-label">
                    {t("EDIT_DEVICES_LOCATIONS_LOCATION_SINCE")}
                  </label>
                </div>
                <div className="ms-3">
                  <input
                    type="datetime-local"
                    className="form-control"
                    id="device_name"
                    value={`${DateTime.fromJSDate(rhi.timeStart_s).toFormat(
                      "yyyy-MM-dd"
                    )}T${DateTime.fromJSDate(rhi.timeStart_s).toFormat(
                      "HH:mm"
                    )}`}
                    onChange={(e) => {
                      updateTime(i, "start", new Date(e.target.value));
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <div>
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    onClick={() => {
                      if (isUndefined(rhi.timeEnd_s)) {
                        updateTime(i, "end", new Date());
                      } else {
                        updateTime(i, "end", undefined);
                      }
                    }}
                    checked={!isUndefined(rhi.timeEnd_s)}
                  />
                </div>
                <div>
                  <label htmlFor="device_name" className="col-form-label">
                    {t("EDIT_DEVICES_LOCATIONS_LOCATION_UNTIL")}
                  </label>
                </div>
                <div className="ms-3">
                  <input
                    type="datetime-local"
                    className="form-control"
                    id="device_name"
                    disabled={isUndefined(rhi.timeEnd_s)}
                    // value={rhi.timeEnd_s?.toUTCString()}
                    value={`${
                      rhi.timeEnd_s &&
                      DateTime.fromJSDate(rhi.timeEnd_s).toFormat("yyyy-MM-dd")
                    }T${
                      rhi.timeEnd_s &&
                      DateTime.fromJSDate(rhi.timeEnd_s).toFormat("HH:mm")
                    }`}
                    onChange={(e) => {
                      updateTime(i, "end", new Date(e.target.value));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-3 mt-2">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <label htmlFor="device_name" className="col-form-label">
                  {t("EDIT_DEVICES_LOCATIONS_LOCATION_LOCATION")}
                </label>
              </div>
              <div>
                <input
                  type="test"
                  className="form-control"
                  id="device_name"
                  onChange={(e)=>{
                    updateLocation(i,e.target.value)
                  }}
                  value={rhi.location?.locationString}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      <hr />
      <div
        className="my-4 mx-2 text-primary"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <button
          onClick={() => {
            setNewRunningHistory((curr) => [
              ...curr,
              {
                timeStart_s: new Date(),
                timeEnd_s: undefined,
                location: {
                  locationString: "Lokalizacja",
                },
              },
            ]);
          }}
          className="btn btn-primary"
        >
          {" "}
          {t("EDIT_DEVICES_LOCATIONS_LOCATION_ADD")}
        </button>
        {/* <AddIcon width="40" height="40" /> */}
      </div>
    </DefaultFormModal>
  );
};

export default EditDevicesLocationsModal;
