import { isEqual } from "lodash";
import { AlarmsState } from "..";
import { calculateCurrentPageAfterFiltersChange } from "./calculateCurrentPageAfterFiltersChange";
import { getSortCompareFunction } from "./getSortCompareFunction";

export const calculateRequestedAlarms = (
  alarmsState: AlarmsState,
  prevState: AlarmsState
): Partial<AlarmsState> => {
  const {
    totalAlarmExceeds,
    filterAlarmType,
    filterDataType,
    filterDeviceName,
    sortMode,
    timeScaleState,
    requestedAlarmExceeds: prevRequestedAlarmExceeds,
    page: prevPage,
  } = alarmsState;

  const {
    sortMode:prevSortMode
  } = prevState;

  let requestedAlarmExceeds = [...totalAlarmExceeds];

  if (filterAlarmType) {
    requestedAlarmExceeds = requestedAlarmExceeds.filter((alarmExceed) =>
      filterAlarmType.includes(alarmExceed.alarmType)
    );
  }

  if (filterDataType) {
    requestedAlarmExceeds = requestedAlarmExceeds.filter((alarmExceed) =>
      filterDataType.includes(alarmExceed.dataType)
    );
  }
  if (filterDeviceName) {
    requestedAlarmExceeds = requestedAlarmExceeds.filter((alarmExceed) =>
      filterDeviceName.includes(alarmExceed.deviceName)
    );
  }

  requestedAlarmExceeds = requestedAlarmExceeds.filter(
    (alarmExceed) =>
      alarmExceed.endTimestamp_s > timeScaleState.startTimestamp_s &&
      alarmExceed.startTimestamp_s < timeScaleState.endTimestamp_s
  );

  const sortCompareF = getSortCompareFunction(sortMode);
  requestedAlarmExceeds.sort(sortCompareF);

  if (isEqual(requestedAlarmExceeds, prevRequestedAlarmExceeds)) {
    return {};
  }

  const page = calculateCurrentPageAfterFiltersChange(
    requestedAlarmExceeds,
    prevRequestedAlarmExceeds,
    sortMode,
    prevSortMode,
    prevPage
  );

  return { requestedAlarmExceeds, page };
};
