import { delay, inject, injectable } from "tsyringe";
import axios, { AxiosError } from "axios";
import { DataType } from "../charts/models/chart-description.model";
import { createStore, Store } from "redux";
import { DataProviderReducer, DataProviderState } from "./store";

import { getConstants } from "../../config/constants";
import {
  AggregationRange,
  Alarm,
  AlarmExceedRecord,
  AlarmExceedStatistic,
  AllUserOrganisationRoles,
  DataTypeAlias,
  Device,
  DeviceGroup,
  Measure,
  Notification,
  UserOrganisationRole,
} from "shared";
import { PutData } from "./store/putData";
import { DateTime } from "luxon";
import { UserService } from "../user/user.service";
import { UserState } from "../user/store";
import { isNull } from "lodash";
import { getContainer } from "../app-container";
import { AuthService } from "../auth/auth.service";

@injectable()
export class DataProviderService {
  private store: Store<DataProviderState>;

  constructor(
    @inject("user") private userService: UserService,
    @inject("auth") private authService: AuthService
  ) // @ts-ignore
  {
    const reducer = new DataProviderReducer();
    //@ts-ignore
    this.store = createStore(reducer.changeState.bind(reducer));

    this.subscribeToUser();
    this.setRefreshDataInterval();
  }

  private setRefreshDataInterval() {
    setInterval(() => {
      const userState = this.userService.getStore().getState();
      if (!isNull(userState.user)) {
        this.downloadDefaultOrganisationData();
      }
    }, 60 * 1000);
  }

  private subscribeToUser() {
    const userStore = this.userService.getStore();
    const selectUsername = (state: UserState) => state.user?.username || null;
    let prevUsername: string | null = null;
    userStore.subscribe(() => {
      const userName = selectUsername(userStore.getState());
      if (userName === prevUsername) {
        return;
      }
      prevUsername = userName;
      if (!isNull(userName)) {
        this.downloadDefaultOrganisationData();
      }
    });
  }

  async downloadDefaultOrganisationData() {
    const userRoles = this.userService.getStore().getState().user?.roles;
    if (!userRoles?.length) {
      return;
    }
    const userOrganisationRole = userRoles.find((role) =>
    //@ts-ignore
      AllUserOrganisationRoles.includes(role.role)
    );
    if (userOrganisationRole) {
      //@ts-ignore
      this.downlaodData(userOrganisationRole.target);
    }
  }

  async downlaodData(organisationId: string) {
    const response = await axios({
      method: "get",
      url: `${
        getConstants().SERVER_URL
      }/devices/organisation-data/${organisationId}`,
    });

    if (response instanceof AxiosError) {
      if (response.response?.data.title === "Incorrect IP") {
        // this.userService.
        this.authService.logout();
        return;
      }
      return;
    }

    const responseData = response.data as {
      devices: Array<{ device: Device; lastMeasure: Measure }>;
      groups: Array<DeviceGroup>;
      alarms: Array<Alarm>;
      notifications: Array<Notification>;
    };

    this.store.dispatch(
      PutData.callAction({
        alarms: responseData.alarms,
        deviceGroups: responseData.groups,
        devices: responseData.devices,
        orgId: organisationId,
        notifications: responseData.notifications,
      })
    );
  }

  getStore() {
    return this.store;
  }

  async getAeStatistics(
    deviceIds: Array<string>,
    alarmIds: Array<string>,
    timeRangeStart: Date,
    timeRangeEnd: Date
  ) {

    const response = (
      await axios({
        method: "post",
        url: `${getConstants().SERVER_URL}/devices/read-ae-statistics`,
        data: {
          devices: deviceIds,
          alarms: alarmIds,
          timeRangeStart: DateTime.fromJSDate(timeRangeStart, {
            zone: "utc",
          }).toSQL(),
          timeRangeEnd: DateTime.fromJSDate(timeRangeEnd, {
            zone: "utc",
          }).toSQL(),
        },
      })
    ).data as {
      aeStatistics: {
        [deviceId: string]: {
          [alarmId: string]: AlarmExceedStatistic;
        };
      };
    };
    return response.aeStatistics
  }

  async getAlarmExceeds(
    timeRangeStart: Date,
    timeRangeEnd: Date
  ): Promise<Array<AlarmExceedRecord>> {
    const deviceIds = this.store
      .getState()
      .devices.map((dev) => dev.device._id);

    const response = (
      await axios({
        method: "post",
        url: `${getConstants().SERVER_URL}/alarm-exceeds`,
        data: {
          devices: deviceIds,
          timeRangeStart: DateTime.fromJSDate(timeRangeStart, {
            zone: "utc",
          }).toSQL(),
          timeRangeEnd: DateTime.fromJSDate(timeRangeEnd, {
            zone: "utc",
          }).toSQL(),
        },
      })
    ).data as { alarmExceeds: Array<AlarmExceedRecord> };

    return response.alarmExceeds;
  }

  getDataType(dataTypeAlias: string): DataType {
    switch (dataTypeAlias) {
      case "CO2":
        return "CO2_LEVEL";
      case "CO":
        return "CO_LEVEL";
      case "TMP":
        return "TEMPERATURE";
      case "AMP":
        return "ATMOSPHERIC_PRESSURE";
      case "HMD":
        return "HUMIDITY";
      case "PWS":
        return "POWER_SUPPLY";
    }
    console.warn("Incorrect dataTypeAlias", dataTypeAlias);
    return "CO2_LEVEL";
  }

  getDataTypeAlias(dataType: DataType): DataTypeAlias {
    switch (dataType) {
      case "CO2_LEVEL":
        return "CO2";
      case "CO_LEVEL":
        return "CO";
      case "TEMPERATURE":
        return "TMP";
      case "ATMOSPHERIC_PRESSURE":
        return "AMP";
      case "HUMIDITY":
        return "HMD";
      case "POWER_SUPPLY":
        return "PWS";
    }
    console.warn("Incorrect dataType", dataType);
    return "CO2";
  }

  async getMeasures(
    deviceIds: Array<string>,
    timeRangeStart: Date,
    timeRangeEnd: Date,
    aggregationRange?: AggregationRange
  ) {
    console.log(
      timeRangeStart,
      DateTime.fromJSDate(timeRangeStart, { zone: "utc" }).toString()
    );

    const response = (
      await axios({
        method: "post",
        url: `${getConstants().SERVER_URL}/measures`,
        data: {
          devices: deviceIds,
          timeRangeStart: DateTime.fromJSDate(timeRangeStart, {
            zone: "utc",
          }).toSQL(),
          timeRangeEnd: DateTime.fromJSDate(timeRangeEnd, {
            zone: "utc",
          }).toSQL(),
          aggregationRange,
        },
      })
    ).data as { measures: Array<Measure> };

    return response.measures;
  }
}
