import { Switch, useLocation } from "react-router";
import { SwitchTransition, Transition } from "react-transition-group";

const ANIMATION_DURATION_MS = 150;

const AnimatedSwitch: React.FC<{ children: React.ReactNode }> = (props) => {
  const { children } = props;
  const location = useLocation();

  const defaultStyle = {
    transition: `opacity ${ANIMATION_DURATION_MS}ms linear`,
    opacity: 0,
    height: "100%",
    width: "100%",
  };
  const transitionStyles = {
    /* 
    display:none allow to move page to top when switching pages (since page component has 0px height), 
    which may be especially usefull on mobile devices
    ;also putting main container here is not a good thing, since it should be in Skeleton component,
    but it allow to get effect described above
    */
    entering: { opacity: 0,display:"none" },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: { opacity: 0 },
  };


  return (
    <SwitchTransition>
      <Transition
        key={location.pathname}
        timeout={{ enter: 1, exit: ANIMATION_DURATION_MS }}
      >
        {(state) => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
          <div
           id="main-container"
           className="bg-light bg-gradient"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Switch location={location}>{children}</Switch>
            </div>
          </div>
        )}
      </Transition>
    </SwitchTransition>
  );
};

export default AnimatedSwitch;
