import { useTranslate } from "react-translate";
import { ReactComponent as AlarmSetsIcon } from "bootstrap-icons/icons/tags-fill.svg";
import { ReactComponent as EditIcon } from "bootstrap-icons/icons/pencil-fill.svg";
import { ReactComponent as DeleteIcon } from "bootstrap-icons/icons/trash-fill.svg";
import { ReactComponent as AddIcon } from "bootstrap-icons/icons/plus-square-fill.svg";
import { Tooltip } from "bootstrap";
import { useLayoutEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import EditAlarmsSetsModal from "../../modals/AlarmsTab/EditAlarmsSetsModal";
import CreateAlarmModal from "../../modals/AlarmsTab/CreateAlarmModal";
import EditAlarmModal from "../../modals/AlarmsTab/EditAlarmModal";
import DeleteAlarmModal from "../../modals/AlarmsTab/DeleteAlarmModal";
import { isNull, isUndefined } from "lodash";
import { useModDataSelector } from "../../../mod-data.provider";
import { Alarm, DeviceGroup } from "shared";
import { useModPanelData } from "../../hooks/useModPanelData";

const AlarmsTab: React.FC<{ organisationId: string | null }> = ({
  organisationId,
}) => {
  const t = useTranslate("mod");
  const tCharts = useTranslate("chart");
  const tAlarms = useTranslate("alarms");

  const primaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-primary-rgb");

  const dangerColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-danger-rgb");


  const {alarms,deviceGroups} = useModPanelData(organisationId)
  
  // const alarms = useDataProviderSelector((state) => state.alarms);
  // const deviceGroups = useDataProviderSelector((state) => state.deviceGroups);

  const alarmSets = useMemo(
    () => deviceGroups.filter((dgroup) => !dgroup.display),
    [deviceGroups]
  );

  const columns = useMemo(() => {



    const columns = [
      {
        Header: t("ALARMS_COLUMN_NAME"),
        accessor: "name",
      },
      {
        Header: t("ALARMS_COLUMN_DATATYPE"),
        accessor: "dataType",
        Cell: (obj: any) => tCharts(`${obj.value}_LABEL`),
      },
      {
        Header: t("ALARMS_COLUMN_TYPE"),
        accessor: "type",
        Cell: (obj: any) => tAlarms(`FILTER_ALARM_TYPE_${obj.value}`),
      },
      {
        Header: t("ALARMS_COLUMN_THRESHOLD"),
        accessor: "threshold",
        Cell: (obj: any) =>
          `${obj.value} ${tCharts(`${obj.row.original.dataType}_UNIT`)}`,
      },
      {
        Header: t("ALARMS_COLUMN_SETS_NUMBER"),
        accessor: "sets_number",
      },
      {
        Header: t("ALARMS_COLUMN_SMS"),
        accessor: "smsEnabled",
        Cell: (obj: any) =>
          obj.value
            ? t("ALARMS_COLUMN_SMS_ENABLED")
            : t("ALARMS_COLUMN_SMS_DISABLED"),
      },
      {
        Header: t("ALARMS_COLUMN_EMAIL"),
        accessor: "emailEnabled",
        Cell: (obj: any) =>
          obj.value
            ? t("ALARMS_COLUMN_SMS_ENABLED")
            : t("ALARMS_COLUMN_SMS_DISABLED"),
      },
      {
        Header: t("ALARMS_COLUMN_EXCEEDED_TYPE"),
        accessor: "exceedBelowThreshold",
        Cell: (obj: any) =>
          obj.value
            ? t("ALARMS_COLUMN_EXCEEDED_TYPE_BELOW")
            : t("ALARMS_COLUMN_EXCEEDED_TYPE_ABOVE"),
      },
    ];

    if(isNull(organisationId)){
      columns.push({
        Header: t("ALARMS_COLUMN_HIDDEN"),
        accessor: "hidden",
        Cell: (obj: any) =>
          obj.value
            ? t("ALARMS_COLUMN_HIDDEN_YES")
            : t("ALARMS_COLUMN_HIDDEN_NO"),
      },)
    }

    return columns
  }, [t,organisationId]);

  const data = useMemo(
    () =>
      (alarms||[]).filter(alarm=>!alarm.options?.hidden||isNull(organisationId)).map((alarm) => ({
        _id: alarm._id,
        name: alarm.name,
        dataType: alarm.dtAlias,
        threshold: alarm.threshold,
        type: alarm.type,
        sets_number: alarmSets.filter((set) => set.alarms.includes(alarm._id))
          .length,
        smsEnabled: alarm.smsMessage?.enabled || false,
        emailEnabled:alarm.emailMessage?.enabled || false,
        hidden:alarm.options?.hidden || false,
        systemAlarm:isNull(alarm.organisationId),
        exceedBelowThreshold:alarm.exceedBelowThreshold
      })),
    [alarms,organisationId]
  );

  useLayoutEffect(() => {
    const tooltipElements = Array.from(
      document.querySelectorAll(".AlarmsTabTooltip")
    );
    const tooltips = tooltipElements.map((el) => new Tooltip(el));
    return () => tooltips.forEach((tooltip) => tooltip.dispose());
  }, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      //@ts-ignore
      columns,
      data,
    });

  const [editedAlarmsSets, setEditedAlarmsSet] = useState<string | null>(null);
  const [creatingAlarm, setCreatingAlarm] = useState<boolean>(false);
  const [editingAlarm, setEditingAlarm] = useState<string | null>(null);
  const [deletingAlarm, setDeletingAlarm] = useState<string | null>(null);

  return (
    <>
      <h4>{isNull(organisationId) ? t("TAB_alarms_global") : t("TAB_alarms")}</h4>
      <p>{isNull(organisationId) ? t("ALARMS_PARAGRAPH_GLOBAL") : t("ALARMS_PARAGRAPH")}</p>
      <div style={{ maxWidth: 1400 }}>
        <table {...getTableProps()} className="table table-striped mb-2">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
                <th />
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                  <td className="ps-4 pe-0" style={{ width: "1%" }}>

                  {row.original.systemAlarm&&!isNull(organisationId) ? "" :(
                    <div className="d-flex justify-content-end">
                      <div className="me-3">
                        <div
                          className="AlarmsTabTooltip"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={t("ALARMS_TOOLTIP_EDIT_SETS")}
                        >
                          <a
                            className="text-decoration-none text-reset"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setEditedAlarmsSet(row.original._id);
                            }}
                          >
                            <AlarmSetsIcon style={{ cursor: "pointer" }} />
                          </a>
                        </div>
                      </div>
                      <div className="me-3">
                        <div
                          className="AlarmsTabTooltip"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={t("ALARMS_TOOLTIP_EDIT")}
                        >
                          <a
                            className="text-decoration-none text-reset"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setEditingAlarm(row.original._id);
                            }}
                          >
                            <EditIcon style={{ cursor: "pointer" }} />
                          </a>
                        </div>
                      </div>
                      <div className="me-4">
                        <div
                          className="AlarmsTabTooltip"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={t("ALARMS_TOOLTIP_DELETE")}
                        >
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setDeletingAlarm(row.original._id);
                            }}
                          >
                            <DeleteIcon
                              fill={`rgb(${dangerColor})`}
                              style={{ cursor: "pointer" }}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="d-flex justify-content-end">
          <div className="pe-4" style={{ marginRight: -2 }}>
            <div
              className="AlarmsTabTooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={t("ALARMS_TOOLTIP_NEW")}
            >
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setCreatingAlarm(true);
                }}
              >
                <AddIcon
                  style={{ cursor: "pointer" }}
                  fill={`rgb(${primaryColor})`}
                  height="20"
                  width="20"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <EditAlarmsSetsModal
      organisationId={organisationId}
        alarmId={editedAlarmsSets}
        onClose={() => setEditedAlarmsSet(null)}
      />
      <CreateAlarmModal
      organisationId={organisationId}
        show={creatingAlarm}
        onClose={() => setCreatingAlarm(false)}
      />
      <EditAlarmModal
      organisationId={organisationId}
        alarmId={editingAlarm}
        onClose={() => setEditingAlarm(null)}
      />
      <DeleteAlarmModal
      organisationId={organisationId}
        alarmId={deletingAlarm}
        onClose={() => setDeletingAlarm(null)}
      />
    </>
  );
};

export default AlarmsTab;
