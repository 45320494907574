import { useEffect, useMemo, useState } from "react";
import { getContainer } from "../../../app-container";
import { ChartsService } from "../../charts.service";
import { ChartData } from "../../models/chart-data";
import { ChartRequests } from "../../models/chart-requests.model";
import { TimeScaleState } from "../../models/time-scale-state";

export type UseChartDataOptions = {
  minimumExcessiveRange: number;
  excessiveRange: number;
};

const defaultOptions: UseChartDataOptions = {
  excessiveRange: 2,
  minimumExcessiveRange: 1.5,
};

const checkIfDownloadNeeded = (
  newChartRequests: ChartRequests,
  currentChartRequests: ChartRequests | null,
  minimumExcessiveRange: number
) => {
  if (!currentChartRequests) {
    return true;
  }

  if (
    newChartRequests.measuresGrouping !== currentChartRequests.measuresGrouping
  ) {
    /* different grouping */

    return true;
  }
  if (
    Object.keys(newChartRequests.requestedDevices).find(
      (devId) =>
        !Object.keys(currentChartRequests.requestedDevices).includes(devId)
    )
  ) {
    /* atleast one device is not included */
    return true;
  }

  const { startTimestamp_s, endTimestamp_s } = newChartRequests.timeScaleState;
  const timeRange_s = endTimestamp_s - startTimestamp_s;
  let minimumExcessiveTimeScaleState = {
    startTimestamp_s: endTimestamp_s - timeRange_s * minimumExcessiveRange,
    endTimestamp_s: startTimestamp_s + timeRange_s * minimumExcessiveRange,
  };

  if (
    minimumExcessiveTimeScaleState.startTimestamp_s <
      currentChartRequests.timeScaleState.startTimestamp_s ||
    minimumExcessiveTimeScaleState.endTimestamp_s >
      currentChartRequests.timeScaleState.endTimestamp_s
  ) {
    /* out of minimum excessive time range */
    return true;
  }

  return false;
};

export const useChartData = (
  chartRequests: ChartRequests,
  partialOptions: Partial<UseChartDataOptions> = defaultOptions,
  disableDownloadingData:boolean
) => {
  const options = useMemo(
    () => ({ ...defaultOptions, ...partialOptions }),
    [partialOptions]
  );
  const [chartData, setChartData] = useState<ChartData>({
    alarmsDatasetsSetup: {},
    dataTypesIncluded: [],
    measuresDatasetsSetup: {},
  });

  const [currDownloadingChartRequests] = useState<{
    currChartRequests: ChartRequests | null;
  }>({ currChartRequests: null });

  const [downloadingChartRequests, setDownloadingChartRequests] =
    useState<ChartRequests | null>(null);
  const [downloadedChartRequests, setDownloadedChartRequests] =
    useState<ChartRequests | null>(null);
  const downloading = useMemo(
    () => downloadingChartRequests !== downloadedChartRequests,
    [downloadedChartRequests, downloadingChartRequests]
  );

  useEffect(() => {

    if(disableDownloadingData){
      return
    }

    if (
      !checkIfDownloadNeeded(
        chartRequests,
        downloadingChartRequests,
        options.minimumExcessiveRange
      )
    ) {
      return;
    }

    const { measuresGrouping, requestedDevices, timeScaleState } =
      chartRequests;
    const devicesIds = Object.keys(requestedDevices)

    const { startTimestamp_s, endTimestamp_s } = timeScaleState;
    const timeRange_s = endTimestamp_s - startTimestamp_s;
    const excessiveTimeScaleState: TimeScaleState = {
      startTimestamp_s: endTimestamp_s - timeRange_s * options.excessiveRange,
      endTimestamp_s: startTimestamp_s + timeRange_s * options.excessiveRange,
    };

    const chartRequestTimeExcessive: ChartRequests = {
      ...chartRequests,
      timeScaleState: excessiveTimeScaleState,
    };

    currDownloadingChartRequests.currChartRequests = chartRequestTimeExcessive;
    setDownloadingChartRequests(chartRequestTimeExcessive);

    const data = getContainer()
      .resolve<ChartsService>("charts")
      .getChartDataProviderService()
      .getChartData(devicesIds, excessiveTimeScaleState, measuresGrouping)
      .then((data) => {
        if (
          chartRequestTimeExcessive !==
          currDownloadingChartRequests.currChartRequests
        ) {
          return;
        }
        setChartData(data);
        setDownloadedChartRequests(chartRequestTimeExcessive);
      });
  }, [
    chartRequests,
    downloadingChartRequests,
    setChartData,
    setDownloadedChartRequests,
    setDownloadingChartRequests,
    options,
    disableDownloadingData
  ]);

  return { chartData, downloading };
};
