import { ChartState } from "../../store";
import { AllDataTypes, DataType } from "../../models/chart-description.model";
import { getTotalRequestedDataTypesArray } from "./getTotalRequestedDataTypesArray";

export const dataTypesImportance:Array<DataType> = AllDataTypes;

export const getGridVisibility = (chartState:ChartState,dataType:DataType):boolean=>{
    const {totalRequestedDataTypes} = chartState;

    const totalRequestedDataTypesArray = getTotalRequestedDataTypesArray(totalRequestedDataTypes)

    const dataTypeImportanceIndex = dataTypesImportance.findIndex(dt=>dt===dataType);
    const higherImportances = dataTypesImportance.slice(0,dataTypeImportanceIndex);
    return !higherImportances.find(dt=>totalRequestedDataTypesArray.includes(dt))

}

