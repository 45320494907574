import { TimeScaleState } from "../../../models/time-scale-state";

export enum DataExportFormat {
    "JSON" = "JSON",
    "XML" = "XML",
    "CSV" = "CSV",
  }
export type DataExporterRequests = {
  format: DataExportFormat;
  devices: Array<string>;
  timeScaleState: TimeScaleState;
};
