import { useCallback, useMemo, useRef } from "react";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { isNull } from "lodash";
import { container } from "tsyringe";
import { DeviceGroupRequestsService } from "../../../request-services/device-group-requests.service";
import { useModPanelData } from "../../hooks/useModPanelData";

const DeleteAlarmSetModal: React.FC<{
  groupId: string | null;
  onClose: () => void;
  organisationId: string | null 
}> = ({ onClose, groupId,organisationId }) => {
  const t = useTranslate("mod");
  const {deviceGroups: groups} = useModPanelData(organisationId);
  const group = useMemo(
    () => groups.find((group) => group._id === groupId),
    [groups, groupId]
  );


  const onSave = useCallback(() => {
      if(isNull(organisationId)){
        /* delete global device group */
        container.resolve(DeviceGroupRequestsService).deleteGlobalDeviceGroup(groupId!).then(()=>{
          onClose()
        })
      }else{
        /* delete device group */
        container.resolve(DeviceGroupRequestsService).deleteDeviceGroup(groupId!,organisationId).then(()=>{
          onClose()
        })
      }
  }, [organisationId,groupId]);

  return (
    <DefaultFormModal
      title={t("ASET_TOOLTIP_DELETE")}
      show={!!groupId}
      onClose={onClose}
      onSave={onSave}
      submitType="delete"
    >
      <>{t("DELETE_ASET_MODAL_PARAGRAPH", { setName: group?.name || "" })}</>
    </DefaultFormModal>
  );
};

export default DeleteAlarmSetModal;
