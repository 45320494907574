import axios from "axios";
import { EmailConfigDto, MissingMeasuresAlertsConfigDto, SmsConfigDto } from "shared";
import { injectable, Lifecycle, scoped } from "tsyringe";
import { getConstants } from "../../../config/constants";
import { getContainer } from "../../app-container";
import { ModDataService } from "../mod-data.service";

@injectable()
@scoped(Lifecycle.ContainerScoped)
export class SystemRequestsService {
  async sendUpdateSmsConfig(dto:SmsConfigDto) {
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/system/smsConfig`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()

  }
  async sendUpdateEmailConfig(dto:EmailConfigDto) {
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/system/emailConfig`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()

  }


  async sendUpdateMissingMeasuresAlertsConfig(dto:MissingMeasuresAlertsConfigDto) {
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/system/missingMeasuresAlertsConfig`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()

  }
}
