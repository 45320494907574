import { ModDataState } from "."
import { ReducerActionClass } from "../../../utils/store/core"

type Payload = {
    selectedOrganisation:string|undefined
}

class PutSelectedOrganisationClass extends ReducerActionClass<ModDataState,Payload>{
    getActionName(): string {
        return "PUT_SELECTED_ORGANISATION"
    }
    handleDispatch(prevState: ModDataState, payload: Payload): ModDataState {
        const {selectedOrganisation} = payload;

        return{
            ...prevState,
            selectedOrganisation
        }
    }

}

export const PutSelectedOrganisaion = new PutSelectedOrganisationClass()