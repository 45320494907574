import { useTranslate } from "react-translate";
import TabContainer from "./res/TabContainer";
import TabList from "./res/TabList";
import { useMemo } from "react";
import { Device, Measure } from "shared";

const params: Array<{ key: string; value: string }> = [
  { key: "Uruchomiony", value: "Tak" },
  { key: "Numer Seryjny", value: "NUM-ERS-ERY-JNY" },
  { key: "Wersja oprogramowania", value: "1.0.1" },
  { key: "Numer Imei", value: "123456 78 901234 5" },
];

const InformationTab: React.FC<{
  deviceObj:
    | {
        device: Device;
        lastMeasure?: Measure | undefined;
      }
    | undefined;
}> = ({ deviceObj: device }) => {
  const t = useTranslate("devices");

  const params = useMemo<Array<{ key: string; value: string }>>(
    () => [{ key: "Numer seryjny", value: device?.device.ssn || "" },{
      key:t("COLUMN_DEVICE_CONNECTED"),
      value:device?.device.connected ? t("COLUMN_DEVICE_CONNECTED_YES") : t("COLUMN_DEVICE_CONNECTED_NO")
    },{
      key:t("COLUMN_DEVICE_RSSI"),
      value:device?.device.connected ? device.device.rssi||"" : "-"
    }],
    [device]
  );

  return (
    <TabContainer>
      <h3 className="mb-3">{t("INFORMATION_TAB")}</h3>
      <TabList items={params} />
    </TabContainer>
  );
};

export default InformationTab;
