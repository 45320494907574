import { DateTime } from "luxon";
import { useMemo, useRef } from "react";
import { useTranslate } from "react-translate";
import { STATS_PAGE_PATH } from "../../../../../skeleton/pages/stats";
import { getContainer } from "../../../../app-container";
import { ChartsService } from "../../../../charts/charts.service";
import { useChart } from "../../../../charts/hooks/useChart";
import { ChartRequests } from "../../../../charts/models/chart-requests.model";
import DefaultTile from "../DefaultTile";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";

const StatsTile: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const {dataLoaded} = useDataProviderSelector(state=>state)

  const chartsService = getContainer().resolve<ChartsService>("charts");
  const defaultChartRequests: ChartRequests = useMemo(
    () => chartsService.getDefaultChartRequest(),
    [dataLoaded]
  );
  useChart(canvasRef, false, defaultChartRequests, undefined, dataLoaded ? 1 : 0, {
    disableChartTimeScaleActions: true,
  },undefined,{excessiveRange:1,minimumExcessiveRange:1});

  const t = useTranslate("menu");

  return (
    <DefaultTile url={STATS_PAGE_PATH} title={t("PAGE_STATS")}>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <div style={{ width: "90%", height: "95%" }}>
          <canvas ref={canvasRef} />
        </div>
      </div>
    </DefaultTile>
  );
};

export default StatsTile;
