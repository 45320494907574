import { useTranslate } from "react-translate";

const ChangeNameModal: React.FC<{ currentName: string }> = (props) => {
  const { currentName } = props;
  const t = useTranslate("devices");

  return (
    <>
      <div
        className="modal fade"
        id="changeNameModal"
        tabIndex={-1}
        aria-labelledby="changeNameModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="changeNameModalLabel">
                {t("CHANGE_NAME_MODAL_TITLE")}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {t("CHANGE_NAME_MODAL_BODY", { currentName })}

              <div
                className="mb-3 mt-4"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "baseline",
                }}
              >
                <div>
                  <label htmlFor="inputNewName" className="col-form-label">
                    {t("CHANGE_NAME_MODAL_NEW_NAME")}
                  </label>
                </div>
                <div>
                  <input
                    defaultValue={currentName}
                    className="form-control"
                    id="inputNewName"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                {t("CHANGE_NAME_MODAL_CLOSE")}
              </button>
              <button
                onClick={() =>
                  alert(
                    "Funkcja w późniejszej wersji będzie zapisywać nazwę wybraną przez użytkownika na serwerze. W wersji działającej na przykładowych danych funkcja jest niedostępna."
                  )
                }
                type="button"
                className="btn btn-primary"
              >
                {t("CHANGE_NAME_MODAL_SAVE")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeNameModal;
