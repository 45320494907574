import AlarmControlls from "./AlarmControlls";
import DevicesControlls from "./DevicesControlls";
import ScalesControlls from "./ScalesControlls";

const ChartControllsContent: React.FC = () => {

  return (
    <div
    style={{height:"100%",position:"relative"}}
      className="container bg-secondary text-white p-3"
    >
      <ScalesControlls />
      <hr/>
      <DevicesControlls/>
      <hr/>
      <AlarmControlls/>
    </div>
  );
};

export default ChartControllsContent;
