import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslate } from "react-translate";
import { NotificationBlock } from "shared";
import { container } from "tsyringe";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { NotificationsRequestsService } from "../../../request-services/notifications-requests.service";
import { useModPanelData } from "../../hooks/useModPanelData";
import { useEditor } from "./useEditor";

const defaultBlocks: Array<NotificationBlock> = [
  {
    type: "header",
    data: {
      level: 2,
      text: "Tytuł",
    },
  },
  { type: "paragraph", data: { text: "Treść" } },
];

const CreateNotificationModal: React.FC<{
  show: boolean;
  onClose: () => void;
}> = ({ onClose, show }) => {
  const t = useTranslate("mod");
  const { organisations } = useModPanelData(null);
  const editorContainerRef = useRef<HTMLDivElement>(null);
  const { editor } = useEditor(editorContainerRef, defaultBlocks);

  const [showAllOrganisations, setShowAllOrganisations] =
    useState<boolean>(true);
  const [selectedOrganisations, setSelectedOrganisations] = useState<
    Array<string>
  >([]);

  const onSave = useCallback(() => {
    editor?.save().then(({ blocks }) => {
      container.resolve(NotificationsRequestsService).createNotification({
        organisations: selectedOrganisations,
        showAllOrganisations,
        //@ts-ignore
        blocks,
      }).then(()=>{
        onClose()
      });;
    });
  }, [editor, showAllOrganisations, selectedOrganisations]);

  return (
    <DefaultFormModal
      title={t("NOTIFICATIONS_TOOLTIP_CREATE")}
      show={show}
      onClose={onClose}
      onSave={onSave}
      submitType="add"
    >
      <>{t("CREATE_NOTIFICATION_MODAL_PARAGRAPH")}</>

      <div className="mb-3 mt-4">
        <>
          <label className="col-sm-2 col-form-label">
            {t("NOTIFICATIONS_MODAL_LABEL_ORGANISATIONS")}
          </label>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="create-notifications-org-all"
              checked={showAllOrganisations}
              onClick={() => {
                setShowAllOrganisations((curr) => !curr);
              }}
            />
            <label
              className="form-check-label"
              htmlFor="create-notifications-org-all"
            >
              {t("NOTIFICATIONS_ORGANISATIONS_ALL")}
            </label>
          </div>
          {organisations.map((org) => {
            const id = org.organisation._id;
            return (
              <div key={id} className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id={`create-notifications-org-${id}`}
                  checked={selectedOrganisations.includes(id)}
                  onClick={() => {
                    setSelectedOrganisations((curr) => {
                      if (curr.includes(id)) {
                        return curr.filter((item) => item !== id);
                      } else {
                        return [...curr, id];
                      }
                    });
                  }}
                  disabled={showAllOrganisations}
                />
                <label
                  className="form-check-label"
                  htmlFor={`create-notifications-org-${id}`}
                >
                  {org.organisation.name}
                </label>
              </div>
            );
          })}
        </>
        <hr/>
        <div ref={editorContainerRef}/>
      </div>
    </DefaultFormModal>
  );
};

export default CreateNotificationModal;
