import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { IsString } from "class-validator";
import { useTranslate } from "react-translate";
import { Match } from "../../../../utils/validation/match.decorator";
import { ChangePasswordDto } from "shared";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useRef } from "react";
import ErrorDisplayFieldError from "../../../../utils/forms/ErrorDisplayFieldError";
import { useAuthSelector } from "../../../auth/auth.provider";
import { getContainer } from "../../../app-container";
import { AuthService } from "../../../auth/auth.service";

class ChangePasswordDtoWConfirmPassword extends ChangePasswordDto {
  @IsString()
  @Match("newPassword")
  //@ts-ignore
  confirmPassword: string;
}

const resolver = classValidatorResolver(ChangePasswordDtoWConfirmPassword);

const ChangePasswordModal: React.FC = () => {
  const t = useTranslate("user");

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
    watch,
  } = useForm<ChangePasswordDtoWConfirmPassword>({ resolver, mode: "onBlur" });

  const submitRef = useRef<HTMLInputElement>(null);
  const onSave = useCallback(() => {
    if (!submitRef.current) {
      return;
    }
    submitRef.current.click();
  }, [submitRef]);

  useEffect(() => {
    const sub = watch((val, { name }) => {
      if (name !== "password" || val.confirmPassword === "") {
        return;
      }
      trigger("confirmPassword");
    });
    return () => sub.unsubscribe();
  }, [watch]);

  const rememberMe = useAuthSelector(state=>state.rememberMe);
  useEffect(()=>{
    setValue("rememberMe",!!rememberMe)
  },[rememberMe,setValue])

  return (
    <div
      id="changePasswordModal"
      className="modal fade"
      tabIndex={-1}
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {t("CHANGE_PASSWORD_MODAL_HEADER")}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {t("CHANGE_PASSWORD_MODAL_BODY")}

            <form onSubmit={handleSubmit((data) => {
              getContainer().resolve<AuthService>("auth").changePassword(data.password,data.newPassword,data.rememberMe).then(()=>{
                
              })
            })}>
              <input ref={submitRef} hidden type="submit" />
              <div className="mb-3 mt-4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                  }}
                >
                  <div>
                    <label htmlFor="inputNewName" className="col-form-label">
                      {t("CHANGE_PASSWORD_MODAL_OLD_PASSWORD")}
                    </label>
                  </div>
                  <div>
                    <input
                      type="password"
                      className="form-control"
                      id="inputNewName"
                      {...register("password")}
                    />
                  </div>
                </div>

                <ErrorDisplayFieldError error={errors.password} />
              </div>
              <div className="mb-3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                  }}
                >
                  <div>
                    <label htmlFor="inputNewName" className="col-form-label">
                      {t("CHANGE_PASSWORD_MODAL_NEW_PASSWORD")}
                    </label>
                  </div>
                  <div>
                    <input
                      type="password"
                      className="form-control"
                      id="inputNewName"
                      {...register("newPassword")}
                    />
                  </div>
                </div>
                <ErrorDisplayFieldError error={errors.newPassword} />
              </div>
              <div className="mb-3">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                  }}
                >
                  <div>
                    <label htmlFor="inputNewName" className="col-form-label">
                      {t("CHANGE_PASSWORD_MODAL_NEW_PASSWORD_REPEAT")}
                    </label>
                  </div>
                  <div>
                    <input
                      type="password"
                      className="form-control"
                      id="inputNewName"
                      {...register("confirmPassword")}
                    />
                  </div>
                </div>
                <ErrorDisplayFieldError error={errors.confirmPassword} />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              {t("CHANGE_PASSWORD_MODAL_CLOSE")}
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={!isValid}
              data-bs-dismiss="modal"
              onClick={() => {
                onSave();
              }}
            >
              {t("CHANGE_PASSWORD_MODAL_SAVE")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
