export default{
    "MODAL_SAVE":"Zapisz",
    "MODAL_CREATE":"Dodaj",
    "MODAL_DELETE":"Usuń",
    "MODAL_CLOSE":"Zamknij",

    "PAGE":"Strona",
    "ERROR_isLength":"Pole powinno mieć od {{0}} do {{1}} znaków.",
    "ERROR_min":"Pole powinno wynosić minimum {{0}}.",
    "ERROR_isEmail":"Pole powinno zawierać email.",
    "ERROR_isNumberString":"Pole powinno zawierać liczby.",
    "ERROR_Match":"Podane hasła nie są identyczne."
}