import { User } from "shared"
import { UserState } from "."
import { ReducerActionClass } from "../../../utils/store/core"

type Payload = {
    user:User|null,
    organisationNames:{[orgId:string]:string}
}

class PutUserDataClass extends ReducerActionClass<UserState,Payload>{
    getActionName(): string {
       return "PUT_USER_DATA"
    }
    handleDispatch(prevState: UserState, payload: Payload): UserState {
        const {organisationNames,user} = payload;
        return {
            ...prevState,
            organisationNames,
            user
        }
    }
}

export const PutUserData = new PutUserDataClass()