import React, { useMemo } from "react";
import { useTranslate } from "react-translate";
import TabControlls from "../../../utils/tabs/TabControlls";
import TabSwitch from "../../../utils/tabs/TabSwitch";
import { TabsArray, useTabView } from "../../../utils/tabs/useTabView";
import AlarmSetsTab from "./tabs/Both/AlarmSetsTab";
import AlarmsTab from "./tabs/Both/AlarmsTab";
import DeviceGroupsTab from "./tabs/Mod/DeviceGroupsTab";
import DevicesTab from "./tabs/Both/DevicesTab";
import SmsConfigTab from "./tabs/Both/SmsConfigTab";
import UsersTab from "./tabs/Mod/UsersTab";
import { useModDataSelector } from "../mod-data.provider";
import EmailConfigTab from "./tabs/Both/EmailConfigTab";
import { useUserSelector } from "../../user/user.provider";
import { UserOrganisationRole } from "shared";
import { useCanAccessModPanel } from "../hooks/useCanAccessModPanel";
import SelectOrganisation from "./SelectOrganisation";
import { useCanAccessAdminPanel } from "../hooks/useCanAccessAdminPanel";
import IpConfigTab from "./tabs/Mod/IpConfigTab";

type ModPanelTab =
  | "users"
  | "devices"
  | "alarms"
  | "device_groups"
  | "alarm_sets"
  | "sms"
  | "email"
  | "ipconfig";

const ModPanel: React.FC = () => {
  const t = useTranslate("mod");

  const organisationId = useModDataSelector((state) => state.selectedOrganisation);
  const organisations = useModDataSelector(state=>state.organisations)
  const organisation = useMemo(()=>organisations[organisationId||""],[organisationId,organisations])


  const {modPanelLevel} = useCanAccessModPanel()
  const {access: adminAccess} = useCanAccessAdminPanel()

  const modPanelTabs = useMemo<
    Array<{ name: ModPanelTab; Component: React.FC }>
  >(() => {

    if(modPanelLevel===1){

      return[
        {
          name: "sms",
          Component: () => <SmsConfigTab organisationID={organisationId||null} />,
        },
        {
          name: "email",
          Component: () => <EmailConfigTab organisationID={organisationId||null} />,
        },]

    }

    return [
      {
        name: "users",
        Component: () => <UsersTab organisationId={organisationId||""} />,
      },
      {
        name: "sms",
        Component: () => <SmsConfigTab organisationID={organisationId||null} />,
      },
      {
        name: "email",
        Component: () => <EmailConfigTab organisationID={organisationId||null} />,
      },
      {
        name: "device_groups",
        Component: () => <DeviceGroupsTab organisationId={organisationId||""} />,
      },
      {
        name: "devices",
        Component: () => <DevicesTab organisationId={organisationId||null} />,
      },
      {
        name: "alarm_sets",
        Component: () => <AlarmSetsTab organisationId={organisationId||null} />,
      },
      {
        name: "alarms",
        Component: () => <AlarmsTab organisationId={organisationId||null} />,
      },
      {
        name:"ipconfig",
        Component:()=><IpConfigTab organisationID={organisationId||null} />
      }
    ];
  }, [organisationId,modPanelLevel]);

  const { tabRef, initialTab } = useTabView(modPanelTabs[0].name, "TAB");

  return (
    <div className="px-xl-5 px-2" style={{ margin: "50px auto" }}>
      <div className="mb-5 text-center text-lg-start">
        <h1>{t("MOD_PANEL_HEADER")}</h1>
        <h5 className="ms-1">
          {t("MOD_PANEL_PARAGRAPH", {
            orgName: organisation?.organisation.name || "",
          })}
        </h5>
          {!adminAccess ? null : <SelectOrganisation/>}
      </div>
      <ul
        style={{ fontSize: "1.1rem" }}
        className="nav nav-tabs"
        role="tablist"
        ref={tabRef}
      >
        <TabControlls items={modPanelTabs} t={t} initialTab={initialTab} />
      </ul>
      <div className="d-flex justify-content-center justify-content-lg-start ps-0 ps-lg-4">
        <div style={{ flexGrow: 1 }} className="ms-lg-5  mt-4 ms-1">
          <div className="tab-content">
            <TabSwitch initialTab={initialTab} items={modPanelTabs} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModPanel;
