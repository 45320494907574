import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { useModDataSelector } from "../../../mod-data.provider";
import { Alarm, DeviceGroup } from "shared";
import { isNull } from "lodash";
import { container } from "tsyringe";
import { AlarmRequestsService } from "../../../request-services/alarm-requests.service";
import { useModPanelData } from "../../hooks/useModPanelData";

const EditAlarmsSetsModal: React.FC<{
  alarmId: string | null;
  onClose: () => void;
  organisationId:string|null
}> = ({ onClose, alarmId,organisationId }) => {
  const t = useTranslate("mod");
  
  const {alarms,deviceGroups: groups} = useModPanelData(organisationId)

  const alarm = useMemo(()=>alarms.find(alarm=>alarm._id===alarmId),[alarms,alarmId])


  // const groups = useDataProviderSelector((state) => state.deviceGroups);
  // const alarmSets = useMemo(
  //   () => groups.filter((group) => !group.display),
  //   [groups]
  // );

  const alarmsGroups = useMemo(()=>groups.filter(aset=>aset.alarms.includes(alarm?._id||"")).map(aset=>aset._id),[alarm,groups])

  const [newAlarmsGroup, setNewAlarmsGroup] = useState<Array<string>>([]);

  const onSave = useCallback(() => {

    const deviceGroupsToAdd = newAlarmsGroup.filter(group=>!alarmsGroups.includes(group))
    const deviceGroupsToRemove = alarmsGroups.filter(group=>!newAlarmsGroup.includes(group))
    if(isNull(organisationId)){
      container.resolve(AlarmRequestsService).editGlobalAlarmGroups({deviceGroupsToAdd,deviceGroupsToRemove},alarmId!).then(()=>{
        onClose()
      })
    }else{ 

      container.resolve(AlarmRequestsService).editAlarmGroups({deviceGroupsToAdd,deviceGroupsToRemove},alarmId!,organisationId).then(()=>{
        onClose()
      })
    }

  }, [newAlarmsGroup,alarmsGroups,organisationId,alarmId]);

  useEffect(() => {
    setNewAlarmsGroup(alarmsGroups);
  }, [alarmsGroups]);

  const toggleGroup = useCallback((groupId:string)=>{
    setNewAlarmsGroup(curr=>{
      if(curr.includes(groupId)){
        return curr.filter(id=>id!==groupId)
      }else{
        return [...curr,groupId]
      }
    })
  },[setNewAlarmsGroup])

  return (
    <DefaultFormModal
      title={t("ALARMS_TOOLTIP_EDIT_SETS")}
      show={!!alarmId}
      onClose={onClose}
      onSave={onSave}
    >
      <>{t("EDIT_ALARMS_SETS_MODAL_PARAGRAPH", { alarmName: alarm?.name || "" })}</>
      <div className="mb-3 mt-4">
      {groups.filter(group=>((group.organisation||"")===(organisationId||""))&&!(group.display)).map((dgroup) =>{
        const checked = newAlarmsGroup.includes(dgroup._id)
        return (
        <div key={dgroup._id} className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={checked}
            onChange={(e)=>{
              toggleGroup(dgroup._id)
            }}
            id={`alarm-check-${dgroup._id}`}
          />
          <label className="form-check-label" htmlFor={`alarm-check-${dgroup._id}`}>
            {dgroup.name}
          </label>
        </div>
      )})}
      </div>
    </DefaultFormModal>
  );
};

export default EditAlarmsSetsModal;
