import { Tab } from "bootstrap";
import { useLayoutEffect, useRef, useState } from "react";
import { useSaveStateInSearchParams } from "../../modules/charts/components/useSaveStateInSearchParams";

export type TabItem<TabT extends string> = { name: TabT; Component: React.FC };
export type TabsArray<TabT extends string> = Array<TabItem<TabT>>;

export const useTabView = <TabsT extends string>(
  defaultTab: TabsT,
  queryParamName: string
) => {
  const { initialValue: initialTab, handleValueChange } =
    useSaveStateInSearchParams<TabsT>(defaultTab, queryParamName);

  const [activeTab, setActiveTab] = useState<TabsT>(initialTab);
  const tabRef = useRef<HTMLUListElement>(null);

  useLayoutEffect(() => {
    if (!tabRef.current) {
      return;
    }

    const handleTabChange = (e: Tab.Events.show) => {
      //@ts-ignore
      const targetId = e.target.id as string;
      const tabName = targetId.split("-")[0] as TabsT;
      setActiveTab(tabName);
      handleValueChange(tabName);
    };

    const tabEl = tabRef.current;
    //@ts-ignore
    tabEl.addEventListener("show.bs.tab", handleTabChange);
    return () => {
      //@ts-ignore
      tabEl.removeEventListener("show.bs.tab", handleTabChange);
    };
  }, [tabRef, setActiveTab, handleValueChange]);

  return { activeTab, tabRef, initialTab };
};
