import { useCallback, useMemo, useRef } from "react";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { isNull } from "lodash";
import { container } from "tsyringe";
import { DeviceGroupRequestsService } from "../../../request-services/device-group-requests.service";
import { useModPanelData } from "../../hooks/useModPanelData";
import { EmailConfigDto, SmsConfigDto } from "shared";
import { OrganisationRequestsService } from "../../../request-services/organisation-requests.service";
import { SystemRequestsService } from "../../../request-services/system-requests.service";

const DeleteSMSModal: React.FC<{
  index: number | null;
  onClose: () => void;
  organisationId: string | null;
}> = ({ onClose, index, organisationId }) => {
  const t = useTranslate("user");
  const { smsData } = useModPanelData(organisationId);
  const smsItem = useMemo(() => smsData?.items[index || 0], [smsData, index]);

  const onSave = useCallback(() => {
    const dto: SmsConfigDto = { items: [...smsData.items] };
    dto.items.splice(index || 0, 1);

    if (isNull(organisationId)) {
      container
        .resolve(SystemRequestsService)
        .sendUpdateSmsConfig(dto)
        .then(() => {
          onClose();
        });
    } else {
      container
        .resolve(OrganisationRequestsService)
        .sendUpdateSmsConfig(dto, organisationId)
        .then(() => {
          onClose();
        });
    }
  }, [organisationId, smsData, index]);

  return (
    <DefaultFormModal
      title={t("SMS_CONFIG_MODAL_HEADER_DELETE")}
      show={!isNull(index)}
      onClose={onClose}
      onSave={onSave}
      submitType="delete"
    >
      <>
        {t("SMS_CONFIG_MODAL_PARAGRAPH_DELETE_1")}
        <span className="fw-bold">{smsItem?.phoneNumber}</span>
        {t("SMS_CONFIG_MODAL_PARAGRAPH_DELETE_2")}
      </>
    </DefaultFormModal>
  );
};

export default DeleteSMSModal;
