import { ReactComponent as DevicesIcon } from "bootstrap-icons/icons/robot.svg";
import DefaultTile from "./DefaultTile";

const DevicesTile: React.FC<{ Icon: React.ReactNode; title: string,url?:string }> = (
  props
) => {
  const { Icon, title,url } = props;

  return (
    <DefaultTile url={url} title={title} background={<div style={{  width: "80%", height: "80%" }}>{Icon}</div>}  />
      
  );

};

export default DevicesTile;
