import React from 'react';
import DevicesGrid from '../../../modules/devices/components/DevicesGrid/DevicesGrid';
import DevicesView from '../../../modules/devices/components/DevicesGrid/DevicesView';

export const DEVICES_PAGE_PATH = "/devices"

const DevicesPage:React.FC = ()=>{

    return(
        <DevicesView/>
    )

}

export default DevicesPage;