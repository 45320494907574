import { AlertService } from "../../../modules/alerts/alert.service";
import { getContainer } from "../../../modules/app-container";
import translations from "../../../translations/translations";
import { NetworkErrorHandler } from "../error-handler.interface";

const usernameError: NetworkErrorHandler = (error) => {
  if ((error?.response?.data as any)?.title !== "Wrong username") {
    return false;
  }

  const alertService = getContainer().resolve<AlertService>("alert");
  alertService.pushMessage({
    theme: "danger",
    message: translations.PL.menu.INCORRECT_LOGIN,
  });
  return true;
};

export default usernameError;
