import { isEqual } from "lodash";
import { useEffect, useMemo } from "react";
import { createStore, Store } from "redux";
import { ALARMS_REQUESTS_SEARCH_PARAM } from "../../../skeleton/pages/alarms";
import { getContainer } from "../../app-container";
import { useSaveStateInSearchParams } from "../../charts/components/useSaveStateInSearchParams";
import { TimeScaleState } from "../../charts/models/time-scale-state";
import { AlarmsService } from "../alarms.service";
import { AlarmRequests } from "../models/alarm-requests.model";
import { AlarmsReducer, AlarmsState } from "../store";
import { SetTotalAlarmExceeds } from "../store/setTotalAlarmExceeds";
import { useDataProviderSelector } from "../../data-provider/data-provider.provider";

type SearchParamsContent = AlarmRequests & { page: number };
export const getAlarmListFullRequest = (
  state: AlarmsState
): SearchParamsContent => {
  const {
    filterAlarmType,
    filterDataType,
    filterDeviceName,
    page,
    sortMode,
    timeScaleState,
  } = state;

  return {
    filterAlarmType,
    filterDataType,
    filterDeviceName,
    page,
    sortMode,
    timeScaleState,
  };
};

type Config = {
  disableSearchParamsSync: boolean;
};

const defaultConfig: Config = {
  disableSearchParamsSync: false,
};

export const useCreateAlarmsStore = (partConfig: Partial<Config> = {}) => {
  const { handleValueChange, initialValue, valueIndex } =
    useSaveStateInSearchParams(
      getAlarmListFullRequest(AlarmsReducer.initialState),
      ALARMS_REQUESTS_SEARCH_PARAM
    );

  const config: Config = useMemo(
    () => ({
      ...defaultConfig,
      ...partConfig,
    }),
    [partConfig]
  );

  const store = useMemo<Store>(() => {
    const reducer = new AlarmsReducer({
      ...initialValue,
      requestedAlarmExceeds: [],
      totalAlarmExceeds: [],
    });
    //@ts-ignore
    return createStore(reducer.changeState.bind(reducer));
  }, [valueIndex]);

  const alarmsService = getContainer().resolve<AlarmsService>("alarms");

  const dataLoaded = useDataProviderSelector(state=>state.dataLoaded)

  /* getting alarm exceeds; this will be replaced */
  useEffect(() => {
    const downloadData = (timeScaleState: TimeScaleState) => {
      alarmsService
        .getAlarmExceeds(
          timeScaleState.startTimestamp_s,
          timeScaleState.endTimestamp_s
        )
        .then((alarmExceeds) => {
          store.dispatch(SetTotalAlarmExceeds.callAction({ totalAlarmExceeds: alarmExceeds }));
        });
    };

    const selectTimeScaleState = (state:AlarmsState)=>state.timeScaleState;
    let prevTimeScaleState = selectTimeScaleState(store.getState());
    store.subscribe(()=>{
      const timeScaleState = selectTimeScaleState(store.getState());
      if(isEqual(prevTimeScaleState,timeScaleState)){
        return
      }

      prevTimeScaleState=timeScaleState;
      downloadData(timeScaleState)

    })

    downloadData(prevTimeScaleState)
  }, [store,dataLoaded]);

  /* saving requests in search params */
  useEffect(() => {
    if (config.disableSearchParamsSync) {
      return;
    }

    const unsubscribe = store.subscribe(() => {
      const state = store.getState() as AlarmsState;
      handleValueChange(getAlarmListFullRequest(state));
    });
    return () => {
      unsubscribe();
    };
  }, [store, config]);

  return store;
};
