import { DateTime } from "luxon";
import { useTranslate } from "react-translate";
import { DataType } from "../../../charts/models/chart-description.model";
import { sortMeasureDataTypes } from "../../utils/sortMeasureDataTypes";
import { getDisplayDefaultDecimalPoints } from "../../../charts/chart-generating/utils/getDisplayDefaultDecimalPoints";
import Decimal from "decimal.js";
import { useHistory } from "react-router";
import ChangeNameModal from "./ChangeNameModal";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDeviceRes } from "../../../charts/hooks/useDeviceRes";
import { ChartData } from "../../../charts/models/chart-data";
import { ChartRequests } from "../../../charts/models/chart-requests.model";
import { useChart } from "../../../charts/hooks/useChart";
import { SetRequestedDataType } from "../../../charts/store/setRequestedDataType";
import { getStatsPageURLWithSearchParamsValue } from "../../../../skeleton/pages/stats";
import { DEVICES_PAGE_PATH } from "../../../../skeleton/pages/devices";
import { TimeScaleState } from "../../../charts/models/time-scale-state";
import { ChartState } from "../../../charts/store";
import { isEqual, isNull, isUndefined } from "lodash";
import { useActivateTooltip } from "../../utils/useActivateTooltip";
import {
  ALARMS_PAGE_URL,
  ALARMS_REQUESTS_SEARCH_PARAM,
} from "../../../../skeleton/pages/alarms";

import { ReactComponent as DisconnectedIcon } from "bootstrap-icons/icons/exclamation-triangle.svg";
import { ReactComponent as DevicesIcon } from "bootstrap-icons/icons/robot.svg";
import { ReactComponent as AlarmIcon } from "bootstrap-icons/icons/bell-fill.svg";
import { ReactComponent as ShowIcon } from "bootstrap-icons/icons/eye-fill.svg";
import { ReactComponent as HideIcon } from "bootstrap-icons/icons/eye-slash-fill.svg";
import { ReactComponent as ReturnIcon } from "bootstrap-icons/icons/grid-fill.svg";
import { ReactComponent as SquareIcon } from "bootstrap-icons/icons/square-fill.svg";
import { ReactComponent as PencilIcon } from "bootstrap-icons/icons/pencil-fill.svg";
import { ReactComponent as AlarmsIcon } from "bootstrap-icons/icons/bell-fill.svg";
import { getAlarmListFullRequest } from "../../../alarms/components/useCreateAlarmsStore";
import { AlarmsReducer } from "../../../alarms/store";
import { getContainer } from "../../../app-container";
import { ChartsService } from "../../../charts/charts.service";
import { useDataProviderSelector } from "../../../data-provider/data-provider.provider";
import { DataProviderService } from "../../../data-provider/data-provider.service";
import { SetRequestedAlarm } from "../../../charts/store/setRequestedAlarms";
import { Measure, RunningHistory } from "shared";
import { DevicesService } from "../../devices.service";
import { connected } from "process";

const DeviceItemHeader: React.FC<{
  deviceId: string;
  deviceName: string;
  deviceColor: string;
  lastMeasure?: Measure;
  chartRequests: ChartRequests;
  setChartRequests: React.Dispatch<React.SetStateAction<ChartRequests>>;
  chartRequestIndex: number;
  setChartRequestIndex:React.Dispatch<React.SetStateAction<number>>;
  deviceSerialNumber:string;
  deviceRunningHistory:RunningHistory
  connected:boolean;
}> = (props) => {
  const tChart = useTranslate("chart");
  const t = useTranslate("devices");
  const {
    deviceColor,
    deviceName,
    lastMeasure,
    deviceId,
    chartRequests,
    setChartRequests,
    chartRequestIndex,
    setChartRequestIndex,
    deviceSerialNumber,
    deviceRunningHistory,
    connected
  } = props;

  const colorString = `rgb(${deviceColor})`;

  const { dtime: date_timestamp_s } = lastMeasure ||{};

  const chartDataProviderService = getContainer()
    .resolve<ChartsService>("charts")
    .getChartDataProviderService();
  const values = useMemo(
    () => chartDataProviderService.getMeasureValueArray(lastMeasure),
    [lastMeasure]
  );

  const sortedValues = sortMeasureDataTypes(values);
  const history = useHistory();

  const lightColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-light-rgb");
  const lightColorString = `rgb(${lightColor})`;

  const backTooltipRef = useRef<HTMLDivElement>(null);
  useActivateTooltip(backTooltipRef);

  const changeNameTooltipRef = useRef<HTMLDivElement>(null);
  useActivateTooltip(changeNameTooltipRef);

  const goToAlarmsTooltip = useRef<HTMLDivElement>(null);
  useActivateTooltip(goToAlarmsTooltip);

  const { height } = useDeviceRes(768);
  const { height: height1200 } = useDeviceRes(1200);

  const dataProviderService =
    getContainer().resolve<DataProviderService>("data-provider");
  const alarms = useDataProviderSelector((state) => state.alarms);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    setChartRequests((curr) => ({
      ...curr,
      requestedDevices: { [deviceId]: { color: deviceColor, enabled: true } },
    }));
  }, [deviceId, deviceColor]);

  const statsPageChartUrl = useMemo(
    () => getStatsPageURLWithSearchParamsValue(JSON.stringify(chartRequests)),
    [chartRequests]
  );

  const { store } = useChart(
    canvasRef,
    undefined,
    chartRequests,
    undefined,
    chartRequestIndex
  );

  const switchDataTypesRequest = useCallback(
    (dataType: DataType) => {
      const currentVal = chartRequests.requestedDataTypes.includes(dataType);
      store.dispatch(
        SetRequestedDataType.callAction({ dataType, requested: !currentVal })
      );
      if (currentVal) {
        setChartRequests((curr) => ({
          ...curr,
          requestedDataTypes: curr.requestedDataTypes.filter(
            (dt) => dt !== dataType
          ),
        }));
      } else {
        setChartRequests((curr) => ({
          ...curr,
          requestedDataTypes: [...curr.requestedDataTypes, dataType],
        }));
      }
    },
    [store, chartRequests, setChartRequests]
  );

  useEffect(() => {
    let selectTSS = (state: ChartState) => state.timeScaleState;

    let prevTTS = selectTSS(store.getState() as ChartState);
    const unsubscribe = store.subscribe(() => {
      const tTS = selectTSS(store.getState() as ChartState);
      if (isEqual(prevTTS, tTS)) {
        return;
      }
      prevTTS = tTS;
      setChartRequests((curr) => ({ ...curr, timeScaleState: tTS }));
    });
    return () => {
      unsubscribe();
    };
  }, [store, setChartRequests]);

  const alarmsFullRequests = useMemo(
    () => ({
      ...getAlarmListFullRequest(AlarmsReducer.initialState),
      filterDeviceName: [deviceName],
    }),
    [deviceName]
  );
  const alarmsPageUrl = useMemo(
    () =>
      `${ALARMS_PAGE_URL}?${ALARMS_REQUESTS_SEARCH_PARAM}=${JSON.stringify(
        alarmsFullRequests
      )}`,
    [alarmsFullRequests]
  );

  const disableAlarms = useCallback(
    () => {
      setChartRequests((curr) => ({ ...curr, requestedAlarms: [] }));
      setChartRequestIndex(curr=>curr+1)
    },
    [setChartRequests,setChartRequestIndex]
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
        flexWrap: "wrap",
      }}
    >
      <ChangeNameModal currentName={deviceName} />
      <div
        style={{
          position: "relative",
          flexGrow: 100,
          maxWidth: !height ? (!height1200 ? 428 : 360) : undefined,
        }}
        className="col-12 col-md-auto"
      >
        <div
          style={{ display: "flex", flexWrap: "nowrap", position: "relative" }}
          className="flex-column flex-xl-row align-items-stretch align-items-md-start"
        >
          <div
            className={`mt-xl-3 ms-2 ms-xl-0  me-1 flex-row flex-xl-column`}
            style={{
              // alignSelf: "flex-start",
              display: "flex",
            }}
          >
            <div
              className="flex-xl-column"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <div
                ref={backTooltipRef}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={t("BACK_TOOLTIP")}
                style={{ cursor: "pointer",position:"relative" }}
                className="mb-3 me-3"
              >
                <a
                  href={DEVICES_PAGE_PATH}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(DEVICES_PAGE_PATH);
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "100%",
                      zIndex: 2,
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 1,
                      top: 10,
                      left: 10,
                    }}
                  >
                    <ReturnIcon
                      fill={lightColorString}
                      width="28"
                      height="28"
                    />
                  </div>
                  <SquareIcon fill={colorString} width="48" height="48" />
                  {/* <ReturnIcon fill={colorString} width="48" height="48" /> */}
                </a>
              </div>

              {/* <div
              
              ref={changeNameTooltipRef}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={t("CHANGE_NAME_MODAL_TITLE")}
                className="mb-3 me-3"
                style={{ position: "relative", cursor: "pointer" }}
              >
                <a href="#" 
                data-bs-toggle="modal"
                data-bs-target="#changeNameModal">
                
                <div
                  style={{ position: "absolute", zIndex: 1, top: 10, left: 10 }}
                >
                  <PencilIcon fill={lightColorString} width="28" height="28" />
                </div>
                <SquareIcon fill={colorString} width="48" height="48" />
                </a>
              </div> */}

              <div 
                    ref={goToAlarmsTooltip}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={t("GO_TO_ALARMS")} className="mb-3 me-3" style={{ position: "relative" }}>
            
                  <a
                    href={alarmsPageUrl}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(alarmsPageUrl);
                    }}
                  >
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 1,
                      top: 10,
                      left: 10,
                    }}
                  >
                    <AlarmsIcon
                      fill={lightColorString}
                      width="28"
                      height="28"
                    />
                  </div>
                  <SquareIcon fill={colorString} width="48" height="48" />
                </a>
              </div>
            </div>
          </div>
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              width: height1200 ? "100%" : undefined,
            }}
          >
            <div
              className="mb-5"
              style={{
                maxWidth: 360,
                flexGrow: 1,
                width: height1200 ? "100%" : undefined,
              }}
            >
              <h1 className="text-center text-md-start">{deviceName}</h1>
              <h5 className="text-center text-md-start">{getContainer().resolve<DevicesService>("devices").getDevicesLatestLocation(deviceRunningHistory)}</h5>

              <div style={{position:"relative"}}>
              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <DevicesIcon
                  style={{ marginTop: 15, marginBottom: 25 }}
                  fill={colorString}
                  width="85"
                  height="85"
                />
                <div style={{position:"absolute",bottom:0,display:connected ? "none" : "flex",justifyContent:"center",color:colorString}}>

                  
          <div style={{display:"flex",alignItems:"center"}}>
            <DisconnectedIcon/>
            <div className="ms-1">{t("DISCONNECTED")}</div>
          </div>
      
                </div>
              </div>
              </div>
              <ul
                className="list-group list-group-flush"
                style={{ fontSize: "1.15rem" }}
              >
                <li className="list-group-item bg-light bg-gradient">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>{t("CURRENT_MEASURE")}</div>

                    <div style={{ display: "flex" }}>
                      <div>
                        {isUndefined(date_timestamp_s) ? "" : DateTime.fromJSDate(date_timestamp_s,{zone:"utc"}).setZone("local").toLocaleString(
                          DateTime.DATETIME_SHORT
                        )}
                      </div>
                      <div
                        className="ms-3"
                        style={{
                          alignSelf: "center",
                          fontSize: 1,
                          opacity: 0,
                          pointerEvents: "none",
                        }}
                      >
                        <ShowIcon
                          // fill={colorString}
                          width="18"
                          height="18"
                        />
                      </div>
                    </div>
                  </div>
                </li>
                {sortedValues.map((measure, i) => {
                  const { dataType, value } = measure;
                  const decimalPoint = getDisplayDefaultDecimalPoints(dataType);
                  const formattedValue =isUndefined(value)||isNull(value) ? "" : new Decimal(value).toFixed(
                    decimalPoint
                  );

                  const dataTypeAlarm = chartRequests.requestedAlarms.find(
                    (aName) =>
                      alarms.find(
                        (alarm) =>
                          alarm.name === aName &&
                          dataType ===
                            dataProviderService.getDataType(alarm.dtAlias)
                      )
                  );

                  const dataTypeIncluded = chartRequests.requestedDataTypes.includes(
                    dataType
                  ) 

                  return (
                    <li
                      className="list-group-item bg-light bg-gradient"
                      key={i}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>{tChart(`${dataType}_LABEL`)}</div>

                        <div style={{ display: "flex" }}>
                          <div>
                            {formattedValue}
                            {tChart(`${dataType}_UNIT`)}
                          </div>

                          <div
                            className="ms-3"
                            style={{ alignSelf: "baseline", fontSize: 18 }}
                          >
                            {
                            dataTypeAlarm ?
                            (
                              <AlarmIcon
                              tabIndex={0}
                              onKeyDown={(e:any)=>{
                                if(e.nativeEvent.key==="Enter"){
                                  disableAlarms();
                                }
                          
                              }}
                                style={{ cursor: "pointer" }}
                                onClick={() => disableAlarms()}
                                width="18"
                                height="18"
                              />
                            )
                            :dataTypeIncluded? (
                              <HideIcon
                              tabIndex={0}
                              onKeyDown={(e:any)=>{
                                if(e.nativeEvent.key==="Enter"){
                                  switchDataTypesRequest(dataType);
                                }
                          
                              }}
                                style={{ cursor: "pointer" }}
                                onClick={() => switchDataTypesRequest(dataType)}
                                fill={colorString}
                                width="18"
                                height="18"
                              />
                            ) : (
                              <ShowIcon
                              tabIndex={0}
                              onKeyDown={(e:any)=>{
                                if(e.nativeEvent.key==="Enter"){
                                  switchDataTypesRequest(dataType);
                                }
                          
                              }}
                                style={{ cursor: "pointer" }}
                                onClick={() => switchDataTypesRequest(dataType)}
                                fill={colorString}
                                width="18"
                                height="18"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className="justify-content-center justify-content-md-end"
        style={{
          flexGrow: 1,
          minWidth: 350,
          height: height ? Math.min(height * 0.8, 500) : undefined,
          alignSelf: "stretch",
          display: "flex",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: 600,
            flexGrow: 1,
          }}
        >
          <div
            style={{ height: 80, display: "flex", justifyContent: "flex-end" }}
          >
            <div style={{ margin: 20 }}>
              <ul className="nav nav-pills">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(statsPageChartUrl);
                    }}
                    style={{ backgroundColor: colorString }}
                    href={statsPageChartUrl}
                  >
                    {t("GO_TO_STATS")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div style={{ flexGrow: 1, position: "relative" }}>
            <div
              style={{ position: "absolute", width: "100%", height: "100%" }}
            >
              <canvas ref={canvasRef} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceItemHeader;
