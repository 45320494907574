import { ModDataState, SystemData } from ".";
import { ReducerActionClass } from "../../../utils/store/core";

type Payload = {
  systemData: SystemData|undefined;
};

class PutSystemDataClass extends ReducerActionClass<ModDataState, Payload> {
  getActionName(): string {
    return "PUT_SYSTEM_DATA";
  }
  handleDispatch(prevState: ModDataState, payload: Payload): ModDataState {
    const { systemData } = payload;
    return {
      ...prevState,
      system: systemData,
    };
  }
}

export const PutSystemData = new PutSystemDataClass()
