import { TotalRequestedDataTypes } from "../../models/data-type-request.model";
import { getTotalRequestedDataTypesArray } from "./getTotalRequestedDataTypesArray";

const borderDashes = [undefined,[3, 3],[10, 5],[5, 8],[20, 10]]
const borderDashesIndexesN = 5

// export const getBorderDash = (chartState:ChartState,dataType:DataType)=>{
    
//     const {totalRequestedDataTypes: totalRequestedDataTypes} = chartState;

//     const dataTypeImportanceIndex = dataTypesImportance.findIndex(dt=>dt===dataType);
//     const higherImportances = dataTypesImportance.slice(0,dataTypeImportanceIndex);
//     const numberHigherImportances = higherImportances.filter(dt=>totalRequestedDataTypes.includes(dt)).length

//     return borderDashes[numberHigherImportances];
// }

export const getBorderDashIndex = (totalRequestedDataTypes:TotalRequestedDataTypes)=>{

    const totalRequestedDataTypesArray = getTotalRequestedDataTypesArray(totalRequestedDataTypes);

    let borderDashesIndex=0;
    for(let i=0;i<borderDashesIndexesN;++i){
        if(totalRequestedDataTypesArray.find(dt=>totalRequestedDataTypes[dt].deviceDashIndex===i)){
            continue;
        }
        borderDashesIndex=i;
        break;
    }

    return borderDashesIndex;
}

export const getBorderDash = (borderDashesIndex:number)=>borderDashes[borderDashesIndex];