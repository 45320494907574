export default {

    "DEVICES_GRID_HEADER":"Urządzenia",
    "DEVICES_GRID_PARAGRAPH":"Wszystkie urządzenia dostępne w systemie.",

    "ALL_GROUPS_OPTION":"Wszystkie",
    "REST_OPTION":"Pozostałe",
    "GRID_VIEW_OPTION":"Kafelki",
    "TABLE_VIEW_OPTION":"Tabela",

    "CURRENT_MEASURE":"Aktualny pomiar",
    "DETAILS":"Szczegóły",
    "DISCONNECTED":"Rozłączono",
    "COLUMN_DEVICE_NAME":"Nazwa",
    "COLUMN_DEVICE_SERIAL_NUMBER":"Numer seryjny",
    "COLUMN_DEVICE_GROUP":"Grupa",
    "COLUMN_DEVICE_LOCATION":"Lokalizacja",
    "COLUMN_DEVICE_CONNECTED":"Połączone",
    "COLUMN_DEVICE_CONNECTED_NO":"Nie",
    "COLUMN_DEVICE_CONNECTED_YES":"Tak",
    "COLUMN_DEVICE_RSSI":"RSSI",
    "COLUMN_DEVICE_LAST_MEASURE":"Data aktualnego pomiaru",
    "WEEKLY_ALARM_EXCEEDED_LABEL":"Przekroczono w ciągu tygodnia",
    "WEEKLY_ALARM_EXCEEDED_TOOLTIP":"Procent czasu w ciągu ostatniego tygodnia, kiedy został przekroczony próg ostrzegawczy.",

    "SELECT_COLUMNS_MODAL_HEADER":"Zaznacz widoczne pola tabeli",
    "SELECT_COLUMNS_MODAL_CLOSE":"Zamknij",
    "SELECT_COLUMNS_MODAL_SAVE":"Zapisz",

    "EXCEEDED_WARNING_LEVEL":"przekroczono próg ostrzegawczy",
    "EXCEEDED_CRITICAL_LEVEL":"przekroczono próg krytyczny",
    "EXCEEDED_WEEKLY_RANGE":"w ciągu tygodnia",
    "EXCEEDED_MONTHLY_RANGE":"w ciągu miesiąca",

    "BACK_TOOLTIP":"Przejdź do galerii urządzeń",

    "GO_TO_STATS":"Przejdź do pomiarów",
    "GO_TO_ALARMS":"Przejdź do przekroczonych wartości alarmowych",

    "CHANGE_NAME_MODAL_TITLE":"Zmień nazwę",
    "CHANGE_NAME_MODAL_BODY":"Zmień nazwę urządzenia {{currentName}}",
    "CHANGE_NAME_MODAL_NEW_NAME":"Nowa nazwa",
    "CHANGE_NAME_MODAL_CLOSE":"Zamknij",
    "CHANGE_NAME_MODAL_SAVE":"Zapisz",


    "INFORMATION_TAB":"Informacje o urządzeniu",
    "TECHNICAL_DATA_TAB":"Dane techniczne",

    "HISTORY_TAB":"Historia",
    "TIME_START_LABEL":"Od",
    "TIME_END_LABEL":"Do",
    "TIME_END_NOW":"Teraz",

    "ALARM_EXCEEDS_STATISTICS_TAB":"Statystyki przekroczeń alarmów",
    "ALARM_EXCEEDS_STATISTICS_LABEL_WEEKLY":"Tygodniowo",
    "ALARM_EXCEEDS_STATISTICS_LABEL_MONTHLY":"Miesięcznie",
    "ALARM_EXCEEDS_STATISTICS_SHOW_IN_STATS":"Pokaż na wykresie",
    "ALARM_EXCEEDS_STATISTICS_GO_TO_STATS":"Przejdź do pomiarów",
    "ALARM_EXCEEDS_STATISTICS_GO_TO_ALARMS":"Przejdź do alarmów",

}