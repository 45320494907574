export default{
    "MOD_PANEL_HEADER":"Panel Moderatora",
    "MOD_PANEL_PARAGRAPH":"Panel Moderatora umożliwia zmianę ustawień dotyczących użytkowników, urządzeń i alarmów w organizacji {{orgName}}.",

    "ADMIN_PANEL_HEADER":"Panel Administratora",
    "ADMIN_PANEL_PARAGRAPH":"Panel Administratora umożliwia zmianę ustawień dotyczących organizacji, konfiguracji urządzeń i alarmów SMS.",

    "TAB_users":"Użytkownicy",
    "TAB_device_groups":"Grupy Urządzeń",
    "TAB_devices":"Urządzenia",
    "TAB_alarm_sets":"Zestawy alarmów",
    "TAB_alarm_sets_global":"Zestawy alarmów globalnych",
    "TAB_alarms":"Alarmy",
    "TAB_alarms_global":"Alarmy globalne",
    "TAB_sms":"Numery Telefonu",
    "TAB_email":"Adresy Email",
    "TAB_organisations":"Organizacje",
    "TAB_ipconfig":"Adresy IP",
    "TAB_notifications":"Powiadomienia",
    "TAB_system":"System",

    "USERS_PARAGRAPH":"W tabeli użytkowników można dodać lub usunąć użytkowników, zmienić hasło lub zmienić rolę.",
    "USERS_COLUMN_NAME":"Użytkownik",
    "USERS_COLUMN_LOGIN":"Login",
    "USERS_COLUMN_ROLE":"Rola",
    "USERS_ROLE_OWNER":"Właściciel",
    "USERS_ROLE_MANAGER":"Menadżer",
    "USERS_ROLE_MOD":"Moderator",
    "USERS_ROLE_USER":"Użytkownik",
    "USERS_TOOLTIP_RESET_PASSWORD":"Zresetuj hasło",
    "USERS_TOOLTIP_EDIT_ROLE":"Zmień rolę",
    "USERS_TOOLTIP_DELETE_USER":"Usuń użytkownika",
    "USERS_TOOLTIP_ADD_USER":"Dodaj użytkownika",
    "RESET_PASSWORD_MODAL_PARAGRAPH":"Zresetuj hasło użytkownika {{userName}}.",
    "RESET_PASSWORD_MODAL_LABEL_NEWPASSWORD":"Nowe hasło",
    "CHANGE_ROLE_MODAL_PARAGRAPH":"Zmień rolę użytkownika {{userName}}.",
    "CHANGE_ROLE_MODAL_LABEL_ROLE":"Nowa rola",
    "CREATE_USER_MODAL_PARAGRAPH":"Utwórz nowego użytkownika.",
    "CREATE_USER_MODAL_LABEL_ROLE":"Rola",
    "CREATE_USER_MODAL_LABEL_NAME":"Nazwa",
    "CREATE_USER_MODAL_LABEL_PASSWORD":"Hasło",
    "DELETE_USER_MODAL_PARAGRAPH":"Usuń użytkownika {{userName}}.",

    "DGROUPS_PARAGRAPH":"W tabeli grup urządzeń można dodać lub usunąć grupę urządzeń lub zmienić nazwę.",
    "DGROUPS_COLUMN_NAME":"Nazwa",
    "DGROUPS_COLUMN_DEVICE_NUMBER":"Liczba urządzeń",
    "DGROUP_TOOLTIP_EDIT":"Zmień nazwę grupy",
    "DGROUP_TOOLTIP_DELETE":"Usuń grupę",
    "DGROUP_TOOLTIP_ADD":"Dodaj grupę",
    "EDIT_DGROUP_MODAL_PARAGRAPH":"Zmień nazwę grupy {{groupName}}.",
    "EDIT_DGROUP_MODAL_LABEL_NAME":"Nazwa grupy",
    "DELETE_DGROUP_MODAL_PARAGRAPH":"Usuń grupę {{groupName}}.",
    "CREATE_DGROUP_MODAL_PARAGRAPH":"Utwórz nową grupę urządzeń.",

    "DEVICES_PARAGRAPH":"W tabeli urządzeń można zmienić nazwę, lokalizację, grupę i zestawy alarmowe urządzenia.",
    "DEVICES_COLUMN_NAME":"Nazwa",
    "DEVICES_COLUMN_SSN":"Numer seryjny",
    "DEVICES_COLUMN_LOCATION":"Lokalizacja",
    "DEVICES_COLUMN_GROUP":"Grupa",
    "DEVICES_COLUMN_ALARM_SETS":"Zestawy alarmów",
    "DEVICES_COLUMN_ALARM_SETS_GLOBAL":"Zestawy alarmów globalnych",
    "DEVICES_COLUMN_MQTT_USERNAME":"Użytkownik MQTT",
    "DEVICES_COLUMN_ORGANISATION":"Organizacja",
    "DEVICES_TOOLTIP_EDIT_NAME":"Zmień nazwę urządzenia",
    "DEVICES_TOOLTIP_EDIT_LOCATION":"Zmień lokalizację urządzenia",
    "DEVICES_TOOLTIP_EDIT_GROUP":"Zmień grupę urządzenia",
    "DEVICES_TOOLTIP_EDIT_ALARMS":"Zmień zestawy alarmów urządzenia",
    "DEVICES_TOOLTIP_CREATE":"Dodaj urządzenie",
    "DEVICES_TOOLTIP_EDIT_MQTT_CONFIGURATION":"Zmień konfigurację MQTT urządzenia",
    "DEVICES_TOOLTIP_EDIT_ORGANISATION":"Zmień organizację urządzenia",
    "DEVICES_TOOLTIP_DELETE":"Usuń urządzenie",
    "EDIT_DEVICE_MODAL_PARAGRAPH":"Zmień nazwę urządzenia {{deviceName}}.",
    "EDIT_DEVICE_MODAL_LABEL_SSN":"Numer seryjny",
    "EDIT_DEVICE_MODAL_LABEL_NAME":"Nazwa urządzenia",
    "EDIT_DEVICES_ALARMS_MODAL_PARAGRAPH":"Zmień wybrane zestawy alarmów urządzenia {{deviceName}}.",
    "EDIT_DEVICES_GROUP_MODAL_PARAGRAPH":"Zmień wybraną grupę urządzenia {{deviceName}}",
    "EDIT_DEVICES_LOCATIONS_MODAL_PARAGRAPH":"Zmień lokalizację urządzenia {{deviceName}}.",
    "EDIT_DEVICES_LOCATIONS_DELETE_LOCATION_TOOLTIP":"Usuń lokalizację",
    "EDIT_DEVICES_LOCATIONS_LOCATION_HEADER":"Lokalizacja #{{i}}",
    "EDIT_DEVICES_LOCATIONS_LOCATION_SINCE":"Od",
    "EDIT_DEVICES_LOCATIONS_LOCATION_UNTIL":"Do",
    "EDIT_DEVICES_LOCATIONS_LOCATION_LOCATION":"Lokalizacja",
    "EDIT_DEVICES_LOCATIONS_LOCATION_ADD":"Dodaj",
    "CREATE_DEVICE_MODAL_PARAGRAPH":"Dodaj nowe urządzenie.",
    "DELETE_DEVICE_MODAL_PARAGRAPH":"Usuń urządzenie {{deviceName}}.",
    "EDIT_DEVICE_MQTT_CONF_MODAL_PARAGRAPH":"Zmień konfigurację MQTT urządzenia {{deviceName}}.",
    "EDIT_DEVICE_MODAL_LABEL_MQTT_USERNAME":"Użytkownik MQTT",
    "EDIT_DEVICE_MODAL_LABEL_MQTT_PASSWORD":"Hasło MQTT",
    "EDIT_DEVICE_MODAL_LABEL_MQTT_PASSWORD_CONFIRM":"Powtórz hasło MQTT",
    "EDIT_DEVICE_ORGANISATION_PARAGRAPH":"Wybierz organizację, do której ma być przypisane urządzenie {{deviceName}}.",


    "ASET_PARAGRAPH":"W tabeli zestawów alarmów można dodać lub usunąć zestaw lub zmienić nazwę.",
    "ASET_PARAGRAPH_GLOBAL":"W tabeli zestawów alarmów globalnych można dodać lub usunąć zestaw lub zmienić nazwę.",
    "ASET_COLUMN_NAME":"Nazwa",
    "ASET_COLUMN_ALARM_NUMBER":"Liczba alarmów",
    "ASET_COLUMN_DEVICE_NUMBER":"Liczba urządzeń",
    "ASET_COLUMN_GLOBAL":"Globalny",
    "ASET_COLUMN_GLOBAL_YES":"Tak",
    "ASET_COLUMN_GLOBAL_NO":"Nie",
    "ASET_TOOLTIP_EDIT":"Zmień nazwę zestawu alarmów",
    "ASET_TOOLTIP_DELETE":"Usuń zestaw alarmów",
    "ASET_TOOLTIP_ADD":"Dodaj zestaw alarmów",
    "EDIT_ASET_MODAL_PARAGRAPH":"Zmień nazwę zestawu {{setName}}.",
    "EDIT_ASET_MODAL_LABEL_NAME":"Nazwa zestawu",
    "DELETE_ASET_MODAL_PARAGRAPH":"Usuń zestaw {{setName}}.",
    "CREATE_ASET_MODAL_PARAGRAPH":"Utwórz nowy zestaw alarmów.",

    "ALARMS_PARAGRAPH":"W tabeli alarmów można edytować i usunąć alarm, dodać nowy alarm oraz edytować przypisanie do zestawów.",
    "ALARMS_PARAGRAPH_GLOBAL":"W tabeli alarmów globalnych można edytować i usunąć alarm, dodać nowy alarm oraz edytować przypisanie do zestawów. Dodatkowo można określić czy w przypadku przekroczenia wartości alarmowych mają zostać wysłane wiadomości SMS oraz czy alarm ma być widoczny dla użytkowników aplikacji (w przypadku gdy alarm jest ukryty i ma włączone wiadomości SMS, wiadomości zostaną wysłane tylko na numery podane w panelu administratora).",
    "ALARMS_COLUMN_NAME":"Nazwa",
    "ALARMS_COLUMN_DATATYPE":"Dane pomiarowe",
    "ALARMS_COLUMN_TYPE":"Rodzaj alarmu",
    "ALARMS_COLUMN_THRESHOLD":"Próg",
    "ALARMS_COLUMN_SETS_NUMBER":"Liczba zestawów alarmów",
    "ALARMS_COLUMN_EMAIL":"Wiadomości email",
    "ALARMS_COLUMN_SMS":"Wiadomości SMS",
    "ALARMS_COLUMN_SMS_ENABLED":"Tak",
    "ALARMS_COLUMN_SMS_DISABLED":"Nie",
    "ALARMS_COLUMN_HIDDEN":"Ukryty",
    "ALARMS_COLUMN_HIDDEN_YES":"Tak",
    "ALARMS_COLUMN_HIDDEN_NO":"Nie",
    "ALARMS_COLUMN_EXCEEDED_TYPE":"Przekroczenie",
    "ALARMS_COLUMN_EXCEEDED_TYPE_ABOVE":"Powyżej",
    "ALARMS_COLUMN_EXCEEDED_TYPE_BELOW":"Poniżej",

    "ALARMS_TOOLTIP_EDIT_SETS":"Edytuj przypisanie do zestawów alarmów",
    "ALARMS_TOOLTIP_EDIT":"Edytuj alarm",
    "ALARMS_TOOLTIP_DELETE":"Usuń alarm",
    "ALARMS_TOOLTIP_NEW":"Dodaj alarm",
    "EDIT_ALARMS_SETS_MODAL_PARAGRAPH":"Zmień zestawy alarmów, w których ma znajdować się alarm {{alarmName}}.",

    "CREATE_ALARM_MODAL_PARAGRAPH":"Utwórz nowy alarm.",
    "EDIT_ALARM_MODAL_PARAGRAPH":"Edytuj alarm {{alarmName}}.",
    "DELETE_ALARM_MODAL_PARAGRAPH":"Usuń alarm {{alarmName}}.",
    "EDIT_ALARM_MODAL_LABEL_NAME":"Nazwa alarmu",
    "EDIT_ALARM_MODAL_LABEL_TYPE":"Typ alarmu",
    "EDIT_ALARM_MODAL_LABEL_DTALIAS":"Dane pomiarowe",
    "EDIT_ALARM_MODAL_LABEL_THRESHOLD":"Wartość",
    "EDIT_ALARM_MODAL_LABEL_SMS":"Wiadomości SMS",
    "EDIT_ALARM_MODAL_LABEL_EMAIL":"Wiadomości email",
    "EDIT_ALARM_MODAL_LABEL_HIDDEN":"Ukryty",
    "EDIT_ALARM_MODAL_LABEL_EXCEED_BELOW_THRESHOLD":"Przekroczenie poniżej progu",

    "ORGANISATION_PARAGRAPH":"W tabeli organizacji można zmienić nazwę organizacji, zmienić login właściciela oraz zresetować jego hasło.",
    "ORGANISATION_COLUMN_NAME":"Nazwa",
    "ORGANISATION_COLUMN_OWNER_NAME":"Nazwa użytkownika właściciela",
    "ORGANISATION_COLUMN_OWNER_LOGIN":"Login właściciela",
    "ORGANISATION_COLUMN_DEVICE_NUMBER":"Liczba urządzeń",
    "ORGANISATION_TOOLTIP_CREATE":"Dodaj nową organizację",
    "ORGANISATION_TOOLTIP_EDIT":"Edytuj organizację",
    "ORGANISATION_TOOLTIP_DELETE":"Usuń organizację",
    "ORGANISATION_TOOLTIP_RESET_OWNER_PASSWORD":"Zresetuj hasło właściciela",
    "EDIT_ORGANISATION_MODAL_PARAGRAPH":"Edytuj organizację {{orgName}}",
    "EDIT_ORGANISATION_MODAL_LABEL_NAME":"Nazwa",
    "EDIT_ORGANISATION_MODAL_LABEL_OWNER":"Nazwa użytkownika właściciela",
    "CREATE_ORGANISATION_MODAL_PARAGRAPH":"Dodan nową organizację.",
    "CREATE_ORGANISATION_MODAL_LABEL_OWNER_PASSWORD":"Hasło właściciela",
    "DELETE_ORGANISATION_MODAL_PARAGRAPH":"Usuń organizację {{orgName}}.",
    
    "NOTIFICATIONS_COLUMN_TITLE":"Tytuł",
    "NOTIFICATIONS_PARAGRAPH":"W tabeli powiadomienia można dodawać, edytować i usuwać powiadomienia. Powiadomienia wyświetlają się na stronie profil i można przypisać do określonych organizacji .",
    "NOTIFICATIONS_COLUMN_CREATED_AT":"Data utworzenia",
    "NOTIFICATIONS_COLUMN_ORGANISATIONS":"Organizacje",
    "NOTIFICATIONS_TOOLTIP_CREATE":"Dodaj nowe powiadomienie",
    "NOTIFICATIONS_TOOLTIP_EDIT":"Edytuj powiadomienie",
    "NOTIFICATIONS_TOOLTIP_DELETE":"Usuń powiadomienie",
    "EDIT_NOTIFICATION_MODAL_PARAGRAPH":"Edytuj powiadomienie {{title}}",
    "CREATE_NOTIFICATION_MODAL_PARAGRAPH":"Dodaj nowe powiadomienie",
    "DELETE_NOTIFICATION_MODAL_PARAGRAPH":"Usuń powiadomienie {{title}}",

    "NOTIFICATIONS_MODAL_LABEL_ORGANISATIONS":"Organizacje",
    "NOTIFICATIONS_ORGANISATIONS_ALL":"Wszystkie",

    "SYSTEM_PARAGRAPH":"W zakładce systemu można zmienić konfigurację serwisów działających w systemie.",
    "SYSTEM_MISSING_MEASURES_HEADER":"Serwis wysyłania komunikatów o brakujących pomiarach",
    "SYSTEM_MISSING_MEASURES_DESCRIPTION":"Serwis wykrywający sytuacje, w których któreś z urządzeń nie wysyła wartości jednego (lub więcej) parametru od określonego czasu (jeśli parametr został wysłany wcześniej). Serwis obejmuje sytuacje, w których urządzenie nie wysyła pomiarów.",
    "SYSTEM_MISSING_MEASURES_ENABLED":"Włączony",
    "SYSTEM_MISSING_MEASURES_ENABLED_YES":"Tak",
    "SYSTEM_MISSING_MEASURES_ENABLED_NO":"Nie",
    "SYSTEM_MISSING_MEASURES_THRESHOLD":"Czas od ostatniego pomiaru po którym ma zostać wysłany alarm (min 180s)",
    "SYSTEM_MISSING_MEASURES_EDIT":"Edytuj",

    "CHANGE_MMAC_MODAL_PARAGRAPH":"Zmień ustawienia działania serwisu wysyłania komunikatów o brakujących pomiarach.",

}