import * as tsyringe from "tsyringe";
import type { DependencyContainer } from "tsyringe";
import { createChartsContainer } from "./charts.container";
import { ChartDataProviderService } from "./submodules/data-provider/chart-data-provider.service";
import { DataProviderService } from "../data-provider/data-provider.service";
import { TimeScaleRange, TimeScaleState } from "./models/time-scale-state";
import { DateTime } from "luxon";
import { ChartRequests } from "./models/chart-requests.model";
import {
  getColorForDeviceGroup,
  getColorForIndex,
} from "./chart-generating/utils/getColor";
import { ChartDataExporterService } from "./submodules/data-exporter/data-exporter.service";
import { ChartsTableService } from "./submodules/table/table.service";
import { MeasuresGrouping } from "./models/measure-dataset-setup.model";
import { getContainer } from "../app-container";
import { DeviceRequest } from "./models/device-request.model";

@tsyringe.injectable()
export class ChartsService {
  private childContainer: DependencyContainer;
  //@ts-ignore
  private chartDataProviderService: ChartDataProviderService;
  //@ts-ignore
  private chartDataExporterService: ChartDataExporterService;
  //@ts-ignore
  private chartTableService: ChartsTableService;

  constructor(
    @tsyringe.inject("depedency-container") container: DependencyContainer
  ) {
    this.childContainer = createChartsContainer(container);

    this.chartDataProviderService = this.childContainer.resolve(
      "chart-data-provider"
    );
    this.chartDataExporterService = this.childContainer.resolve(
      "chart-data-exporter"
    );
    this.chartTableService = this.childContainer.resolve("charts-table");
    this.addChartTableService();
  }

  private addChartTableService() {}

  getDefaultTimeScaleState(): TimeScaleState {
    return {
      startTimestamp_s: DateTime.now().toSeconds() - 24 * 60 * 60,
      endTimestamp_s: DateTime.now().toSeconds(),
    };
  }

  getChartDataProviderService(): ChartDataProviderService {
    return this.chartDataProviderService;
  }

  getChartDataExporterService(): ChartDataExporterService {
    return this.chartDataExporterService;
  }

  getChartTableService(): ChartsTableService {
    return this.chartTableService;
  }

  getDefaultChartRequest(showEmpty: boolean = false): ChartRequests {
    const { devices, deviceGroups } = getContainer()
      .resolve<DataProviderService>("data-provider")
      .getStore()
      .getState();

    const requestedDevices: {
      [deviceName: string]: DeviceRequest;
    } = {};

    if (!showEmpty) {
      deviceGroups
        .filter((dgroup) => dgroup.display)
        .forEach((dgroup, i) => {
          const deviceFromGroup = devices.find((dev) =>
            dev.device.groupIds.includes(dgroup._id)
          );
          if (!deviceFromGroup) {
            return;
          }
          requestedDevices[deviceFromGroup?.device._id] = {
            enabled: true,
            color: getColorForIndex(i),
          };
        });
    }

    return {
      requestedAlarms: ["CO2 Level", "CO_LEVEL"],
      requestedDataTypes: ["CO2_LEVEL", "CO_LEVEL"],
      requestedDevices,
      timeScaleState: this.getDefaultTimeScaleState(),
      measuresGrouping: "NONE",
    };
  }

  getMeasureTimestampDisplay(
    timestamp: number,
    grouping: MeasuresGrouping,
    unit: "S" | "MS" = "S"
  ): string {
    const timestamp_s = unit === "S" ? timestamp : Math.floor(timestamp / 1000);
    const dateTime = DateTime.fromSeconds(timestamp_s);

    if (grouping === "DAILY") {
      return dateTime.toLocaleString(DateTime.DATE_FULL);
    } else if (grouping === "HOUR") {
      return `${DateTime.fromSeconds(+timestamp_s - 1800).toLocaleString(
        DateTime.DATETIME_MED
      )}-${DateTime.fromSeconds(+timestamp_s + 1800).toLocaleString(
        DateTime.TIME_SIMPLE
      )}`;
    }
    return dateTime.toLocaleString(DateTime.DATETIME_MED);
  }
}
