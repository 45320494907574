import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { useModDataSelector } from "../../../mod-data.provider";
import { isNull } from "lodash";
import { container } from "tsyringe";
import { DeviceRequestsService } from "../../../request-services/device-requests.service";
import { useModPanelData } from "../../hooks/useModPanelData";

const EditDevicesAlarmSetsModal: React.FC<{
  deviceId: string | null;
  onClose: () => void;
  organisationId:string|null
}> = ({ onClose, deviceId,organisationId }) => {
  const t = useTranslate("mod");



  const {devices,deviceGroups: groups} = useModPanelData(organisationId);

  const device = useMemo(
    () => devices&&devices.find((dev) => dev.device._id === deviceId),
    [deviceId, devices]
  );
  const displayGroups = useMemo(
    () => groups&&groups.filter((group) => group.display)||[],
    [groups]
  );
  const alarmSets = useMemo(
    () => groups&&groups.filter((group) => !group.display)||[],
    [groups]
  );

  const [newDevicesGroup, setNewDevicesGroup] = useState<Array<string>>([]);

  const onSave = useCallback(() => {
      if(isNull(organisationId)){
        //edit global alarms
        container.resolve(DeviceRequestsService).editDevicesGlobalGroups(deviceId!,{groups:newDevicesGroup}).then(()=>{
          onClose()
        })
      }else{
        container.resolve(DeviceRequestsService).editDevicesGroups(deviceId!,organisationId,{groups:newDevicesGroup}).then(()=>{
          onClose()
        })
      }

  }, [newDevicesGroup,deviceId]);

  useEffect(() => {
    setNewDevicesGroup([...(device?.device.groupIds || [])]);
  }, [device]);

  const toggleGroup = useCallback((groupId:string)=>{
    setNewDevicesGroup(curr=>{
      if(curr.includes(groupId)){
        return curr.filter(id=>id!==groupId)
      }else{
        return [...curr,groupId]
      }
    })
  },[setNewDevicesGroup])

  return (
    <DefaultFormModal
      title={t("DEVICES_TOOLTIP_EDIT_ALARMS")}
      show={!!deviceId}
      onClose={onClose}
      onSave={onSave}
    >
      <>{t("EDIT_DEVICES_ALARMS_MODAL_PARAGRAPH", { deviceName: device?.device.name || "" })}</>
      <div className="mb-3 mt-4">
      {alarmSets.filter(group=>(group.organisation||"")===(organisationId||"")).map((dgroup) =>{
        const checked = newDevicesGroup.includes(dgroup._id)
        return (
        <div key={dgroup._id} className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={checked}
            onChange={(e)=>{
              toggleGroup(dgroup._id)
            }}
            id={`dev-check-${dgroup._id}`}
          />
          <label className="form-check-label" htmlFor={`dev-check-${dgroup._id}`}>
            {dgroup.name}
          </label>
        </div>
      )})}
      </div>
    </DefaultFormModal>
  );
};

export default EditDevicesAlarmSetsModal;
