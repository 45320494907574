import {
  CartesianScaleTypeRegistry,
  Chart,
  ChartConfiguration,
  DatasetController,
  LegendItem,
  ScaleOptionsByType,
} from "chart.js";
import { _DeepPartialObject } from "chart.js/types/utils";
import { DateTime } from "luxon";
import { getContainer } from "../../app-container";
import { ChartsService } from "../charts.service";
import { MeasuresGrouping } from "../models/measure-dataset-setup.model";
import { TimeScaleRange } from "../models/time-scale-state";
import { ChartState } from "../store";
import { getDatasets } from "./get-datasets";
import { getXScale } from "./get-x-scale";
import { getYScales } from "./get-y-scales";
import { isNull } from "lodash";
import Decimal from "decimal.js";
import { getDisplayDefaultDecimalPoints } from "./utils/getDisplayDefaultDecimalPoints";
import { DataType } from "../models/chart-description.model";

let pointerPositionY = 0;

export type GenerateChartConfig = {
  disableChartTimeScaleActions: boolean;
  showLegend: boolean;
};

export const DEFAULT_GENERATE_CHART_CONFIG: GenerateChartConfig = {
  disableChartTimeScaleActions: false,
  showLegend: false,
};

export const generateChartData = (
  state: ChartState,
  t: (key: string) => string,
  onTimeScaleChanged: (range: TimeScaleRange) => void = () => {},
  animations: boolean = false,
  partialGenerateChartConfig: Partial<GenerateChartConfig> = {}
): ChartConfiguration => {
  const chartsService = getContainer().resolve<ChartsService>("charts");

  const generateChartConfig: GenerateChartConfig = {
    ...DEFAULT_GENERATE_CHART_CONFIG,
    ...partialGenerateChartConfig,
  };
  const { datasets } = getDatasets(state, t);
  const { dataTypesIncluded } = state;

  const scales: _DeepPartialObject<{
    [key: string]: ScaleOptionsByType<
      "radialLinear" | keyof CartesianScaleTypeRegistry
    >;
  }> = getYScales(state, t);
  scales.x = getXScale(state);

  const handleTimeScaleChanged = (chart: Chart) => {
    // @ts-ignore
    const timeScale = chart.config._config.options.scales.x;
    const startTimestamp_s = Math.floor(timeScale.min / 1000);
    const endTimestamp_s = Math.floor(timeScale.max / 1000);
    onTimeScaleChanged({ startTimestamp_s, endTimestamp_s });
  };

  const config: ChartConfiguration = {
    type: "scatter",
    //@ts-ignore
    data: {
      datasets,
    },
    options: {
      // parsing:false,
      normalized: true,
      animation: animations ? undefined : false,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: generateChartConfig.showLegend,
          position: "top",
          labels: {
            //@ts-ignore
            pointStyleWidth: 50,
            usePointStyle: true,
            generateLabels: (chart) => {
              return (
                //@ts-ignore
                chart.config._config.data.datasets
                  .map((dataset: any, i: number): LegendItem | null => {
                    const [type, deviceName, dataTypeLabel] =
                      dataset.label.split(".");

                    if (type !== "data") {
                      return null;
                    }

                    return {
                      text: `${deviceName} - ${dataTypeLabel}`,
                      datasetIndex: i,
                      fillStyle: "rgba(0,0,0,0)",
                      lineDash: dataset.borderDash,
                      strokeStyle: dataset.borderColor,
                      lineWidth: 3,
                      pointStyle: "line",
                    };
                  })
                  //@ts-ignore
                  .filter((item) => !isNull(item))
              );
            },
          },
        },
        filler: {
          propagate: true,
        },
        zoom: generateChartConfig.disableChartTimeScaleActions
          ? undefined
          : {
              zoom: {
                mode: "x",
                wheel: {
                  enabled: true,
                },
                pinch: {
                  enabled: true,
                },
                drag: {
                  enabled: true,
                  modifierKey: "ctrl",
                },
                onZoomComplete: (e) => {
                  handleTimeScaleChanged(e.chart);
                },
              },
              pan: {
                mode: "x",
                enabled: true,
                //@ts-ignore
                onPanStart: ({ event }) => {
                  //@ts-ignore

                  pointerPositionY = window.event.screenY;

                  //@ts-ignore
                  return !window.event.ctrlKey;
                },
                onPanComplete: (e) => {
                  handleTimeScaleChanged(e.chart);
                },
                // onPan:()=>{
                //@ts-ignore

                // let currPointerPositionY=window.event.screenY;
                // let pointerDiff = currPointerPositionY-pointerPositionY;
                // pointerPositionY=currPointerPositionY;
                // //@ts-ignore
                // window.scrollTo({top:window.scrollY-pointerDiff,behavior:"instant"})
                // window.scrollY=window.scrollY-pointerDiff
                // document.querySelector("#main-container")?.scrollTo({top:document.querySelector("#main-container")!.scrollTop+window.event.movementY})
                // document.querySelector("#main-container")!.scrollTop(window.event.movementY)
                // },
              },
            },
        tooltip: {
          //@ts-ignore
          interaction: {
            //@ts-ignore
            mode: "nearest",
          },
          callbacks: {
            //@ts-ignore

            labelColor: (ctx) => ({
              backgroundColor: ctx.dataset.borderColor,
            }),

            //@ts-ignore
            label: (ctx) => {
              const timestamp_ms = ctx.parsed.x;
              const label = ctx.dataset.label!;
              const [_dataType, _measurerSlug, _measuredValue, grouping] =
                label.split(".");

              return chartsService.getMeasureTimestampDisplay(
                timestamp_ms,
                grouping as MeasuresGrouping,
                "MS"
              );
            },
            beforeFooter: (ctx) => {
              const label = ctx[0].dataset.label!;
              const [
                _dataType,
                _measurerSlug,
                measuredValue,
                _grouping,
                dataType,
              ] = label.split(".");

              const value = ctx[0].parsed.y;

              const valueString = new Decimal(value).toFixed(
                getDisplayDefaultDecimalPoints(dataType as any)
              );

              return `${measuredValue}: ${valueString}${t(`${dataType}_UNIT`)}`;
            },
            //@ts-ignore
            footer: (ctx) => {
              const label = ctx[0].dataset.label!;
              const [_dataType, measurerSlug] = label.split(".");

              return measurerSlug;
            },
          },
        },
      },

      scales,
    },
  };
  return config;
};
