import { SetAccessToken } from "./setAccessToken";
import { SetAuthData } from "./setAuthData";
import { getLoginInfo } from "../auth.storage";
import {
  ReducerActionClass,
  ReducerCollection,
} from "../../../utils/store/core";

let loginInfo = getLoginInfo();

const loggedIn = loginInfo.loginState !== "LOGGED_OUT";
const rememberMe = !!loginInfo.expires;

export type AuthState = {
  loggedIn: boolean;
  accessToken?: string;
  rememberMe?: boolean;
};

export class AuthReducer extends ReducerCollection<AuthState> {
  static initialState: AuthState = {
    loggedIn,
    rememberMe,
  };

  protected initState() {
    return AuthReducer.initialState;
  }

  protected getActionClassObjects(): ReducerActionClass<AuthState, any>[] {
    return [SetAccessToken, SetAuthData];
  }
}
