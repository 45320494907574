import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useTranslate } from "react-translate";
import { Notification } from "shared";
import PaginationBlock from "../../../../utils/components/PaginationBlock";
import { useDataProviderSelector } from "../../../data-provider/data-provider.provider";
import NotificationCard from "./NotificationCard";
import NotificationModal from "./NotificationModal";

import { SwitchTransition, Transition } from "react-transition-group";
import { getContainer } from "../../../app-container";
import { UserService } from "../../user.service";

const ANIMATION_DURATION_MS = 150;

const ITEMS_ON_PAGE = 5;

const NotificationView: React.FC = () => {
  const t = useTranslate("user");

  const notifications = useDataProviderSelector((state) => state.notifications);
  const [expandedNotification, setExpandedNotification] =
    useState<Notification | null>(null);

  const [page, setPage] = useState<number>(0);
  const totalPages = useMemo(
    () => Math.ceil(notifications.length / ITEMS_ON_PAGE),
    [notifications]
  );

  useEffect(()=>{
    getContainer().resolve<UserService>("user").updateNotificationRefreshTime()
  },[])

  const defaultAnimatedStyle = {
    transition: `opacity ${ANIMATION_DURATION_MS}ms linear`,
    opacity: 0,
    height: "100%",
    width: "100%",
  };
  const transitionAnimatedStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: { opacity: 0 },
  };

  return (
    <>
      <div className="mt-5 pt-3">
        <div className="mb-4 text-center text-sm-start">
          <h2>{t("NOTIFICATIONS_HEADER")}</h2>
          <h5 className="ms-1">{t("NOTIFICATIONS_PARAGRAPH")}</h5>
        </div>

        <div>
          <SwitchTransition>
            <Transition
              key={page}
              timeout={{ enter: 1, exit: ANIMATION_DURATION_MS }}
            >
              {(state) => (
                <div
                  style={{
                    ...defaultAnimatedStyle,
                    ...transitionAnimatedStyles[state],
                  }}
                >
                  {notifications
                    .sort(
                      (notA, notB) =>
                        DateTime.fromJSDate(notB.createdAt).toSeconds() -
                        DateTime.fromJSDate(notA.createdAt).toSeconds()
                    )
                    .slice(
                      page * ITEMS_ON_PAGE,
                      page * ITEMS_ON_PAGE + ITEMS_ON_PAGE
                    )
                    .map((notification) => (
                      <NotificationCard
                        notification={notification}
                        key={notification._id}
                        onExpand={() => setExpandedNotification(notification)}
                      />
                    ))}
                </div>
              )}
            </Transition>
          </SwitchTransition>
        </div>

        <div
          className={`${
            totalPages === 1 ? "d-none" : "d-flex"
          } justify-content-end`}
        >
          <PaginationBlock
            currentPage={page}
            totalPages={totalPages}
            onPageChange={setPage}
          />
        </div>
      </div>
      <NotificationModal
        notification={expandedNotification}
        onClose={() => setExpandedNotification(null)}
      />
    </>
  );
};

export default NotificationView;
