import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useTranslate } from "react-translate";
import { Notification } from "shared";
import DefaultModal from "../../../../utils/components/DefaultModal";

const NotificationModal: React.FC<{
  notification: Notification | null;
  onClose: () => void;
}> = ({ notification, onClose }) => {
  const tForms = useTranslate("forms");

  const [loadedNotification, setLoadedNotification] =
    useState<Notification | null>(null);

  useEffect(() => {
    if (!notification) {
      return;
    }
    setLoadedNotification(notification);
  }, [notification]);

  const {
    blocks = [],
    createdAt = new Date(),
    title,
  } = loadedNotification || {};


  return (
    <DefaultModal show={!!notification} onClose={onClose}>
      <div className="modal-content">
        <div className="modal-header">
          <div>
            <h5 className="card-title">{title}</h5>

            <h6 className="card-subtitle mb-2 text-muted">
              {DateTime.fromJSDate(createdAt).toLocaleString(
                DateTime.DATETIME_SHORT
              )}
            </h6>
          </div>

          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          {blocks.map((block) => {
            if (block.type === "header") {
              switch (block.data.level) {
                case 1:
                  return (
                    <h1 dangerouslySetInnerHTML={{ __html: block.data.text }} />
                  );
                case 2:
                  return (
                    <h2 dangerouslySetInnerHTML={{ __html: block.data.text }} />
                  );
                case 3:
                  return (
                    <h3 dangerouslySetInnerHTML={{ __html: block.data.text }} />
                  );
                case 4:
                  return (
                    <h4 dangerouslySetInnerHTML={{ __html: block.data.text }} />
                  );
                case 5:
                  return (
                    <h5 dangerouslySetInnerHTML={{ __html: block.data.text }} />
                  );
                case 6:
                  return (
                    <h6 dangerouslySetInnerHTML={{ __html: block.data.text }} />
                  );
              }
            }
            return (
              <div dangerouslySetInnerHTML={{ __html: block.data.text }} />
            );
          })}
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {tForms("MODAL_CLOSE")}
          </button>
        </div>
      </div>
    </DefaultModal>
  );
};

export default NotificationModal;
