import { useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { CreateOrganisationDto } from "shared";
import ErrorDisplayFieldError from "../../../../../utils/forms/ErrorDisplayFieldError";
import {generate as generatePassword} from "generate-password-browser";
import { container } from "tsyringe";
import { OrganisationRequestsService } from "../../../request-services/organisation-requests.service";

const resolver = classValidatorResolver(CreateOrganisationDto);

const CreateOrganisationModal: React.FC<{
  show:boolean;
  onClose: () => void;
}> = ({ onClose,show }) => {
  const t = useTranslate("mod");
  const {
    register,
    handleSubmit,
    formState: { errors,isValid },
    setValue,
    trigger
  } = useForm<CreateOrganisationDto>({ resolver, mode: "onBlur" });

  const submitRef = useRef<HTMLInputElement>(null);
  const onSave = useCallback(() => {
    if (!submitRef.current) {
      return;
    }
    submitRef.current.click();
  }, [submitRef]);

  useEffect(()=>{
    setValue("password",generatePassword({length:12,numbers:true,symbols:true,strict:true}))

  },[show])

  return (
    <DefaultFormModal
      title={t("ORGANISATION_TOOLTIP_CREATE")}
      show={show}
      onClose={onClose}
      onSave={onSave}
      saveDisabled={!isValid}
    >
      <>{t("CREATE_ORGANISATION_MODAL_PARAGRAPH")}</>
      <form
        onSubmit={handleSubmit((data) => {
          container.resolve(OrganisationRequestsService).createOrganisationDto(data).then(()=>onClose())
        })}
      >
        <input ref={submitRef} hidden type="submit" />
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <label htmlFor="create_organisation_name" className="col-form-label">
                {t("EDIT_ORGANISATION_MODAL_LABEL_NAME")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="create_organisation_name"
                {...register("name")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.name} />
        </div>
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <label htmlFor="create_organisation_owner" className="col-form-label">
                {t("EDIT_ORGANISATION_MODAL_LABEL_OWNER")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="create_organisation_owner"
                {...register("owner")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.owner} />
        </div>

        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <label htmlFor="create_organisation_owner_password" className="col-form-label">
                {t("CREATE_ORGANISATION_MODAL_LABEL_OWNER_PASSWORD")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="create_organisation_owner_password"
                {...register("password")}
              />
            </div>
          </div>

          <ErrorDisplayFieldError error={errors.password} />
        </div>
      </form>
    </DefaultFormModal>
  );
};

export default CreateOrganisationModal;
