import { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { ResetPasswordDto } from "shared";
import { exampleUsers } from "../../tabs/Mod/UsersTab";
import {generate as generatePassword} from "generate-password-browser";
import { container } from "tsyringe";
import { UserRequestsService } from "../../../request-services/user-requests.service";
import ErrorDisplayFieldError from "../../../../../utils/forms/ErrorDisplayFieldError";

const resolver = classValidatorResolver(ResetPasswordDto);

const ResetPasswordModal: React.FC<{
  userId: string | null;
  onClose: () => void;

  organisationId:string,
  systemRequest?:boolean
}> = ({ onClose, userId,organisationId,systemRequest }) => {
  const t = useTranslate("mod");
  const user = useMemo(()=>exampleUsers.find(user=>user._id===userId),[userId])

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<ResetPasswordDto>({ resolver, mode: "onBlur" });

  const submitRef = useRef<HTMLInputElement>(null);
  const onSave = useCallback(() => {
    if (!submitRef.current) {
      return;
    }
    submitRef.current.click();
  }, [submitRef]);

  useEffect(()=>{
    setValue("newPassword",generatePassword({length:12,numbers:true,symbols:true,strict:true}))
  },[user])

  return (
    <DefaultFormModal
      title={t("USERS_TOOLTIP_RESET_PASSWORD")}
      show={!!userId}
      onClose={onClose}
      onSave={onSave}
    >
      <>{t("RESET_PASSWORD_MODAL_PARAGRAPH", { userName: user?.user || "" })}</>
      <form
        onSubmit={handleSubmit((data) => {
          container.resolve(UserRequestsService).resetPassword(data,userId!,organisationId,systemRequest).then(()=>{
            onClose()
          })
        })}
      >
        <input ref={submitRef} hidden type="submit" />
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <label htmlFor="user_newPassword" className="col-form-label">
                {t("RESET_PASSWORD_MODAL_LABEL_NEWPASSWORD")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="user_newPassword"
                {...register("newPassword")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.newPassword} />
        </div>
      </form>
    </DefaultFormModal>
  );
};

export default ResetPasswordModal;
