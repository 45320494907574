import React from 'react';
import HomePageView from '../../../modules/home-page/components/HomePageView';

export const HOME_PAGE_PATH = "/home"

const HomePage:React.FC = ()=>{

    return(
        <HomePageView/>
    )

}

export default HomePage;