import { Chart } from "chart.js"
import { ChartState } from "."
import { ReducerActionClass } from "../../../utils/store/core"

type Payload = {
    chart:Chart|null,
}

class PutChartClass extends ReducerActionClass<ChartState,Payload>{

    getActionName(){
        return "PUT_CHART_CLASS"
    }

    handleDispatch(prevState:ChartState,payload:Payload){
        const {chart} = payload;
        return{
            ...prevState,
            chart
        }
    }
} 

export const PutChart = new PutChartClass();