import { AlarmsState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";
import { SortMode } from "../models/sort-mode.model";
import { calculateRequestedAlarms } from "./utils/calculateRequestedAlarms";

type Payload = {
  sortMode: SortMode;
};

class SetSortModeClass extends ReducerActionClass<AlarmsState, Payload> {
  getActionName() {
    return "SET_SORT_MODE";
  }

  handleDispatch(prevState: AlarmsState, payload: Payload): AlarmsState {
    const { sortMode } = payload;

    const midState: AlarmsState = {
      ...prevState,
      sortMode,
    };

    const updatedData = calculateRequestedAlarms(midState,prevState);
    return {
      ...midState,
      ...updatedData,
      page:0
    };
  }
}

export const SetSortMode = new SetSortModeClass();
