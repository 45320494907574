import Decimal from "decimal.js";
import { DataType } from "../../models/chart-description.model";
import { CO2_DISPLAY_DEFAULT_DECIMAL_POINTS } from "../yScales/getCO2Scale";
import { CO_DISPLAY_DEFAULT_DECIMAL_POINTS } from "../yScales/getCOScale";
import { POWER_SUPPLY_DISPLAY_DEFAULT_DECIMAL_POINT } from "../yScales/getPowerSupplyScale";
import { TEMPERATURE_DISPLAY_DEFAULT_DECIMAL_POINTS } from "../yScales/getTemperatureScale";

export const displayDataTypeNumber = (value:number,dataType:DataType)=>new Decimal(value).toFixed(getDisplayDefaultDecimalPoints(dataType))

export const getDisplayDefaultDecimalPoints = (dataType: DataType): number => {
  if (dataType === "CO2_LEVEL") {
    return CO2_DISPLAY_DEFAULT_DECIMAL_POINTS;
  } else if (dataType === "TEMPERATURE") {
    return TEMPERATURE_DISPLAY_DEFAULT_DECIMAL_POINTS;
  } else if (dataType==="CO_LEVEL"){
    return CO_DISPLAY_DEFAULT_DECIMAL_POINTS
  } else if (dataType==="POWER_SUPPLY"){
    return POWER_SUPPLY_DISPLAY_DEFAULT_DECIMAL_POINT;
  }
  return 0;
};
