import axios from "axios";
import { isArray, isObject, isString } from "lodash";
import { DateTime } from "luxon";
import { DependencyContainer } from "tsyringe";
import { AlertService } from "../../modules/alerts/alert.service";
import { getContainer } from "../../modules/app-container";
import { AuthService } from "../../modules/auth/auth.service";
import ipError from "./error-handlers/ip-error";
import passwordError from "./error-handlers/password-error";
import unknownError from "./error-handlers/unknown-error";
import usernameError from "./error-handlers/username-error";

const parseResponse = (data: object) => {
  if (isArray(data)) {
    data.forEach((item, i) => {
      if (isString(item)) {
        const parseDateResult = DateTime.fromISO(item, { zone: "utc" });
        if (parseDateResult.isValid) {
          data[i] = parseDateResult.toJSDate();
        }
      } else if (isObject(item) || isArray(item)) {
        parseResponse(item);
      }
    });
  } else if (isObject(data)) {
    Object.entries(data).forEach(([i, item]) => {
      if (isString(item)) {
        const parseDateResult = DateTime.fromISO(item, { zone: "utc" });
        if (parseDateResult.isValid) {
          //@ts-ignore
          data[i] = parseDateResult.toJSDate();
        }
      } else if (isObject(item) || isArray(item)) {
        parseResponse(item);
      }
    });
  }
};

export const configAxiosBeforeServices = () => {
  axios.interceptors.response.use((response) => {
    parseResponse(response.data);
    return response;
  });
};

const errorHandlers = [passwordError, usernameError,ipError, unknownError];

export const configAxiosAfterServices = (appContainer:DependencyContainer) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      errorHandlers.find((eh) => eh(error));

      return error;
    }
  );

  const authStore = appContainer
  .resolve<AuthService>("auth")
  .getStore()
  let accessToken = authStore.getState().accessToken;
  authStore.subscribe(()=>{
    accessToken = authStore.getState().accessToken
  })


  axios.interceptors.request.use(async (config) => {
    console.log(`Token debug: Request made with token ${accessToken}, time:${DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}`)
    if (accessToken) {
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }

    return config;
  });
};
