import { ChartDataset } from "chart.js";
import { isNull } from "lodash";
import { ChartState } from "../store";
import { getMergedScaleDataType } from "./get-y-scales";
import { getAlarmDefaultColor } from "./utils/getAlarmDefaultColor";
import { getBorderDash } from "./utils/getBorderDash";
import { getTotalRequestedDataTypesArray } from "./utils/getTotalRequestedDataTypesArray";
import { findMinimumAlarmThreshold } from "./utils/findMinimumAlarmThreshold";
import { getContainer } from "../../app-container";
import { DataProviderService } from "../../data-provider/data-provider.service";

const MAX_INTERVAL_GROUPING_NONE_S = 300;
const MAX_INTERVAL_GROUPING_HOUR_S = 60 * 60;
const MAX_INTERVAL_GROUPING_DAILY_S = 60 * 60 * 24;

export const getDatasets = (
  chartState: ChartState,
  t: (label: string) => string
): { datasets: Array<ChartDataset> } => {
  const {
    measuresDatasetsSetup,
    alarmsDatasetsSetup,
    requestedAlarms,
    requestedDataTypes,
    requestedDevices,
    totalRequestedDataTypes,
    measuresGrouping,
  } = chartState;

  const totalRequestedDataTypesArray = getTotalRequestedDataTypesArray(
    totalRequestedDataTypes
  );
  let alarmDatasetIndex = 0;

  const DARK_COLOR = getAlarmDefaultColor();
  const darkColorString = `rgb(${DARK_COLOR})`;
  const darkColorTransparentString = `rgb(${DARK_COLOR},0.15)`;

  const datasets: Array<ChartDataset> = [];

  Object.keys(alarmsDatasetsSetup).forEach((name) => {
    const { dataType, value } = alarmsDatasetsSetup[name];

    if (
      !(
        totalRequestedDataTypesArray.includes(dataType) &&
        requestedAlarms.includes(name)
      )
    ) {
      return;
    }

    const borderDashIndex = totalRequestedDataTypes[dataType].deviceDashIndex;
    const borderDash = getBorderDash(borderDashIndex);
    //@ts-ignore
    const mergedScaleDataType = getMergedScaleDataType(dataType);

    datasets.push({
      type: "line",
      label: `alarmThreshold.${name}`,
      backgroundColor: darkColorString,
      yAxisID: `y${mergedScaleDataType}`,
      borderColor: darkColorString,
      pointRadius: 0,
      borderWidth: 4,
      borderDash,
      pointHoverRadius: 0,
      data: [
        { x: 657180800000, y: value },
        { x: 2657195200000, y: value },
      ],
    });
  });

  const devices = getContainer()
    .resolve<DataProviderService>("data-provider")
    .getStore()
    .getState().devices;

  Object.keys(measuresDatasetsSetup).forEach((deviceId, i) => {
    if (!requestedDevices[deviceId]?.enabled) {
      return;
    }

    const deviceName =
      devices.find((dev) => dev.device._id === deviceId)?.device.name || "";

    const deviceReqDataTypes =
      requestedDevices[deviceId].requestedDataTypes || requestedDataTypes;

    let color = requestedDevices[deviceId].color;
    const colorString = `rgb(${color})`;
    // const colorStringTransparent = `rgba(${color},0.1)`
    //@ts-ignore
    Object.keys(measuresDatasetsSetup[deviceId]).forEach(
      //@ts-ignore
      (dataType: DataType) => {
        //@ts-ignore
        if (!deviceReqDataTypes.includes(dataType)) {
          return;
        }

        measuresDatasetsSetup[deviceId][dataType].forEach((data) => {
          if (data.grouping !== measuresGrouping) {
            return;
          }

          const { minimumAlarmThreshold, maximumAlarmThreshold } =
            findMinimumAlarmThreshold(chartState, dataType);
          const datasetAlarmFillMinimum = isNull(minimumAlarmThreshold)
            ? undefined
            : {
                target: { value: minimumAlarmThreshold },
                above: darkColorTransparentString,
                below: "rgba(0,0,0,0)",
              };

          const datasetAlarmFillMaximum = isNull(maximumAlarmThreshold)
            ? undefined
            : {
                target: { value: maximumAlarmThreshold },
                below: darkColorTransparentString,
                above: "rgba(0,0,0,0)",
              };

          //@ts-ignore
          const borderDashIndex =
            totalRequestedDataTypes[dataType].deviceDashIndex;
          const borderDash = getBorderDash(borderDashIndex);
          // if (dataType === "CO2_LEVEL") {
          //   borderDash = undefined;
          //   // pointStyle = undefined;
          //   // pointRadius = 4;
          //   // pointHoverRadius = 5.3;
          // } else if(dataType==="TEMPERATURE") {
          //   borderDash = [10, 5];
          //   // pointStyle = "rect";
          //   // pointRadius = 5;
          //   // pointHoverRadius = 6.6;
          // } else if(dataType==="CO_LEVEL"){
          //   borderDash = [3, 3];
          // } else if(dataType==="ATMOSPHERIC_PRESSURE"){
          //   borderDash = [20, 10];

          // }else{
          //   borderDash = [5, 10];

          // }

          // if (dataType==="CO2_LEVEL"||dataType==="TEMPERATURE"||dataType==="CO_LEVEL"){
          //   borderWidth=3
          // }else{
          //   borderWidth=5
          // }

          //@ts-ignore
          const mergedScaleDataType = getMergedScaleDataType(dataType);

          const datasetData = data.measures.map((measure) => ({
            x: measure.timestamp_s * 1000,
            y: measure.value,
          }));

          const MAX_INTERVAL_S =
            data.grouping === "NONE"
              ? MAX_INTERVAL_GROUPING_NONE_S
              : data.grouping === "HOUR"
              ? MAX_INTERVAL_GROUPING_HOUR_S
              : MAX_INTERVAL_GROUPING_DAILY_S;

          let breaksAdded = 0;
          let datasetLength = data.measures.length;
          data.measures.forEach((meas, i) => {
            if (i + 1 === datasetLength) {
              return;
            }
            if (
              meas.timestamp_s + MAX_INTERVAL_S <
              data.measures[i + 1].timestamp_s
            ) {
              //@ts-ignore
              datasetData.splice(i + breaksAdded + 1, 0, null);
              breaksAdded++;
            }
          });

          const pointRadius = datasetData.map((_item, i) => {
            if (i === 0 && !datasetData[1]?.y) return 3;
            if (!datasetData[i - 1]?.y && !datasetData[i + 1]?.y) return 3;
            return 0;
          });

          datasets.push({
            type: "line",
            label: `data.${deviceName}.${t(`${dataType}_LABEL`)}.${
              data.grouping
            }.${dataType}`,
            backgroundColor: colorString,
            borderColor: colorString,
            // pointBackgroundColor: colorString,
            pointBorderColor: colorString,
            yAxisID: `y${mergedScaleDataType}`,
            borderWidth: 3,
            hoverBorderWidth: 3,
            borderDash,
            pointStyle: "line",
            pointRadius,
            pointHitRadius: 30,
            pointHoverRadius: pointRadius,
            data: datasetData,
            // fill:{target:"origin"}
            fill: datasetAlarmFillMinimum,
          });

          /* chart area to fill values below maximum threshold */

          if (datasetAlarmFillMaximum) {
            datasets.push({
              type: "line",
              label: `data.${deviceName}.${t(`${dataType}_LABEL`)}.${
                data.grouping
              }.${dataType}.FILL_BELOW`,
              yAxisID: `y${mergedScaleDataType}`,
              borderWidth: 0,
              hoverBorderWidth: 0,
              pointStyle: "line",
              pointRadius: 0,
              pointHitRadius: 0,
              pointHoverRadius: 0,
              data: datasetData,
              fill: datasetAlarmFillMaximum,
            });
          }

          /* chart area showing min max values in range. feature was not seeming usefull */

          // if(data.grouping!=="NONE"){
          //   datasets.push({
          //     type: "line",
          //     label: `data.${deviceName}.${t(`${dataType}_LABEL`)}.${data.grouping}.max`,
          //     backgroundColor: colorStringTransparent,
          //     borderColor: colorStringTransparent,
          //     borderWidth:0,
          //     yAxisID: `y${dataType}`,
          //     pointRadius:0,
          //     pointHoverRadius:0,
          //     data: data.measures.map(measure=>({
          //       x:measure.timestamp_s*1000,
          //       y:measure.max!
          //     })),
          //   });

          //   datasets.push({
          //     type: "line",
          //     label: `data.${deviceName}.${t(`${dataType}_LABEL`)}.${data.grouping}.min`,
          //     backgroundColor: colorStringTransparent,
          //     borderColor: colorStringTransparent,
          //     borderWidth:0,
          //     yAxisID: `y${dataType}`,
          //     pointRadius:0,
          //     pointHoverRadius:0,
          //     data: data.measures.map(measure=>({
          //       x:measure.timestamp_s*1000,
          //       y:measure.min!
          //     })),
          //     fill:{target:"-1"}
          //   });

          // }
        });
      }
    );
  });

  return { datasets };
};
