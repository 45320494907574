import { DataExporterState } from ".";
import { ReducerActionClass } from "../../../../../utils/store/core";
import { getContainer } from "../../../../app-container";
import { ChartsService } from "../../../charts.service";
import { DataExporterRequests } from "../models/data-exporter-requests.model";

type Payload = {
  dataExporterRequests: Partial<DataExporterRequests>;
};


class UpdateDataExporterRequestsClass extends ReducerActionClass<
  DataExporterState,
  Payload
> {
  getActionName(): string {
    return "PUT_DATA_EXPORTER_REQUESTS";
  }

  handleDispatch(
    prevState: DataExporterState,
    payload: Payload
  ): DataExporterState {
    const { dataExporterRequests: partDataExporterRequests } = payload;
    

    const chartDataExporterService = getContainer()
    .resolve<ChartsService>("charts")
    .getChartDataExporterService();

    const downloadable =
      chartDataExporterService.checkIfRequestsAreDownloadable(
        {...prevState,...partDataExporterRequests}
      );

    return {
      ...prevState,
      downloadable,
      ...partDataExporterRequests,
    };
  }
}

export const UpdateDataExporterRequests = new UpdateDataExporterRequestsClass()
