import ReactDOM from "react-dom/client";
import React from "react";
import { ChartData } from "../../../charts/models/chart-data";
import { ChartRequests } from "../../../charts/models/chart-requests.model";
import OffscreenContainer from "./OffscreenContainer";
import ChartPdfComponent from "./ChartPdfComponent";
import { TranslatorProvider } from "react-translate";
import translations from "../../../../translations/translations";
import { injectable } from "tsyringe";
import {
  GenerateChartConfig,
  generateChartData,
} from "../../../charts/chart-generating/generateChartData";
import download from "downloadjs";

type CreateChartImageOptions = {
  width: number;
  height: number;
};

const defautlCreateChartImageOptions: CreateChartImageOptions = {
  width: 800,
  height: 400,
};

@injectable()
export class ChartToImageService {
  async downloadChartImage(
    chartData: ChartData,
    chartRequests: ChartRequests,
    generateChartConfig?: Partial<GenerateChartConfig>,
    createChartImageOptions?: Partial<CreateChartImageOptions>
  ) {
    const chartImage = await this.getChartImage(
      chartData,
      chartRequests,
      generateChartConfig,
      createChartImageOptions
    );
    download(chartImage.dataUrl, "wykres.png", "image/png");
  }

  getChartImage(
    chartData: ChartData,
    chartRequests: ChartRequests,
    generateChartConfig?: Partial<GenerateChartConfig>,
    createChartImageOptions: Partial<CreateChartImageOptions> = defautlCreateChartImageOptions
  ): Promise<{ dataUrl: string; resolution: number }> {
    const options: CreateChartImageOptions = {
      ...defautlCreateChartImageOptions,
      ...createChartImageOptions,
    };


    return new Promise<{ dataUrl: string; resolution: number }>(
      async (resolve) => {
        const elContainer = document.createElement("div");
        document.body.appendChild(elContainer);
        const root = ReactDOM.createRoot(elContainer);

        const chartCanvasId = `table-pdf-${Math.floor(Math.random() * 10000)}`;

        const renderCallback = async () => {
          const canvasEl = document.querySelector(
            `#${chartCanvasId}`
          ) as HTMLCanvasElement;
          const dataUrl = canvasEl.toDataURL();
          root.unmount();

          resolve({
            dataUrl,
            resolution: 2,
          });

          document.body.removeChild(elContainer);
        };

        root.render(
          <React.StrictMode>
            <OffscreenContainer>
              <TranslatorProvider translations={translations.PL}>
                <ChartPdfComponent
                  height={options.height}
                  width={options.width}
                  chartCanvasId={chartCanvasId}
                  chartData={chartData}
                  chartRequests={chartRequests}
                  onRender={renderCallback}
                  generateChartConfig={generateChartConfig}
                />
              </TranslatorProvider>
            </OffscreenContainer>
          </React.StrictMode>
        );
      }
    );
  }
}
