import { injectable } from "tsyringe";
import { IPdfCreatorService } from "../../interfaces/pdf-creator.interface";
import {
  PdfItem,
  PdfItemImage,
  PdfItemTable,
  PdfItemText,
} from "../../models/pdf-item.model";
import { defaultPdfConfig, PdfConfig } from "../../models/pdf-config";
import jsPDF from "jspdf";
import autoTable, { RowInput } from "jspdf-autotable";
import { isString, isUndefined } from "lodash";
import { PdfItemArray } from "../../models/pdf-item-array.model";

const DEFAULT_MARGIN = 20;
const MARGIN_TOP = DEFAULT_MARGIN;
const MARGIN_BOTTOM = DEFAULT_MARGIN;
const MARGIN_LEFT = DEFAULT_MARGIN;
const MARGIN_RIGHT = DEFAULT_MARGIN;

const LINE_HEIGHT = 1.5;
const MARGIN_BETWEEN_ITEMS_MM = 10;

const BODY_FONT_SIZE = 13;
const SUBTITLE_FONT_SIZE = 16;
const TITLE_FONT_SIZE = 20;

type TextType = "BODY" | "TITLE" | "SUBTITLE";

type YPointer = {
  val: number;
};

@injectable()
export class JspdfCreatorService implements IPdfCreatorService {
  async downloadDocument(
    items: PdfItemArray,
    partConfig: Partial<PdfConfig> = {}
  ) {
    const config = {
      ...defaultPdfConfig,
      ...partConfig,
    };
    const { name } = config;

    const doc = this.createDocument();
    const yPointer = this.createYPointer(doc);

    for (let i = 0; i < items.length; ++i) {
      const item = items[i];
      if (Array.isArray(item)) {
        const textHeight = this.getTextHeight(doc, item[0]);
        const { height_mm: imageHeight } = this.getItemImageRes(doc, item[1]);
        const totalHeight = textHeight + imageHeight;
        this.addNewPageIfNeeded(doc, totalHeight, yPointer);
        await this.addItem(doc, yPointer, item[0], i);
        await this.addItem(doc, yPointer, item[1], i);

        continue;
      }
      await this.addItem(doc, yPointer, item, i);
    }

    this.saveDocument(doc, name);
  }

  private async addItem(
    doc: jsPDF,
    yPointer: YPointer,
    item: PdfItem,
    i: number
  ) {
    if (item.type === "image") {
      this.addImage(doc, yPointer, item, i);
    } else if (item.type === "table") {
      await this.addTable(doc, yPointer, item);
    } else if (item.type === "text") {
      this.addText(doc, yPointer, item);
    } else {
      //@ts-ignore
      console.warn(`Unknown pdfItem type:${item.type}`, item.type);
    }
  }

  private createDocument() {
    const doc = new jsPDF();

    doc.addFont("SEGOEUI.TTF", "SegoeUi", "normal");
    doc.addFont("SEGOEUI-Bold.TTF", "SegoeUi", "bold");
    doc.addFont("SEGOEUI-Italic.TTF", "SegoeUi", "italic");
    doc.addFont("SEGOEUI-Bold Italic.TTF", "SegoeUi", "bold-italic");
    doc.setFont("SegoeUi");

    return doc;
  }

  private saveDocument(doc: jsPDF, name?: string) {
    doc.save(name);
  }

  private getDocumentDimensions(doc: jsPDF) {
    //@ts-ignore
    const pageHeight = doc.getPageHeight(0) as number;
    //@ts-ignore
    const pageWidth = doc.getPageWidth(0) as number;

    return {
      pageHeight,
      pageWidth,
      marginBottom: MARGIN_BOTTOM,
      marginLeft: MARGIN_LEFT,
      marginRight: MARGIN_RIGHT,
      marginTop: MARGIN_TOP,
    };
  }

  private createYPointer(doc: jsPDF) {
    const { marginTop } = this.getDocumentDimensions(doc);
    return { val: marginTop };
  }

  private addNewPageIfNeeded(
    doc: jsPDF,
    height_mm: number,
    yPointer: YPointer
  ) {
    const { pageHeight, marginBottom, marginTop } =
      this.getDocumentDimensions(doc);

    if (yPointer.val + height_mm > pageHeight - marginBottom) {
      doc.addPage();
      yPointer.val = marginTop;
    }
  }

  private getItemImageRes(doc: jsPDF, pdfItemImage: PdfItemImage) {
    const {
      height_mm: optHeight_mm,
      width_mm: optWidth_mm,
      resolution,
    } = pdfItemImage;

    let height_mm: number, width_mm: number;

    if (!isUndefined(optHeight_mm) && !isUndefined(optWidth_mm)) {
      height_mm = optHeight_mm;
      width_mm = optWidth_mm;
    } else if (!isUndefined(resolution)) {
      if (optHeight_mm) {
        height_mm = optHeight_mm;
        width_mm = height_mm * resolution;
      } else {
        if (optWidth_mm) {
          width_mm = optWidth_mm;
        } else {
          const { pageWidth, marginLeft, marginRight } =
            this.getDocumentDimensions(doc);
          width_mm = pageWidth - (marginLeft + marginRight);
        }
        height_mm = width_mm / resolution;
      }
    } else {
      throw Error(
        `You need to set image width+height or resolution on pdf-item`
      );
      return { height_mm: 0, width_mm: 0 };
    }
    return { height_mm, width_mm };
  }

  private addImage(
    doc: jsPDF,
    yPointer: YPointer,
    data: PdfItemImage,
    i: number
  ) {
    const {
      dataUrl,
      height_mm: optHeight_mm,
      width_mm: optWidth_mm,
      resolution,
    } = data;

    const { height_mm, width_mm } = this.getItemImageRes(doc, data);

    const { marginLeft, marginTop, marginBottom, pageHeight } =
      this.getDocumentDimensions(doc);

    this.addNewPageIfNeeded(doc, height_mm, yPointer);

    doc.addImage({
      imageData: dataUrl,
      x: marginLeft,
      y: yPointer.val,
      width: width_mm,
      height: height_mm,
    });
    yPointer.val += height_mm + MARGIN_BETWEEN_ITEMS_MM;
  }

  private async addTable(doc: jsPDF, yPointer: YPointer, data: PdfItemTable) {
    return new Promise<void>((resolve) => {
      const { body, head } = data;
      const { marginTop, marginBottom, marginLeft, marginRight } =
        this.getDocumentDimensions(doc);
      
      const jspdfBody:Array<RowInput> = body.map(row=>row.map(cell=>isString(cell) ? cell : ({
        content:cell.content,
        colSpan:cell.colSpan,
        rowSpan:cell.rowSpan,
        styles:{
          fontStyle:cell.bold ? 'bold' : 'normal'
        }
      })))
      console.log(jspdfBody)

      autoTable(doc, {
        body:jspdfBody,
        head,
        margin: {
          top: marginTop,
          bottom: marginBottom,
          left: marginLeft,
          right: marginRight,
        },
        theme: "grid",
        startY: yPointer.val,
        headStyles: {
          fillColor: 124,
        },
        didDrawPage: (hookData) => {
          yPointer.val = hookData.cursor!.y;
          resolve();
        },
        styles: {
          font: "SegoeUi",
        },
      });

      yPointer.val = yPointer.val + MARGIN_BETWEEN_ITEMS_MM;
    });
  }

  private addText(doc: jsPDF, yPointer: YPointer, data: PdfItemText) {
    const textObjects = this.getTextObjects(data);
    textObjects.forEach(({text,type})=>{
      this.addTextObject(doc,yPointer,type,text)
    })
    yPointer.val += MARGIN_BETWEEN_ITEMS_MM;
  }

  private getTextHeight(doc: jsPDF, data: PdfItemText) {
    const textObjects = this.getTextObjects(data);
    let height = textObjects.reduce(
      (val, { text, type }) => val + this.getTextObjectHeight(doc, type, text),
      0
    );

    height += MARGIN_BETWEEN_ITEMS_MM;
    return height;
  }

  private addTextObject(
    doc: jsPDF,
    yPointer: YPointer,
    type: TextType,
    text: string
  ) {
    const {
      pageWidth,
      marginLeft,
      marginRight,
      marginTop,
      marginBottom,
      pageHeight,
    } = this.getDocumentDimensions(doc);

    let fontSize = this.getFontSize(type);
    doc.setFontSize(fontSize);
    const splitedParagraph: Array<string> = doc.splitTextToSize(
      text,
      pageWidth - (marginLeft + marginRight)
    );

    if (text) {
      for (let j = 0; j < splitedParagraph.length; j++) {
        const textHeight = doc.getTextDimensions(splitedParagraph[j]).h;

        this.addNewPageIfNeeded(doc, textHeight, yPointer);

        yPointer.val += textHeight * (1 + (LINE_HEIGHT - 1) / 2);

        doc.text(splitedParagraph[j], marginLeft, yPointer.val);
        yPointer.val += textHeight * ((LINE_HEIGHT - 1) / 2);
      }
    }
  }

  private getTextObjectHeight(doc: jsPDF, type: TextType, text: string) {
    let height = 0;
    let fontSize = this.getFontSize(type);
    doc.setFontSize(fontSize);

    const {
      pageWidth,
      marginLeft,
      marginRight,
      marginTop,
      marginBottom,
      pageHeight,
    } = this.getDocumentDimensions(doc);

    const splitedParagraph: Array<string> = doc.splitTextToSize(
      text,
      pageWidth - (marginLeft + marginRight)
    );

    for (let j = 0; j < splitedParagraph.length; j++) {
      const textHeight = doc.getTextDimensions(splitedParagraph[j]).h;
      height += textHeight * 1.1;
      height += textHeight * 0.1;
    }
    return height;
  }

  private getFontSize(type: "BODY" | "TITLE" | "SUBTITLE") {
    if (type === "BODY") {
      return BODY_FONT_SIZE;
    } else if (type === "TITLE") {
      return TITLE_FONT_SIZE;
    } else {
      return SUBTITLE_FONT_SIZE;
    }
  }

  private getTextObjects(pdfItemText: PdfItemText) {
    const { body, subtitle, title } = pdfItemText;

    const textObjects: Array<{ text: string; type: TextType }> = [];
    if (title) {
      textObjects.push({ text: title, type: "TITLE" });
    }
    if (subtitle) {
      textObjects.push({ text: subtitle, type: "SUBTITLE" });
    }
    if (body) {
      textObjects.push({ text: body, type: "BODY" });
    }

    return textObjects;
  }
}
