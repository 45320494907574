import { AlarmExceed } from "../../models/alarm-exceed.model";
import { SortMode } from "../../models/sort-mode.model";

export const getSortCompareFunction = (
  sortMode: SortMode
): ((alarmExceedA: AlarmExceed, alarmExceedB: AlarmExceed) => number) => {
  if (sortMode === "DATE_DESCENDING") {
    return (alarmExceedA, alarmExceedB) =>
      alarmExceedB.endTimestamp_s - alarmExceedA.endTimestamp_s;
  } else if (sortMode === "DATE_ASCENDING") {
    return (alarmExceedA, alarmExceedB) =>
      alarmExceedA.startTimestamp_s - alarmExceedB.startTimestamp_s;
  } else if (sortMode === "DEVICE_NAME") {
    return (alarmExceedA, alarmExceedB) =>
      alarmExceedA.deviceName.localeCompare(alarmExceedB.deviceName);
  } else if (sortMode === "DEVICE_NAME_REVERTED") {
    return (alarmExceedA, alarmExceedB) =>
      -alarmExceedA.deviceName.localeCompare(alarmExceedB.deviceName);
    //   } else if (sortMode === "VALUE_DESCENDING") {
  } else {
    return (alarmExceedA, alarmExceedB) =>
      alarmExceedA.dataType.localeCompare(alarmExceedB.dataType) ||
      alarmExceedB.maxValue - alarmExceedA.maxValue;
  }
};
