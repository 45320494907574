import { MeasureDatasetSetup } from "../models/measure-dataset-setup.model";

/* it does mutate passed values */

export const sumMeasuresDatasets = (
  measuresDatasetsSetups: Array<{
    [deviceName: string]: {
      [dataType: string]: Array<MeasureDatasetSetup>;
    };
  }>
): {
  [deviceName: string]: {
    [dataType: string]: Array<MeasureDatasetSetup>;
  };
} => measuresDatasetsSetups.reduce((prevSum, curr) => {
    Object.keys(curr).forEach((deviceName) => {
      const deviceMeasures = curr[deviceName];
      if (!(deviceName in prevSum)) {
        prevSum[deviceName] = deviceMeasures;
        return;
      }
      Object.keys(deviceMeasures).forEach((dataType) => {
        const dataTypeMeasures = deviceMeasures[dataType];

        if (!(dataType in prevSum[deviceName])) {
          prevSum[deviceName][dataType] = dataTypeMeasures;
          return;
        }
        prevSum[deviceName][dataType].push(...dataTypeMeasures);
      });
    });

    return prevSum;
  }, {});