import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { DeviceMqttConfigurationDto } from "shared";
import ErrorDisplayFieldError from "../../../../../utils/forms/ErrorDisplayFieldError";
import { IsString } from "class-validator";
import { Match } from "../../../../../utils/validation/match.decorator";
import { container } from "tsyringe";
import { DeviceRequestsService } from "../../../request-services/device-requests.service";
import { useModDataSelector } from "../../../mod-data.provider";


class DeviceMqttConfigurationDtoWConfirmPassword extends DeviceMqttConfigurationDto{

  @IsString()
  @Match('password')
  //@ts-ignore
  confirmPassword:string
}

const resolver = classValidatorResolver(DeviceMqttConfigurationDtoWConfirmPassword);



const EditDeviceMqttConfigurationModal: React.FC<{
  deviceId: string | null;
  onClose: () => void;
}> = ({ onClose, deviceId }) => {
  const t = useTranslate("mod");

  const devices = useModDataSelector(state=>state.system?.devices)

  const device = useMemo(
    () => devices&&devices.find((dev) => dev._id === deviceId),
    [deviceId, devices]
  );

  const {
    register,
    handleSubmit,
    formState: { errors,isValid },
    setValue,
    trigger,
    watch
  } = useForm<DeviceMqttConfigurationDtoWConfirmPassword>({ resolver, mode: "onBlur" });

  const submitRef = useRef<HTMLInputElement>(null);
  const onSave = useCallback(() => {
    if (!submitRef.current) {
      return;
    }
    submitRef.current.click();
  }, [submitRef]);

  useEffect(()=>{
    const sub = watch((val,{name})=>{
        if(name!=="password"||val.confirmPassword===""){
          return
        }
        trigger("confirmPassword")
    })
    return ()=>sub.unsubscribe()
  },[watch])

  useEffect(() => {
    if(!device){
      return
    }

    setValue("ssn", device?.ssn || "");
    //username
    trigger()
  }, [device]);

  return (
    <DefaultFormModal
      title={t("DEVICES_TOOLTIP_EDIT_MQTT_CONFIGURATION")}
      show={!!deviceId}
      onClose={onClose}
      onSave={onSave}
      saveDisabled={!isValid}
    >
      <>{t("EDIT_DEVICE_MQTT_CONF_MODAL_PARAGRAPH", { deviceName: device?.name || "" })}</>
      <form
        onSubmit={handleSubmit((data) => {
          const {password,ssn,username} = data;
          const dto:DeviceMqttConfigurationDto = {
            password,
            ssn,
            username
          } 
          if(!deviceId){
            return
          }
          container.resolve(DeviceRequestsService).editDevicesMqttConfiguration(deviceId,dto).then(()=>{
            onClose()
          })
        })}
      >
        <input ref={submitRef} hidden type="submit" />
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <label htmlFor="device_mqtt_ssn" className="col-form-label">
                {t("EDIT_DEVICE_MODAL_LABEL_SSN")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="device_mqtt_ssn"
                {...register("ssn")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.ssn} />
        </div>

        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <label htmlFor="device_mqtt_username" className="col-form-label">
                {t("EDIT_DEVICE_MODAL_LABEL_MQTT_USERNAME")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="device_mqtt_username"
                {...register("username")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.username} />
        </div>
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <label htmlFor="device_mqtt_password" className="col-form-label">
                {t("EDIT_DEVICE_MODAL_LABEL_MQTT_PASSWORD")}
              </label>
            </div>
            <div>
              <input
                type="password"
                className="form-control"
                id="device_mqtt_password"
                {...register("password")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.password} />
        </div>


        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <label htmlFor="device_mqtt_password_confirm" className="col-form-label">
                {t("EDIT_DEVICE_MODAL_LABEL_MQTT_PASSWORD_CONFIRM")}
              </label>
            </div>
            <div>
              <input
                type="password"
                className="form-control"
                id="device_mqtt_password_confirm"
                {...register("confirmPassword")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.confirmPassword} />
        </div>

      </form>
    </DefaultFormModal>
  );
};

export default EditDeviceMqttConfigurationModal;
