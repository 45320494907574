import { AlertService } from "../../../modules/alerts/alert.service";
import { getContainer } from "../../../modules/app-container";
import translations from "../../../translations/translations";
import { NetworkErrorHandler } from "../error-handler.interface";

let prevCallTime = -300

const ipError: NetworkErrorHandler = (error) => {
  if ((error?.response?.data as any)?.title !== "Incorrect IP") {
    return false;
  }

  const callTime = performance.now()

  if(callTime-prevCallTime>300){

    const alertService = getContainer().resolve<AlertService>("alert");
    alertService.pushMessage({
      theme: "danger",
      message: translations.PL.menu.INCORRECT_IP,
    });
    prevCallTime=callTime
  }

  return true;
};

export default ipError;
