import "./HomePageView.scss";
import AlarmTile from "./Tiles/AlarmTile";
import DevicesTile from "./Tiles/DevicesTile";
import ProfileTile from "./Tiles/ProfileTile";
import ReportsTile from "./Tiles/ReportsTile";
import StatsTile from "./Tiles/StatsTile/StatsTile";

const HomePageView: React.FC = () => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "stretch",
      }}
    >
      <div
        style={{
          maxWidth: 1180,
          margin: "50px auto",
          flexGrow: 1,
          flexDirection: "column",
          alignItems: "stretch",
          position: "relative",
        }}
      >
        <div
          className="flex-md-row flex-column home-page-tiles-container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "stretch",
            position: "absolute",
            width: "100%",
            height:"100%"
          }}
        >
          <div
            className="home-page-tiles-group-1"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            <div
              className="home-page-box home-page-tiles-stats"
            >
              <StatsTile/>
            </div>
            <div
              className="home-page-box-wide"
              style={{ flexGrow: 1 }}
            >
              <AlarmTile/>
            </div>
          </div>
          <div
            className="home-page-tiles-group-2"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
            }}
          >
            <div className="home-page-box home-page-tiles-devices-pdf">
              <DevicesTile />
            </div>
            <div className="home-page-box home-page-tiles-devices-pdf">
              <ReportsTile />
            </div>
            <div className="home-page-box-wide" style={{ flexGrow: 1 }}>
              <ProfileTile />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePageView;
