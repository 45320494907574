import { AlarmsState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";
import { AlarmExceed } from "../models/alarm-exceed.model";
import { calculateRequestedAlarms } from "./utils/calculateRequestedAlarms";

type Payload = {
  totalAlarmExceeds: Array<AlarmExceed>;
};

class SetTotalAlarmExceedsClass extends ReducerActionClass<
  AlarmsState,
  Payload
> {
  getActionName() {
    return "SET_TOTAL_ALARM_EXCEEDS";
  }

  handleDispatch(prevState: AlarmsState, payload: Payload): AlarmsState {
    const { totalAlarmExceeds } = payload;

    const midState = {
      ...prevState,
      totalAlarmExceeds,
    };
    
    const updatedData = calculateRequestedAlarms(midState,prevState);
    
    return {
      ...midState,
      ...updatedData,
    };
  }
}

export const SetTotalAlarmExceeds = new SetTotalAlarmExceedsClass();
