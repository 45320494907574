import { Chart } from "chart.js";
import { ReducerCollection } from "../../../utils/store/core";
import { DataType } from "../models/chart-description.model";
import { AlarmDatasetSetup } from "../models/alarm-dataset-setup-model";
import {
  DataTypeRequest,
  TotalRequestedDataTypes,
} from "../models/data-type-request.model";
import { DeviceRequest } from "../models/device-request.model";
import {
  MeasureDatasetSetup,
  MeasuresGrouping,
} from "../models/measure-dataset-setup.model";
import { ScaleConfig } from "../models/scale-config.model";
import { TimeScaleState } from "../models/time-scale-state";
import { PutChart } from "./putChart";
import { PutMeasuresGrouping } from "./putMeasuresGrouping";
import { PutRequestedDevices } from "./putRequestedDevice";
import { PutScaleConfig } from "./putScaleConfig";
import { PutTimeScaleState } from "./putTimeScaleState";
import { SetAnimationsEnabled } from "./setAnimationsEnabled";
import { SetRequestedAlarm } from "./setRequestedAlarms";
import { SetRequestedDataType } from "./setRequestedDataType";
import { ChartData } from "../models/chart-data";
import { ChartRequests } from "../models/chart-requests.model";
import { PutChartData } from "./putChartData";

export type ChartState = ChartData &
  ChartRequests & {
    totalRequestedDataTypes: TotalRequestedDataTypes;
    scalesConfig: { [dataType: string]: ScaleConfig };
    chart: Chart | null;
    animationsEnabled?: boolean;
  };

export class ChartReducer extends ReducerCollection<ChartState> {
  static initialState: ChartState = {
    alarmsDatasetsSetup: {},
    measuresDatasetsSetup: {},
    dataTypesIncluded: [],
    requestedAlarms: [],
    requestedDataTypes: [],
    totalRequestedDataTypes: {},
    requestedDevices: {},
    scalesConfig: {},
    measuresGrouping: "NONE",
    chart: null,
    timeScaleState: {
      /* default value is 1.08.2022; it really shouldnt be used anytime here, its here just to pass type checks */
      startTimestamp_s: 1659304800,
      endTimestamp_s: 1659391200,
    },
  };

  initState() {
    return ChartReducer.initialState;
  }

  getActionClassObjects() {
    return [
      PutRequestedDevices,
      SetRequestedDataType,
      SetRequestedAlarm,
      PutScaleConfig,
      PutMeasuresGrouping,
      PutChart,
      PutTimeScaleState,
      SetAnimationsEnabled,
      PutChartData
    ];
  }
}
