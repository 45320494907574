import { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { DeviceDto } from "shared";
import ErrorDisplayFieldError from "../../../../../utils/forms/ErrorDisplayFieldError";
import { useModDataSelector } from "../../../mod-data.provider";
import { container } from "tsyringe";
import { DeviceRequestsService } from "../../../request-services/device-requests.service";
import { useModPanelData } from "../../hooks/useModPanelData";

const resolver = classValidatorResolver(DeviceDto);

const EditDeviceModal: React.FC<{
  deviceId: string | null;
  onClose: () => void;
  organisationId:string

}> = ({ onClose, deviceId,organisationId }) => {
  const t = useTranslate("mod");

  const {devices} = useModPanelData(organisationId);

  const device = useMemo(
    () => devices&&devices.find((dev) => dev.device._id === deviceId),
    [deviceId, devices]
  );
  const {
    register,
    handleSubmit,
    formState: { errors,isValid },
    setValue,
    trigger
  } = useForm<DeviceDto>({ resolver, mode: "onBlur" });

  const submitRef = useRef<HTMLInputElement>(null);
  const onSave = useCallback(() => {
    if (!submitRef.current) {
      return;
    }
    submitRef.current.click();
  }, [submitRef]);

  useEffect(() => {
    if(!device){
      return
    }

    setValue("name", device?.device.name || "");
    trigger()
  }, [device]);

  return (
    <DefaultFormModal
      title={t("DEVICES_TOOLTIP_EDIT_NAME")}
      show={!!deviceId}
      onClose={onClose}
      onSave={onSave}
      saveDisabled={!isValid}
    >
      <>{t("EDIT_DEVICE_MODAL_PARAGRAPH", { deviceName: device?.device.name || "" })}</>
      <form
        onSubmit={handleSubmit((data) => {
          container.resolve(DeviceRequestsService).editDevice(data,deviceId!,organisationId).then(()=>{
            onClose()
          });
        })}
      >
        <input ref={submitRef} hidden type="submit" />
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <label htmlFor="device_name" className="col-form-label">
                {t("EDIT_DEVICE_MODAL_LABEL_NAME")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="device_name"
                {...register("name")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.name} />
        </div>
      </form>
    </DefaultFormModal>
  );
};

export default EditDeviceModal;
