import { DateTime } from "luxon";
import { TimeRangeType, TimeScaleRange } from "../../models/time-scale-state";

const DAY_LENGTH_S = 60 * 60 * 24;
const WEEK_LENGTH_S = DAY_LENGTH_S * 7;

export const getTimeRange = (
  timeRangeType: TimeRangeType,
  timestamp_s: number,
  timestamp_ref: "START" | "END",
  currentTimeRange?: TimeScaleRange
): TimeScaleRange => {
  let timeRangeLength_s: number;

  if (timeRangeType === "CUSTOM") {
    if (!currentTimeRange) {
      throw Error("Need current time range to get custom time range");
    }
    const timeRange = { ...currentTimeRange };
    const startOfPassedDay_s = getStartOfTheDay(timestamp_s);

    if (timestamp_ref === "START") {
      timeRange.startTimestamp_s = startOfPassedDay_s;
      if (timeRange.endTimestamp_s <= timeRange.startTimestamp_s) {
        timeRange.endTimestamp_s = timeRange.startTimestamp_s + DAY_LENGTH_S;
      }
    } else {
      timeRange.endTimestamp_s = startOfPassedDay_s + DAY_LENGTH_S;
      if (timeRange.endTimestamp_s <= timeRange.startTimestamp_s) {
        timeRange.startTimestamp_s = timeRange.endTimestamp_s - DAY_LENGTH_S;
      }
    }
    return timeRange;
  }

  if (timeRangeType === "DAILY") {
    timeRangeLength_s = DAY_LENGTH_S;
  } else if (timeRangeType === "WEEKLY") {
    timeRangeLength_s = WEEK_LENGTH_S;
  } else {
    throw Error("Not handled range type");
  }

  const startOfPassedDay_s = getStartOfTheDay(timestamp_s);

  if (timestamp_ref === "START") {
    return {
      startTimestamp_s: startOfPassedDay_s,
      endTimestamp_s: startOfPassedDay_s + timeRangeLength_s,
    };
  } else {
    let endTimestamp_s = startOfPassedDay_s + DAY_LENGTH_S;
    return {
      startTimestamp_s: endTimestamp_s - timeRangeLength_s,
      endTimestamp_s,
    };
  }
};

export const getTimeRangeType = (range: TimeScaleRange): TimeRangeType => {
  const timeDiff = range.endTimestamp_s - range.startTimestamp_s;
  if (timeDiff === DAY_LENGTH_S) {
    return "DAILY";
  } else if (timeDiff === WEEK_LENGTH_S) {
    return "WEEKLY";
  }

  return "CUSTOM";
};

const getStartOfTheDay = (timestamp_s: number): number => {
  const passedDateTime = DateTime.fromSeconds(timestamp_s);
  const startOfPassedDay_s =
    timestamp_s -
    (passedDateTime.second +
      (passedDateTime.minute + passedDateTime.hour * 60) * 60);
  return startOfPassedDay_s;
};

export const moveRange = (
  timeRange: TimeScaleRange,
  pageNumber: number
): TimeScaleRange => {
  const timeRangeLength =
    timeRange.endTimestamp_s - timeRange.startTimestamp_s;
  const moveVector = timeRangeLength * pageNumber;
  return {
    startTimestamp_s: timeRange.startTimestamp_s + moveVector,
    endTimestamp_s: timeRange.endTimestamp_s + moveVector,
  };
};
