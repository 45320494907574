import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { isNull } from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslate } from "react-translate";
import { EmailConfigDto, EmailItemDto } from "shared";
import { container } from "tsyringe";
import ErrorDisplayFieldError from "../../../../../utils/forms/ErrorDisplayFieldError";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { OrganisationRequestsService } from "../../../request-services/organisation-requests.service";
import { SystemRequestsService } from "../../../request-services/system-requests.service";
import { useModPanelData } from "../../hooks/useModPanelData";

const resolver = classValidatorResolver(EmailItemDto);


const CreateEmailModal: React.FC<{
    show:boolean;
    onClose: () => void;
    organisationId:string|null
  }> = ({ onClose,show,organisationId }) => {
    const t = useTranslate("user");
    const {
      register,
      handleSubmit,
      formState: { errors,isValid },
      setValue
    } = useForm<EmailItemDto>({ resolver, mode: "onBlur" });
    const { emailData } = useModPanelData(organisationId);
  
    const submitRef = useRef<HTMLInputElement>(null);
    const onSave = useCallback(() => {
      if (!submitRef.current) {
        return;
      }
      submitRef.current.click();
    }, [submitRef]);
  
    useEffect(()=>{
        setValue("comment","")
        setValue("email","")
    },[show])
  
    return (
      <DefaultFormModal
        title={t("EMAIL_CONFIG_MODAL_HEADER_CREATE")}
        show={show}
        onClose={onClose}
        onSave={onSave}
        saveDisabled={!isValid}
        submitType="add"
      >
        <>{t("EMAIL_CONFIG_MODAL_PARAGRAPH_CREATE")}</>
        <form
          onSubmit={handleSubmit((data) => {
            console.log(data)
            const {comment,email} = data;
            const dto: EmailConfigDto = { items: [...emailData.items] };
            
        dto.items.push({ email, comment });
        
        
            if (isNull(organisationId)) {
              container.resolve(SystemRequestsService).sendUpdateEmailConfig(dto).then(()=>{

              onClose()
              });
            } else {
              container.resolve(OrganisationRequestsService).sendUpdateEmailConfig(dto,organisationId).then(()=>{
                onClose()

              });
              
          }
          })}
        >
          <input ref={submitRef} hidden type="submit" />
          <div className="mb-3 mt-4">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <div>
                <label htmlFor="create_email_email" className="col-form-label">
                  {t("EMAIL_CONFIG_TABLE_EMAIL")}
                </label>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  id="create_email_email"
                  {...register("email")}
                />
              </div>
            </div>
            <ErrorDisplayFieldError error={errors.email} />
          </div>
          <div className="mb-3 mt-4">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <div>
                <label htmlFor="create_email_comment" className="col-form-label">
                  {t("EMAIL_CONFIG_TABLE_COMMENT")}
                </label>
              </div>
              <div>
                <input
                  type="text"
                  className="form-control"
                  id="create_email_comment"
                  {...register("comment")}
                />
              </div>
            </div>
            <ErrorDisplayFieldError error={errors.comment} />
          </div>
  
        </form>
      </DefaultFormModal>
    );
  };
  
  export default CreateEmailModal;
  