import { isUndefined } from "lodash";
import { useCallback, useMemo } from "react";
import { useTranslate } from "react-translate";
import { useDataProviderSelector } from "../../data-provider/data-provider.provider";
import {
  getManyUnusedColors,
  getUnusedColorForDevice,
} from "../chart-generating/utils/getColor";
import {
  useMainChartDispatch,
  useMainChartSelector,
} from "../hooks/chart.context";
import { PutRequestedDevices } from "../store/putRequestedDevice";
import { useInitCollapseToggle, useToggleCollapse } from "./utils/collapse-fix";
import { getContainer } from "../../app-container";
import { DevicesService } from "../../devices/devices.service";
import IndeterminateInput from "../../../utils/components/IndeterminateInput";
import { ReactComponent as NavigateIcon } from "bootstrap-icons/icons/arrow-right-square-fill.svg";
import { generatePath, useHistory } from "react-router";
import {
  DEVICE_ITEM_PAGE_PATH,
  getDeviceUrl,
} from "../../../skeleton/pages/devices/device-item-page";

const DevicesControlls: React.FC = () => {
  const t = useTranslate("chart");
  const tDevices = useTranslate("devices");
  const measuresDatasetsSetup = useMainChartSelector(
    (state) => state.measuresDatasetsSetup
  );
  const requestedDataTypes = useMainChartSelector(
    (state) => state.requestedDataTypes
  );

  const devicesObj = useDataProviderSelector((state) => state.devices);
  const devices = useMemo(
    () => devicesObj.map((dev) => dev.device),
    [devicesObj]
  );
  const deviceGroups = useDataProviderSelector((state) => state.deviceGroups);

  const displayGroups = useMemo(
    () =>
      getContainer()
        .resolve<DevicesService>("devices")
        .getDeviceGroups(deviceGroups, devicesObj),
    [deviceGroups, devicesObj]
  );

  const deviceIds = useMemo(() => devices.map((dev) => dev._id), [devices]);
  const groupIds = useMemo(
    () => displayGroups.map((group) => (group === false ? "false" : group._id)),
    [displayGroups]
  );

  const requestedDevices = useMainChartSelector(
    (state) => state.requestedDevices
  );

  const dispatch = useMainChartDispatch();

  const SECONDARY_COLOR = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-secondary-rgb");

  const isAllDeviceRequestedDataTypesDefault = useMemo(
    () =>
      !Object.values(requestedDevices).find(
        (dev) => !isUndefined(dev.requestedDataTypes)
      ),
    [requestedDevices]
  );

  const setAllDevicesRequestedDataDefault = useCallback(() => {
    Object.keys(requestedDevices).forEach((deviceName) => {
      const reqDevice = requestedDevices[deviceName];

      if (isUndefined(reqDevice.requestedDataTypes)) {
        return;
      }

      dispatch(
        PutRequestedDevices.callAction({
          deviceName,
          value: {
            ...reqDevice,
            requestedDataTypes: undefined,
          },
        })
      );
    });
  }, [requestedDevices, dispatch]);

  useInitCollapseToggle(".deviceControlls", { toggle: false });
  const toggleCollapse = useToggleCollapse();

  const history = useHistory();

  return (
    <>
      <h3>{t("DEVICES_DATA")}</h3>

      {displayGroups.map((dgroup, i) => {
        const groupId = dgroup === false ? "false" : dgroup._id;
        const groupName =
          dgroup === false ? tDevices("REST_OPTION") : dgroup.name;

        const devicesInGroup = getContainer()
          .resolve<DevicesService>("devices")
          .getGroupsDevices(
            deviceGroups,
            devicesObj,
            groupId === "false" ? false : groupId
          )
          .map((devObj) => devObj.device);

        const devicesInGroupNumber = devicesInGroup.filter(
          (dev) => requestedDevices[dev._id]?.enabled
        ).length;

        const allDevicesInGroupSelected =
          devicesInGroupNumber === 0
            ? "FALSE"
            : devicesInGroupNumber === devicesInGroup.length
            ? "TRUE"
            : "INDETERMINATE";

        return (
          <div key={i} className="ps-2 pb-1">
            <div
              className="fs-5"
              style={{
                display: "flex",
                flexWrap: "nowrap",
                justifyContent: "space-between",
              }}
            >
              <div
                className="form-check-inline "
                style={{ display: "flex", alignItems: "center" }}
              >
                <div>
                  <label
                    style={{ cursor: "pointer" }}
                    className="btn-toggle form-check-label"
                    onClick={() => toggleCollapse(`#deviceControlls_${i}`)}
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        //@ts-ignore
                        e.target.click();
                      }
                    }}
                  >
                    <a className="dropdown-toggle text-white pe-2" />
                    {groupName}
                  </label>
                </div>

                {!devicesInGroupNumber ? null : (
                  <div
                    style={{
                      fontSize: 13,
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="ms-2 badge rounded-pill bg-primary"
                  >
                    <div>{devicesInGroupNumber}</div>
                    <div>
                      <button
                        onClick={() => {
                          devicesInGroup.forEach((dev) => {
                            dispatch(
                              PutRequestedDevices.callAction({
                                deviceName: dev._id,
                                value: null,
                              })
                            );
                          });
                        }}
                        style={{ fontSize: 9 }}
                        type="button"
                        className="ms-1 btn-close btn-close-white"
                        aria-label="Close"
                      ></button>
                    </div>
                  </div>
                )}
              </div>
              {/* <div className="form-check-inline">
                <input
                  className="form-check-input"
                  style={{
                    backgroundColor: !enabled ? undefined : colorString,
                    borderColor: !enabled ? undefined : colorString,
                  }}
                  type="checkbox"
                  value=""
                  checked={enabled}
                  onClick={() =>
                    dispatch(
                      PutRequestedDevices.callAction({
                        deviceName,
                        value: {
                          requestedDataTypes:
                            requestedDevice?.requestedDataTypes,
                          enabled: !requestedDevice.enabled,
                          color: color
                        },
                      })
                    )
                  }
                  id="flexCheckDefault"
                />
              </div> */}
            </div>

            <div
              className="collapse deviceControlls "
              id={`deviceControlls_${i}`}
            >
              <div className="ps-2" style={{ width: "100%" }}>
                <div className="form-check">
                  <IndeterminateInput
                    indeterminate={
                      allDevicesInGroupSelected === "INDETERMINATE"
                    }
                    className="form-check-input"
                    id={`device-datatype-${i}-selectAll`}
                    type="checkbox"
                    checked={allDevicesInGroupSelected === "TRUE"}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        //@ts-ignore
                        e.target.click();
                      }
                    }}
                    onClick={() => {
                      if (allDevicesInGroupSelected === "TRUE") {
                        devicesInGroup.forEach((dev) => {
                          dispatch(
                            PutRequestedDevices.callAction({
                              deviceName: dev._id,
                              value: null,
                            })
                          );
                        });
                      } else {
                        const notSelectedDevicesInGroup = devicesInGroup.filter(
                          (dev) => !requestedDevices[dev._id]?.enabled
                        );

                        const colors = getManyUnusedColors(
                          requestedDevices,
                          notSelectedDevicesInGroup.length
                        );

                        notSelectedDevicesInGroup.forEach((dev, i) => {
                          dispatch(
                            PutRequestedDevices.callAction({
                              deviceName: dev._id,
                              value: { enabled: true, color: colors[i] },
                            })
                          );
                        });
                      }
                    }}
                  />
                  <label
                    className="form-check-label ps-1"
                    htmlFor={`device-datatype-${i}-selectAll`}
                    style={{ cursor: "pointer" }}
                  >
                    <b>{t("SELECT_ALL_DEVICES")}</b>
                  </label>
                </div>
                {devicesInGroup.map((device, j) => {
                  const { name: deviceNamed, _id: deviceId } = device;
                  const requestedDevice = requestedDevices[deviceId] || {
                    enabled: false,
                  };
                  const deviceRequestedDataTypesExist =
                    !!requestedDevice?.requestedDataTypes;
                  const deviceRequestedDataTypes =
                    requestedDevice?.requestedDataTypes || requestedDataTypes;
                  const color =
                    requestedDevice.color ||
                    getUnusedColorForDevice(requestedDevices, device);
                  const colorString = `rgb(${color})`;
                  const enabled = requestedDevice?.enabled;

                  return (
                    <div className="d-flex">
                      <div className="form-check" key={j}>
                        <input
                          className="form-check-input"
                          style={{
                            backgroundColor: !enabled ? undefined : colorString,
                            borderColor: !enabled ? undefined : colorString,
                          }}
                          id={`device-datatype-${i}-${j}`}
                          type="checkbox"
                          checked={enabled}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              //@ts-ignore
                              e.target.click();
                            }
                          }}
                          onClick={() => {
                            if (enabled) {
                              dispatch(
                                PutRequestedDevices.callAction({
                                  deviceName: deviceId.toString(),
                                  value: null,
                                })
                              );
                            } else {
                              dispatch(
                                PutRequestedDevices.callAction({
                                  deviceName: deviceId.toString(),
                                  value: {
                                    enabled: true,
                                    color: color,
                                  },
                                })
                              );
                            }
                          }}
                        />
                        <label
                          className="form-check-label ps-1"
                          htmlFor={`device-datatype-${i}-${j}`}
                          style={{ cursor: "pointer" }}
                        >
                          {deviceNamed}
                        </label>
                      </div>
                      <div style={{ position: "relative" }}>
                        <span
                          tabIndex={0}
                          onKeyDown={(e) => {
                            if (e.nativeEvent.key === "Enter") {
                              e.stopPropagation();
                              //@ts-ignore
                              e.target.click();
                            }
                          }}
                          style={{ cursor: "pointer" }}
                          className="dropdown-toggle ms-1"
                          data-bs-toggle="dropdown"
                        />
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuLink"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href={getDeviceUrl(device.name)}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                history.push(getDeviceUrl(device.name))
                              }}
                              onKeyDown={(e) => {
                                if (e.nativeEvent.key === "Enter") {
                                  e.stopPropagation();
                                  //@ts-ignore
                                  e.target.click();
                                }
                              }}
                            >
                              {t("DEVICES_CONTROLLS_MOVE_TO_DEVICE_PAGE")}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
      <div className="ps-2 pb-1">
        <div
          style={{
            opacity: !isAllDeviceRequestedDataTypesDefault ? 1 : 0,
            pointerEvents: !isAllDeviceRequestedDataTypesDefault
              ? undefined
              : "none",
            cursor: !isAllDeviceRequestedDataTypesDefault
              ? "pointer"
              : undefined,
          }}
          onClick={setAllDevicesRequestedDataDefault}
        >
          {t("SET_DEFAULT_ALL")}
        </div>
      </div>
    </>
  );
};

export default DevicesControlls;
