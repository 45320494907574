import { DataExporterState } from ".";
import { ReducerActionClass } from "../../../../../utils/store/core";
import { getContainer } from "../../../../app-container";
import { ChartsService } from "../../../charts.service";

type Payload = {
  devicesToAdd?: Array<string>;
  devicesToRemove?: Array<string>;
};

class UpdateExportedDevicesClass extends ReducerActionClass<
  DataExporterState,
  Payload
> {
  getActionName(): string {
    return "UPDATE_DEVICES";
  }

  handleDispatch(
    prevState: DataExporterState,
    payload: Payload
  ): DataExporterState {
    const { devices: prevDevices } = prevState;
    const { devicesToAdd=[], devicesToRemove=[] } = payload;


    const chartDataExporterService = getContainer()
    .resolve<ChartsService>("charts")
    .getChartDataExporterService();

    const devices = prevDevices
      .filter((dev) => !devicesToRemove.includes(dev))
      
    devices.push(...devicesToAdd);



    const downloadable =
      chartDataExporterService.checkIfRequestsAreDownloadable(
        {...prevState,devices}
      );

    return{
        ...prevState,
        devices,
        downloadable
    }
  }
}

export const UpdateExportedDevices = new UpdateExportedDevicesClass()