import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { AllUserOrganisationRoles, ResetPasswordDto,UserOrganisationRole } from "shared";
import ErrorDisplayFieldError from "../../../../../utils/forms/ErrorDisplayFieldError";
import { exampleUsers } from "../../tabs/Mod/UsersTab";
import {generate as generatePassword} from "generate-password-browser";
import { container } from "tsyringe";
import { UserRequestsService } from "../../../request-services/user-requests.service";

const ChangeRoleModal: React.FC<{
  userId: string | null;
  onClose: () => void;
  organisationId:string
}> = ({ onClose, userId,organisationId }) => {
  const t = useTranslate("mod");
  const tUser = useTranslate("user")
  const user = useMemo(()=>exampleUsers.find(user=>user._id===userId),[userId])

  const [role,setRole] = useState<UserOrganisationRole>("USER")

  const onSave = useCallback(() => {
      container.resolve(UserRequestsService).changeRoles({organisationRole:role},userId!,organisationId).then(()=>{
        onClose()
      })
  }, [role,userId,organisationId]);

  useEffect(()=>{
    setRole(user?.role||"USER")
  },[user])

  return (
    <DefaultFormModal
      title={t("USERS_TOOLTIP_EDIT_ROLE")}
      show={!!userId}
      onClose={onClose}
      onSave={onSave}
    >
      <>{t("CHANGE_ROLE_MODAL_PARAGRAPH", { userName: user?.user || "" })}</>
      
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <label htmlFor="user_newPassword" className="col-form-label">
                {t("CHANGE_ROLE_MODAL_LABEL_ROLE")}
              </label>
            </div>
            <div>
              <select
                className="form-select"
                id="user_newPassword"
                value={role}
                onChange={(e)=>{setRole(e.target.value as any)}}
              >
                {AllUserOrganisationRoles.filter(role=>role!=="OWNER").map(role=>(
                  <option key={role} value={role}>{tUser(`USER_ROLE_${role}`)}</option>
                ))}
                </select>
            </div>
          </div>
          </div>
    </DefaultFormModal>
  );
};

export default ChangeRoleModal;
