  import { container, DependencyContainer, Lifecycle } from "tsyringe";
import { configAxiosAfterServices, configAxiosBeforeServices } from "../utils/network/axios.config";
import { AlarmsService } from "./alarms/alarms.service";
import { AlertService } from "./alerts/alert.service";
import { AuthService } from "./auth/auth.service";
import { ChartsService } from "./charts/charts.service";
import { DataProviderService } from "./data-provider/data-provider.service";
import { DevicesService } from "./devices/devices.service";
import { ModDataService } from "./mod/mod-data.service";
import { PdfGeneratorService } from "./pdf-generator/pdf-generator.service";
import { UserService } from "./user/user.service";

const registerServices = (container: DependencyContainer) => {
  container.register("depedency-container", { useValue: container });

  container.register(
    "data-provider",
    { useClass: DataProviderService },
    { lifecycle: Lifecycle.Singleton }
  );

  container.register(
    "charts",
    { useClass: ChartsService },
    {
      lifecycle: Lifecycle.Singleton,
    }
  );

  container.register(
    "alarms",
    { useClass: AlarmsService },
    {
      lifecycle: Lifecycle.Singleton,
    }
  );

  container.register(
    "pdf-generator",
    { useClass: PdfGeneratorService },
    {
      lifecycle: Lifecycle.Singleton,
    }
  );

  container.register(
    "devices",
    { useClass: DevicesService },
    {
      lifecycle: Lifecycle.Singleton,
    }
  );

  container.register(
    "auth",
    { useClass: AuthService },
    {
      lifecycle: Lifecycle.Singleton,
    }
  );


  container.register(
    "alert",
    { useClass: AlertService },
    {
      lifecycle: Lifecycle.Singleton,
    }
  );

  container.register(
    "mod-data",
    { useClass: ModDataService },
    {
      lifecycle: Lifecycle.Singleton,
    }
  );

  container.register(
    "user",
    { useClass: UserService },
    {
      lifecycle: Lifecycle.Singleton,
    }
  );
};

configAxiosBeforeServices()
const appContainer = container.createChildContainer();
registerServices(appContainer);
configAxiosAfterServices(appContainer)
export const getContainer = () => appContainer;
