import { PdfGeneratorState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";
import { DataType } from "../../charts/models/chart-description.model";
import { PdfRequestDataType } from "../models/pdf-requests.model";
import { checkIfStateIsDownloadable } from "./utils/checkIfStateIsDownloadable";

type Payload = {
  dataType: PdfRequestDataType;
  requested: boolean;
};

class SetRequestedDataTypeClass extends ReducerActionClass<
  PdfGeneratorState,
  Payload
> {
  getActionName() {
    return "SET_REQUESTED_DATA_TYPES";
  }
  handleDispatch(
    prevState: PdfGeneratorState,
    payload: Payload
  ): PdfGeneratorState {
    const { requestedDataTypes: prevRequestedDataTypes } = prevState;
    const { dataType, requested } = payload;

    let curr: PdfGeneratorState;

    if (requested) {
      if (prevRequestedDataTypes.includes(dataType)) {
        return prevState;
      }
      const requestedDataTypes: Array<PdfRequestDataType> = [...prevRequestedDataTypes];
      requestedDataTypes.push(dataType);
      curr = {
        ...prevState,
        requestedDataTypes,
      };
    } else {
      if (!prevRequestedDataTypes.includes(dataType)) {
        return prevState;
      }
      const requestedDataTypes: Array<PdfRequestDataType> = [
        ...prevRequestedDataTypes,
      ].filter((aName) => aName !== dataType);
      curr = {
        ...prevState,
        requestedDataTypes,
      };
    }

    const downloadable = checkIfStateIsDownloadable(curr);

    return {
      ...curr,
      downloadable,
    };
  }
}

export const SetRequestedDataType = new SetRequestedDataTypeClass();
