import React from "react";
import { BrowserRouter } from "react-router-dom";
import { getConstants } from "../config/constants";

const RouterProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const { children } = props;

  return (
    <BrowserRouter
      basename={
        getConstants().SUBDIR ? `/${getConstants().SUBDIR_NAME}/` : undefined
      }
    >
      {children}
    </BrowserRouter>
  );
};

export default RouterProvider;
