import { useModDataDispatch, useModDataSelector } from "../mod-data.provider";
import { PutSelectedOrganisaion } from "../store/putSelectedOrganisation";

const SelectOrganisation: React.FC = () => {
  const organisations = useModDataSelector(
    (state) => state.system?.organisations
  );

  const selectedOrganisation = useModDataSelector(state=>state.selectedOrganisation)
  const dispatch = useModDataDispatch()

  return (
    <div className="ms-1">
      <div className="mb-3 mt-4 row" style={{ maxWidth: 700 }}>
        <label
          htmlFor="select-organisation"
          className="col-sm-5 col-form-label"
        >
          <h5>Wybierz organizację</h5>
        </label>
        <div className="col-sm-7">
          <select
            className="form-control-lg form-select"
            id="select-organisation"
            value={selectedOrganisation}
            onChange={(e)=>{
                dispatch(PutSelectedOrganisaion.callAction({selectedOrganisation:e.target.value}))
            }}
          >
            {(organisations || []).map((org) => (
              <option value={org.organisation._id}>
                {org.organisation.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default SelectOrganisation;
