import { ReactComponent as DevicesIcon } from "bootstrap-icons/icons/robot.svg";
import Decimal from "decimal.js";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo } from "react";
import { generatePath, useHistory } from "react-router";
import { useTranslate } from "react-translate";
import { DEVICE_ITEM_PAGE_PATH } from "../../../../skeleton/pages/devices/device-item-page";
import {
  DataType,
  MeasurePossibleValue,
  MeasureValue,
} from "../../../charts/models/chart-description.model";
import { getDisplayDefaultDecimalPoints } from "../../../charts/chart-generating/utils/getDisplayDefaultDecimalPoints";
import { sortMeasureDataTypes } from "../../utils/sortMeasureDataTypes";
import { getContainer } from "../../../app-container";
import { ChartsService } from "../../../charts/charts.service";
import { DevicesService } from "../../devices.service";

import { ReactComponent as QuestionmarkIcon } from "bootstrap-icons/icons/question-circle-fill.svg";
import { ReactComponent as DisconnectedIcon } from "bootstrap-icons/icons/exclamation-triangle.svg";
import { Tooltip } from "bootstrap";
import { AlarmsService } from "../../../alarms/alarms.service";
import AlarmExceedsStatisticDisplay from "../components/AlarmExceedsStatisticDisplay";
import { Alarm, AlarmExceedStatistic, Device, Measure } from "shared";
import { isNull, isUndefined } from "lodash";

const displayedDataTypes: Array<DataType> = ["CO2_LEVEL", "CO_LEVEL"];
const displayedDataTypesBottom: Array<DataType> = ["POWER_SUPPLY"];

const aesCriticalPercentage = 0.3;
const aesWarningPercentage = 0.1;

const DeviceCard: React.FC<{
  device: Device;
  deviceColor: string;
  lastMeasure?: Measure;
}> = (props) => {
  const tChart = useTranslate("chart");
  const t = useTranslate("devices");
  const { deviceColor, device, lastMeasure } = props;
  const deviceName = useMemo(() => device.name, [device]);
  const connected = !!device.connected;

  const devicesService = getContainer().resolve<DevicesService>("devices");
  const alarmsService = getContainer().resolve<AlarmsService>("alarms");
  const alarmExceedDisplayed = useMemo<
    Array<{
      aes: AlarmExceedStatistic | undefined;
      dataType: DataType;
      alarm: Alarm;
    }>
  >(
    () =>
      displayedDataTypes.map((dataType) => ({
        dataType,
        alarm: alarmsService.getAlarmItem(dataType, "WARNING", device)!,
        aes: devicesService.findAlarmExceedStatistic(
          device.statistics.alarmExceedsStatistics,
          "WEEKLY",
          dataType,
          "WARNING"
        ),
      })),
    [device]
  );

  const disconnectedColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-warning-rgb");

  const colorString = useMemo(()=>`rgb(${connected ? deviceColor : disconnectedColor})`,[connected,deviceColor])

  const { dtime: date_timestamp_s, v } = lastMeasure || {};

  const chartsService = getContainer().resolve<ChartsService>("charts");

  const values: Array<MeasurePossibleValue> = useMemo(
    () =>
      chartsService
        .getChartDataProviderService()
        .getMeasureValueArray(lastMeasure)
        .filter((val) => displayedDataTypes.includes(val.dataType)),
    [lastMeasure]
  );

  const sortedValues = sortMeasureDataTypes(values);

  const valuesBottom: Array<MeasurePossibleValue> = useMemo(
    () =>
      chartsService
        .getChartDataProviderService()
        .getMeasureValueArray(lastMeasure)
        .filter((val) => displayedDataTypesBottom.includes(val.dataType)),
    [lastMeasure]
  );

  const sortedValuesBottom = sortMeasureDataTypes(valuesBottom);

  const history = useHistory();

  const devicePage = useMemo(
    () => generatePath(DEVICE_ITEM_PAGE_PATH, { deviceName }),
    [deviceName]
  );

  const navigateToDevicePage = useCallback(() => {
    history.push(devicePage);
  }, [history, devicePage]);

  useEffect(() => {
    const tooltipsEl = Array.from(document.querySelectorAll(".aes-tooltip")!);
    const tooltips = tooltipsEl.map((tooltipEl) => new Tooltip(tooltipEl));
    return () => {
      tooltips.forEach((tooltip) => tooltip.dispose());
    };
  }, []);

  return (
    <div
      className="card h-100"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.nativeEvent.key === "Enter") {
          navigateToDevicePage();
        }
      }}
    >
      {/* <img src="..." className="card-img-top" alt="..."/> */}
      <div className="card-header">
        <a
          style={{ color: "inherit", textDecoration: "inherit" }}
          href={devicePage}
          tabIndex={-1}
          onClick={(e) => {
            e.preventDefault();
            navigateToDevicePage();
          }}
        >
          <h4 className="card-title">{deviceName}</h4>
        </a>
        <p className="card-text " style={{ minHeight: 24 }}>
          {getContainer()
            .resolve<DevicesService>("devices")
            .getDevicesLatestLocation(device.history)}
        </p>
      </div>

      <div style={{ position: "relative" }}>
        <div
          style={{
            width: "100%",
            height: 125,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DevicesIcon
            style={{ marginTop: 10 }}
            width="64"
            height="64"
            fill={colorString}
          />
        </div>
        <div
          style={{
            display: !connected ? "flex" : "none",
            position: "absolute",
            bottom: 2,
            justifyContent: "center",
            color:colorString,
            width: "100%",
          }}
        >
          <div style={{display:"flex",alignItems:"center"}}>
            <DisconnectedIcon/>
            <div className="ms-1">{t("DISCONNECTED")}</div>
          </div>
        </div>
      </div>
      <ul
        className="list-group list-group-flush px-2 "
        style={{ fontSize: "0.9rem" }}
      >
        <li className="list-group-item px-2">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>{t("CURRENT_MEASURE")}</div>
            <div>
              {isUndefined(date_timestamp_s)
                ? ""
                : DateTime.fromJSDate(date_timestamp_s, { zone: "utc" })
                    .setZone("local")
                    .toLocaleString(DateTime.DATETIME_SHORT)}
            </div>
          </div>
        </li>
        {sortedValues.map((measure, i) => {
          const { dataType, value } = measure;
          const decimalPoint = getDisplayDefaultDecimalPoints(dataType);
          const formattedValue =
            isUndefined(value) || isNull(value)
              ? ""
              : new Decimal(value).toFixed(decimalPoint);
          // const highestAlarmExceeded = alarmsService.getHighestAlarmExceeded(
          //   device,
          //   dataType,
          //   value,
          //   device.measure.dtime
          // );
          return (
            <li className="list-group-item px-2" key={i}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>{tChart(`${dataType}_LABEL`)}</div>
                {/* <div className={`${highestAlarmExceeded==="CRITICAL" ? "text-danger" : highestAlarmExceeded==="WARNING" ? "text-warning" : ""} ${highestAlarmExceeded ? "fw-bold" : ""}`}> */}
                <div>
                  {formattedValue}
                  {tChart(`${dataType}_UNIT`)}
                </div>
              </div>
            </li>
          );
        })}
        <li className="list-group-item px-2">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>{t(`WEEKLY_ALARM_EXCEEDED_LABEL`)}:</div>
            <div
              style={{ marginTop: -2 }}
              className="aes-tooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={t("WEEKLY_ALARM_EXCEEDED_TOOLTIP")}
            >
              <QuestionmarkIcon className="ms-2 text-info" />
            </div>
          </div>
        </li>
        {alarmExceedDisplayed.map(({ aes, dataType, alarm }, i) => {
          return (
            <li className="list-group-item px-2" key={i}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>{tChart(`${dataType}_LABEL`)}</div>
                {/* <div className={`${(aes?.exceededPercentage||0)>=aesCriticalPercentage ? "text-danger" : (aes?.exceededPercentage||0)>=aesWarningPercentage ? "text-warning" : ""} ${(aes?.exceededPercentage||0)>=aesWarningPercentage ? "fw-bold" : ""}`}> */}
                <div>
                  {!aes ? (
                    ""
                  ) : (
                    <AlarmExceedsStatisticDisplay
                      alarm={alarm}
                      alarmExceedStatistic={aes}
                      device={device}
                      requestedColor={deviceColor}
                    />
                  )}
                </div>
              </div>
            </li>
          );
        })}

        {sortedValuesBottom.map((measure, i) => {
          const { dataType, value } = measure;
          const decimalPoint = getDisplayDefaultDecimalPoints(dataType);
          const formattedValue = isUndefined(value)
            ? ""
            : new Decimal(value).toFixed(decimalPoint);
          // const highestAlarmExceeded = alarmsService.getHighestAlarmExceeded(
          //   device,
          //   dataType,
          //   value,
          //   device.measure.dtime
          // );
          return (
            <li className="list-group-item px-2" key={i}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>{tChart(`${dataType}_LABEL`)}</div>
                {/* <div className={`${highestAlarmExceeded==="CRITICAL" ? "text-danger" : highestAlarmExceeded==="WARNING" ? "text-warning" : ""} ${highestAlarmExceeded ? "fw-bold" : ""}`}> */}
                <div>
                  {formattedValue}
                  {tChart(`${dataType}_UNIT`)}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
      <div style={{ flexGrow: 1, display: "flex", alignItems: "flex-end" }}>
        <a
          href={devicePage}
          tabIndex={-1}
          onClick={(e) => {
            e.preventDefault();
            navigateToDevicePage();
          }}
          style={{
            backgroundColor: colorString,
            borderColor: colorString,
            width: "100%",
          }}
          className={`btn fs-5 text-white fw-bold`}
        >
          {t("DETAILS")}
        </a>
      </div>
    </div>
  );
};

export default DeviceCard;
