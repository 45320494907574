import { isNull } from "lodash";
import { useEffect, useState } from "react";
import { SwitchTransition, Transition } from "react-transition-group";
import { useTranslate } from "react-translate";
import { useSaveStateInSearchParams } from "../../../charts/components/useSaveStateInSearchParams";
import DevicesGrid from "./DevicesGrid";
import DevicesTable from "./DevicesTable";
import DevicesViewSwitch from "./DevicesViewSwitch";

const ANIMATION_DURATION_MS = 150;

export enum DevicesViewOption {
  "GRID" = "GRID",
  "TABLE" = "TABLE",
}

const DevicesView: React.FC = () => {
  const t = useTranslate("devices");

  const {
    initialValue: initialValueView,
    handleValueChange: handleValueChangeView,
    valueIndex: valueIndexView,
  } = useSaveStateInSearchParams<DevicesViewOption>(
    DevicesViewOption.GRID,
    "VIEW"
  );
  const [selectedView, setSelectedView] =
    useState<DevicesViewOption>(initialValueView);
  useEffect(() => {
    handleValueChangeView(selectedView);
  }, [selectedView]);
  useEffect(() => {
    setSelectedView(initialValueView);
  }, [valueIndexView]);

  const {
    initialValue: initialValueGroup,
    handleValueChange: handleValueChangeGroup,
    valueIndex: valueIndexGroup,
  } = useSaveStateInSearchParams<string>("", "GROUP");
  const [selectedGroupId, setSelectedGroupId] = useState<string | null >(initialValueGroup === "" ? null : initialValueGroup);
  useEffect(() => {
    handleValueChangeGroup(isNull(selectedGroupId) ? "" : selectedGroupId);
  }, [selectedGroupId]);
  useEffect(() => {
    setSelectedGroupId(initialValueGroup === "" ? null : initialValueGroup);
  }, [valueIndexGroup]);

  const defaultAnimatedStyle = {
    transition: `opacity ${ANIMATION_DURATION_MS}ms linear`,
    opacity: 0,
    height: "100%",
    width: "100%",
  };
  const transitionAnimatedStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: { opacity: 0 },
  };
  return (
    <div
      className="px-xl-5 px-2"
      style={{ maxWidth: 1380, margin: "50px auto" }}
    >
      <div className="mb-5 text-center text-lg-start">
        <h1>{t("DEVICES_GRID_HEADER")}</h1>
        <h5 className="ms-1">{t("DEVICES_GRID_PARAGRAPH")}</h5>
      </div>

      <DevicesViewSwitch
        selectedGroupId={selectedGroupId}
        selectedView={selectedView}
        setSelectedGroupId={setSelectedGroupId}
        setSelectedView={setSelectedView}
      />
      <SwitchTransition>
        <Transition
          key={`${selectedView}-${selectedGroupId}`}
          timeout={{ enter: 1, exit: ANIMATION_DURATION_MS }}
        >
          {(state) => (
            <div
              style={{
                ...defaultAnimatedStyle,
                ...transitionAnimatedStyles[state],
              }}
            >
              {selectedView === DevicesViewOption.GRID ? (
                <DevicesGrid selectedGroupId={selectedGroupId} />
              ) : (
                <DevicesTable selectedGroupId={selectedGroupId} />
              )}
            </div>
          )}
        </Transition>
      </SwitchTransition>
    </div>
  );
};

export default DevicesView;
