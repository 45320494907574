import { Redirect } from "react-router";
import AdminPanel from "../../../modules/mod/components/AdminPanel";
import { useCanAccessAdminPanel } from "../../../modules/mod/hooks/useCanAccessAdminPanel";
import { HOME_PAGE_PATH } from "../home";

export const ADMIN_PAGE_PATH = "/admin";

const AdminPage: React.FC = () => {
  const { access, dataLoaded } = useCanAccessAdminPanel();

  if (dataLoaded && !access) {
    return <Redirect to={HOME_PAGE_PATH} />;
  }

  return <AdminPanel />;
};

export default AdminPage;
