import { AlarmsState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";
import { TimeScaleState } from "../../charts/models/time-scale-state";
import { calculateRequestedAlarms } from "./utils/calculateRequestedAlarms";

type Payload = {
  updatedTimeScaleState: Partial<TimeScaleState>;
};

class UpdateTimeScaleStateClass extends ReducerActionClass<
  AlarmsState,
  Payload
> {
  getActionName() {
    return "UPDATE_TIME_SCALE_STATE";
  }

  handleDispatch(prevState: AlarmsState, payload: Payload): AlarmsState {
    const { timeScaleState: prevTimeScaleState } = prevState;
    const { updatedTimeScaleState } = payload;

    const timeScaleState: TimeScaleState = {
      ...prevTimeScaleState,
      ...updatedTimeScaleState,
    };

    const midState: AlarmsState = {
      ...prevState,
      timeScaleState,
    };

    const updatedData = calculateRequestedAlarms(midState,prevState);

    return {
      ...midState,
      ...updatedData,
    };
  }
}

export const UpdateTimeScaleState = new UpdateTimeScaleStateClass();
