import { useState } from "react";
import { useHistory } from "react-router";

const DefaultTile: React.FC<{
  children?: React.ReactNode;
  title: string;
  background?: React.ReactNode;
  disableBlur?: boolean;
  url?: string;
}> = (props) => {
  const primaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-primary-rgb");

  const { title, children, background, disableBlur, url } = props;

  const [hover,setHover] = useState<boolean>(false);
  const [focus,setFocus] = useState<boolean>(false)

  const history = useHistory();

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "stretch",
      }}
    >
      <div
        style={{ flexGrow: 1, cursor: "pointer" }}
        className="m-2 card border-light bg-gradient"
      >
        <a
          onFocus={()=>setFocus(true)}
          onBlur={()=>setFocus(false)}
          href={url}
          onClick={(e) => {
            e.preventDefault();
            if (!url) {
              return;
            }
          
            history.push(url);
          }}
        >
          <div onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)} style={{position:"absolute",width:"100%",height:"100%"}}/>
        </a>
          <div
            style={{
              position: "absolute",
              pointerEvents: "none",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {background}
          </div>
          <div
            style={{
              position: "absolute",
              pointerEvents: "none",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "stretch",
            }}
          >
            <div style={{ flexGrow: 1, position: "relative" }}>
              <div
                style={{ width: "100%", height: "100%", position: "absolute" }}
              >
                {children}
              </div>
            </div>
            <div
              className="text-primary"
              style={{
                backdropFilter: disableBlur ? undefined : `blur(15px)`,
                // filter:"blur(8px)",
                backgroundColor: `rgba(${primaryColor},0.3)`,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <h1 style={{textDecoration:hover||focus ? "underline" : undefined}} className="m-3 fw-bold">{title}</h1>
            </div>
          </div>
      </div>
    </div>
  );
};

export default DefaultTile;
