import { useCallback, useMemo } from "react";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { EditOrganisationDto } from "shared";
import { exampleOrganisations } from "../../tabs/Admin/OrganisationsTab";
import { useModDataSelector } from "../../../mod-data.provider";
import { container } from "tsyringe";
import { OrganisationRequestsService } from "../../../request-services/organisation-requests.service";

const resolver = classValidatorResolver(EditOrganisationDto);

const DeleteOrganisationModal: React.FC<{
  organisationId: string | null;
  onClose: () => void;
}> = ({ onClose, organisationId }) => {
  const t = useTranslate("mod");
  const organisations = useModDataSelector(state=>state.system?.organisations);

  const organisation = useMemo(()=>organisations?.find(org=>org.organisation._id===organisationId),[organisationId,organisations])

  const onSave = useCallback(() => {

    container.resolve(OrganisationRequestsService).deleteOrganisationDto(organisation?.organisation._id||"").then(()=>{
      onClose()
    })

  }, [onClose,organisation]);


  return (
    <DefaultFormModal
      title={t("ORGANISATION_TOOLTIP_DELETE")}
      show={!!organisation}
      onClose={onClose}
      onSave={onSave}
      submitType="delete"
    >
      <>{t("DELETE_ORGANISATION_MODAL_PARAGRAPH", { orgName: organisation?.organisation.name || "" })}</>
      
    </DefaultFormModal>
  );
};

export default DeleteOrganisationModal;
