import { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";

import ErrorDisplayFieldError from "../../../../../utils/forms/ErrorDisplayFieldError";
import { CreateDeviceGroupDto } from "shared";
import { container } from "tsyringe";
import { DeviceGroupRequestsService } from "../../../request-services/device-group-requests.service";

const resolver = classValidatorResolver(CreateDeviceGroupDto);

const CreateDeviceGroupModal: React.FC<{
  show: boolean;
  onClose: () => void;
  organisationId:string
}> = ({ onClose, show,organisationId }) => {
  const t = useTranslate("mod");

  const {
    register,
    handleSubmit,
    formState: { errors,isValid },
  } = useForm<CreateDeviceGroupDto>({ resolver, mode: "onBlur",defaultValues:{display:true} });

  const submitRef = useRef<HTMLInputElement>(null);
  const onSave = useCallback(() => {
    if (!submitRef.current) {
      return;
    }
    submitRef.current.click();
  }, [submitRef]);

  return (
    <DefaultFormModal
      title={t("DGROUP_TOOLTIP_EDIT")}
      show={show}
      onClose={onClose}
      onSave={onSave}
      submitType="add"
      saveDisabled={!isValid}
    >
      <>{t("CREATE_DGROUP_MODAL_PARAGRAPH")}</>
      <form
        onSubmit={handleSubmit((data) => {
          container.resolve(DeviceGroupRequestsService).createDeviceGroup(data,organisationId).then(()=>{
            onClose()
          })
        })}
      >
        <input ref={submitRef} hidden type="submit" />
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <label htmlFor="create_dgroup_name" className="col-form-label">
                {t("EDIT_DGROUP_MODAL_LABEL_NAME")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="create_dgroup_name"
                {...register("name")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.name} />
        </div>
      </form>
    </DefaultFormModal>
  );
};

export default CreateDeviceGroupModal;
