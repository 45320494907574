import {
  ReducerActionClass,
  ReducerCollection,
} from "../../../../../utils/store/core";
import { getContainer } from "../../../../app-container";
import { PdfGeneratorService } from "../../../../pdf-generator/pdf-generator.service";
import { DataExporterRequests, DataExportFormat } from "../models/data-exporter-requests.model";
import { UpdateDataExporterRequests } from "./updateDataExporterRequests";
import { UpdateExportedDevices } from "./updateExportedDevices";


export type DataExporterState =DataExporterRequests & {downloadable:boolean};

export class DataExporterReducer extends ReducerCollection<DataExporterState> {
  static initialState: DataExporterState = {
    format: DataExportFormat.JSON,
    devices: [],
    timeScaleState: getContainer()
      .resolve<PdfGeneratorService>("pdf-generator")
      .getDefaultReportsTimeScaleState(),
    downloadable:false
  };

  protected initState(): DataExporterState {
    return DataExporterReducer.initialState;
  }

  protected getActionClassObjects(): ReducerActionClass<
    DataExporterState,
    any
  >[] {
    return [UpdateDataExporterRequests,UpdateExportedDevices];
  }
}
