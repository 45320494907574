import { TabsArray } from "./useTabView";

const TabSwitch = <TabT extends string>({
  items,
  initialTab,
}: {
  items: TabsArray<TabT>;
  initialTab: TabT;
}) => {
  return (
    <>
      {items.map((tab) => (
        <div
          key={tab.name}
          className={`tab-pane fade ${
            initialTab === tab.name ? "show active" : ""
          }`}
          id={tab.name}
        >
          <tab.Component />
        </div>
      ))}
    </>
  );
};

export default TabSwitch;
