import React from 'react';
import { generatePath, useParams } from 'react-router';
import DeviceItemView from '../../../modules/devices/components/DeviceItem/DeviceItemView';

export const DEVICE_ITEM_PAGE_PATH = "/devices/:deviceName"
export const getDeviceUrl = (deviceName:string)=>generatePath(DEVICE_ITEM_PAGE_PATH,{deviceName})


const DeviceItemPage:React.FC = ()=>{

    const {deviceName} = useParams<{deviceName:string}>();

    return(
        <DeviceItemView deviceName={deviceName} />
    )

}

export default DeviceItemPage;