import { createContext, useMemo } from "react";
import { createDispatchHook, createSelectorHook, Provider } from "react-redux";
import { Action, Dispatch } from "redux";
import { getContainer } from "../app-container";
import { ModDataService } from "./mod-data.service";
import { ModDataState } from "./store";

const ModDataContext = createContext({})

export const useModDataDispatch: <
AppDispatch extends Dispatch<Action<ModDataState>> = Dispatch<
  Action<ModDataState>
>
>() => AppDispatch = createDispatchHook(
//@ts-ignore
ModDataContext
);

export const useModDataSelector: <S>(
    selector: (state: ModDataState) => S
    //@ts-ignore
  ) => S = createSelectorHook(ModDataContext);


export const ModDataProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => {
    const store = useMemo(
      () => getContainer().resolve<ModDataService>("mod-data").getStore(),
      []
    );
  
    return (
      <Provider context={ModDataContext as any} store={store}>
        <>{children}</>
      </Provider>
    );
  };
  