import React, { createContext, useMemo } from "react";
import { createDispatchHook, createSelectorHook, Provider } from "react-redux";
import { Action, Dispatch } from "redux";
import { getContainer } from "../app-container";
import { DataProviderService } from "./data-provider.service";
import { DataProviderState } from "./store";

export const DataProviderContext = createContext({});

export const useDataProviderDispatch: <
  AppDispatch extends Dispatch<Action<DataProviderState>> = Dispatch<
    Action<DataProviderState>
  >
>() => AppDispatch = createDispatchHook(
  //@ts-ignore
  DataProviderContext
);
export const useDataProviderSelector: <S>(
  selector: (state: DataProviderState) => S
  //@ts-ignore
) => S = createSelectorHook(DataProviderContext);

export const DataProviderProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const store = useMemo(
    () => getContainer().resolve<DataProviderService>("data-provider").getStore(),
    []
  );

  return (
    <Provider context={DataProviderContext as any} store={store}>
      <>{children}</>
    </Provider>
  );
};
