import { useCallback, useMemo, useRef } from "react";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { isNull } from "lodash";
import { container } from "tsyringe";
import { DeviceGroupRequestsService } from "../../../request-services/device-group-requests.service";
import { useModPanelData } from "../../hooks/useModPanelData";
import { EmailConfigDto } from "shared";
import { OrganisationRequestsService } from "../../../request-services/organisation-requests.service";
import { SystemRequestsService } from "../../../request-services/system-requests.service";

const DeleteEmailModal: React.FC<{
  index: number | null;
  onClose: () => void;
  organisationId: string | null;
}> = ({ onClose, index, organisationId }) => {
  const t = useTranslate("user");
  const { emailData } = useModPanelData(organisationId);
  const emailItem = useMemo(
    () => emailData?.items[index || 0],
    [emailData, index]
  );

  const onSave = useCallback(() => {

    const dto: EmailConfigDto = { items: [...emailData.items] };
    dto.items.splice(index || 0, 1);


    if (isNull(organisationId)) {
      container.resolve(SystemRequestsService).sendUpdateEmailConfig(dto).then(()=>{
        onClose()

      });
    } else {
      container.resolve(OrganisationRequestsService).sendUpdateEmailConfig(dto,organisationId).then(()=>{
        onClose()

      });
      
    }
  }, [organisationId, emailData, index]);

  return (
    <DefaultFormModal
      title={t("EMAIL_CONFIG_MODAL_HEADER_DELETE")}
      show={!isNull(index)}
      onClose={onClose}
      onSave={onSave}
      submitType="delete"
    >
      <>
        {t("EMAIL_CONFIG_MODAL_PARAGRAPH_DELETE_1")}
        <span className="fw-bold"> {emailItem?.email}</span>
        {t("EMAIL_CONFIG_MODAL_PARAGRAPH_DELETE_2")}
      </>
    </DefaultFormModal>
  );
};

export default DeleteEmailModal;
