import { PdfGeneratorState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";
import { PdfAlarmRequest } from "../models/pdf-requests.model";

type Payload = {
  alarmName: string;
  pdfAlarmRequestChanges:Partial<PdfAlarmRequest>
};

class SetRequestedAlarmClass extends ReducerActionClass<
  PdfGeneratorState,
  Payload
> {
  getActionName() {
    return "SET_REQUESTED_ALARM";
  }
  handleDispatch(
    prevState: PdfGeneratorState,
    payload: Payload
  ): PdfGeneratorState {
    const { requestedAlarms: prevRequestedAlarms } = prevState;
    const { alarmName, pdfAlarmRequestChanges } = payload;

    const prevAlarmRequest = prevState.requestedAlarms[alarmName]||{};
    const alarmRequest = {...prevAlarmRequest,...pdfAlarmRequestChanges}

    return{
      ...prevState,
      requestedAlarms:{
        ...prevRequestedAlarms,
        [alarmName]:alarmRequest
      }
    }
  }
}

export const SetRequestedAlarm = new SetRequestedAlarmClass();
