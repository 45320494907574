import { getColorForDeviceGroup } from "../../../charts/chart-generating/utils/getColor";
import { useTranslate } from "react-translate";
import DeviceCard from "./DeviceCard";
import { useDataProviderSelector } from "../../../data-provider/data-provider.provider";
import { useMemo, useState } from "react";
import { isNull, isUndefined } from "lodash";
import { getDevicesInGroup } from "../../utils/getDevicesInGroup";
import { getContainer } from "../../../app-container";
import { DevicesService } from "../../devices.service";

type Props = {
  selectedGroupId: string | null;
};

const DevicesGrid: React.FC<Props> = ({ selectedGroupId }) => {
  const t = useTranslate("devices");

  const deviceGroups = useDataProviderSelector((state) => state.deviceGroups);
  const devices = useDataProviderSelector((state) => state.devices);

  const PRIMARY_COLOR = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-primary-rgb");

  const devicesInGroup = useMemo(
    () =>
      isNull(selectedGroupId)
        ? devices
        : getContainer().resolve<DevicesService>("devices").getGroupsDevices(deviceGroups,devices,selectedGroupId==="false" ? false : selectedGroupId),
    [selectedGroupId, devices,deviceGroups]
  );

  return (
    <>
      <div
        className="justify-content-xl-start justify-content-center"
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "stretch",
        }}
      >
        {devicesInGroup
          .map((deviceObj, i) => {
            const color = getColorForDeviceGroup(deviceObj.device.groupIds[0]);
            const { device:device, lastMeasure:measure } = deviceObj;
            const {name} = device;

            return (
              <div style={{ margin: 10, marginTop: 30 }} key={i}>
                <div
                  style={{
                    width: 300,
                    height: "100%",
                  }}
                >
                  <DeviceCard
                    device={device}
                    deviceColor={PRIMARY_COLOR}
                    lastMeasure={measure}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default DevicesGrid;
