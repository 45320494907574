import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Collapse } from "bootstrap";
import { useTranslate } from "react-translate";
import { DateTime } from "luxon";
import { ChartRequests } from "../../charts/models/chart-requests.model";
import { useChart } from "../../charts/hooks/useChart";
import { getStartOfTheDay } from "../../charts/chart-generating/utils/getStartOfTheDay";
import { AlarmExceed } from "../models/alarm-exceed.model";
import { getValueRoundedToDefaultDecimalPoint } from "../../charts/chart-generating/utils/getValueRoundedToDefaultDecimalPoint";
import { getColorForDeviceGroup } from "../../charts/chart-generating/utils/getColor";
import { generatePath, useHistory } from "react-router-dom";
import { DEVICE_ITEM_PAGE_PATH } from "../../../skeleton/pages/devices/device-item-page";
import { getStatsPageURLWithSearchParamsValue } from "../../../skeleton/pages/stats/index";
import { useDataProviderSelector } from "../../data-provider/data-provider.provider";
import { getCorrectGroupingsForTimeRange } from "../../charts/chart-generating/utils/getCorrectGroupingsForTimeRange";

const AlarmItem: React.FC<{
  alarmExceed: AlarmExceed;
  previewMode?: boolean;
}> = (props) => {
  const { alarmExceed, previewMode } = props;
  const {
    alarmName,
    alarmType,
    dataType,
    deviceName,
    endTimestamp_s,
    maxValue,
    startTimestamp_s,
    maxValueLast24h
  } = alarmExceed;

  const [expanded, setExpanded] = useState(false);

  const collapseRef = useRef<HTMLDivElement>(null);
  const t = useTranslate("alarms");
  const tCharts = useTranslate("chart");
  useLayoutEffect(() => {
    if (!collapseRef.current) {
      return;
    }

    const el = collapseRef.current;
    const collapse = new Collapse(el, { toggle: false });

    const handleShow = () => {
      setExpanded(true);
    };

    const handleHidden = () => {
      setExpanded(false);
    };

    el.addEventListener("show.bs.collapse", handleShow);
    el.addEventListener("hidden.bs.collapse", handleHidden);

    return () => {
      el.removeEventListener("show.bs.collapse", handleShow);
      el.removeEventListener("hidden.bs.collapse", handleHidden);
      collapse.dispose();
    };
  }, [collapseRef, setExpanded]);

  const toggle = useCallback(() => {
    if (!collapseRef.current) {
      return;
    }
    const collapse = Collapse.getInstance(collapseRef.current);
    collapse?.toggle();
  }, [collapseRef]);

  const chartCanvasRef = useRef<HTMLCanvasElement>(null);

  const devices = useDataProviderSelector((state) => state.devices);
  const device = useMemo(
    () => devices.find((dev) => dev.device.name === deviceName)?.device,
    [deviceName, devices]
  );

  // const deviceColor = useMemo(() => {
    // return getColorForDeviceGroup(device!.groupIds[0]);
  // }, [device]);

    const deviceColor = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--bs-primary-rgb");
  

  const measuresGrouping = useMemo(
    () =>
      getCorrectGroupingsForTimeRange({ startTimestamp_s, endTimestamp_s })[0],
    []
  );

  const chartRequests = useMemo<ChartRequests>(
    () => ({
      requestedAlarms: [alarmName],
      //@ts-ignore
      requestedDataTypes: [dataType],
      requestedDevices: { [device!._id]: { color: deviceColor, enabled: true } },
      timeScaleState: {
        startTimestamp_s: getStartOfTheDay(startTimestamp_s),
        endTimestamp_s: getStartOfTheDay(endTimestamp_s) + 60 * 60 * 24,
      },
      measuresGrouping,
    }),
    []
  );

  useChart(
    chartCanvasRef,
    !expanded,
    chartRequests,
    undefined,
    undefined,
    {
      disableChartTimeScaleActions: true,
    },
    undefined,
    {
      excessiveRange: 1,
      minimumExcessiveRange: 1,
    }
  );

  const history = useHistory();

  const devicePageUrl = useMemo(() => {
    return generatePath(DEVICE_ITEM_PAGE_PATH, { deviceName });
  }, [deviceName, history]);

  const navigatoToDevicePage = useCallback(() => {
    history.push(devicePageUrl);
  }, [devicePageUrl]);

  const statsPageUrl = useMemo(() => {
    const chartRequestsJson = JSON.stringify(chartRequests);
    return getStatsPageURLWithSearchParamsValue(chartRequestsJson);
  }, [chartRequests, history]);

  const navigateToStatsPage = useCallback(() => {
    history.push(statsPageUrl);
  }, [statsPageUrl]);

  return (
    <>
      <div
        className="card border-white"
        style={{ backgroundColor: previewMode ? "rgba(0,0,0,0)" : undefined }}
      >
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
          }}
          onClick={toggle}
        >
          <div
            className="card-body "
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>
              {t("ALARM_CARD_HEADER_1", {
                deviceName: deviceName,
                dataType: tCharts(`${dataType}_LABEL`),
              })}
              {alarmType === "WARNING" ? (
                <span className="text-warning fw-bold">
                  {t("ALARM_CARD_HEADER_VALUE_WARNING")}
                </span>
              ) : (
                <span className="text-danger fw-bold">
                  {t("ALARM_CARD_HEADER_VALUE_CRITICAL")}
                </span>
              )}

              {t("ALARM_CARD_HEADER_2", {
                maxValueLast24h: `${getValueRoundedToDefaultDecimalPoint(
                  maxValueLast24h,
                  dataType
                )}${tCharts(`${dataType}_UNIT`)}`,
              })}
            </div>
            <div className="ms-3">
              {DateTime.fromSeconds(startTimestamp_s).toLocaleString(
                DateTime.DATETIME_SHORT
              )}
              <span> </span>-{" "}
              {DateTime.fromSeconds(endTimestamp_s).toLocaleString(
                DateTime.DATETIME_SHORT
              )}
            </div>
          </div>
          <div
            className=" me-2"
            style={{
              overflow: "visible",
              fontSize: "2.1rem",
              display: !previewMode ? "flex" : "none",
              alignItems: "flex-end",
            }}
          >
            <div>
              <a className="dropdown-toggle text-muted pe-2" tabIndex={0} href="#" onClick={e=>e.preventDefault()} />
            </div>
          </div>
        </div>
        <div ref={collapseRef} className="collapse">
          <div className="card-body pt-0">
            <hr className="mt-0" />
            <div style={{ display: "flex", alignItems: "stretch" }}>
              <div
                className="mt-0 m-3 me-5"
                style={{ flexGrow: 1, height: 300 }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <canvas ref={chartCanvasRef} />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                }}
              >
                <div className="m-1 mb-2">
                  <a
                    role="button"
                    href={devicePageUrl}
                    onClick={(e) => {
                      e.preventDefault();
                      navigatoToDevicePage();
                    }}
                    style={{
                      backgroundColor: `rgb(${deviceColor})`,
                      borderColor: `rgb(${deviceColor})`,
                    }}
                    className="btn btn-primary w-100"
                  >
                    {t("GO_TO_DEVICE_PAGE")}
                  </a>
                </div>
                <div className="m-1 mb-2">
                  <a
                    role="button"
                    href={statsPageUrl}
                    onClick={(e) => {
                      e.preventDefault();
                      navigateToStatsPage();
                    }}
                    style={{
                      backgroundColor: `rgb(${deviceColor})`,
                      borderColor: `rgb(${deviceColor})`,
                    }}
                    className="btn btn-primary w-100"
                  >
                    {t("GO_TO_STATS_PAGE")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlarmItem;
