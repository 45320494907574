import { AlarmType } from "shared";

export type MeasureToRemove = {
    date_timestamp_s: number;
    values: Array<MeasureValue>
  }

export type MeasureValue = {
  dataType:DataType,
  value:number
}

export type MeasurePossibleValue = {
  dataType:DataType,
  value?:number
}

export type ChartGenerationData = {
  alarmThresholds: Array<{
    name: string;
    threshold: number;
    dataType: DataType;
    alarmType:AlarmType
  }>;
  devices: Array<{
    deviceName: string;
    measures: Array<MeasureToRemove>;
  }>;
};


export type DataType = "TEMPERATURE" | "CO2_LEVEL" | "CO_LEVEL" | "ATMOSPHERIC_PRESSURE" | "HUMIDITY" | "POWER_SUPPLY";

export const AllDataTypes:Array<DataType> =["CO2_LEVEL","CO_LEVEL","TEMPERATURE","ATMOSPHERIC_PRESSURE","HUMIDITY","POWER_SUPPLY"]
