import { ReducerCollection } from "../../../utils/store/core";
import { PdfRequests } from "../models/pdf-requests.model";
import { PutRequestedDevice } from "./putRequestedDevice";
import { SetRequestedAlarm } from "./setRequestedAlarm";
import { SetRequestedDataType } from "./setRequestedDataType";
import { UpdateTimeScaleState } from "./updateTimeScaleState";

export type PdfGeneratorState = PdfRequests & {
  downloadable: boolean;
};

export class PdfGeneratorReducer extends ReducerCollection<PdfGeneratorState> {
  static initialState: PdfGeneratorState = {
    requestedAlarms: {},
    requestedDataTypes: [],
    requestedDevice: undefined,
    timeScaleState: {
      /* default value is 1.08.2022; it really shouldnt be used anytime here, its here just to pass type checks */
      startTimestamp_s: 1659304800,
      endTimestamp_s: 1659391200,
    },
    downloadable: false,
  };

  initState() {
    return PdfGeneratorReducer.initialState;
  }

  getActionClassObjects() {
    return [
      PutRequestedDevice,
      SetRequestedAlarm,
      SetRequestedDataType,
      UpdateTimeScaleState,
    ];
  }
}
