import { isNaN } from "lodash";
import { useMemo } from "react";
import { useTranslate } from "react-translate";
import { getMergedScaleDataType } from "../chart-generating/get-y-scales";
import { MeasuresGrouping } from "../models/measure-dataset-setup.model";
import { PutMeasuresGrouping } from "../store/putMeasuresGrouping";
import { PutScaleConfig } from "../store/putScaleConfig";
import { SetRequestedDataType } from "../store/setRequestedDataType";
import { useInitCollapseToggle, useToggleCollapse } from "./utils/collapse-fix";
import { sortDataTypes } from "../../devices/utils/sortMeasureDataTypes";
import { useMainChartDispatch, useMainChartSelector } from "../hooks/chart.context";
import { dataTypesImportance } from "../chart-generating/utils/getGridVisibility";
import { ScaleConfig } from "../models/scale-config.model";
import { getCorrectGroupingsForTimeRange } from "../chart-generating/utils/getCorrectGroupingsForTimeRange";

const ScalesControlls: React.FC = () => {
  const t = useTranslate("chart");

  const dataTypesIncluded = useMainChartSelector(
    (state) => state.dataTypesIncluded
  );

  const dataTypesIncludedSorted = sortDataTypes(dataTypesIncluded)

  const requestedDataTypes = useMainChartSelector(
    (state) => state.requestedDataTypes
  );

  const dispatch = useMainChartDispatch();

  const scalesConfig = useMainChartSelector((state) => state.scalesConfig);
  const measuresGrouping = useMainChartSelector((state)=>state.measuresGrouping)

  useInitCollapseToggle(`.scalesControlls`,{toggle:false})
  const toggleCollapse = useToggleCollapse()


  const timeScaleState = useMainChartSelector(state=>state.timeScaleState)
  const correctGroupings = useMemo(()=>getCorrectGroupingsForTimeRange(timeScaleState),[timeScaleState])
  

  return (
    <>
      <h3>{t("MEASURES_DATA")}</h3>

      {dataTypesImportance.map((dataType, i) => {
        const mergedScaleDataType = getMergedScaleDataType(dataType);
        const scaleConfig = scalesConfig[mergedScaleDataType] as ScaleConfig
        const { auto, max, min } = scaleConfig;
        const requested = requestedDataTypes.includes(dataType);

        return (
          <div className="ps-2 pb-1" key={i}>
            <div
              className="fs-5"
              style={{
                display: "flex",
                flexWrap: "nowrap",
                justifyContent: "space-between",
              }}
            >
              <div className="form-check-inline ">
                <label
                  style={{ cursor: "pointer" }}
                  className="btn-toggle form-check-label"
                  // data-bs-toggle="collapse"
                  // data-bs-target={`#scalesControlls_${i}`}
                  tabIndex={0}
                  onKeyDown={(e)=>{
                    if(e.key==="Enter"){
                      //@ts-ignore
                      e.target.click()
                    }
                  }}
                  onClick={()=>toggleCollapse(`#scalesControlls_${i}`)}
                >
                  <a className="dropdown-toggle text-white pe-2" />
                  {t(`${dataType}_LABEL`)}
                </label>
              </div>
              <div className="form-check-inline">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onKeyDown={(e)=>{
                    if(e.key==="Enter"){
                      //@ts-ignore
                      e.target.click()
                    }
                  }}
                  checked={requested}
                  onClick={() =>
                    dispatch(
                      SetRequestedDataType.callAction({
                        dataType,
                        requested: !requested,
                      })
                    )
                  }
                />
              </div>
            </div>
            <div className="collapse scalesControlls" id={`scalesControlls_${i}`}>
              <div className="ps-2">
                <div className="form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={auto}
                    
                  onKeyDown={(e)=>{
                    if(e.key==="Enter"){
                      //@ts-ignore
                      e.target.click()
                    }
                  }}  
                    id={`auto-scale_${i}`}
                    onClick={() =>
                      dispatch(
                        PutScaleConfig.callAction({
                          dataType:mergedScaleDataType,
                          partialScaleConfig: { auto: !auto },
                        })
                      )
                    }
                    style={{ cursor: "pointer" }}
                  />
                  <label
                    className="form-check-label ps-1"
                    htmlFor={`auto-scale_${i}`}
                    style={{ cursor: "pointer" }}
                  >
                    {t("AUTO_SCALE")}
                  </label>
                </div>

                <div>
                  <input
                    className="form-control-inline m-1"
                    style={{ width: 50 }}
                    value={min}
                    onChange={(e) => {
                      const val = +e.target.value;
                      if (isNaN(val)) {
                        return;
                      }
                      dispatch(
                        PutScaleConfig.callAction({
                          dataType:mergedScaleDataType,
                          partialScaleConfig: { min: val },
                        })
                      );
                    }}
                    disabled={auto}
                    type="text"
                    aria-label=".form-control-sm example"
                  />
                  -
                  <input
                    className="form-control-inline m-1"
                    style={{ width: 50 }}
                    value={max}
                    onChange={(e) => {
                      const val = +e.target.value;
                      if (isNaN(val)) {
                        return;
                      }
                      dispatch(
                        PutScaleConfig.callAction({
                          dataType:mergedScaleDataType,
                          partialScaleConfig: { max: val },
                        })
                      );
                    }}
                    disabled={auto}
                    type="text"
                    aria-label=".form-control-sm example"
                  />
                  {t(`${dataType}_UNIT`)}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <div className="ps-3 pt-3 pb-1">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <div>{t("GROUPING_LABEL")}</div>
          <div>
            <select
              className="form-select form-select-sm"
              aria-label="size 3 select example"
              value={measuresGrouping}
              onChange={(e)=>{
                const val = e.target.value as MeasuresGrouping
                dispatch(PutMeasuresGrouping.callAction({measuresGrouping:val}))
                
              }}
            >
              {correctGroupings.map((gruping,i)=>
              <option value={gruping} key={i}> {t(`GROUPING_${gruping}`)}</option>
              )}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScalesControlls;
