import UserProfileHeader from "./Header/UserProfileHeader";
import NotificationView from "./Notifications/NotificationView";

const UserProfileView: React.FC = () => {
  return (
    <div
      className="px-xl-5 px-2"
      style={{ maxWidth: 900, margin: "50px auto" }}
    >
      <div>
        <UserProfileHeader />
        <NotificationView />
      </div>
    </div>
  );
};

export default UserProfileView;
