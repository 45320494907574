import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";

import ChartControllsContent from "./ChartControllsContent";

import { ReactComponent as GearIcon } from "bootstrap-icons/icons/gear-fill.svg";
import { ReactComponent as CollapseIcon } from "bootstrap-icons/icons/justify.svg";
import AnimationsSwitch from "./AnimationsSwitch";

const ChartControllsDesktopContent:React.FC<{setExpanded:(val:boolean)=>void}> = (props)=>{

    const WHITE_COLOR = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--bs-white-rgb");
    const {setExpanded} = props;

    return(
        <div style={{height:"100%",display:"flex",flexDirection:"column",alignItems:"stretch"}}>
            <div className="container bg-secondary text-white ps-1 pe-1 pt-1">
                
      <div onClick={()=>setExpanded(false)} style={{ position: "relative", top: "0%", right: "0%", margin: 20,color:`rgb(${WHITE_COLOR})`,cursor:"pointer" }}>
        <CollapseIcon
        
        //   fill={}
          style={{ top: 0, right: 0, position: "relative" }}
          width="50"
          height="50"
        />
      </div>
      {/* <div className="ps-3">
      <AnimationsSwitch/>
      </div>
                <hr/> */}
            </div>
            <div style={{flexGrow:1,position:"relative"}}>
                <ChartControllsContent/>
            </div>

        </div>
    )
}



const ChartControllsSideDesktop:React.FC = ()=>{
    const [expanded, setExpanded] = useState<boolean>(true);

    const containerRef = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState<number | undefined>(undefined);
    const [styles, api] = useSpring(() => ({  height:"100%",position:"relative", width: "auto",left:"0%" }));

    const PRIMARY_COLOR = getComputedStyle(
        document.documentElement
      ).getPropertyValue("--bs-primary-rgb");

      useLayoutEffect(() => {
        if (!containerRef.current) {
          return;
        }
        
        /* adding +1 to fix that decimal points is not handled */
        const setCurrentWidth = ()=>{
        const width = containerRef.current!.clientWidth+1;
        setWidth(width);
        }
        setCurrentWidth()

        const el = containerRef.current;
        const resizeObserver = new ResizeObserver(setCurrentWidth)
        resizeObserver.observe(el)
        containerRef.current.addEventListener("resize",setCurrentWidth)
        //@ts-ignore
        containerRef.current.inert=true;
        return ()=>{
        resizeObserver.unobserve(el)
          containerRef.current?.removeEventListener("resize",setCurrentWidth)
        }

      }, [containerRef,setWidth]);
    
      useEffect(() => {
        if (!width) {
          return;
        }
        //@ts-ignore
        api.start({width: expanded ? width : 70,left:expanded ? "0%" : "100%" });
      }, [width,expanded]);

    return (

        <div
        style={{ height: "100%", position: "relative" }}
      >
      <div onClick={()=>setExpanded(true)} style={{ position: "absolute", top: "0%", right: "0%", margin: 20,color:`rgb(${PRIMARY_COLOR})`,cursor:"pointer" }}>
        <GearIcon
        
        //   fill={}
          style={{ top: 0, right: 0, position: "absolute" }}
          width="50"
          height="50"
        />
      </div>
      
      <div style={{position:"absolute",width:1200,height:"100%",opacity:0,pointerEvents:"none"}}>
      <div
        ref={containerRef}
        style={{
          height: "100%",
          position: "absolute",
        }}
      >
      <ChartControllsDesktopContent setExpanded={setExpanded}/></div>
      </div>

      {/* @ts-ignore */}
      <animated.div style={styles}>
          <div style={{width:!width ? "auto" : width,height:"100%"}}>
      <ChartControllsDesktopContent setExpanded={setExpanded}/>
      </div>
      </animated.div>
        </div>
    )
}

export default ChartControllsSideDesktop;