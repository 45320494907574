import { useCallback, useMemo } from "react";
import { useTranslate } from "react-translate";
import { AllDataTypes } from "../../charts/models/chart-description.model";
import {
  usePdfGeneratorDispatch,
  usePdfGeneratorSelector,
} from "./PdfGenerator";
import { PutRequestedDevice } from "../store/putRequestedDevice";
import { DateTime } from "luxon";
import { UpdateTimeScaleState } from "../store/updateTimeScaleState";
import { SetRequestedDataType } from "../store/setRequestedDataType";
import { getTimeRange } from "../../charts/chart-generating/utils/getTimeRange";
import { getContainer } from "../../app-container";
import { PdfGeneratorService } from "../pdf-generator.service";
import { useDataProviderSelector } from "../../data-provider/data-provider.provider";
import { getDeviceDisplayGroupId } from "../../devices/utils/getDeviceDisplayGroup";
import { getDevicesInGroup } from "../../devices/utils/getDevicesInGroup";
import { isUndefined } from "lodash";
import { DevicesService } from "../../devices/devices.service";
import alarms from "../../../translations/pl/alarms";
import { SetRequestedAlarm } from "../store/setRequestedAlarm";

const PdfGeneratorStandard: React.FC = () => {
  const t = useTranslate("pdf-generator");
  const tChart = useTranslate("chart");
  const tDevices = useTranslate("devices");

  const downloadable = usePdfGeneratorSelector((state) => state.downloadable);

  const requestedAlarms = usePdfGeneratorSelector(
    (state) => state.requestedAlarms
  );
  const requestedDataTypes = usePdfGeneratorSelector(
    (state) => state.requestedDataTypes
  );
  const requestedDevice = usePdfGeneratorSelector(
    (state) => state.requestedDevice
  );
  const timeScaleState = usePdfGeneratorSelector(
    (state) => state.timeScaleState
  );
  const { endTimestamp_s, startTimestamp_s } = timeScaleState;

  const dispatch = usePdfGeneratorDispatch();

  const devices = useDataProviderSelector((state) => state.devices);
  const alarms = useDataProviderSelector((state) => state.alarms);
  const groups = useDataProviderSelector((state) => state.deviceGroups);

  const requestedDevicesAlarms = useMemo(()=>{
    const device = devices.find(dev=>dev.device._id===requestedDevice);
    if(!device){
      return []
    }
    return getContainer().resolve<DevicesService>("devices").getDeviceAlarms(device.device,alarms,groups)
  },[devices,alarms,requestedDevice,alarms,groups])

  const displayGroups = useMemo(()=>getContainer().resolve<DevicesService>("devices").getDeviceGroups(groups,devices),[devices,groups])

  const selectedGroup = useMemo(
    ()  => {
      const devicesService = getContainer().resolve<DevicesService>("devices");
      return devicesService
        .getDeviceGroups(groups, devices)
        .find((displayGroup) =>
          devicesService.getGroupsDevices(
            groups,
            devices,
            displayGroup === false ? displayGroup : displayGroup._id
          ).map(dev=>dev.device._id).includes(requestedDevice||"")
        );
    },
    [requestedDevice, groups, devices]
  );
  const devicesInGroup = useMemo(
    () => isUndefined(selectedGroup) ? [] : getContainer().resolve<DevicesService>("devices").getGroupsDevices(groups,devices,selectedGroup===false ? false : selectedGroup._id),
    [devices, selectedGroup]
  );

  const handleDownloadPdf = useCallback(() => {
    if (isUndefined(requestedDevice)) {
      console.warn("Pdf is not downloadable");
      return;
    }

    getContainer().resolve<PdfGeneratorService>("pdf-generator").downloadPdf({
      requestedAlarms,
      requestedDataTypes,
      timeScaleState,
      requestedDevice,
    });
  }, [requestedDevice, requestedDataTypes, timeScaleState, requestedAlarms]);

  return (
    <>
      <div className="mb-3">
        <label htmlFor="formGroupExampleInput" className="form-label">
          {t("FIELD_GROUP")}
        </label>
        <select
          value={!isUndefined(selectedGroup) ? selectedGroup===false ? "false" : selectedGroup._id : "NONE"}
          onChange={(e) => {
            const groupId = e.target.value;
            const devicesInGroup = getContainer().resolve<DevicesService>("devices").getGroupsDevices(groups,devices,groupId==="false" ? false : groupId);

            dispatch(
              PutRequestedDevice.callAction({
                requestedDevice: devicesInGroup[0].device._id,
              })
            );
          }}
          className="form-select mb-3"
        >
          {displayGroups.map((group) => (
            <option key={group===false ? "false" : group._id} value={group===false ? "false" : group._id}>
              {group===false ? tDevices("REST_OPTION") : group.name}
            </option>
          ))}
        </select>
      </div>
      <div className="mb-3">
        <label htmlFor="formGroupExampleInput" className="form-label">
          {t("FIELD_DEVICE")}
        </label>
        <select
          value={!isUndefined(requestedDevice) ? requestedDevice : "NONE"}
          onChange={(e) =>
            dispatch(
              PutRequestedDevice.callAction({
                requestedDevice: e.target.value,
              })
            )
          }
          className="form-select mb-3"
        >
          {devicesInGroup.map(({ device }) => {
            const { _id, name } = device;
            return (
              <option key={_id} value={_id}>
                {name}
              </option>
            );
          })}
        </select>
      </div>

      <div className="mb-3">
        <label htmlFor="formGroupExampleInput" className="form-label">
          {t("FIELD_SINCE")}
        </label>
        <input
          value={DateTime.fromSeconds(startTimestamp_s).toFormat("yyyy-MM-dd")}
          onChange={(e) => {
            const timestamp_s = DateTime.fromFormat(
              e.target.value,
              "yyyy-MM-dd"
            ).toSeconds();
            const newTimeScaleState = getTimeRange(
              "CUSTOM",
              timestamp_s,
              "START",
              timeScaleState
            );
            dispatch(
              UpdateTimeScaleState.callAction({
                timeScaleState: newTimeScaleState,
              })
            );
          }}
          className="form-control"
          type="date"
          placeholder="Default input"
        />
      </div>

      <div className="mb-3">
        <label htmlFor="formGroupExampleInput" className="form-label">
          {t("FIELD_UNTIL")}
        </label>
        <input
          value={DateTime.fromSeconds(endTimestamp_s - 1).toFormat(
            "yyyy-MM-dd"
          )}
          onChange={(e) => {
            const timestamp_s = DateTime.fromFormat(
              e.target.value,
              "yyyy-MM-dd"
            ).toSeconds();

            const newTimeScaleState = getTimeRange(
              "CUSTOM",
              timestamp_s,
              "END",
              timeScaleState
            );

            dispatch(
              UpdateTimeScaleState.callAction({
                timeScaleState: newTimeScaleState,
              })
            );
          }}
          className="form-control"
          type="date"
          placeholder="Default input"
        />
      </div>

      <div className="mb-3">
        <label htmlFor="formGroupExampleInput" className="form-label">
          {t("FIELD_DATA_TYPES")}
        </label>

        {AllDataTypes.map((dataType) => {
          const requested = requestedDataTypes.includes(dataType);
          return (
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={requested}
                onChange={() =>
                  dispatch(
                    SetRequestedDataType.callAction({
                      dataType,
                      requested: !requested,
                    })
                  )
                }
                id="defaultCheck1"
              />
              <label className="form-check-label" htmlFor="defaultCheck1">
                {tChart(`${dataType}_LABEL`)}
              </label>
            </div>
          );
        })}
        
        <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={requestedDataTypes.includes("CO2+ATMOSPHERIC_PRESSURE")}
                onChange={() =>
                  dispatch(
                    SetRequestedDataType.callAction({
                      dataType:"CO2+ATMOSPHERIC_PRESSURE",
                      requested: !requestedDataTypes.includes("CO2+ATMOSPHERIC_PRESSURE"),
                    })
                  )
                }
                id="defaultCheck1"
              />
              <label className="form-check-label" htmlFor="defaultCheck1">
                {tChart(`CO2+ATMOSPHERIC_PRESSURE_LABEL`)}
              </label>
            </div>
      </div>
      
      <div className="mb-3">
        <label htmlFor="formGroupExampleInput" className="form-label">
          {t("FIELD_AESTATISTICS")}
        </label>
</div>

<div className="d-flex">
  <div>
<table className="table table-borderless">
  <thead>
    <tr>
      <td scope="col"></td>
      <td style={{textAlign:"center"}} scope="col">{t("FIELD_SHOW_IN_TABLE")}</td>
      <td style={{textAlign:"center"}} scope="col">{t("FIELD_SHOW_ON_CHARTS")}</td>
      <td style={{textAlign:"center"}} scope="col">{t("FIELD_SHOW_IN_STATS_SECTION")}</td>
    </tr>
  </thead>
  <tbody>
    {
      requestedDevicesAlarms.map(alarm=>{
        const tableChecked = !!requestedAlarms[alarm.name]?.table
        const chartChecked = !!requestedAlarms[alarm.name]?.chart
        const aesChecked = !!requestedAlarms[alarm.name]?.aeStatistics
        
        return (
        <tr>
        <td scope="row">{alarm.name}</td>
        <td style={{textAlign:"center"}}>
                <input
                checked={tableChecked}
                onClick={()=>dispatch(SetRequestedAlarm.callAction({alarmName:alarm.name,pdfAlarmRequestChanges:{table:!tableChecked}}))}
                  className="form-check-input"
                  type="checkbox"
                />
                </td>
        <td style={{textAlign:"center"}}>
                <input
                checked={chartChecked}
                onClick={()=>dispatch(SetRequestedAlarm.callAction({alarmName:alarm.name,pdfAlarmRequestChanges:{chart:!chartChecked}}))}

                  className="form-check-input"
                  type="checkbox"
                />
                </td>
        <td style={{textAlign:"center"}}>
                <input
                checked={aesChecked}
                onClick={()=>dispatch(SetRequestedAlarm.callAction({alarmName:alarm.name,pdfAlarmRequestChanges:{aeStatistics:!aesChecked}}))}

                  className="form-check-input"
                  type="checkbox"
                />
                </td>
      </tr>
      )})
    }
  </tbody>
</table>
</div>
</div>



      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <button
          type="button"
          disabled={!downloadable}
          className="btn btn-primary mt-3 me-1"
          onClick={handleDownloadPdf}
        >
          {t("DOWNLOAD_REPORT")}
        </button>
      </div>
    </>
  );
};

export default PdfGeneratorStandard;
