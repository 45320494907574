import { injectable, inject } from "tsyringe";
import type { DependencyContainer } from "tsyringe";
import { ChartToImageService } from "./submodules/chart-to-image/chart-to-image.service";
import { JspdfCreatorService } from "./submodules/jspdf-creator/jspdf-creator.service";
import { PdfItemsGeneratorService } from "./submodules/pdf-items-generator/pdf-items-generator.service";
import { createPdfGeneratorContainer } from "./pdf-generator.container";
import { PdfRequestsDownloadable } from "./models/pdf-requests.model";
import { DataProviderService } from "../data-provider/data-provider.service";
import { ChartsService } from "../charts/charts.service";
import { getCorrectGroupingsForTimeRange } from "../charts/chart-generating/utils/getCorrectGroupingsForTimeRange";
import { sumMeasuresDatasets } from "../charts/data-parsers/sum-measures-datasets";
import { ChartData } from "../charts/models/chart-data";
import { TimeScaleState } from "../charts/models/time-scale-state";
import { DateTime } from "luxon";

@injectable()
export class PdfGeneratorService {
  private pdfGeneratorContainer;
  private chartToImageService: ChartToImageService;
  private pdfCreatorService: JspdfCreatorService;
  private pdfItemsGeneratorService: PdfItemsGeneratorService;

  constructor(
    @inject("depedency-container")
    container: DependencyContainer,
    @inject("charts")
    private chartsService: ChartsService,
    @inject("data-provider")
    private dataProviderService: DataProviderService
  ) {
    this.pdfGeneratorContainer = createPdfGeneratorContainer(container);
    this.chartToImageService =
      this.pdfGeneratorContainer.resolve("chart-to-image");
    this.pdfCreatorService = this.pdfGeneratorContainer.resolve("pdf-creator");
    this.pdfItemsGeneratorService = this.pdfGeneratorContainer.resolve(
      "pdf-items-generator"
    );
  }

  getChartToImageService(){
    return this.chartToImageService;
  }

  getDefaultReportsTimeScaleState(): TimeScaleState {
    const dataTimeNow = DateTime.now();

    const { year, month } = DateTime.now().toObject();

    const startTimestamp_s = DateTime.fromFormat(
      `${month} ${year}`,
      "M yyyy"
    ).toSeconds();
    const endTimestamp_s = DateTime.fromFormat(
      `${month + 1} ${year}`,
      "M yyyy"
    ).toSeconds();
    return {endTimestamp_s,startTimestamp_s};
  }

  async downloadPdf(pdfRequests: PdfRequestsDownloadable,name?:string) {
    const chartDataH = await this.chartsService
      .getChartDataProviderService()
      .getChartData(
        [pdfRequests.requestedDevice],
        pdfRequests.timeScaleState,
        getCorrectGroupingsForTimeRange(pdfRequests.timeScaleState)[0]
      );

    const chartDataD = await this.chartsService
      .getChartDataProviderService()
      .getChartData(
        [pdfRequests.requestedDevice],
        pdfRequests.timeScaleState,
        "DAILY"
      );

    const datasetsSum = sumMeasuresDatasets([
      chartDataH.measuresDatasetsSetup,
      chartDataD.measuresDatasetsSetup,
    ]);
    const chartData: ChartData = {
      alarmsDatasetsSetup: chartDataH.alarmsDatasetsSetup,
      dataTypesIncluded: chartDataH.dataTypesIncluded,
      measuresDatasetsSetup: datasetsSum,
    };

    if(!name){
      const devices = this.dataProviderService.getStore().getState().devices;
      name = `${devices.find(dev=>dev.device._id===pdfRequests.requestedDevice)?.device.name}-raport`
    }

    const items = await this.pdfItemsGeneratorService.getChartItems(
      chartData,
      pdfRequests
    );
    this.pdfCreatorService.downloadDocument(items,{name});
  }
}
