import { Alarm, Device, DeviceGroup, Measure, Notification } from "shared";
import {
  ReducerActionClass,
  ReducerCollection,
} from "../../../utils/store/core";
import { PutData } from "./putData";

export type OrganisationViewData = {
devices: Array<{device:Device,lastMeasure?:Measure}>;
deviceGroups: Array<DeviceGroup>;
alarms:Array<Alarm>,
notifications:Array<Notification>
}

export type DataProviderState = OrganisationViewData & {dataLoaded:boolean,orgId:string|null};

export class DataProviderReducer extends ReducerCollection<DataProviderState> {
  static initialState: DataProviderState = {
    dataLoaded:false,
    orgId:null,
    deviceGroups: [],
    devices: [],
    alarms:[],
    notifications:[]
  };

  protected initState(): DataProviderState {
    return DataProviderReducer.initialState;
  }

  protected getActionClassObjects(): ReducerActionClass<
    DataProviderState,
    any
  >[] {
    return [PutData];
  }
}
