import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
// import { Location } from "history";
import queryString from "query-string";
import Semaphore from "semaphore-async-await";
import { createBrowserHistory, Location } from "history";
import { getConstants } from "../../../config/constants";

const lock = new Semaphore(1);

export const readParamsValue = (history: any) => {
  const urlSearchParams = new URLSearchParams(history.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return params;
};

export const getURIWithSearchParamsValue = (
  location: Location<unknown>,
  paramName: string,
  chartRequestsJson?: string,
) => {
  const params = queryString.parse(location.search);
  if (chartRequestsJson) {
    params[paramName] = chartRequestsJson;
  } else if (paramName in params) {
    delete params[paramName];
  }
  if (Object.keys(params).length === 0) {
    return location.pathname;
  }

  const stringified = queryString.stringify(params);


  const pathname = location.pathname.split("/").reduce((uri:string,curr)=>{
    if(curr===""){
      return uri
    }
    if(getConstants().SUBDIR&&curr===getConstants().SUBDIR_NAME){
      return uri
    }
    return `${uri}/${curr}`
  },"")

  return `${pathname}?${stringified}`;
};

export const useSaveStateInSearchParams = <S>(
  defaultValue: S,
  paramName: string,
  dataLoaded:boolean=false
) => {
  const history = useHistory();
  const location = useLocation();

  const [requestedValSP, setRequestedValSP] = useState<string>(
    readParamsValue(history)[paramName]
  );
  const [valueIndex, setChartDataIndex] = useState<number>(0);

  const handleValueChange = useCallback(
    (value: S) => {
      const run = async () => {
        await lock.acquire();
        const chartRequestsJson = JSON.stringify(value);
        setRequestedValSP(chartRequestsJson);

        const newPathname = getURIWithSearchParamsValue(
          createBrowserHistory().location,
          paramName,
          chartRequestsJson
        );

        if (window.location.pathname === newPathname) {
          return;
        }

        history.replace(newPathname);
        lock.release();
      };
      run();
    },
    [history, setRequestedValSP, location, paramName]
  );

  /* this effect was added to handle a situation when search params was changed not by app components (for example pressing browser back button)
      in that case component should rerender chart to keep it compatible with search params (changing chart data index triger chart rerender)
    */
  useEffect(() => {
    const newValSP = readParamsValue(history)[paramName];
    // console.log
    if (!isEqual(requestedValSP, newValSP)) {
      setRequestedValSP(newValSP);
      setChartDataIndex((curr) => curr + 1);
    }
  }, [
    location,
    requestedValSP,
    setChartDataIndex,
    setRequestedValSP,
    paramName,
  ]);

  const initialValue = useMemo<S>(() => {
    const valSP = readParamsValue(history)[paramName];

    let chartRequests: S | undefined;
    try {
      chartRequests = JSON.parse(valSP);
    } catch (e) {
      chartRequests = undefined;
    }
    return chartRequests || defaultValue;
  }, [valueIndex]);


  useEffect(()=>{
    if(!dataLoaded){
      return
    }
    setChartDataIndex(val=>val+1)
  },[dataLoaded])

  return {
    handleValueChange,
    initialValue,
    valueIndex
  };
};
