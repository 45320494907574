import { DataType, MeasurePossibleValue, MeasureValue } from "../../charts/models/chart-description.model";
import { dataTypesImportance } from "../../charts/chart-generating/utils/getGridVisibility";


export const sortMeasureDataTypes = <T extends MeasurePossibleValue | MeasureValue>(arr:Array<T>):Array<T>=>{
    const sortedArray = [...arr];
    return sortedArray.sort((mvA,mvB)=>dataTypesImportance.findIndex(dt=>dt===mvA.dataType)-dataTypesImportance.findIndex(dt=>dt===mvB.dataType))
}

export const sortDataTypes = (arr:Array<DataType>):Array<DataType>=>{
    const sortedArray = [...arr];
    return sortedArray.sort((dtA,dtB)=>dataTypesImportance.findIndex(dt=>dt===dtA)-dataTypesImportance.findIndex(dt=>dt===dtB))

}