import { useTranslate } from "react-translate";
import { ReactComponent as PasswordIcon } from "bootstrap-icons/icons/key-fill.svg";
import { ReactComponent as EditIcon } from "bootstrap-icons/icons/pencil-fill.svg";
import { ReactComponent as DeleteIcon } from "bootstrap-icons/icons/trash-fill.svg";
import { ReactComponent as AddIcon } from "bootstrap-icons/icons/plus-square-fill.svg";
import { Tooltip } from "bootstrap";
import { useLayoutEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import EditDeviceGroupModal from "../../modals/DeviceGroupsTab/EditDeviceGroupModal";
import DeleteDeviceGroupModal from "../../modals/DeviceGroupsTab/DeleteDeviceGroupModal";
import CreateDeviceGroupModal from "../../modals/DeviceGroupsTab/CreateDeviceGroupModal";
import { useModPanelData } from "../../hooks/useModPanelData";

const DeviceGroupsTab: React.FC<{organisationId:string}> = ({organisationId}) => {
  const t = useTranslate("mod");

  const primaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-primary-rgb");

  const dangerColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-danger-rgb");

  const {deviceGroups,devices} = useModPanelData(organisationId)

  const mainDeviceGroups = useMemo(()=>deviceGroups.filter(dgroup=>dgroup.display),[deviceGroups]);

  const columns = useMemo(
    () => [
      {
        Header: t("DGROUPS_COLUMN_NAME"),
        accessor: "name",
      },
      {
        Header: t("DGROUPS_COLUMN_DEVICE_NUMBER"),
        accessor: "device_number",
      },
    ],
    [t]
  );

  const data = useMemo(
    () =>
    mainDeviceGroups.map(dgroup=>({
      _id:dgroup._id,
        name:dgroup.name,
        device_number:devices.filter(dev=>dev.device.groupIds.includes(dgroup._id)).length
      })),
    [mainDeviceGroups,devices]
  );


  useLayoutEffect(() => {
    const tooltipElements = Array.from(
      document.querySelectorAll(".DGroupsTabTooltip")
    );
    const tooltips = tooltipElements.map((el) => new Tooltip(el));
    return () => tooltips.forEach((tooltip) => tooltip.dispose());
  }, [data]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      //@ts-ignore
      columns,
      data,
    });

    const [editingGroup,setEditingGroup] = useState<string|null>(null)
    const [deletingGroup,setDeletingGroup] = useState<string|null>(null)
    const [creatingGroup,setCreatingGroup] = useState<boolean>(false)

  return (
    <>
      <h4>{t("TAB_device_groups")}</h4>
      <p>{t("DGROUPS_PARAGRAPH")}</p>
      <div style={{ maxWidth: 1100 }}>
        <table {...getTableProps()} className="table table-striped mb-2">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
                <th />
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                  <td className="ps-4 pe-0" style={{ width: "1%" }}>
                    <div className="d-flex justify-content-end">
                      <div className="me-3">
                        <div
                          className="DGroupsTabTooltip"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={t("DGROUP_TOOLTIP_EDIT")}
                        >
                          <a
                            className="text-decoration-none text-reset"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setEditingGroup(row.original._id)
                            }}
                          >
                            <EditIcon style={{ cursor: "pointer" }} />
                          </a>
                        </div>
                      </div>
                      <div className="me-4">
                        <div
                          className="DGroupsTabTooltip"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={t("DGROUP_TOOLTIP_DELETE")}
                        >
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setDeletingGroup(row.original._id)
                            }}
                            // data-bs-toggle="modal"
                            // data-bs-target="#configItemSms"
                          >
                            <DeleteIcon
                              fill={`rgb(${dangerColor})`}
                              style={{ cursor: "pointer" }}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="d-flex justify-content-end">
          <div className="pe-4" style={{ marginRight: -2 }}>
            <div
              className="DGroupsTabTooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={t("DGROUP_TOOLTIP_ADD")}
            >
              <a
                href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setCreatingGroup(true)
                  }}
              >
                <AddIcon
                  style={{ cursor: "pointer" }}
                  fill={`rgb(${primaryColor})`}
                  height="20"
                  width="20"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <EditDeviceGroupModal organisationId={organisationId} groupId={editingGroup} onClose={()=>setEditingGroup(null)} />
      <DeleteDeviceGroupModal organisationId={organisationId} groupId={deletingGroup} onClose={()=>setDeletingGroup(null)} />
      <CreateDeviceGroupModal organisationId={organisationId} show={creatingGroup} onClose={()=>setCreatingGroup(false)} />
    </>
  );
};

export default DeviceGroupsTab;
