import { isUndefined } from "lodash";
import React from "react";
import { useTranslate } from "react-translate";
import DefaultModal from "../../components/DefaultModal";

const DefaultFormModal: React.FC<{
  children: React.ReactNode;
  submitType?: "add" | "delete" | "edit";
  show: boolean;
  onClose: () => void;
  onSave: () => void;
  title: string;
  saveText?: string;
  saveDisabled?:boolean;
}> = ({
  children,
  onClose,
  show,
  title,
  saveText,
  onSave,
  submitType = "edit",
  saveDisabled
}) => {
  const t = useTranslate("forms");

  return (
    <DefaultModal show={show} onClose={onClose}>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">{children}</div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {t("MODAL_CLOSE")}
          </button>
          <button
          disabled={saveDisabled}
            type="button"
            onClick={onSave}
            className={`btn ${submitType === "delete" ? "btn-danger" : "btn-primary"}`}
          >
            {isUndefined(saveText)
              ? submitType === "edit"
                ? t("MODAL_SAVE")
                : submitType === "add"
                ? t("MODAL_CREATE")
                : t("MODAL_DELETE")
              : saveText}
          </button>
        </div>
      </div>
    </DefaultModal>
  );
};

export default DefaultFormModal;
