import { AlarmsState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";

type Payload = {
  page: number;
};

class SetPageClass extends ReducerActionClass<AlarmsState, Payload> {
  getActionName() {
    return "SET_PAGE";
  }
  handleDispatch(prevState: AlarmsState, payload: Payload): AlarmsState {
    const { page } = payload;

    return {
      ...prevState,
      page,
    };
  }
}

export const SetPage = new SetPageClass()
