import { useTranslate } from "react-translate";
import { ReactComponent as EditIcon } from "bootstrap-icons/icons/pencil-fill.svg";
import { ReactComponent as DeleteIcon } from "bootstrap-icons/icons/trash-fill.svg";
import { ReactComponent as AddIcon } from "bootstrap-icons/icons/plus-square-fill.svg";
import { Tooltip } from "bootstrap";
import { useLayoutEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import DeleteAlarmSetModal from "../../modals/AlarmSetsTab/DeleteAlarmSetModal";
import CreateAlarmSetModal from "../../modals/AlarmSetsTab/CreateAlarmSetModal";
import EditAlarmSetModal from "../../modals/AlarmSetsTab/EditAlarmSetModal";
import { isNull } from "lodash";
import { Device, DeviceGroup } from "shared";
import { useModDataSelector } from "../../../mod-data.provider";
import { useModPanelData } from "../../hooks/useModPanelData";

const AlarmSetsTab: React.FC<{ organisationId: string | null }> = ({organisationId}) => {
  const t = useTranslate("mod");

  const primaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-primary-rgb");

  const dangerColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-danger-rgb");

  const {deviceGroups,devices} = useModPanelData(organisationId)
  // const deviceGroups = useDataProviderSelector((state) => state.deviceGroups);
  // const devices = useDataProviderSelector((state) => state.devices);


  const alarmSets = useMemo(
    () => deviceGroups.filter((dgroup) => !dgroup.display),
    [deviceGroups]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("ASET_COLUMN_NAME"),
        accessor: "name",
      },{
        Header: t("ASET_COLUMN_GLOBAL"),
        accessor:"global",
        Cell: (obj: any) =>
          obj.value
            ? t("ASET_COLUMN_GLOBAL_YES")
            : t("ASET_COLUMN_GLOBAL_NO"),
      },
      {
        Header: t("ASET_COLUMN_ALARM_NUMBER"),
        accessor: "alarm_number",
      },
      {
        Header: t("ASET_COLUMN_DEVICE_NUMBER"),
        accessor: "device_number",
      },
    ],
    [t]
  );

    //todo: later replace devicegroups with alarmsets here

  const data = useMemo(
    () =>
    alarmSets.map((set) => ({
      _id:set._id,
        name: set.name,
        orgId:set.organisation,
        alarm_number:set.alarms.length,
        device_number:devices.filter(dev=>dev.device.groupIds.includes(set._id)).length,
        global:!set.organisation
      })),
    [devices,alarmSets]
  );

  useLayoutEffect(() => {
    const tooltipElements = Array.from(
      document.querySelectorAll(".AlarmSetsTabTooltip")
    );
    const tooltips = tooltipElements.map((el) => new Tooltip(el));
    return () => tooltips.forEach((tooltip) => tooltip.dispose());
  }, [data]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      //@ts-ignore
      columns,
      data,
    });

    const [editingGroup,setEditingGroup] = useState<string|null>(null)
    const [deletingGroup,setDeletingGroup] = useState<string|null>(null)
    const [creatingGroup,setCreatingGroup] = useState<boolean>(false)

  return (
    <>
      <h4>{ isNull(organisationId) ? t("TAB_alarm_sets_global") : t("TAB_alarm_sets")}</h4>
      <p>{isNull(organisationId) ? t("ASET_PARAGRAPH_GLOBAL") : t("ASET_PARAGRAPH")}</p>
      <div style={{ maxWidth: 1100 }}>
        <table {...getTableProps()} className="table table-striped mb-2">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
                <th />
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                  <td className="ps-4 pe-0" style={{ width: "1%" }}>
                  {!row.original.orgId&& !isNull(organisationId) ? null : (
                    <div className="d-flex justify-content-end">
                      <div className="me-3">
                        <div
                          className="AlarmSetsTabTooltip"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={t("ASET_TOOLTIP_EDIT")}
                        >
                          <a
                            className="text-decoration-none text-reset"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setEditingGroup(row.original._id)
                            }}
                          >
                            <EditIcon style={{ cursor: "pointer" }} />
                          </a>
                        </div>
                      </div>
                      <div className="me-4">
                        <div
                          className="AlarmSetsTabTooltip"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={t("ASET_TOOLTIP_DELETE")}
                        >
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setDeletingGroup(row.original._id)
                            }}
                          >
                            <DeleteIcon
                              fill={`rgb(${dangerColor})`}
                              style={{ cursor: "pointer" }}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="d-flex justify-content-end">
          <div className="pe-4" style={{ marginRight: -2 }}>
            <div
              className="AlarmSetsTabTooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={t("ASET_TOOLTIP_ADD")}
            >
              <a
                href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setCreatingGroup(true)
                  }}
              >
                <AddIcon
                  style={{ cursor: "pointer" }}
                  fill={`rgb(${primaryColor})`}
                  height="20"
                  width="20"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <EditAlarmSetModal organisationId={organisationId} groupId={editingGroup} onClose={()=>setEditingGroup(null)} />
      <DeleteAlarmSetModal organisationId={organisationId} groupId={deletingGroup} onClose={()=>setDeletingGroup(null)} />
      <CreateAlarmSetModal organisationId={organisationId} show={creatingGroup} onClose={()=>setCreatingGroup(false)} />
  
    </>
  );
};

export default AlarmSetsTab;
