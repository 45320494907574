import { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { EmailConfigDto, EmailItemDto } from "shared";
import ErrorDisplayFieldError from "../../../../../utils/forms/ErrorDisplayFieldError";
import { isNull } from "lodash";
import { useModPanelData } from "../../hooks/useModPanelData";
import { container } from "tsyringe";
import { SystemRequestsService } from "../../../request-services/system-requests.service";
import { OrganisationRequestsService } from "../../../request-services/organisation-requests.service";

const resolver = classValidatorResolver(EmailItemDto);

const EditEmailModal: React.FC<{
  organisationId: string | null;
  onClose: () => void;
  index: number | null;
}> = ({ onClose, organisationId, index }) => {
  const t = useTranslate("user");

  const { emailData } = useModPanelData(organisationId);
  const emailItem = useMemo(
    () => emailData?.items[index || 0],
    [emailData, index]
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm<EmailItemDto>({ resolver, mode: "onBlur" });

  const submitRef = useRef<HTMLInputElement>(null);
  const onSave = useCallback(() => {
    if (!submitRef.current) {
      return;
    }
    submitRef.current.click();
  }, [submitRef]);

  useEffect(() => {
    if (!emailItem) {
      return;
    }
    setValue("email", emailItem.email);
    setValue("comment", emailItem.comment);
    trigger();
  }, [emailItem]);

  return (
    <DefaultFormModal
      title={t("EMAIL_CONFIG_MODAL_HEADER_EDIT")}
      show={!isNull(index)}
      onClose={onClose}
      onSave={onSave}
      saveDisabled={!isValid}
    >
      <>
        {t("EMAIL_CONFIG_MODAL_PARAGRAPH_EDIT_1")}
        <span className="fw-bold">{emailItem?.email}</span>
        {t("EMAIL_CONFIG_MODAL_PARAGRAPH_EDIT_2")}
      </>
      <form
        onSubmit={handleSubmit((data) => {
          const {comment,email} = data;
          const dto: EmailConfigDto = { items: [...emailData.items] };
          dto.items[index || 0] = {
            comment,
            email,
          };
      
      
          if (isNull(organisationId)) {
            container.resolve(SystemRequestsService).sendUpdateEmailConfig(dto).then(()=>{
            });
            onClose()

          } else {
            container.resolve(OrganisationRequestsService).sendUpdateEmailConfig(dto,organisationId).then(()=>{
              onClose()

            });
            
        }})}
      >
        <input ref={submitRef} hidden type="submit" />
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <label htmlFor="edit_email_email" className="col-form-label">
                {t("EMAIL_CONFIG_TABLE_EMAIL")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="edit_email_email"
                {...register("email")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.email} />
        </div>
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <label htmlFor="edit_email_comment" className="col-form-label">
                {t("EMAIL_CONFIG_TABLE_COMMENT")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="edit_email_comment"
                {...register("comment")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.comment} />
        </div>
      </form>
    </DefaultFormModal>
  );
};

export default EditEmailModal;
