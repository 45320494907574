import { isNull } from "lodash";
import { ChartState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";
import { DataType } from "../models/chart-description.model";
import { TotalRequestedDataTypes } from "../models/data-type-request.model";
import { calculateTotalRequestedDataTypesFromState } from "./utils/calculateTotalRequestedDataTypes";

type Payload = {
  dataType: DataType;
  requested: boolean;
};

class SetRequestedDataTypeClass extends ReducerActionClass<
  ChartState,
  Payload
> {
  getActionName() {
    return "SET_REQUESTED_DATA_TYPE";
  }

  handleDispatch(prevState: ChartState, payload: Payload) {
    const { dataType, requested } = payload;
    const { requestedDataTypes: prevRequestedDataTypes } = prevState;

    const requestedDataTypes = [...prevRequestedDataTypes];

    if (requested) {
      //add data type if is not included
      if (!requestedDataTypes.find((dt) => dt === dataType)) {
        requestedDataTypes.push(dataType);
      }

    } else {
      //remove data type if is included
      const index = requestedDataTypes.findIndex((dt) => dt === dataType);
      if (index !== -1) {
        requestedDataTypes.splice(index, 1);
      }
    }

    const state: ChartState = {
      ...prevState,
      requestedDataTypes: requestedDataTypes,
    };

    const totalRequestedDataTypes: TotalRequestedDataTypes | null = calculateTotalRequestedDataTypesFromState(state);
    if (!isNull(totalRequestedDataTypes)) {
      state.totalRequestedDataTypes = totalRequestedDataTypes;
    }

    return state;
  }
}

export const SetRequestedDataType = new SetRequestedDataTypeClass();
