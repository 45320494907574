import React from "react";
import Router from "./Router";
import { NavbarMobileNoFollow } from "./menu/NavbarMobile";
import SideMenuDesktop from "./menu/SideMenuDesktop";
import "./skeleton.scss"
import SkipNavigationButton from "./SkipNavigationButton";
import { useAuthSelector } from "../modules/auth/auth.provider";
import LoginView from "../modules/auth/components/LoginView";
import {getContainer} from "../modules/app-container"
import { AuthService } from "../modules/auth/auth.service";
import { useDataProviderSelector } from "../modules/data-provider/data-provider.provider";

const Skeleton: React.FC = () => {

  const loggedIn = useAuthSelector(state=>state.loggedIn);
  const dataLoaded = useDataProviderSelector(state=>state.dataLoaded)

  if(!(loggedIn&&dataLoaded)){
    return(
      <LoginView/>
    )
  }

  return (
    <>
    <SkipNavigationButton/>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        position: "absolute",
        flexWrap: "wrap",
      }}
    >
      <div
     style={{ display: "flex", alignItems: "stretch", flexGrow: 1 }}>
        <div className="d-none d-lg-block">
          <SideMenuDesktop />
        </div>
        <div style={{ flexGrow: 1, position: "relative" }}>
      <div className="d-lg-none">
        <NavbarMobileNoFollow />
      </div>
            <Router />
        </div>
      </div>
    </div>
    </>
  );
};

export default Skeleton;
