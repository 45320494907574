import { callForAllAesUnits, DevicesTableColumnName } from "./DevicesTable";
import { ReactComponent as ColumnSelectIcon } from "bootstrap-icons/icons/eye-fill.svg";
import { useTranslate } from "react-translate";
import {
  AllDataTypes,
  DataType,
} from "../../../charts/models/chart-description.model";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AESTimeRange, AlarmType } from "shared";

type Props = {
  requestedColumns: Array<DevicesTableColumnName>;
  setRequestedColumns: React.Dispatch<
    React.SetStateAction<Array<DevicesTableColumnName>>
  >;
};

const DevicesTableColumnSelect: React.FC<Props> = ({
  requestedColumns,
  setRequestedColumns,
}) => {
  const t = useTranslate("devices");
  const tCharts = useTranslate("chart");

  const aesColumns = useMemo(() => {
    const columns: Array<{
      columnName: DevicesTableColumnName;
      timeRange: AESTimeRange;
      alarmType: AlarmType;
      dataType: DataType;
    }> = [];
    callForAllAesUnits((timeRange, alarmType, dataType) => {
      const columnName =
        `aes-${timeRange}-${alarmType}-${dataType}` as DevicesTableColumnName;
      columns.push({ columnName, timeRange, alarmType, dataType });
    });
    return columns;
  }, []);

  const [newRequestedColumns,setNewRequestedColumns] = useState<Array<DevicesTableColumnName>>([])

  const toggleNewRequestedColumns = useCallback((columnName:DevicesTableColumnName)=>{
    setNewRequestedColumns(curr=>{
      const enabled = curr.includes(columnName);
      if(enabled){
        return [...curr].filter(cName=>cName!==columnName)

      }else{
        const newVal = [...curr]
        newVal.push(columnName)
        return newVal
      }

    })
  },[setNewRequestedColumns])

  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(()=>{
    if(!modalRef.current){
      return
    }

    const resetColumns = ()=>{
      setNewRequestedColumns(requestedColumns)
    }

    modalRef.current.addEventListener("show.bs.modal",resetColumns)

    return ()=>{
      modalRef.current?.removeEventListener("show.bs.modal",resetColumns)
    }
  },[modalRef,requestedColumns])

  const saveColumns = useCallback (()=>{
    setRequestedColumns(newRequestedColumns)
  },[newRequestedColumns,setRequestedColumns])

  return (
    <>
      <div
      ref={modalRef}
        className="modal fade"
        id="devicesTableColumnSelectModal"
        tabIndex={-1}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("SELECT_COLUMNS_MODAL_HEADER")}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <>
                {(
                  [
                    "NAME",
                    "SERIAL_NUMBER",
                    "GROUP",
                    "LOCATION",
                    DevicesTableColumnName.CONNECTED,
                    DevicesTableColumnName.RSSI,
                    DevicesTableColumnName.LAST_MEASURE
                  ] as Array<DevicesTableColumnName>
                ).map((columnName, i) => (
                  <div key={i} className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={newRequestedColumns.includes(columnName)}
                      onChange={()=>toggleNewRequestedColumns(columnName)}
                      id={`devicesTableColumnSelect-0-${i}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`devicesTableColumnSelect-0-${i}`}
                    >
                      {t(`COLUMN_DEVICE_${columnName}`)}
                    </label>
                  </div>
                ))}
                {AllDataTypes.map((dataType, i) => (
                  <div key={i} className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      /* @ts-ignore */
                      checked={newRequestedColumns.includes(dataType)}
                      /* @ts-ignore */
                      onChange={()=>toggleNewRequestedColumns(dataType)}
                      id={`devicesTableColumnSelect-1-${i}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`devicesTableColumnSelect-1-${i}`}
                    >
                      {tCharts(`${dataType}_LABEL`)}
                    </label>
                  </div>
                ))}
                {aesColumns.map(({dataType,alarmType,timeRange,columnName},i) => (
                  <div key={i} className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={newRequestedColumns.includes(columnName)}
                      onChange={()=>toggleNewRequestedColumns(columnName)}
                      id={`devicesTableColumnSelect-2-${i}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`devicesTableColumnSelect-2-${i}`}
                    >
                      {`${tCharts(`${dataType}_LABEL`)} - ${t(
                        `EXCEEDED_${alarmType}_LEVEL`
                      )} ${t(`EXCEEDED_${timeRange}_RANGE`)}`}
                    </label>
                  </div>
                ))}
              </>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                {t("SELECT_COLUMNS_MODAL_CLOSE")}
              </button>
              <button type="button" data-bs-dismiss="modal" onClick={saveColumns} className="btn btn-primary">
              {t("SELECT_COLUMNS_MODAL_SAVE")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="m-3"
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <div className="bg-white mx-2">
          <button
            data-bs-toggle="modal"
            data-bs-target="#devicesTableColumnSelectModal"
            type="button"
            style={{ paddingLeft: 24.5, paddingRight: 24.5 }}
            className="btn btn-lg btn-outline-primary"
          >
            <ColumnSelectIcon width="25" height="25" />
          </button>
        </div>
      </div>
    </>
  );
};

export default DevicesTableColumnSelect;
