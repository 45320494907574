import { ReactComponent as EditIcon } from "bootstrap-icons/icons/pencil-fill.svg";
import { ReactComponent as DeleteIcon } from "bootstrap-icons/icons/trash-fill.svg";
import { ReactComponent as AddIcon } from "bootstrap-icons/icons/plus-square-fill.svg";

import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Tooltip } from "bootstrap";
import { useTranslate } from "react-translate";
import { useModDataSelector } from "../../../mod-data.provider";
import { isNull, parseInt } from "lodash";
import { SmsConfig } from "shared";
import { useModPanelData } from "../../hooks/useModPanelData";
import ConfigEmailModal, {
  ConfigEmailItemModalData,
} from "../../modals/EmailConfigTab/ConfigEmailModal";
import CreateEmailModal from "../../modals/EmailConfigTab/CreateEmailModal";
import EditEmailModal from "../../modals/EmailConfigTab/EditEmailModal";
import DeleteEmailModal from "../../modals/EmailConfigTab/DeleteEmailModal";

const MAX_EMAIL_NUMBER = 100;

const EmailConfigTab: React.FC<{ organisationID: string | null }> = ({
  organisationID: organisationId,
}) => {
  const { emailData } = useModPanelData(organisationId);

  const primaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-primary-rgb");

  const dangerColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-danger-rgb");

  // const [modalData, setModalData] = useState<ConfigEmailItemModalData>({
  //   mode: "CREATE",
  // });

  useLayoutEffect(() => {
    const tooltipElements = Array.from(
      document.querySelectorAll(".EmailConfigTooltip")
    );
    const tooltips = tooltipElements.map((el) => new Tooltip(el));
    return () => tooltips.forEach((tooltip) => tooltip.dispose());
  }, []);

  const t = useTranslate("user");

  const [editingEmail, setEditingEmail] = useState<number | null>(null);
  const [deletingEmail, setDeletingEmail] = useState<number | null>(null);
  const [creatingEmail, setCreatingEmail] = useState<boolean>(false);

  return (
    <>
      <h4>{t("EMAIL_CONFIG_HEADER")}</h4>
      <p className="ms-1">
        {t("EMAIL_CONFIG_PARAGRAPH", {
          maxNumber: MAX_EMAIL_NUMBER.toString(),
        })}
      </p>
      <div style={{ maxWidth: 1100 }}>
        <table className="table table-striped mb-2">
          <thead>
            <tr>
              <th
                style={{ width: "1%", whiteSpace: "nowrap" }}
                className="ps-4"
              >
                #
              </th>
              <th className="ps-4" style={{ whiteSpace: "nowrap" }}>
                {t("EMAIL_CONFIG_TABLE_EMAIL")}
              </th>
              <th className="ps-4">{t("EMAIL_CONFIG_TABLE_COMMENT")}</th>
              <th className="ps-4 pe-3" style={{ width: "1%" }}></th>
            </tr>
          </thead>
          <tbody>
            {emailData.items.map((item, i) => (
              <tr>
                <th
                  style={{ width: "1%", whiteSpace: "nowrap" }}
                  className="ps-4"
                >
                  {i + 1}
                </th>
                <td
                  className="ps-4"
                  style={{ width: "1%", whiteSpace: "nowrap" }}
                >
                  {item.email}
                </td>
                <td className="ps-4">{item.comment}</td>
                <td className="ps-4 pe-0" style={{ width: "1%" }}>
                  <div className="d-flex justify-content-end">
                    <div className="me-3">
                      <div
                        className="EmailConfigTooltip"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={t("EMAIL_CONFIG_MODAL_HEADER_EDIT")}
                      >
                        <a
                          className="text-decoration-none text-reset"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            // setModalData({
                            //   mode: "EDIT",
                            //   comment: item.comment,
                            //   email: item.email,
                            //   index: i,
                            // });
                            setEditingEmail(i);
                          }}
                          // data-bs-toggle="modal"
                          // data-bs-target="#configEmailModal"
                        >
                          <EditIcon style={{ cursor: "pointer" }} />
                        </a>
                      </div>
                    </div>
                    <div className="me-4">
                      <div
                        className="EmailConfigTooltip"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={t("EMAIL_CONFIG_MODAL_HEADER_DELETE")}
                      >
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            // setModalData({
                            //   mode: "DELETE",
                            //   comment: item.comment,
                            //   email: item.email,
                            //   index: i,
                            // });
                            setDeletingEmail(i);
                          }}
                          // data-bs-toggle="modal"
                          // data-bs-target="#configEmailModal"
                        >
                          <DeleteIcon
                            fill={`rgb(${dangerColor})`}
                            style={{ cursor: "pointer" }}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {emailData.items.length >= MAX_EMAIL_NUMBER ? null : (
          <div className="d-flex justify-content-end">
            <div className="pe-4" style={{ marginRight: -2 }}>
              <div
                className="EmailConfigTooltip"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={t("EMAIL_CONFIG_MODAL_HEADER_CREATE")}
              >
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    // setModalData({ mode: "CREATE" });
                    setCreatingEmail(true);
                  }}
                  // data-bs-toggle="modal"
                  // data-bs-target="#configEmailModal"
                >
                  <AddIcon
                    style={{ cursor: "pointer" }}
                    fill={`rgb(${primaryColor})`}
                    height="20"
                    width="20"
                  />
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <ConfigEmailModal data={modalData} organisationId={organisationId} emailConfig={emailData}/> */}
      <CreateEmailModal
        organisationId={organisationId}
        show={creatingEmail}
        onClose={() => setCreatingEmail(false)}
      />
      <EditEmailModal
        organisationId={organisationId}
        index={editingEmail}
        onClose={() => setEditingEmail(null)}
      />
      <DeleteEmailModal
        organisationId={organisationId}
        index={deletingEmail}
        onClose={() => setDeletingEmail(null)}
      />
    </>
  );
};

export default EmailConfigTab;
