import axios, { AxiosResponse } from "axios";
import { createStore, Store } from "redux";
import { LoginInfo } from "shared";
import { getConstants } from "../../config/constants";
// import { FULL_URL } from "../../config/server";
import {
  registerLoggedInListener,
  setLoginInfo,
} from "./auth.storage";
import { AuthReducer, AuthState } from "./store";
import { SetAccessToken } from "./store/setAccessToken";
import { SetAuthData } from "./store/setAuthData";
import { injectable } from "tsyringe";
import { DateTime } from "luxon";

const ACCESS_TOKEN_LIFETIME = 600;


@injectable()
export class AuthService {

  private store:Store<AuthState>;

  constructor(
  ) {
    const reducer = new AuthReducer();
    //@ts-ignore
    this.store = createStore(reducer.changeState.bind(reducer));
    this.setRefreshAccessTokenSubscriber();
    this.checkIfRememberedMe();
    this.registerLoginInfoChangeSubscribtion();
  }

  getStore(){
    return this.store
  }

  private setRefreshAccessTokenSubscriber() {
    let prevLoggedIn = false;

    let interval: ReturnType<typeof setInterval> | undefined;

    this.store.subscribe(async() => {
      const state = this.store.getState() as AuthState;
      let loggedIn = state.loggedIn;
      console.log(prevLoggedIn,loggedIn)
      
      if (loggedIn === prevLoggedIn) {
        return;
      }
      prevLoggedIn = loggedIn;
      console.log(prevLoggedIn,loggedIn)
      if (loggedIn) {

        if(!interval){
          console.log("token debug: interval enabled")
        interval = setInterval(
          ()=>{console.log("Token debug: Token update function started.",DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS));this.refreshAccessToken()},
          (ACCESS_TOKEN_LIFETIME / 2) * 1000
        );
        }
      } else {
        if (interval) {

          clearInterval(interval);
          interval = undefined;
        }
      }
    });
  }

  private async refreshAccessToken(){
    const accessToken = await this.sendRefreshAccessTokenRequest();
    this.store.dispatch(SetAccessToken.callAction({accessToken}))
  }

  private async checkIfRememberedMe() {
    const state = this.store.getState() as AuthState;
    if (!state.loggedIn) {
      //not remembered me
      return;
    }
    this.handleLogin(true);
  }

  private registerLoginInfoChangeSubscribtion() {
    registerLoggedInListener(
      (rememberMe) => this.handleLogin(rememberMe),
      () => this.handleLogout()
    );
  }

  async loginLocal(username: string, password: string, rememberMe: boolean) {
    const state = this.store.getState() as AuthState;
    const loggedIn = state.loggedIn;
    if (loggedIn) {
      throw Error("User already logged in");
    }

    const loginInfo = await this.sendLoginLocalRequest(
      username,
      password,
      rememberMe
    );

    setLoginInfo(loginInfo);
  }

  async logout() {
    await this.sendLogoutRequest();
    setLoginInfo();
  }

  private async handleLogin(rememberMe: boolean) {
    const accessToken = await this.sendRefreshAccessTokenRequest();
    this.store.dispatch(SetAuthData.callAction({loggedIn:true,accessToken,rememberMe}))
  }

  private async handleLogout() {
    this.store.dispatch(SetAuthData.callAction({loggedIn:false}))
  }

  async changePassword(
    password: string,
    newPassword: string,
    rememberMe: boolean
  ) {
    await this.sendChangePasswordRequest(password, newPassword, rememberMe);
    const accessToken = await this.sendRefreshAccessTokenRequest();
    this.store.dispatch(SetAuthData.callAction({loggedIn:true,accessToken,rememberMe}))
    return true;
  }

  private async sendLoginLocalRequest(
    username: string,
    password: string,
    rememberMe: boolean
  ): Promise<LoginInfo> {
    const response = await axios({
      method: "POST",
      url: `${getConstants().SERVER_URL}/auth/local/login`,
      data: {
        username,
        password,
        rememberMe,
      },
      withCredentials: true,
    })
    return (response as AxiosResponse).data;
  }

  private async sendRefreshAccessTokenRequest() {
    return (
      await axios.post(
        `${getConstants().SERVER_URL}/auth/local/get-access-token`,
        {},
        {
          withCredentials: true,
        }
      )
    ).data["access-token"] as string;
  }

  private async sendLogoutRequest() {
    await axios.post(
      `${getConstants().SERVER_URL}/auth/local/logout`,
      {},
      {
        withCredentials: true,
      }
    );
    return;
  }

  private async sendChangePasswordRequest(
    password: string,
    newPassword: string,
    rememberMe: boolean
  ) {
    await axios
      .post(
        `${getConstants().SERVER_URL}/auth/local/change-password`,
        {
          password,
          newPassword,
          rememberMe,
        },
        {
          withCredentials: true,
        }
      )
    return;
  }
}
