/* 
This file containt 2 hooks that help handle bootstrap 5.1 collapse toggle bug (which is that when specific other components (including tooltips) are 
initialized in js then collapse toggle via data attributes for some reason does not hide collapse when its expanded)

To fix that u need to use via js method. This 2 hooks help with that. U need to use useInitCollapseToggle to init collapses and callback useToggleCollapse
instead of data attributes

If there is list of collapse element to init good practise may be to give all elements same class and every element individual id (fore example in 
ScalesControlls component every collapse has class "scalesControlls" and unique id (scalesControlls_0,scalesControlls_1,scalesControlls_2...)).
This allows to use class selector when initializing all components (selector = ".scalesControlls") and id selector when toggling individual component
(selector = `#scalesControlls_${id}`)

*/

import { Collapse } from "bootstrap";
import { useCallback } from "react";

export const useInitCollapseToggle = (
  selector: string | Array<string>,
  options?: Partial<Collapse.Options>
) => {
  let elements: Array<Element>;
  if (Array.isArray(selector)) {
    elements = selector.reduce((arr: Array<Element>, curr) => {
      arr.push(...Array.from(document.querySelectorAll(curr)));
      return arr;
    }, []);
  } else {
    elements = Array.from(document.querySelectorAll(selector));
  }
  const collapses = Array.from(elements).map((el) => {
    return new Collapse(el, options);
  });

  return () => {
    collapses.forEach((cl) => cl.dispose());
  };
};

export const useToggleCollapse = () => {
  return useCallback((selector: string) => {
    const elements = document.querySelectorAll(selector);
    elements.forEach((el) => {
      const collapse = Collapse.getInstance(el);
      collapse?.toggle();
    });
  }, []);
};
