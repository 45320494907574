import axios from "axios";
import { isNull } from "lodash";
import { createStore, Store } from "redux";
import {
  Alarm,
  AllUserOrganisationRoles,
  Device,
  DeviceGroup,
  Measure,
  Notification,
  Organisation,
  System,
  User,
  UserRoles,
} from "shared";
import { delay, inject, injectable } from "tsyringe";
import { getConstants } from "../../config/constants";
import { UserState } from "../user/store";
import { UserService } from "../user/user.service";
import { ModDataReducer, ModDataState } from "./store";
import { PutSystemData } from "./store/putSystemData";
import { RemoveData } from "./store/removeData";
import { PutOrganisationData } from "./store/putOrganisationData";
import { DataProviderService } from "../data-provider/data-provider.service";
import { PutSelectedOrganisaion } from "./store/putSelectedOrganisation";

@injectable()
export class ModDataService {
  private store: Store<ModDataState>;

  //@ts-ignore
  constructor(@inject("user") private userService: UserService,@inject("data-provider") private dataProviderService:DataProviderService) {
    const reducer = new ModDataReducer();
    //@ts-ignore
    this.store = createStore(reducer.changeState.bind(reducer));
    this.subscribeToUser();
    this.subscribeToSelectedOrganisation()
  }

  getStore() {
    return this.store;
  }

  private subscribeToUser() {
    const userStore = this.userService.getStore();
    const selectUsername = (state: UserState) => state.user?.username || null;
    let prevUsername: string | null = null;

    const userCheck = () => {
      const userState = userStore.getState();
      const userName = selectUsername(userState);
      if (userName === prevUsername) {
        return;
      }

      prevUsername = userName;
      if (!isNull(userName)) {
        const userRoles = userState.user!.roles;
        this.checkIfUserIsAdmin(userRoles);
        this.checkIfUserIsModerator(userRoles);
      }else{
      this.removeData();
      }
    };

    userStore.subscribe(() => userCheck());
    userCheck();
  }

  private checkIfUserIsAdmin(userRoles: UserRoles) {
    userRoles.forEach((role) => {
      if (role.role !== "ADMIN") {
        return;
      }
      this.downloadSystemData().then(()=>{
        const organisations = this.store.getState().system?.organisations||[]
        if(!organisations[0]){
          return
        }
        this.store.dispatch(PutSelectedOrganisaion.callAction({selectedOrganisation:organisations[0].organisation._id}))
      });
    });
  }

  private checkIfUserIsModerator(userRoles: UserRoles) {
    userRoles.forEach((role) => {
      //@ts-ignore
      if (!AllUserOrganisationRoles.includes(role.role)) {
        return;
      }
      if (role.role === "USER") {
        return;
      }
      //@ts-ignore
      this.store.dispatch(PutSelectedOrganisaion.callAction({selectedOrganisation:role.target}))
    });
  }

  private subscribeToSelectedOrganisation(){
    let prevSelectedOrganisation:string|undefined = undefined;
    this.store.subscribe(()=>{
      const {selectedOrganisation} = this.store.getState()
      if(selectedOrganisation===prevSelectedOrganisation){
        return
      }
      prevSelectedOrganisation=selectedOrganisation;
      if(selectedOrganisation){
      this.downloadOrganisationData(selectedOrganisation,this.checkOrganisationAccess(selectedOrganisation)==="MODERATOR_ONLY");
      this.dataProviderService.downlaodData(selectedOrganisation)
      }
    })
  }

  async handleOrganisationDataChanges(id:string){
    await this.downloadOrganisationData(id,this.checkOrganisationAccess(id)==="MODERATOR_ONLY")

    const organisationDataId = this.dataProviderService.getStore().getState().orgId;
    if(isNull(organisationDataId)||organisationDataId!==id){
      return
    }
    await this.dataProviderService.downlaodData(id)
  }

  private checkOrganisationAccess(orgId:string){
      const userRoles = this.userService.getStore().getState().user?.roles||[]
    if(userRoles.find(role=>role.role==="MOD"&&role.target===orgId)){
      return "MODERATOR_ONLY"
    }
    return "FULL"

  }

  async handleSystemDataChanges(){
    await this.downloadSystemData()
    
    const organisationDataId = this.dataProviderService.getStore().getState().orgId;
    if(isNull(organisationDataId)){
      return
    }
    await this.dataProviderService.downlaodData(organisationDataId)
    await this.downloadOrganisationData(organisationDataId)
  }

  private async downloadOrganisationData(id: string,moderatorOnly:boolean=false) {
    const [{ alarms, devices, groups,notifications }, { organisation }, { users }] =
      await Promise.all([
        this.sendOrganisationViewDataRequest(id),
        this.sendOrganisationDataRequest(id),
        moderatorOnly ? {users:[]} : this.sendOrganisationUsersRequest(id),
      ]);

    this.store.dispatch(
      PutOrganisationData.callAction({
        organisationId: id,
        organisationData: {
          viewData: { alarms, devices, deviceGroups: groups,notifications },
          organisation,
          users,
        },
      })
    );
  }

  private async sendOrganisationViewDataRequest(orgId:string) {
    const response = (
      await axios({
        method: "get",
        url: `${getConstants().SERVER_URL}/devices/organisation-data/${orgId}`,
      })
    ).data as {
      devices: Array<{ device: Device; lastMeasure: Measure }>;
      groups: Array<DeviceGroup>;
      alarms: Array<Alarm>;
      notifications:Array<Notification>
    };
    return response;
  }

  private async sendOrganisationDataRequest(id: string) {
    const response = (
      await axios({
        method: "get",
        url: `${getConstants().SERVER_URL}/organisations/${id}`,
      })
    ).data as {
      organisation: Organisation;
    };
    return response;
  }

  private async sendOrganisationUsersRequest(id: string) {
    const response = (
      await axios({
        method: "get",
        url: `${getConstants().SERVER_URL}/users/organisations/${id}`,
      })
    ).data as {
      users: Array<User>;
    };
    return response;
  }

  private async downloadSystemData() {
    const [
      system,
      { organisations },
      { devices },
      { device_groups },
      { alarms },
      {notifications}
    ] = await Promise.all([
      this.sendSystemDataRequest(),
      this.sendOrganisationsDataRequest(),
      this.sendSystemDevicesDataRequest(),
      this.sendGlobalDeviceGroupsDataRequest(),
      this.sendGlobalAlarmsDataRequest(),
      this.sendNotificationsDataRequest()
    ]);

    this.store.dispatch(
      PutSystemData.callAction({
        systemData: { alarms, device_groups, devices, organisations, system,notifications },
      })
    );
  }

  private async removeData() {
    this.store.dispatch(RemoveData.callAction({}));
  }

  private async sendSystemDataRequest() {
    const response = (
      await axios({
        method: "get",
        url: `${getConstants().SERVER_URL}/system`,
      })
    ).data as System;
    return response;
  }

  private async sendOrganisationsDataRequest() {
    const response = (
      await axios({
        method: "get",
        url: `${getConstants().SERVER_URL}/organisations`,
      })
    ).data as {
      organisations: Array<{ organisation: Organisation; owner: User }>;
    };
    return response;
  }
  private async sendSystemDevicesDataRequest() {
    const response = (
      await axios({
        method: "get",
        url: `${getConstants().SERVER_URL}/devices/system`,
      })
    ).data as { devices: Array<Device> };
    return response;
  }
  private async sendGlobalDeviceGroupsDataRequest() {
    const response = (
      await axios({
        method: "get",
        url: `${getConstants().SERVER_URL}/device-groups/system`,
      })
    ).data as { device_groups: Array<DeviceGroup> };
    return response;
  }
  private async sendGlobalAlarmsDataRequest() {
    const response = (
      await axios({
        method: "get",
        url: `${getConstants().SERVER_URL}/alarms/system`,
      })
    ).data as { alarms: Array<Alarm> };
    return response;
  }

  private async sendNotificationsDataRequest(){

    const response = (
      await axios({
        method: "get",
        url: `${getConstants().SERVER_URL}/notifications/`,
      })
    ).data as { notifications: Array<Notification> };
    return response;
  }
}
