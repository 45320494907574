import { createContext } from "react";
import { createDispatchHook, createSelectorHook } from "react-redux";
import { Action, Dispatch } from "redux";
import { ChartState } from "../store";

export const MainChartContext = createContext({
    alarmsDatasetsConfig: {},
    measuresDatasetsConfig: {},
    dataTypesIncluded: [],
    requestedAlarms: [],
    requestedDataTypes: [],
    requestedDevices: {},
  });
  
  export const useMainChartDispatch: <AppDispatch extends Dispatch<
    Action<ChartState>
  > = Dispatch<Action<ChartState>>>() => AppDispatch = createDispatchHook(
    //@ts-ignore
    MainChartContext
  );
  //@ts-ignore
  export const useMainChartSelector: <S>(
    selector: (state: ChartState) => S
    //@ts-ignore
  ) => S = createSelectorHook(MainChartContext);