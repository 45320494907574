import {
  Chart,
  LinearScale,
  PointElement,
  LineElement,
  ScatterController,
  TimeScale,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import "chartjs-adapter-luxon";
import "hammerjs";
import ZoomPlugin from "chartjs-plugin-zoom";
import {
  createContext,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ChartGenerationData } from "../models/chart-description.model";
import { useChart } from "../hooks/useChart";
import devices from "../example-data/output.json";
import ChartControllsSideDesktop from "../chart-controlls/ChartControllsSideDesktop";
import ChartControllsMobile from "../chart-controlls/ChartControllsMobile";
import { Provider } from "react-redux";
import { createDispatchHook, createSelectorHook } from "react-redux";
import { ChartState } from "../store";
import { Dispatch } from "redux";
import TimeScaleConfig from "./TimeScaleConfig";
import { useTranslate } from "react-translate";
import TableView from "../table/TableView";
import "./MeasuresChart.scss";
import { getColorForDeviceGroup } from "../chart-generating/utils/getColor";
import { useDeviceRes } from "../hooks/useDeviceRes";
import { ChartRequests } from "../models/chart-requests.model";
import { ChartData } from "../models/chart-data";
import { exampleGenerationData } from "../example-data/example-generation-data";
import { useHistory, useLocation } from "react-router";
import {
  getStatsPageURLWithSearchParamsValue,
  readStatsPageSearchParamsValue,
  STATS_PAGE_PATH,
} from "../../../skeleton/pages/stats";
import { isEqual } from "lodash";
import { useSaveStateInSearchParams } from "./useSaveStateInSearchParams";
import { SwitchTransition, Transition } from "react-transition-group";
import { getStartOfTheDay } from "../chart-generating/utils/getStartOfTheDay";
import { DateTime } from "luxon";
import { MainChartContext } from "../hooks/chart.context";
import { getContainer } from "../../app-container";
import { ChartsService } from "../charts.service";
import { useDataProviderSelector } from "../../data-provider/data-provider.provider";

const ANIMATION_DURATION_MS = 150;

Chart.register(
  ScatterController,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Tooltip,
  ZoomPlugin,
  Filler,
  Legend
);

type Subpages = "TABLE" | "CHART";

const MeasuresChart: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const chartsService = getContainer().resolve<ChartsService>("charts");
  const {dataLoaded} = useDataProviderSelector(state=>state)
  const defaultChartRequests: ChartRequests = useMemo(
    () => chartsService.getDefaultChartRequest(true),
    [dataLoaded]
  );


  const {
    valueIndex: chartDataIndex,
    initialValue: initialChartRequests,
    handleValueChange: handleChartRequestsChange,
  } = useSaveStateInSearchParams(defaultChartRequests, "CHR");

  const [subpage, setSubpage] = useState<Subpages>("CHART");
  const [canvasLoaded, setCanvasLoaded] = useState<boolean>(false);

  const { store } = useChart(
    canvasRef,
    undefined,
    initialChartRequests,
    handleChartRequestsChange,
    chartDataIndex,
    undefined,
    canvasLoaded
  );

  const chartRes = useDeviceRes(992);

  const t = useTranslate("chart");

  const defaultAnimatedStyle = {
    transition: `opacity ${ANIMATION_DURATION_MS}ms linear`,
    opacity: 0,
    height: "100%",
    width: "100%",
  };
  const transitionAnimatedStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
    unmounted: { opacity: 0 },
  };

  return (
    <Provider context={MainChartContext as any} store={store}>
      <div
        style={{
          display: "flex",
          alignItems: "stretch",
          flexWrap: "wrap",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            flexShrink: 1,
            flexBasis: "auto",
            position: "relative",
            alignSelf: "stretch",
            // padding: 50,
          }}
        >
          <div
            className="ps-lg-5 pe-lg-5 pb-lg-5 stats-main-container"
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              // overflowY:"scroll"
            }}
          >
            <div
              style={{
                width: chartRes.width || "100%",
                height: chartRes.height ? Math.max(chartRes.height,500) : "100%",
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  width: "100%",
                  height: chartRes.height ? Math.max(chartRes.height,500) : "100%",
                }}
              >
                <div>
                  <div
                    className="mb-5 mt-3 mb-3 me-3 justify-content-center justify-content-lg-between"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          flexWrap: "wrap",
                        }}
                      >
                        <ul
                          className="nav nav-pills p-1 me-3"
                          style={{ display: "flex", flexWrap: "nowrap" }}
                        >
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                subpage === "CHART" ? "active" : ""
                              }`}
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setSubpage("CHART");
                              }}
                            >
                              {t("SUBPAGE_CHART")}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                subpage === "TABLE" ? "active" : ""
                              }`}
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                setSubpage("TABLE");
                              }}
                            >
                              {t("SUBPAGE_TABLE")}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      <TimeScaleConfig />
                    </div>
                  </div>
                </div>
                <div
                  style={{ flexGrow: 1, position: "relative", width: "100%"}}
                >
                  <SwitchTransition>
                    <Transition
                      onEntering={() => {
                        if (subpage === "CHART") {
                          setCanvasLoaded(true);
                        }
                      }}
                      onExited={() => {
                        if (subpage === "CHART") {
                          setCanvasLoaded(false);
                        }
                      }}
                      key={subpage}
                      timeout={{ enter: 1, exit: ANIMATION_DURATION_MS }}
                    >
                      {(state) => (
                        <div
                          style={{
                            ...defaultAnimatedStyle,
                            ...transitionAnimatedStyles[state],
                          }}
                        >
                          <div
                            style={{
                              display: subpage === "CHART" ? undefined : "none",
                              height: "100%",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                position: "relative",
                                width: "100%",
                                height: "100%",
                                zIndex: 0,
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  height: "100%",
                                }}
                              >
                                {subpage === "CHART" ? (
                                  <canvas ref={canvasRef} />
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              display: subpage === "TABLE" ? undefined : "none",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            {subpage === "TABLE" ? <TableView /> : null}
                          </div>
                        </div>
                      )}
                    </Transition>
                  </SwitchTransition>
                </div>
              </div>
            </div>
            <div className="d-lg-none">
              <div
                style={{
                  margin: "0",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <div
                  className="bg-secondary"
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "absolute",
                  }}
                />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ChartControllsMobile />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ flexGrow: 0, flexShrink: 0, height: "100%" }}>
          <div
            className="d-none d-lg-block"
            style={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}
          >
            <ChartControllsSideDesktop />
          </div>
        </div>
      </div>
    </Provider>
  );
};

export default MeasuresChart;
