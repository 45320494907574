import { ChartState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";
import { MeasuresGrouping } from "../models/measure-dataset-setup.model";
import { checkMeasuresGroupingCorrectProcedure } from "./utils/checkMeasuresGroupingCorrectProcedure";

type Payload = {
  measuresGrouping: MeasuresGrouping;
};

class PutMeasuresGroupingClass extends ReducerActionClass<ChartState, Payload> {
  getActionName() {
    return "PUT_MEASURES_GROUPING";
  }

  handleDispatch(prevState: ChartState, payload: Payload): ChartState {
    const { measuresGrouping } = payload;


    const state = {
      ...prevState,
      measuresGrouping,
    };

    checkMeasuresGroupingCorrectProcedure(state);

    return state; 
  }
}

export const PutMeasuresGrouping = new PutMeasuresGroupingClass();
