import { DateTime } from "luxon";
import { useMemo } from "react";
import { useTranslate } from "react-translate";
import { AllAlarmTypes, DeviceGroup } from "shared";
import { getContainer } from "../../app-container";
import { getTimeRange } from "../../charts/chart-generating/utils/getTimeRange";
import { useDataProviderSelector } from "../../data-provider/data-provider.provider";
import { AlarmsService } from "../alarms.service";
import { AllSortModes } from "../models/sort-mode.model";
import { SetSortMode } from "../store/setSortMode";
import { UpdateFiltersConfig } from "../store/updateFiltersConfig";
import { UpdateTimeScaleState } from "../store/updateTimeScaleState";
import { useAlarmsDispatch, useAlarmsSelector } from "./AlarmsView";
import { DevicesService } from "../../devices/devices.service";

const AlarmsFilter: React.FC = () => {
  const dispatch = useAlarmsDispatch();

  const sortMode = useAlarmsSelector((state) => state.sortMode);

  const timeScaleState = useAlarmsSelector((state) => state.timeScaleState);

  const devices = useDataProviderSelector((state) => state.devices);
  const groups = useDataProviderSelector((state) => state.deviceGroups);

  const displayGroups = useMemo(
    () =>
      getContainer()
        .resolve<DevicesService>("devices")
        .getDeviceGroups(groups, devices),
    [groups, devices]
  );

  

  const filterDeviceNames = useAlarmsSelector(
    (state) => state.filterDeviceName
  );
  const filterDeviceItem = useMemo(() => {
    const type = !filterDeviceNames
      ? "NONE"
      : filterDeviceNames.length === 1
      ? "DEVICE"
      : "GROUP";

    if (type === "NONE") {
      return { type, value: "ALL" };
    }
    if (type === "DEVICE") {
      return { type, value: filterDeviceNames![0] };
    }
    const value = displayGroups.find((group) =>
    getContainer()
    .resolve<DevicesService>("devices")
    .getGroupsDevices(
      groups,
      devices,
      group === false ? false : group._id
    ).find(dev=>dev.device._id===filterDeviceNames?.[0])
    )!;
    return { type, value };
  }, [filterDeviceNames, displayGroups,devices,groups]);
  const filterGroup = useMemo<DeviceGroup | undefined | false>(() => {
    if (!filterDeviceNames) {
      return undefined;
    }

    const group = displayGroups.find((group) =>
    getContainer()
    .resolve<DevicesService>("devices")
    .getGroupsDevices(
      groups,
      devices,
      group === false ? false : group._id
    ).find(dev=>dev.device.name===filterDeviceNames?.[0]
    ))
    return group;
  }, [filterDeviceNames, devices, groups,displayGroups]);


  const alarmsService = getContainer().resolve<AlarmsService>("alarms");
  const alarms = useDataProviderSelector((state) => state.alarms);

  const dataTypes = useMemo(() => alarmsService.getAlarmsDataTypes(alarms), []);
  const filterDataType = useAlarmsSelector((state) => state.filterDataType);

  const filterAlarmType = useAlarmsSelector((state) => state.filterAlarmType);

  const t = useTranslate("alarms");
  const tDevices = useTranslate("devices");
  const tChart = useTranslate("chart");

  return (
    <div className="d-flex flex-wrap flex-row flex-md-column justify-content-center mb-4 mb-md-0">
      <div className="card  border-light">
        <div style={{ width: 250 }} className="card-body">
          <h5>{t("TIME_SCALE_LABEL")}</h5>
          <h6 className="mt-3">{t("FIELD_SINCE")}</h6>
          <input
            className="form-control"
            type="date"
            onChange={(e) => {
              const timestamp_s = DateTime.fromFormat(
                e.target.value,
                "yyyy-MM-dd"
              ).toSeconds();
              const newTimeScaleState = getTimeRange(
                "CUSTOM",
                timestamp_s,
                "START",
                timeScaleState
              );
              dispatch(
                UpdateTimeScaleState.callAction({
                  updatedTimeScaleState: newTimeScaleState,
                })
              );
            }}
            value={DateTime.fromSeconds(
              timeScaleState.startTimestamp_s
            ).toFormat("yyyy-MM-dd")}
          />
          <h6 className="mt-3">{t("FIELD_UNTIL")}</h6>
          <input
            className="form-control"
            type="date"
            value={DateTime.fromSeconds(
              timeScaleState.endTimestamp_s - 1
            ).toFormat("yyyy-MM-dd")}
            onChange={(e) => {
              const timestamp_s = DateTime.fromFormat(
                e.target.value,
                "yyyy-MM-dd"
              ).toSeconds();
              const newTimeScaleState = getTimeRange(
                "CUSTOM",
                timestamp_s,
                "END",
                timeScaleState
              );
              dispatch(
                UpdateTimeScaleState.callAction({
                  updatedTimeScaleState: newTimeScaleState,
                })
              );
            }}
          />
        </div>
      </div>
      <div className="card  border-light">
        <div style={{ width: 250 }} className="card-body">
          <h5>{t("SORT_LABEL")}</h5>
          <select
            value={sortMode}
            onChange={(e) =>
              //@ts-ignore
              dispatch(SetSortMode.callAction({ sortMode: e.target.value }))
            }
            className="form-select mt-3"
            aria-label="Default select example"
          >
            {AllSortModes.map((sortMode, i) => (
              <option key={i} value={sortMode}>
                {t(`SORT_${sortMode}`)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="card border-light">
        <div style={{ width: 250 }} className="card-body">
          <h5>{t("FILTER_LABEL")}</h5>
          <h6>{t("FILTER_DEVICE_GROUP")}</h6>
          <select
            className="form-select"
            value={filterGroup ? filterGroup._id : filterGroup===false ? "false" :  "NONE"}
            onChange={(e) => {
              const value = e.target.value;
              let filterDeviceName: Array<string> | undefined;

              if (value === "NONE") {
                filterDeviceName = undefined;
              } else {
                
                filterDeviceName = getContainer().resolve<DevicesService>("devices").getGroupsDevices(groups,devices,value==="false" ? false : value).map(dev=>dev.device.name)
              }

              dispatch(
                UpdateFiltersConfig.callAction({
                  updatedFiltersConfig: { filterDeviceName },
                })
              );
            }}
            aria-label="Default select example"
          >
            <option value="NONE">{t("FILTER_GROUP_ALL")}</option>

            {displayGroups.map((group) => (
              <option value={group===false ? "false" : group._id}>{group===false ? tDevices("REST_OPTION") :group.name}</option>
            ))}
          </select>
          <h6 className="mt-3">{t("FILTER_DEVICE")}</h6>
          <select
            className="form-select"
            disabled={!filterGroup}
            value={`${filterDeviceItem.type}/${filterDeviceItem.value}`}
            onChange={(e) => {
              const [type, value] = e.target.value.split("/");
              let filterDeviceName: Array<string> | undefined;
              if (type === "DEVICE") {
                filterDeviceName = [value];
              } else if (type === "NONE") {
                filterDeviceName = undefined;
              } else {
                filterDeviceName = devices
                  .filter((dev) =>
                    dev.device.groupIds.includes(value)
                  )
                  .map((dev) => dev.device.name);
              }
              dispatch(
                UpdateFiltersConfig.callAction({
                  updatedFiltersConfig: { filterDeviceName },
                })
              );
            }}
            aria-label="Default select example"
          >
            {/* <option value="NONE/ALL">{t("FILTER_DEVICE_ALL")}</option> */}
            {groups.map((group, i) => {
              if (group !== filterGroup) {
                return null;
              }

              const devicesInGroup = devices.filter((dev) =>
                dev.device.groupIds
                  .includes(group._id.toString())
              );
              return (
                <optgroup key={i} label={group.name}>
                  <option value={`GROUP/${group._id}`}>
                    {t("FILTER_DEVICE_ALL")}
                  </option>
                  {devicesInGroup.map((dev, j) => (
                    <option key={j} value={`DEVICE/${dev.device.name}`}>
                      {dev.device.name}
                    </option>
                  ))}
                </optgroup>
              );
            })}
          </select>
          <h6 className="mt-3">{t("FILTER_DATA_TYPES")}</h6>
          <select
            value={filterDataType ? filterDataType[0] : ""}
            onChange={(e) => {
              const value = e.target.value ? [e.target.value] : undefined;
              dispatch(
                UpdateFiltersConfig.callAction({
                  //@ts-ignore
                  updatedFiltersConfig: { filterDataType: value },
                })
              );
            }}
            className="form-select"
            aria-label="Default select example"
          >
            <option value="">{t("FILTER_DATA_TYPES_ALL")}</option>
            {dataTypes.map((dataType, i) => (
              <option key={i} value={dataType}>
                {tChart(`${dataType}_LABEL`)}
              </option>
            ))}
          </select>
          <h6 className="mt-3">{t("FILTER_ALARM_TYPE")}</h6>
          <select
            value={filterAlarmType ? filterAlarmType[0] : ""}
            onChange={(e) => {
              const value = e.target.value ? [e.target.value] : undefined;
              dispatch(
                UpdateFiltersConfig.callAction({
                  //@ts-ignore
                  updatedFiltersConfig: { filterAlarmType: value },
                })
              );
            }}
            className="form-select"
            aria-label="Default select example"
          >
            <option value="">{t("FILTER_ALARM_TYPE_ALL")}</option>
            {AllAlarmTypes.map((alarmType, i) => (
              <option key={i} value={alarmType}>
                {t(`FILTER_ALARM_TYPE_${alarmType}`)}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default AlarmsFilter;
