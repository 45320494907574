import { isNull } from "lodash";
import { ChartState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";
import { DataType } from "../models/chart-description.model";
import { TotalRequestedDataTypes } from "../models/data-type-request.model";
import { DeviceRequest } from "../models/device-request.model";
import { calculateTotalRequestedDataTypesFromState } from "./utils/calculateTotalRequestedDataTypes";

type Payload = {
  deviceName: string;
  value: DeviceRequest | null;
};

class PutRequestedDeviceClass extends ReducerActionClass<ChartState, Payload> {
  getActionName() {
    return "PUT_REQUESTED_DEVICE";
  }

  handleDispatch(prevState: ChartState, payload: Payload) {
    const { deviceName, value } = payload;
    const { requestedDevices: prevRequestedDevices } = prevState;

    const requestedDevices = { ...prevRequestedDevices };
    if (value === null) {
      //delete object
      delete requestedDevices[deviceName];
    } else {
      //put object
      requestedDevices[deviceName] = value;
    }

    const state:ChartState={
      ...prevState,
      requestedDevices
    }

    const totalRequestedDataTypes:TotalRequestedDataTypes|null=calculateTotalRequestedDataTypesFromState(state)
    if(!isNull(totalRequestedDataTypes)){
      state.totalRequestedDataTypes=totalRequestedDataTypes
    }

    return state;
  }
}

export const PutRequestedDevices = new PutRequestedDeviceClass();