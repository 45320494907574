import menu from "./menu";
import chart from "./chart";
import devices from "./devices";
import pdfGenerator from "./pdf-generator";
import alarms from "./alarms";
import user from "./user";
import alert from "./alert";
import mod from "./mod";
import forms from "./forms";

export default {
  locale: "pl",
  languageTag: {
    languageTag: "PL",
  },
  alert,
  menu,
  chart,
  devices,
  "pdf-generator": pdfGenerator,
  alarms,
  user,
  mod,
  forms
};
