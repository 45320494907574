import { useState } from "react";
import { useTranslate } from "react-translate";

/* copied from stackoverflow https://stackoverflow.com/questions/4490831/prepare-to-focus-first-active-element-in-a-container  */
function focusFirst(element: HTMLElement) {
  try {
    element.focus();
  } catch (ex) {}

  if (document.activeElement == element) return true;

  for (var i = 0; i < element.childNodes.length; i++) {
    var oChild = element.childNodes[i];
    //@ts-ignore
    if (focusFirst(oChild)) return true;
  }

  return false;
}

const SkipNavigationButton: React.FC = () => {
  const t = useTranslate("menu");
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <div
      className="bg-dark text-primary p-3"
      onFocus={(e) => {
        setIsFocused(true);
      }}
      onBlur={(e) => {
        setIsFocused(false);
      }}
      style={{
        position: "fixed",
        top: isFocused ? 0 : "-100%",
        width: "100%",
        textAlign: "center",
        zIndex: 10,
      }}
      tabIndex={1}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          const mainContainer = document.querySelector("#main-container")!;
          //@ts-ignore
          focusFirst(mainContainer);
        }
      }}
    >
      <a>
        <h4>{t("SKIP_NAVIGATION")}</h4>
      </a>
    </div>
  );
};

export default SkipNavigationButton;
