import { useCallback, useMemo, useRef } from "react";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { container } from "tsyringe";
import { DeviceRequestsService } from "../../../request-services/device-requests.service";
import { useModDataSelector } from "../../../mod-data.provider";



const DeleteDeviceModal: React.FC<{
  deviceId: string | null;
  onClose: () => void;
}> = ({ onClose, deviceId }) => {
  const t = useTranslate("mod");
  const devices = useModDataSelector(state=>state.system?.devices)

  const device = useMemo(
    () => devices&&devices.find((dev) => dev._id === deviceId),
    [deviceId, devices]
  );


  const onSave = useCallback(() => {
    container.resolve(DeviceRequestsService).deleteDevice(deviceId||"").then(()=>{
      onClose()
    })
  }, [deviceId]);

  return (
    <DefaultFormModal
      title={t("DEVICES_TOOLTIP_DELETE")}
      show={!!deviceId}
      onClose={onClose}
      onSave={onSave}
      submitType="delete"
    >
      <>{t("DELETE_DEVICE_MODAL_PARAGRAPH", { deviceName: device?.name || "" })}</>
      
    </DefaultFormModal>
  );
};

export default DeleteDeviceModal;
