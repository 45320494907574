import { DependencyContainer, Lifecycle } from "tsyringe";
import { ChartDataExporterService } from "./submodules/data-exporter/data-exporter.service";
import { ChartDataProviderService } from "./submodules/data-provider/chart-data-provider.service";
import { ChartsTableService } from "./submodules/table/table.service";

export const createChartsContainer = (container: DependencyContainer) => {
  const childContainer = container.createChildContainer();

  container.register("depedency-container", { useValue: childContainer });
  childContainer.register(
    "chart-data-provider",
    { useClass: ChartDataProviderService },
    { lifecycle: Lifecycle.ContainerScoped }
  );
  childContainer.register(
    "chart-data-exporter",
    { useClass: ChartDataExporterService },
    { lifecycle: Lifecycle.ContainerScoped }
  );
  childContainer.register(
    "charts-table",
    { useClass: ChartsTableService },
    { lifecycle: Lifecycle.ContainerScoped }
  );
  

  return childContainer;
};
