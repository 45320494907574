import { DateTime } from "luxon";
import { AuthState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";

type Payload = {
  accessToken: string | undefined;
};

class SetAccessTokenClass extends ReducerActionClass<AuthState, Payload> {
  getActionName(): string {
    return "SET_ACCESS_TOKEN";
  }

  handleDispatch(prevState: AuthState, payload: Payload): AuthState {
    const { loggedIn } = prevState;
    const { accessToken } = payload;

    if (!loggedIn) {
      return prevState;
    }
    console.log(`Token debug: New token received ${accessToken}, time:${DateTime.now().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}`)

    return {
      ...prevState,
      accessToken,
    };
  }
}

export const SetAccessToken = new SetAccessTokenClass()