import { Redirect } from "react-router";
import ModPanel from "../../../modules/mod/components/ModPanel";
import { useCanAccessModPanel } from "../../../modules/mod/hooks/useCanAccessModPanel";
import { HOME_PAGE_PATH } from "../home";

export const MOD_PAGE_PATH = "/mod";

const ModPage: React.FC = () => {
  const { access, dataLoaded } = useCanAccessModPanel();

  if (dataLoaded && !access) {
    return <Redirect to={HOME_PAGE_PATH} />;
  }

  return (
    <>
      <ModPanel />
    </>
  );
};

export default ModPage;
