import { Chart } from "chart.js";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useTranslate } from "react-translate";
import { createStore } from "redux";
import { GenerateChartConfig, generateChartData } from "../chart-generating/generateChartData";
import { ChartReducer, ChartState } from "../store";
import { getDatasets } from '../chart-generating/get-datasets';
import { updateDatasetsProcedure } from "../chart-generating/procedures/update-datasets-procedure";
import { calculateTotalRequestedDataTypes } from "../store/utils/calculateTotalRequestedDataTypes";
import { updateYScalesProcedure } from "../chart-generating/procedures/update-yscales-procedure";
import { getDefaultScalesConfig } from "../chart-generating/getDefaultScalesConfig";
import { PutChart } from "../store/putChart";
import { PutTimeScaleState } from "../store/putTimeScaleState";
import { ChartData } from "../models/chart-data";
import { ChartRequests } from "../models/chart-requests.model";
import { isEqual } from "lodash";
import { useChartData, UseChartDataOptions } from "../submodules/data-provider/useChartData";
import { PutChartData } from "../store/putChartData";

type Options = Partial<GenerateChartConfig & UseChartDataOptions>


export const useChart = (
  canvasRef: React.RefObject<HTMLCanvasElement>,
  chartDisabled: boolean|undefined,
  chartInitialRequests:ChartRequests,
  onChartRequestsChange?:(chartRequests:ChartRequests)=>void,
  chartDataIndex:any=0,
  generateChartConfig?:Partial<GenerateChartConfig>,
  canvasRefIndex:any=0,
  optionsUseChartData?:Partial<UseChartDataOptions>,
  providedChartData?:ChartData,
) => {
  const t = useTranslate("chart");
  const [chart,setChart] = useState<Chart|null>(null);



  const [chartRequests,setChartRequests] = useState<ChartRequests>(chartInitialRequests);
  const {chartData:downloadedChartData} = useChartData(chartRequests,optionsUseChartData,!!chartDisabled||!!providedChartData)

  const chartData = useMemo(()=>providedChartData||downloadedChartData,[providedChartData,downloadedChartData])

  const store = useMemo(() => {

    const {alarmsDatasetsSetup,dataTypesIncluded,measuresDatasetsSetup} = chartData;
    const {requestedAlarms,requestedDataTypes: requestedDataTypes,requestedDevices,timeScaleState,measuresGrouping} = chartInitialRequests;

    const scalesConfig = getDefaultScalesConfig(dataTypesIncluded);
    const {totalRequestedDataTypes} = calculateTotalRequestedDataTypes(requestedDevices,requestedDataTypes);


    const reducer = new ChartReducer({
      alarmsDatasetsSetup,
      measuresDatasetsSetup,
      dataTypesIncluded,
      totalRequestedDataTypes,
      requestedAlarms,
      requestedDataTypes: requestedDataTypes,
      requestedDevices,
      scalesConfig,
      measuresGrouping,
      chart:null,
      timeScaleState
    });

    setChartRequests(chartInitialRequests)

    //@ts-ignore
    return createStore(reducer.changeState.bind(reducer));
  }, [chartDataIndex,setChartRequests,providedChartData]);


  useEffect(()=>{

    let prevChartRequests = chartInitialRequests; 
    const unsubscribe= store.subscribe(()=>{
      const state = store.getState() as ChartState;
      const chartRequests:ChartRequests = {
        measuresGrouping:state.measuresGrouping,
        requestedAlarms:state.requestedAlarms,
        requestedDataTypes:state.requestedDataTypes,
        requestedDevices:state.requestedDevices,
        timeScaleState:state.timeScaleState,
      }

      if(isEqual(prevChartRequests,chartRequests)){
        return
      }
      prevChartRequests=chartRequests
      setChartRequests(chartRequests)
    })

  },[store,setChartRequests])


  /* updating chart data reworked */
  useEffect(()=>{
    
    store.dispatch(PutChartData.callAction({chartData}))

  },[store,chartData])

  /* cant use selector here, so this is why this hooks */
  const [animationsEnabled,setAnimationsEnabled] = useState<boolean|undefined>((store.getState() as ChartState).animationsEnabled);
  useEffect(()=>{

    const selectAnimationsEnabled = (chartState:ChartState)=>!!chartState.animationsEnabled;

    //@ts-ignore
    let prevAnimationsEnabled = selectAnimationsEnabled(store.getState());
    const unsubscribe = store.subscribe(()=>{

      //@ts-ignore
      const animationsEnabled = selectAnimationsEnabled(store.getState())

      if(prevAnimationsEnabled===animationsEnabled){
        return
      }
      prevAnimationsEnabled=animationsEnabled;
      setAnimationsEnabled(animationsEnabled)
    })
    return ()=>{
      unsubscribe()
    }
  },[store,setAnimationsEnabled])

  useEffect(() => {
    if (!canvasRef?.current||chartDisabled) {
      return;
    }

    const state= store.getState() as ChartState
    const config = generateChartData(state, t,(timeRange)=>{
      store.dispatch(PutTimeScaleState.callAction({timeScaleState:timeRange}))
    },animationsEnabled,generateChartConfig);
    const mainChart = new Chart(canvasRef.current, config);
    setChart(mainChart)

    store.dispatch(PutChart.callAction({chart:mainChart}))

    const unsubscribe = store.subscribe(()=>{
      const state = store.getState() as ChartState;
      const {datasets} = getDatasets(state,t);

      updateDatasetsProcedure(mainChart,datasets);
      updateYScalesProcedure(mainChart,state,t);

      mainChart.update()

    })


    return () => {
      unsubscribe();
      mainChart.destroy();
    };
  }, [canvasRef, t,store,animationsEnabled,canvasRefIndex,chartDisabled]);

  /* pushing chart requests */
  useEffect(()=>{
      if(!onChartRequestsChange||!store){
        return;
      }

      const selectChartRequests = (state:ChartState):ChartRequests=>{
        const {requestedAlarms,requestedDataTypes: requestedDataTypes,requestedDevices,timeScaleState,measuresGrouping} = state;
        return{
          requestedAlarms,requestedDataTypes: requestedDataTypes,requestedDevices,timeScaleState,measuresGrouping
        }
      }
      let prevChartRequests = selectChartRequests(store.getState() as ChartState)

      const unsubscribe = store.subscribe(()=>{
        const chartRequests = selectChartRequests(store.getState() as ChartState)
        if(isEqual(prevChartRequests,chartRequests)){
          return
        }
        prevChartRequests=chartRequests;
        onChartRequestsChange(chartRequests);
      })

      return ()=>{
        unsubscribe()
      }

  },[onChartRequestsChange,store])

  return {store,chart};
};
