import { getConstants } from "../../../../config/constants";
import { MeasuresGrouping } from "../../models/measure-dataset-setup.model";
import { TimeScaleRange } from "../../models/time-scale-state";

export const getCorrectGroupingsForTimeRange = (timeRange:TimeScaleRange):Array<MeasuresGrouping>=>{

    const groupings:Array<MeasuresGrouping> = [];
    const timeDiff = timeRange.endTimestamp_s-timeRange.startTimestamp_s;

    if(timeDiff<=getConstants().MAX_CHART_RANGE_MINUTE){
        groupings.push("NONE");
    }
    if(timeDiff<=getConstants().MAX_CHART_RANGE_HOUR){
        groupings.push("HOUR")
    }
    groupings.push("DAILY")


    return groupings

}