import { Tooltip } from "bootstrap";
import { useLayoutEffect } from "react";

export const useActivateTooltip = (ref:React.RefObject<HTMLElement>)=>{
    useLayoutEffect(() => {
        if (!ref.current) {
          return;
        }
        const tooltip = new Tooltip(ref.current);
        return () => {
          tooltip.dispose();
        };
      }, [ref]);

}