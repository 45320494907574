import { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { EmailConfigDto, EmailItemDto, SmsConfigDto, SmsItemDto } from "shared";
import ErrorDisplayFieldError from "../../../../../utils/forms/ErrorDisplayFieldError";
import { isNull } from "lodash";
import { useModPanelData } from "../../hooks/useModPanelData";
import { container } from "tsyringe";
import { SystemRequestsService } from "../../../request-services/system-requests.service";
import { OrganisationRequestsService } from "../../../request-services/organisation-requests.service";

const resolver = classValidatorResolver(SmsItemDto);

const EditSMSModal: React.FC<{
  organisationId: string | null;
  onClose: () => void;
  index: number | null;
}> = ({ onClose, organisationId, index }) => {
  const t = useTranslate("user");

  const { smsData } = useModPanelData(organisationId);
  const smsItem = useMemo(
    () => smsData?.items[index || 0],
    [smsData, index]
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm<SmsItemDto>({ resolver, mode: "onBlur" });

  const submitRef = useRef<HTMLInputElement>(null);
  const onSave = useCallback(() => {
    if (!submitRef.current) {
      return;
    }
    submitRef.current.click();
  }, [submitRef]);

  useEffect(() => {
    if (!smsItem) {
      return;
    }
    setValue("phoneNumber", smsItem.phoneNumber);
    setValue("comment", smsItem.comment);
    trigger();
  }, [smsItem]);

  return (
    <DefaultFormModal
      title={t("SMS_CONFIG_MODAL_HEADER_EDIT")}
      show={!isNull(index)}
      onClose={onClose}
      onSave={onSave}
      saveDisabled={!isValid}
    >
      <>
      {t("SMS_CONFIG_MODAL_PARAGRAPH_EDIT_1")}
                <span className="fw-bold">
                  {smsItem?.phoneNumber}
                </span>
                {t("SMS_CONFIG_MODAL_PARAGRAPH_EDIT_2")}
      </>
      <form
        onSubmit={handleSubmit((data) => {
          const {comment,phoneNumber} = data;
          const dto: SmsConfigDto = { items: [...smsData.items] };
          dto.items[index || 0] = {
            comment,
            phoneNumber,
          };
      
      
          if (isNull(organisationId)) {
            container.resolve(SystemRequestsService).sendUpdateSmsConfig(dto).then(()=>{
            });
            onClose()

          } else {
            container.resolve(OrganisationRequestsService).sendUpdateSmsConfig(dto,organisationId).then(()=>{
              onClose()

            });
            
        }})}
      >
        <input ref={submitRef} hidden type="submit" />
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <label htmlFor="edit_sms_pnumber" className="col-form-label">
                {t("EMAIL_CONFIG_TABLE_EMAIL")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="edit_sms_pnumber"
                {...register("phoneNumber")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.phoneNumber} />
        </div>
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <label htmlFor="edit_sms_comment" className="col-form-label">
                {t("EMAIL_CONFIG_TABLE_COMMENT")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="edit_sms_comment"
                {...register("comment")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.comment} />
        </div>
      </form>
    </DefaultFormModal>
  );
};

export default EditSMSModal;
