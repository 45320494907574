import { useTranslate } from "react-translate";
import TabContainer from "./res/TabContainer";
import TabList from "./res/TabList";

const params: Array<{ key: string; value: string }> = [
  { key: "Zakresy pomiarowe CO2", value: "0-40000ppm" },
  { key: "Rozdzielczość pomiaru CO2", value: "100ppm" },

  { key: "Zakresy pomiaru CO", value: "0-1000ppm" },
  { key: "Dokładność pomiaru CO", value: "5ppm" },

  { key: "Zakresy pomiaru temperatury", value: "-20 - 50 ℃" },
  { key: "Precyzja pomiaru temperatury", value: "0,1 ℃" },
  { key: "Dokładność pomiaru temperatury", value: "0,5 ℃" },
  
  { key: "Zakresy pomiaru ciśnienia atmosferycznego", value: "900-1100 hPa" },
  { key: "Precyzja pomiaru ciśnienia atmosferycznego", value: "0,1 hPa" },
  { key: "Dokładność pomiaru ciśnienia atmosferycznego", value: "1 hPa" },
  
  { key: "Zakresy pomiaru wilgotności", value: "0% - 100%" },
  { key: "Dokładność pomiaru wilgotności", value: "3%" },
];

const TechnicalDataTab: React.FC = ()=>{
    const t = useTranslate("devices");

    return (
      <TabContainer>
        <h3 className="mb-3">{t("TECHNICAL_DATA_TAB")}</h3>
        <TabList items={params}/>
      </TabContainer>
    );
  }

export default TechnicalDataTab