import { ReactComponent as EditIcon } from "bootstrap-icons/icons/pencil-fill.svg";
import { ReactComponent as DeleteIcon } from "bootstrap-icons/icons/trash-fill.svg";
import { ReactComponent as AddIcon } from "bootstrap-icons/icons/plus-square-fill.svg";
import { useLayoutEffect, useState } from "react";
import { Tooltip } from "bootstrap";
import { useTranslate } from "react-translate";
import { useModPanelData } from "../../hooks/useModPanelData";
import { DateTime } from "luxon";
import CreateNotificationModal from "../../modals/NotificationsTab/CreateNotificationModal";
import EditNotificationModal from "../../modals/NotificationsTab/EditNotificationModal";
import DeleteNotificationModal from "../../modals/NotificationsTab/DeleteNotificationModal";


const NotificationsTab: React.FC = () => {


  const {notifications,organisations} = useModPanelData(null)


  const primaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-primary-rgb");

  const dangerColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-danger-rgb");

  const [editingNotification,setEditingNotification] = useState<string|null>(null)
  const [deletingNotification,setDeletingNotification] = useState<string|null>(null)
  const [creatingNotification,setCreatingNotification] = useState<boolean>(false)


  useLayoutEffect(() => {
    const tooltipElements = Array.from(
      document.querySelectorAll(".NotificationsTooltip")
    );
    const tooltips = tooltipElements.map((el) => new Tooltip(el));
    return () => tooltips.forEach((tooltip) => tooltip.dispose());
  }, []);

  const t = useTranslate("mod");

  return (
    <>
      <h4>{t("TAB_notifications")}</h4>
      <p className="ms-1">{t("NOTIFICATIONS_PARAGRAPH")}</p>
      <div style={{ maxWidth: 1100 }}>
        <table className="table table-striped mb-2">
          <thead>
            <tr>
              <th
                style={{ width: "1%", whiteSpace: "nowrap" }}
                className="ps-4"
              >
                #
              </th>
              <th className="ps-4">
                {t("NOTIFICATIONS_COLUMN_TITLE")}
              </th>
              <th className="ps-4">
                {t("NOTIFICATIONS_COLUMN_CREATED_AT")}
              </th>
              <th className="ps-4">
                {t("NOTIFICATIONS_COLUMN_ORGANISATIONS")}
              </th>
              <th className="ps-4 pe-3" style={{ width: "1%" }}></th>
            </tr>
          </thead>
          <tbody>
            {notifications.map((item, i) => (
              <tr>
                <th
                  style={{ width: "1%", whiteSpace: "nowrap" }}
                  className="ps-4"
                >
                  {i + 1}
                </th>
                <td
                  className="ps-4"
                >
                  {item.title}
                </td>
                <td
                  className="ps-4"
                >
                  {DateTime.fromJSDate(item.createdAt).toLocaleString(DateTime.DATETIME_SHORT)}
                </td>
                <td
                  className="ps-4"
                >
                  {item.showAllOrganisations ? t("NOTIFICATIONS_ORGANISATIONS_ALL") : item.organisations.reduce((prev,curr)=>`${prev}${organisations.find(org=>org.organisation._id===curr)?.organisation.name||""}, `,"").slice(0,-2)}
                </td>
                <td className="ps-4 pe-0" style={{ width: "1%" }}>
                  <div className="d-flex justify-content-end">
                    <div className="me-3">
                      <div
                        className="NotificationsTooltip"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={t("NOTIFICATIONS_TOOLTIP_EDIT")}
                      >
                        <a
                          className="text-decoration-none text-reset"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setEditingNotification(item._id)
                          }}
                        >
                          <EditIcon style={{ cursor: "pointer" }} />
                        </a>
                      </div>
                    </div>
                    <div className="me-4">
                      <div
                        className="NotificationsTooltip"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={t("NOTIFICATIONS_TOOLTIP_DELETE")}
                      >
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setDeletingNotification(item._id)
                          }}
                        >
                          <DeleteIcon
                            fill={`rgb(${dangerColor})`}
                            style={{ cursor: "pointer" }}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="d-flex justify-content-end">
          <div className="pe-4" style={{ marginRight: -2 }}>
            <div
              className="NotificationsTooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={t("NOTIFICATIONS_TOOLTIP_CREATE")}
            >
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setCreatingNotification(true)
                }}
              >
                <AddIcon
                  style={{ cursor: "pointer" }}
                  fill={`rgb(${primaryColor})`}
                  height="20"
                  width="20"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
       <CreateNotificationModal show={creatingNotification} onClose={()=>setCreatingNotification(false)} /> 
      <EditNotificationModal notificationId={editingNotification} onClose={()=>setEditingNotification(null)} />  
      <DeleteNotificationModal notificationId={deletingNotification} onClose={()=>setDeletingNotification(null)} /> 
    </>
  );
};

export default NotificationsTab;