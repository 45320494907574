import { ModDataState, OrganisationData } from ".";
import { ReducerActionClass } from "../../../utils/store/core";

type Payload = {
  organisationData: OrganisationData;
  organisationId: string;
};

class PutOrganisationDataClass extends ReducerActionClass<
  ModDataState,
  Payload
> {
  getActionName(): string {
    return "PUT_ORGANISATION_DATA";
  }
  handleDispatch(prevState: ModDataState, payload: Payload): ModDataState {
    const { organisationData, organisationId } = payload;
    const { organisations: prevOrganisations } = prevState;
    return {
      ...prevState,
      organisations: {
        ...prevOrganisations,
        [organisationId]: organisationData,
      },
    };
  }
}

export const PutOrganisationData = new PutOrganisationDataClass();
