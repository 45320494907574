import { useCallback, useMemo } from "react";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { exampleUsers } from "../../tabs/Mod/UsersTab";
import { container } from "tsyringe";
import { UserRequestsService } from "../../../request-services/user-requests.service";


const DeleteUserModal: React.FC<{
  userId: string | null;
  onClose: () => void;

  organisationId:string
}> = ({ onClose, userId,organisationId }) => {
  const t = useTranslate("mod");
  const user = useMemo(()=>exampleUsers.find(user=>user._id===userId),[userId])


  const onSave = useCallback(() => {
    container.resolve(UserRequestsService).delete(userId!,organisationId).then(()=>{
      onClose()
    })
  },[userId,organisationId]);


  return (
    <DefaultFormModal
      title={t("USERS_TOOLTIP_DELETE_USER")}
      show={!!userId}
      onClose={onClose}
      onSave={onSave}
      submitType="delete"
    >
      <>{t("RESET_PASSWORD_MODAL_PARAGRAPH", { userName: user?.user || "" })}</>
      
    </DefaultFormModal>
  );
};

export default DeleteUserModal;
