import { Device } from "shared";
import { DeviceRequest } from "../../models/device-request.model";

/* 
colors generated with: https://www.learnui.design/tools/data-color-picker.html,
https://www.sessions.edu/color-calculator/ and https://www.rgbtohex.net/hex-to-rgb/.
Firs use color calculator to generate triadic scheme with primary color (remember to switch to rgb).
Then use tgese 3 colors to create more colors with data-color-picker by
passing 3 combinations of 2 of 3 colors created earlier and getting
as many colors as needed with them. Then use 3rd page to convert hash to rgb
colors

colors generated:
39,128,227
127,227,39
227,39,127
0,196,218
246,131,0
171,91,199
0,166,250
205,183,0
206,66,167
0,217,142
255,75,75
121,112,220


*/


const hsbToRgb = (h:number,s:number,b:number)=>{
  let red,green,blue;

  /* apply hue */
  if(h<=60){
      /* <0;60> */
      red=255;
      green=255*(h/60)
      blue=0;
  }else if(h<=120){
      /* (60;120> */
          red=255-255*((h-60)/60);
          green=255
          blue=0
  }else if(h<=180){
      /* (120;180> */
          red=0;
          green=255
          blue=255*((h-120)/60);

  }else if(h<=240){
      /* (180;240> */
          red=0;
          green=255 - 255*((h-180)/60)
          blue=255;

  }else if(h<=300){
      /* (240;300> */
          red=255*((h-240)/60)
          green=0;
          blue=255

  }else{
      /* (300;360> */
          red=255;
          green=0
          blue=255-255*((h-300)/60)
  }

  /* apply saturation */
  red = red*s + 255*(1-s);
  green = green*s + 255*(1-s);
  blue = blue*s + 255*(1-s);

  /* apply brightness */
  red = red*b
  green = green*b
  blue = blue*b

  /* round values */
  red = Math.round(red);
  green = Math.round(green)
  blue = Math.round(blue)

  return `${red}, ${green}, ${blue}`

}

// const primaryColorHue = 212
// const colorsSaturation = 0.83
// const colorsBrightness = 0.89

const primaryColorHue = 147
const colorsSaturation = 0.83
const colorsBrightness = 0.70
const getColorsArray = ()=>{

  const recurrencyF = (startingColorHue:number,depth:number,range:number=120):Array<number>=>{
    const color = startingColorHue;
    const color2 = (startingColorHue - 120) % 360;
    const color3 = (startingColorHue + 120) % 360;
    let array=[color,color2,color3];
    if(depth>1){
      const postArrayA = recurrencyF(startingColorHue-range/3,depth-1,range/3);
      const postArrayB = recurrencyF(startingColorHue+range/3,depth-1,range/3);
      let colorSets = postArrayA.length/3;
      for(let i=0;i<colorSets;++i){
        let ib = i*3;
        array.push(postArrayA[ib],postArrayA[ib+1],postArrayA[ib+2],postArrayB[ib],postArrayB[ib+1],postArrayB[ib+2])
      }

    }
    return array;
  }
  // const colorsHue = recurrencyF(primaryColorHue,3).filter(hue=>hue!==primaryColorHue);
  const colorsHue = recurrencyF(primaryColorHue,3)
  colorsHue.splice(3,1)
  return colorsHue.map(hue=> hsbToRgb(hue,colorsSaturation,colorsBrightness))

}

const checkIfColorWasUsed = (color:string,
  requestedDevices: {
    [deviceName: string]: DeviceRequest;
  }):boolean=>!Object.values(requestedDevices).find(val=>val.color===color)

  /* format is `${r},${g},${b}` */

// const colorsArray:Array<string> = [
//   "39,128,227",
//   "127,227,39",
//   "227,39,127",
//   "0,196,218",
//   "246,131,0",
//   "171,91,199",
//   "0,166,250",
//   "205,183,0",
//   "206,66,167",
//   "0,217,142",
//   "255,75,75",
//   "121,112,220",
// ]

const colorsArray = getColorsArray()


export const getUnusedColorForDevice = (
  requestedDevices: {
    [deviceName: string]: DeviceRequest;
  },
  device?:Device
): string => {


  const unusedColor = colorsArray.find(color=>checkIfColorWasUsed(color,requestedDevices));


  return unusedColor || colorsArray[0]
};

export const getManyUnusedColors = (requestedDevices: {
  [deviceName: string]: DeviceRequest;
},colorsNumber:number)=>{

  const colors:Array<string> = []
  for(let i=0;i<colorsNumber;++i){
  const unusedColor = colorsArray.find(color=>checkIfColorWasUsed(color,requestedDevices)&&!colors.includes(color));
    colors.push(unusedColor||colorsArray[0])
  }
  return colors

}

export const getColorForDeviceGroup = (id?:string)=>colorsArray[0]

export const getColorForIndex = (i:number)=>colorsArray[i]