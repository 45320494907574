import { CoreScaleOptions, Scale, Tick } from "chart.js";
import Decimal from "decimal.js";
import { ScaleConfig } from "../../models/scale-config.model";
import { getGridVisibility } from "../utils/getGridVisibility";
//@ts-ignore
export const getHumidityScale: IGetScale = (chartState, t) => {
  let displayGrid = getGridVisibility(chartState, "HUMIDITY");

  let ticksCallback: (
    this: Scale<CoreScaleOptions>,
    tickValue: string | number,
    index: number,
    ticks: Tick[]
  ) => string | number | string[] | number[] | null | undefined = (val) =>
    `${new Decimal(+val).toFixed(1)}${t("HUMIDITY_UNIT")}`;

  const scaleConfig = chartState.scalesConfig["HUMIDITY"];

  let min = scaleConfig.auto ? undefined : scaleConfig.min;
  let max = scaleConfig.auto ? undefined : scaleConfig.max;

  let id = `yHUMIDITY`;
  return {
    //@ts-ignore
    type: "linear",
    id,
    //@ts-ignore
    min,
    //@ts-ignore
    max,
    grid: {
      display: displayGrid,
    },
    ticks: {
      callback: ticksCallback,
    },
  };
};

export const getDefaultHumidityScaleConfig = (): ScaleConfig => ({
  auto: true,
  min: 20,
  max: 100,
});
