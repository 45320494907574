import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { container } from "tsyringe";
import { DeviceRequestsService } from "../../../request-services/device-requests.service";
import { useModPanelData } from "../../hooks/useModPanelData";

const EditDevicesGroupModal: React.FC<{
  deviceId: string | null;
  onClose: () => void;
  organisationId:string
}> = ({ onClose, deviceId,organisationId }) => {
  const t = useTranslate("mod");

  const {devices,deviceGroups: groups} = useModPanelData(organisationId);
  const device = useMemo(
    () => devices.find((dev) => dev.device._id === deviceId)?.device,
    [deviceId, devices]
  );
  const displayGroups = useMemo(
    () => groups.filter((group) => group.display),
    [groups]
  );
  const alarmSets = useMemo(
    () => groups.filter((group) => !group.display),
    [groups]
  );

  const [newDevicesGroup, setNewDevicesGroup] = useState<string|null>(null);

  const onSave = useCallback(() => {

    const deviceAlarmSets = alarmSets.filter(aset=>device!.groupIds.includes(aset._id));

    const newTotalGroups =[...deviceAlarmSets].map(group=>group._id)
    if(newDevicesGroup){
      newTotalGroups.push(newDevicesGroup)
    } 

    container.resolve(DeviceRequestsService).editDevicesGroups(deviceId!,organisationId,{groups:newTotalGroups}).then(()=>{
      onClose()
    })

  }, [alarmSets,newDevicesGroup,deviceId,organisationId]);

  useEffect(() => {
    setNewDevicesGroup(displayGroups.find(dgroup=>device?.groupIds.includes(dgroup._id))?._id||null);
  }, [device,displayGroups]);


  return (
    <DefaultFormModal
      title={t("DEVICES_TOOLTIP_EDIT_GROUP")}
      show={!!deviceId}
      onClose={onClose}
      onSave={onSave}
    >
      <>{t("EDIT_DEVICES_GROUP_MODAL_PARAGRAPH", { groupName: device?.name || "" })}</>
      <div className="mb-3 mt-4">
      {displayGroups.map((dgroup) =>{
        const checked = newDevicesGroup===dgroup._id
        return (
        <div key={dgroup._id} className="form-check">
          <input
            className="form-check-input"
            type="radio"
            checked={checked}
            onChange={(e)=>{
              setNewDevicesGroup(dgroup._id)
            }}
            id={`dev-radio-${dgroup._id}`}
          />
          <label className="form-check-label" htmlFor={`dev-radio-${dgroup._id}`}>
            {dgroup.name}
          </label>
        </div>
      )})}
      </div>
    </DefaultFormModal>
  );
};

export default EditDevicesGroupModal;
