import { ReactComponent as PrevIcon } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
import { ReactComponent as NextIcon } from "bootstrap-icons/icons/arrow-right-square-fill.svg";
import { useEffect, useMemo, useRef } from "react";
import { useTranslate } from "react-translate";

const PaginationBlock: React.FC<{
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}> = ({ currentPage, totalPages,onPageChange }) => {
  const t = useTranslate("forms");

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.value = `${currentPage + 1}`;
  }, [currentPage, inputRef]);

  const prevPageButtonEnabled = useMemo(() => !!currentPage, [currentPage]);
  const nextPageButtonEnabled = useMemo(
    () => currentPage < totalPages - 1,
    [currentPage, totalPages]
  );

  const primaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-primary-rgb");
  const primaryColorString = `rgb(${primaryColor})`;

  const secondaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-secondary-rgb");
  const secondaryColorString = `rgb(${secondaryColor})`;


  return (
    <div className="card  border-light">
      <div
        className="card-body"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <div
          className="input-number-no-arrows"
          style={{ display: "flex", alignItems: "baseline" }}
        >
          {t("PAGE")}{" "}
          <input
            ref={inputRef}
            className="form-control form-control-sm ms-1"
            style={{ width: 40 }}
            type="number"
            pattern="[0-9]+"
            inputMode="numeric"
            defaultValue={currentPage + 1}
            onChange={(e) => {
              if (e.target.value === "") {
                return;
              }
              onPageChange((+e.target.value || 1) - 1);
            }}
          />
          /{totalPages}
        </div>
        <div style={{}}>
          <PrevIcon
            tabIndex={prevPageButtonEnabled ? 0 : null}
            //@ts-ignore
            onKeyDown={(e) => {
              console.log(e);
              if (e.nativeEvent.key === "Enter") {
                if (prevPageButtonEnabled) onPageChange(currentPage - 1);
              }
            }}
            style={{
              cursor: prevPageButtonEnabled ? "pointer" : undefined,
              userSelect: "none",
            }}
            fill={
              prevPageButtonEnabled ? primaryColorString : secondaryColorString
            }
            onClick={
              prevPageButtonEnabled
                ? () => {
                    onPageChange(currentPage - 1);
                  }
                : undefined
            }
            width="27"
            height="27"
          />{" "}
          <NextIcon
            tabIndex={nextPageButtonEnabled ? 0 : null}
            //@ts-ignore
            onKeyDown={(e) => {
              if (e.nativeEvent.key === "Enter") {
                if (nextPageButtonEnabled) onPageChange(currentPage + 1);
              }
            }}
            style={{
              cursor: nextPageButtonEnabled ? "pointer" : undefined,
              userSelect: "none",
            }}
            fill={
              nextPageButtonEnabled ? primaryColorString : secondaryColorString
            }
            onClick={
              nextPageButtonEnabled
                ? () => {
                    onPageChange(currentPage + 1);
                  }
                : undefined
            }
            width="27"
            height="27"
          />
        </div>
      </div>
    </div>
  );
};

export default PaginationBlock;
