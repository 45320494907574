import { useTranslate } from "react-translate";
import { ALARMS_PAGE_URL } from "../../../../skeleton/pages/alarms";
import IconTile from "./IconTile";

import { ReactComponent as AlarmsIcon } from "bootstrap-icons/icons/bell-fill.svg";

const AlarmTile: React.FC = () => {
  const t = useTranslate("menu");

  return (
    <IconTile
      title={t("PAGE_ALARMS")}
      url={ALARMS_PAGE_URL}
      Icon={<AlarmsIcon width="100%" height="100%" />}
    />
  );
};

export default AlarmTile;
