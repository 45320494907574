import { ReactNode, useEffect, useState } from "react";
import { getContainer } from "../app-container";
import { AlertService } from "./alert.service";
import { AlertConfig } from "./models/alert-config.model";
import { v4 as uuidv4 } from 'uuid';

export const AlertProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [alerts, setAlerts] = useState<
    Array<{ config: AlertConfig; id: string }>
  >([]);

  useEffect(() => {
    const alertService = getContainer().resolve<AlertService>("alert");

    const cb = ((config: AlertConfig) => {
      setAlerts((currAlarms) => [...currAlarms, { config, id: uuidv4() }]);
    }).bind(this);

    const unsub = alertService.subscribeToMessages(cb);
    return () => unsub();
  }, []);

  return (
    <>
      {children}
      <div style={{ display: "fixed", width: "100%", height: "100%" }}>
        <div className="p-2" style={{display:"flex",flexDirection:"column",alignItems:"stretch"}}>
        {alerts.map(({ config, id }) => (
            <div key={id} style={{display:"flex",justifyContent:"center"}}>
          <div
          
            id={`app-alert-${id}`}
            className={`alert alert-${config.theme} alert-dismissible fade show`}
            role="alert"
            style={{maxWidth:600,flexGrow:1}}
          >
            {config.message}
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
          </div>

        ))}

      </div>
      </div>
    </>
  );
};
