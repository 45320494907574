import { CartesianScaleTypeRegistry, CoreScaleOptions, Scale, ScaleOptionsByType, Tick } from "chart.js";
import { ScaleConfig } from "../../models/scale-config.model";
import { IGetScale } from "../get-y-scales";
import { getGridVisibility } from "../utils/getGridVisibility";

export const CO_DISPLAY_DEFAULT_DECIMAL_POINTS = 3;

export const getCOScale: IGetScale = (
  chartState,
  t
): ScaleOptionsByType<"radialLinear" | keyof CartesianScaleTypeRegistry> => {
  let ticksCallback: (
    this: Scale<CoreScaleOptions>,
    tickValue: string | number,
    index: number,
    ticks: Tick[]
  ) => string | number | string[] | number[] | null | undefined = (val) => {
    return `${Math.floor(+val)}${t("CO_LEVEL_UNIT")}`;
  };

  let displayGrid = getGridVisibility(chartState,"CO_LEVEL");


  const scaleConfig = chartState.scalesConfig["CO_LEVEL"];

  let min = scaleConfig.auto ? 0 : scaleConfig.min;
  let max = scaleConfig.auto ? undefined : scaleConfig.max;
  let id = `y$CO_LEVEL`;

  return {
    //@ts-ignore
    type: "linear",
    id,
    min,
    //@ts-ignore
    max,
    //@ts-ignore
    grid: {
      display: displayGrid,
    },
    //@ts-ignore
    ticks: {
      callback: ticksCallback,
    },
  };
};

export const getDefaultCOLevelScaleConfig = ():ScaleConfig=>{

    return {
        auto:true,
        min:0,
        max:500
    }
}