import { Device, Measure } from "shared";


export const getDevicesInGroup = (devices: Array<{device:Device,lastMeasure?:Measure}>, groupID: string) =>
  devices.filter((dev) =>
    dev.device.groupIds.map((id) => id.toString()).find((id) => id === groupID)
  );

  export const getDevicesInGroupShort = (devices: Array<Device>, groupID: string) =>
  devices.filter((dev) =>
    dev.groupIds.map((id) => id.toString()).find((id) => id === groupID)
  );