import { CartesianScaleTypeRegistry, ScaleOptionsByType } from "chart.js";
import { ChartState } from "../store";

export const getXScale = (chartState:ChartState):ScaleOptionsByType<
"radialLinear" | keyof CartesianScaleTypeRegistry
>=>{

  const {startTimestamp_s,endTimestamp_s} = chartState.timeScaleState

    return {
        //@ts-ignore
        type: "time",
        min: startTimestamp_s*1000,
        max: endTimestamp_s*1000,
        //@ts-ignore
        ticks:{
          maxTicksLimit:10
        },
        //@ts-ignore
        time: {
          parser: (args) => {
            //@ts-ignore
            return 1 * args;
          },
          displayFormats:{
            //@ts-ignore
            hour:{day: 'numeric', month: 'short',hour: 'numeric', minute: 'numeric',}
          }
        },
      };
}