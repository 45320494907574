import { useLayoutEffect, useMemo, useState } from "react";
import { useTranslate } from "react-translate";
import { DataType } from "../models/chart-description.model";
import { getAlarmDefaultColor } from "../chart-generating/utils/getAlarmDefaultColor";
import { SetRequestedAlarm } from "../store/setRequestedAlarms";
import { useInitCollapseToggle,useToggleCollapse } from "./utils/collapse-fix";
import { useMainChartDispatch, useMainChartSelector } from "../hooks/chart.context";
import { useDataProviderSelector } from "../../data-provider/data-provider.provider";
import { getContainer } from "../../app-container";
import { DataProviderService } from "../../data-provider/data-provider.service";

const AlarmControlls: React.FC = () => {
  const t = useTranslate("chart");

  const alarmsDatasetsSetup = useMainChartSelector(
    (state) => state.alarmsDatasetsSetup
  );

  const { alarms } = useDataProviderSelector(state=>state)

  const requestedAlarms = useMainChartSelector(
    (state) => state.requestedAlarms
  );

  // const alarmsDataTypes = useMemo<Array<DataType>>(
  //   () =>
  //     Object.values(alarmsDatasetsSetup).reduce(
  //       (arr: Array<DataType>, curr) => {
  //         if (!arr.includes(curr.dataType)) {
  //           arr.push(curr.dataType);
  //         }
  //         return arr;
  //       },
  //       []
  //     ),
  //   [alarmsDatasetsSetup]
  // );

  const dataProviderService = getContainer().resolve<DataProviderService>("data-provider")
  const alarmsDataTypes = useMemo<Array<DataType>>(
      () =>
      alarms.reduce(
        (arr: Array<DataType>, curr) => {
          const dataType = dataProviderService.getDataType(curr.dtAlias)
          if (!arr.includes( dataType)) {
            arr.push(dataType);
          }
          return arr;
        },
        []
      )
  ,[alarms])

  console.log(alarmsDatasetsSetup,alarmsDataTypes)


  const dispatch = useMainChartDispatch();

  const DANGER_COLOR = getAlarmDefaultColor();

  /* this was done to init collapses each time items changes */
  const [collapsesSelector,setCollapsesSelector] = useState<Array<string>>([".alarmControlls"])
  useLayoutEffect(()=>{
    setCollapsesSelector([".alarmControlls"])
  },[alarmsDataTypes])
  useInitCollapseToggle(collapsesSelector,{toggle:false});
  const toggleCollapse = useToggleCollapse()



  return (
    <>
     <h3>{t("ALARMS_DATA")}</h3>

      {alarmsDataTypes.map((dataType, i) => {
        const dataTypeAlarms = alarms.filter(
          (alarm) => dataProviderService.getDataType(alarm.dtAlias) === dataType
        ).map(alarm=>alarm.name);

        return (
          <div key={i} className="ps-2 pb-1">
            <div
              className="fs-5"
              style={{
                display: "flex",
                flexWrap: "nowrap",
                justifyContent: "space-between",
              }}
            >
              <div className="form-check-inline ">
                <label
                  style={{ cursor: "pointer" }}
                  className="btn-toggle form-check-label"
                  onClick={()=>toggleCollapse(`#alarmControlls_${i}`)}
                  tabIndex={0}
                  onKeyDown={(e)=>{
                    if(e.key==="Enter"){
                      //@ts-ignore
                      e.target.click()  
                    }
                  }}
                >
                  <a className="dropdown-toggle text-white pe-2" />
                  {t(`${dataType}_LABEL`)}
                </label>
              </div>
            </div>
            <div className="collapse alarmControlls" id={`alarmControlls_${i}`}>
              <div className="ps-2">
                {dataTypeAlarms.map((alarmName, j) => {
                  const requested = requestedAlarms.includes(alarmName);

                  return (
                    <div className="form-check" key={j}>
                      <input
                        className="form-check-input"
                        id={`alarm-datatype-${i}-${j}`}
                        type="checkbox"
                        onKeyDown={(e)=>{
                          if(e.key==="Enter"){
                            //@ts-ignore
                            e.target.click()  
                          }
                        }}
                        checked={requested}
                        onClick={() => {
                          dispatch(
                            SetRequestedAlarm.callAction({
                              alarmName,
                              requested: !requested,
                            })
                          );
                        }}
                      />
                      <label
                        className="form-check-label ps-1"
                        htmlFor={`alarm-datatype-${i}-${j}`}
                        style={{ cursor: "pointer" }}
                      >
                        {alarmName}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );

  // return (
  //   <>
  //     <h3>{t("ALARMS_DATA")}</h3>
  //     {Object.keys(alarmsDatasetsSetup).map((alarmName) => {
  //       //   const {} = alarmsDatasetsSetup[alarmName];
  //       const requested = requestedAlarms.includes(alarmName);
  //       return (
  //         <div className="ps-3 pb-1 fs-5">
  //           <div
  //             className="fs-5"
  //             style={{
  //               display: "flex",
  //               flexWrap: "nowrap",
  //               justifyContent: "space-between",
  //             }}
  //           >
  //             <div className="form-check-inline ">
  //               <label
  //                 style={{ cursor: "pointer" }}
  //                 className="btn-toggle form-check-label"
  //               >
  //                 {alarmName}
  //               </label>
  //             </div>

  //             <div className="form-check-inline">
  //               <input
  //                 className="form-check-input"
  //                 style={{
  //                     borderColor:requested ? `rgb(${DANGER_COLOR})` : undefined,
  //                     backgroundColor:requested ? `rgb(${DANGER_COLOR})` : undefined
  //                 }}
  //                 type="checkbox"
  //                 checked={requested}
  //                 onClick={() =>
  //                   dispatch(
  //                     SetRequestedAlarm.callAction({
  //                       alarmName,
  //                       requested: !requested,
  //                     })
  //                   )
  //                 }
  //               />
  //             </div>
  //           </div>
  //         </div>
  //       );
  //     })}
  //   </>
  // );
};

export default AlarmControlls;
