import { ScaleConfig } from "../models/scale-config.model";
import { DataType } from "../models/chart-description.model";
import { getMergedScaleDataType } from "./get-y-scales";
import { getDefaultAtmosphericPressureScaleConfig } from "./yScales/getAtmosphericPressureScale";
import { getDefaultCO2ScaleConfig } from "./yScales/getCO2Scale";
import { getDefaultCOLevelScaleConfig } from "./yScales/getCOScale";
import { getDefaultHumidityScaleConfig } from "./yScales/getHumidityScale";
import { getDefaultTemperatureScaleConfig } from "./yScales/getTemperatureScale";
import { dataTypesImportance } from "./utils/getGridVisibility";

export const getDefaultScalesConfig = (
  dataTypesIncluded: Array<DataType>
): {
  [dataType: string]: ScaleConfig;
} => {
  const scalesConfig: {
    [dataType: string]: ScaleConfig;
  } = {};

  dataTypesImportance.forEach(dataType=>{
      const mergedDataType = getMergedScaleDataType(dataType);
      if(mergedDataType==="CO2_LEVEL"){
        scalesConfig["CO2_LEVEL"]=getDefaultCO2ScaleConfig()
      }else if(mergedDataType==="TEMPERATURE"){
        scalesConfig["TEMPERATURE"]=getDefaultTemperatureScaleConfig()
      }else if(mergedDataType==="CO_LEVEL"){
        scalesConfig["CO_LEVEL"]=getDefaultCOLevelScaleConfig()
      }else if(mergedDataType==="ATMOSPHERIC_PRESSURE"){
        scalesConfig["ATMOSPHERIC_PRESSURE"]=getDefaultAtmosphericPressureScaleConfig()
      }else if(mergedDataType==="HUMIDITY"){
        scalesConfig["HUMIDITY"]=getDefaultHumidityScaleConfig()
      }
  })


  return scalesConfig;
};
