import { TotalRequestedDataTypes } from "../../models/data-type-request.model";
import { DataType } from "../../models/chart-description.model";

export const getTotalRequestedDataTypesArray = (
  totalRequestedDataTypes: TotalRequestedDataTypes
): Array<DataType> =>
//@ts-ignore
  Object.keys(totalRequestedDataTypes).filter(
    (rdt) => totalRequestedDataTypes[rdt]?.enabled
  );
