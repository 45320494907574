import { ValidationError } from "class-validator";
import { FieldError } from "react-hook-form";
import { useTranslate } from "react-translate";
import ErrorDisplay from "./ErrorDisplay";

const ErrorDisplayFieldError: React.FC<{ error: FieldError | undefined }> = ({
  error,
}) => {
  const t = useTranslate("forms");

  let params: Array<string>;
  try {
    params = !error?.message ? [] : JSON.parse(error.message);
  } catch (e) {
    params = [];
  }

  const paramsObj: any = {};
  params.forEach((val, i) => (paramsObj[i] = val));

    // return <>{error ? t(`ERROR_${error.type}`, paramsObj) : ""}</>;
  return (
    <ErrorDisplay errorExist={!!error} paramsObj={paramsObj} type={error?.type||""} />
  );
};

export default ErrorDisplayFieldError;
