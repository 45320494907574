import { useCallback, useMemo } from "react";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { useModDataSelector } from "../../../mod-data.provider";
import { Alarm } from "shared";
import { isNull } from "lodash";
import { container } from "tsyringe";
import { AlarmRequestsService } from "../../../request-services/alarm-requests.service";
import { useModPanelData } from "../../hooks/useModPanelData";

const DeleteAlarmModal: React.FC<{
  alarmId: string | null;
  onClose: () => void;
  organisationId: string | null;
}> = ({ onClose, alarmId, organisationId }) => {
  const t = useTranslate("mod");

  const { alarms} = useModPanelData(organisationId);
  const alarm = useMemo(
    () => alarms.find((alarm) => alarm._id === alarmId),
    [alarms, alarmId]
  );

  const onSave = useCallback(() => {
    if(isNull(organisationId)){
      container.resolve(AlarmRequestsService).deleteGlobalAlarm(alarmId!).then(()=>{
        onClose()
      })
    }else{
      container.resolve(AlarmRequestsService).deleteAlarm(alarmId!,organisationId).then(()=>{
        onClose()
      })
    }


  }, [alarmId,organisationId]);

  return (
    <DefaultFormModal
      title={t("ALARMS_TOOLTIP_DELETE")}
      show={!!alarmId}
      onClose={onClose}
      onSave={onSave}
      submitType="add"
    >
      <>{t("DELETE_ALARM_MODAL_PARAGRAPH", { alarmName: alarm?.name || "" })}</>
    </DefaultFormModal>
  );
};

export default DeleteAlarmModal;
