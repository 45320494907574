import React from 'react';
import PdfGenerator from '../../../modules/pdf-generator/components/PdfGenerator';

export const RAPORTS_PAGE_PATH = "/raports"

const RaportsPage:React.FC = ()=>{

    return(
        <PdfGenerator/>
    )

}

export default RaportsPage;