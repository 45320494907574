import axios from "axios";
import { CreateOrganisationDto, EditOrganisationDto, EmailConfigDto, SmsConfigDto, IpConfigDto, NotificationBlock } from "shared";
import { injectable, Lifecycle, scoped } from "tsyringe";
import { getConstants } from "../../../config/constants";
import { getContainer } from "../../app-container";
import { ModDataService } from "../mod-data.service";

type Dto={
  showAllOrganisations:boolean,
  organisations:Array<string>
  blocks:Array<NotificationBlock>
}

@injectable()
@scoped(Lifecycle.ContainerScoped)
export class NotificationsRequestsService {
  async createNotification(dto:Dto) {
    await axios({
      method: "post",
      url: `${getConstants().SERVER_URL}/notifications`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()

  }

  async editNotification(dto:Dto,id:string) {
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/notifications/notifications/${id}`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()

  }

  async deleteNotification(id:string) {
    await axios({
      method: "delete",
      url: `${getConstants().SERVER_URL}/notifications/notifications/${id}`,
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()
  }
}
