import { Alarm, Device, DeviceGroup, DeviceWMqttConfWOPassword, Notification, Organisation, System, User } from "shared";
import {
  ReducerActionClass,
  ReducerCollection,
} from "../../../utils/store/core";
import { PutOrganisationData } from "./putOrganisationData";
import { PutSystemData } from "./putSystemData";
import { RemoveData } from "./removeData";
import { OrganisationViewData } from "../../data-provider/store";
import { PutSelectedOrganisaion } from "./putSelectedOrganisation";

export type SystemData = {
  system:System,
  organisations:Array<{organisation:Organisation,owner:User}>,
  devices:Array<DeviceWMqttConfWOPassword>,
  device_groups:Array<DeviceGroup>
  alarms:Array<Alarm>
  notifications:Array<Notification>
};

export type OrganisationData = {
  viewData:OrganisationViewData,
  organisation:Organisation,
  users:Array<User>
};

export type ModDataState = {
  system?: SystemData;
  organisations: {
    [orgId: string]: OrganisationData;
  };
  selectedOrganisation?:string
};

export class ModDataReducer extends ReducerCollection<ModDataState> {
  static initialState: ModDataState = {
    organisations: {},
  };

  protected initState(): ModDataState {
    return ModDataReducer.initialState;
  }

  protected getActionClassObjects(): ReducerActionClass<ModDataState, any>[] {
    return [PutSystemData,PutOrganisationData,RemoveData,PutSelectedOrganisaion];
  }
}
