import { isUndefined } from "lodash";
import { DateTime } from "luxon";
import { AggregationRange, Alarm, Measure } from "shared";
import { inject, injectable } from "tsyringe";
import { DataProviderService } from "../../../data-provider/data-provider.service";
import { AlarmDatasetSetup } from "../../models/alarm-dataset-setup-model";
import { ChartData } from "../../models/chart-data";
import { AllDataTypes, DataType } from "../../models/chart-description.model";
import {
  MeasureDatasetSetup,
  MeasuresGrouping,
} from "../../models/measure-dataset-setup.model";
import { TimeScaleState } from "../../models/time-scale-state";

@injectable()
export class ChartDataProviderService {
  constructor(
    @inject("data-provider") private dataProviderService: DataProviderService
  ) {}

  getAggregationRange(grouping: MeasuresGrouping): AggregationRange|undefined {
    return grouping === "DAILY"
      ? "DAILY"
      : grouping === "HOUR"
      ? "HOUR"
      : undefined;
  }

  getMeasuresGrouping(aggregation?:AggregationRange):MeasuresGrouping{
    return aggregation ==="DAILY"
    ? "DAILY"
    : aggregation === "HOUR"
    ? "HOUR"
    : "NONE"
  }

  async getChartData(
    devicesId: Array<string>,
    timeScaleState: TimeScaleState,
    grouping: MeasuresGrouping
  ): Promise<ChartData> {
    const aggregation = this.getAggregationRange(grouping);
    const measures = this.dataProviderService.getMeasures(
      devicesId,
      DateTime.fromSeconds(timeScaleState.startTimestamp_s,{zone:"utc"}).toJSDate(),
      DateTime.fromSeconds(timeScaleState.endTimestamp_s,{zone:"utc"}).toJSDate(),
      aggregation
    );

    const { alarms } = this.dataProviderService.getStore().getState();

    return this.convertToChartData(await measures, grouping, alarms);
  }

  async getChartDataWithMeasures(
    measures: Array<Measure>,
    grouping: MeasuresGrouping
  ): Promise<ChartData> {
    const { alarms } = this.dataProviderService.getStore().getState();

    return this.convertToChartData(measures, grouping, alarms);
  }

  private convertToChartData(
    measures: Array<Measure>,
    grouping: MeasuresGrouping,
    alarms: Array<Alarm>
  ) {
    const { dataTypesIncluded, measuresDatasetsSetup } =
      this.getMeasuresDatasets(measures, grouping);

    const alarmsDatasetsSetup = this.getAlarmsDatasets(alarms);

    return {
      dataTypesIncluded,
      measuresDatasetsSetup,
      alarmsDatasetsSetup,
    };
  }

  getMeasureValueArray(measure?: Measure):Array<{dataType:DataType,value?:number}> {
    return AllDataTypes.map(dataType=>({
      dataType,
      value:measure?.v[this.dataProviderService.getDataTypeAlias(dataType)]
    }))

  }

  private getMeasuresDatasets(
    measures: Array<Measure>,
    grouping: MeasuresGrouping
  ) {
    const measuresDatasetsSetup: {
      [deviceName: string]: {
        [dataType: string]: Array<MeasureDatasetSetup>;
      };
    } = {};

    const dataTypesIncluded: Array<DataType> = [];

    measures.forEach((measure) => {
      const deviceId = measure.dev;
      const date_timestamp_s = measure.dtime;

      if (isUndefined(measuresDatasetsSetup[deviceId])) {
        measuresDatasetsSetup[deviceId] = {};
      }

      Object.entries(measure.v).forEach(([dataTypeAlias, value]) => {
        const dataType: DataType =
          this.dataProviderService.getDataType(dataTypeAlias);

        if (isUndefined(measuresDatasetsSetup[deviceId][dataType])) {
          measuresDatasetsSetup[deviceId][dataType] = [
            {
              grouping,
              dataType,
              deviceId,
              measures: [],
            },
          ];

          if (!dataTypesIncluded.includes(dataType)) {
            dataTypesIncluded.push(dataType);
          }
        }
        measuresDatasetsSetup[deviceId][dataType][0].measures.push({
          timestamp_s: DateTime.fromJSDate(date_timestamp_s,{zone:"utc"}).toSeconds(),
          value,
        });
      });
    });

    return { dataTypesIncluded, measuresDatasetsSetup };
  }

  private getAlarmsDatasets(alarms: Array<Alarm>): {
    [alarmName: string]: AlarmDatasetSetup;
  } {
    const alarmsDatasetsSetup: {
      [alarmName: string]: AlarmDatasetSetup;
    } = {};

    alarms.forEach((alarmObj) => {
      const {
        dtAlias: dataTypeAlias,
        name,
        threshold,
        type: alarmType,
        exceedBelowThreshold
      } = alarmObj;
      const dataType = this.dataProviderService.getDataType(dataTypeAlias);
      alarmsDatasetsSetup[name] = { dataType, value: threshold, alarmType,exceedBelowThreshold:!!exceedBelowThreshold };
    });
    return alarmsDatasetsSetup;
  }
}
