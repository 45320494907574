import { ChartState } from "."
import { ReducerActionClass } from "../../../utils/store/core"

type Payload = {
    animationsEnabled:boolean
}

class SetAnimationsEnabledClass extends ReducerActionClass<ChartState,Payload>{

    getActionName(){
        return "SET_ANIMATIONS_ENABLED"
    }

    handleDispatch(prevState:ChartState,payload:Payload){
        const {animationsEnabled} = payload;
        return {
            ...prevState,
            animationsEnabled
        }
    }

}

export const SetAnimationsEnabled = new SetAnimationsEnabledClass()