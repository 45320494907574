import { Action, Store } from "redux";

interface Dictionary<T> {
  [key: string]: T;
}

type ReducerAction<Data> = Action<string> & { data: Data };

export function callAction<Data>(
  type: string,
  data: Data
): ReducerAction<Data> {
  return { type, data };
}

export abstract class ReducerCollection<State> {
  private actions: Dictionary<(state: State, action?: any) => State>;
  private initialState: State;
  private actionClassObjects: Array<ReducerActionClass<State, any>>;

  constructor(passedState?:State) {
    this.actionClassObjects = this.getActionClassObjects();
    this.initialState = passedState || this.initState();
    this.actions = this.createActions();
  }

  public changeState<Data>(
    state: State = this.initialState,
    action: ReducerAction<Data> = ({
      type: "",
      data: undefined,
    } as unknown) as ReducerAction<Data>
  ): State {
    if (action.type && this.actions[action.type]) {
      return this.actions[action.type](state, action.data);
    }

    return state;
  }

  private createActions(): Dictionary<(state: State, action?: any) => State> {
    const actions: Dictionary<(state: State, action?: any) => State> = {};
    this.actionClassObjects.forEach((obj) => {
      actions[obj.getActionName()] = obj.handleDispatch.bind(obj);
    });

    return actions;
  }

  protected abstract initState(): State;
  protected abstract getActionClassObjects(): Array<
    ReducerActionClass<State, any>
  >;
}

export abstract class ReducerActionClass<State, Payload> {
  private _actionName: string;
  abstract getActionName(): string;
  constructor() {
    this._actionName = this.getActionName();
  }

  abstract handleDispatch(state: State, payload: Payload): State;

  callAction(payload: Payload):Action<State> {
    //@ts-ignore
    return callAction(this._actionName, payload);
  }
}
