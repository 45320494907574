export default {
  COLLAPSE_MENU: "Zwiń",

  PAGE_DEVICES: "Urządzenia",
  PAGE_STATS: "Pomiary",
  PAGE_RAPORTS: "Raporty",
  PAGE_ALARMS: "Alarmy",
  PAGE_PROFILE: "Profil",
  PAGE_MOD_PANEL: "Panel Moderatora",
  PAGE_ADMIN_PANEL: "Panel Administratora",
  "LOGOUT":"Wyloguj",

  "SKIP_NAVIGATION":"Pomiń nawigację",

  "LOGIN_HEADER":"Zaloguj się do panelu",
  "USERNAME":"Nazwa użytkownika",
  "PASSWORD":"Hasło",
  "REMEMBER_ME":"Zapamiętaj mnie",
  "LOGIN_BUTTON":"Zaloguj",

  "INCORRECT_LOGIN":"Nieprawidłowa nazwa użytkownika.",
  "INCORRECT_PASSWORD":"Nieprawidłowe hasło.",
  "INCORRECT_IP":"Niedozwolony adres IP."
};
