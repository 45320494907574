import { ChartState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";
import { getDefaultScalesConfig } from "../chart-generating/getDefaultScalesConfig";
import { ChartData } from "../models/chart-data";

type Payload = {
  chartData: ChartData;
};

class PutChartDataClass extends ReducerActionClass<ChartState, Payload> {
  getActionName(): string {
    return "PUT_CHART_DATA";
  }

  handleDispatch(prevState: ChartState, payload: Payload): ChartState {
    const { chartData } = payload;
    const { alarmsDatasetsSetup, dataTypesIncluded, measuresDatasetsSetup } =
      chartData;

    const scalesConfig = getDefaultScalesConfig(dataTypesIncluded);

    return {
      ...prevState,
      alarmsDatasetsSetup,
      measuresDatasetsSetup,
      dataTypesIncluded,
      scalesConfig,
    };
  }
}
export const PutChartData = new PutChartDataClass();
