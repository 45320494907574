import React from 'react';
import MeasuresChart from '../../../modules/charts/components/MeasuresChart';

export const STATS_PAGE_PATH = "/stats"

export const readStatsPageSearchParamsValue = (history:any)=>{
    const urlSearchParams = new URLSearchParams(history.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return {CHR:params.CHR};
}

export const getStatsPageURLWithSearchParamsValue = (chartRequestsJson?:string)=>{
    if(!chartRequestsJson){
        return STATS_PAGE_PATH
    }

    return `${STATS_PAGE_PATH}?CHR=${chartRequestsJson}`;
}

const StatsPage:React.FC = ()=>{

    return(
        <>
            <MeasuresChart/>
        </>
    )

}

export default StatsPage;