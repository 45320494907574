import Decimal from "decimal.js";
import { useMemo } from "react";
import { useTranslate } from "react-translate";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import TabContainer from "./res/TabContainer";
import AlarmExceedsStatisticDisplay from "../../components/AlarmExceedsStatisticDisplay";
import { ChartRequests } from "../../../../charts/models/chart-requests.model";
import { DateTime } from "luxon";
const WEEK_LENGTH = 86400 * 7;
const MONTH_LENGTH = 86400 * 30;

const AlarmExceedsStatisticsTab: React.FC<{
  deviceId: string;
  setChartRequests: (chartRequests: ChartRequests) => void;
}> = ({ deviceId, setChartRequests }) => {
  const t = useTranslate("devices");

  const devices = useDataProviderSelector((state) => state.devices);
  const device = useMemo(
    () => devices.find((dev) => dev.device._id === deviceId),
    [devices, deviceId]
  );
  const { alarmExceedsStatistics } = device!.device.statistics;
  const alarmIds = useMemo(
    () =>
      alarmExceedsStatistics.reduce((arr: Array<string>, curr) => {
        const alarmId = curr.alarm;
        if (!arr.includes(alarmId)) {
          arr.push(alarmId);
        }
        return arr;
      }, []),
    [alarmExceedsStatistics]
  );
  const totalAlarms = useDataProviderSelector((state) => state.alarms);
  const alarms = useMemo(
    () => totalAlarms.filter((alarm) => alarmIds.includes(alarm._id)),
    [totalAlarms, alarmIds]
  );

  //   useInitCollapseToggle(".ae-statistic-dropdown")
  //   const toggleCollapse = useToggleCollapse()

  return (
    <TabContainer>
      <h3 className="mb-3">{t("ALARM_EXCEEDS_STATISTICS_TAB")}</h3>
      <div className="m-3">
        <table
          style={{
            fontSize: "1.15rem",
            borderBottomColor: "rgba(0,0,0,0.125)",
          }}
          className="table"
        >
          <thead>
            <tr>
              <th />
              <th
                className="ps-4"
                style={{ width: "1%", whiteSpace: "nowrap" }}
              >
                {t("ALARM_EXCEEDS_STATISTICS_LABEL_WEEKLY")}
              </th>
              <th
                className="ps-4"
                style={{ width: "1%", whiteSpace: "nowrap" }}
              >
                {t("ALARM_EXCEEDS_STATISTICS_LABEL_MONTHLY")}
              </th>
            </tr>
          </thead>
          <tbody>
            {alarms.map((alarm, i) => {
              const alarmStatistics = alarmExceedsStatistics.filter(
                (stat) => stat.alarm.toString() === alarm._id
              );
              const weeklyStatistic = alarmStatistics.find(
                (stat) =>
                  DateTime.fromJSDate(stat.timeRangeEnd_s, {
                    zone: "utc",
                  }).toSeconds() -
                    DateTime.fromJSDate(stat.timeRangeStart_s, {
                      zone: "utc",
                    }).toSeconds() ===
                  WEEK_LENGTH
              );
              const monthlyStatistic = alarmStatistics.find(
                (stat) =>
                  DateTime.fromJSDate(stat.timeRangeEnd_s, {
                    zone: "utc",
                  }).toSeconds() -
                    DateTime.fromJSDate(stat.timeRangeStart_s, {
                      zone: "utc",
                    }).toSeconds() ===
                  MONTH_LENGTH
              );

              const weeklyValue =
                weeklyStatistic &&
                new Decimal(weeklyStatistic.exceededPercentage * 100).toFixed(
                  1
                );
              const monthlyValue =
                monthlyStatistic &&
                new Decimal(monthlyStatistic.exceededPercentage * 100).toFixed(
                  1
                );

              return (
                <tr key={i}>
                  <td>{alarm.name}</td>
                  <td className="text-end dropdown">
                    {weeklyStatistic && (
                      <AlarmExceedsStatisticDisplay
                        alarmExceedStatistic={weeklyStatistic}
                        device={device!.device}
                        alarm={alarm}
                        onChartRequestChange={setChartRequests}
                      />
                    )}
                  </td>
                  <td className="text-end">
                    {monthlyStatistic && (
                      <AlarmExceedsStatisticDisplay
                        alarmExceedStatistic={monthlyStatistic}
                        device={device!.device}
                        alarm={alarm}
                        onChartRequestChange={setChartRequests}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </TabContainer>
  );
};

export default AlarmExceedsStatisticsTab;
