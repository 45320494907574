import { AlarmsReducer, AlarmsState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";
import { FiltersConfig } from "../models/filters-config.model";
import { calculateRequestedAlarms } from "./utils/calculateRequestedAlarms";

type Payload = {
  updatedFiltersConfig: Partial<FiltersConfig>;
};

class UpdateFiltersConfigClass extends ReducerActionClass<
  AlarmsState,
  Payload
> {
  getActionName() {
    return "UPDATE_FILTERS_CONFIG";
  }

  handleDispatch(prevState: AlarmsState, payload: Payload): AlarmsState {
    const { filterDeviceName, filterAlarmType, filterDataType } = prevState;
    const { updatedFiltersConfig } = payload;

    const filtersConfig = {
      filterDeviceName,
      filterAlarmType,
      filterDataType,
      ...updatedFiltersConfig,
    };

    const midState: AlarmsState = {
      ...prevState,
      ...filtersConfig,
    };
    const updatedData = calculateRequestedAlarms(midState,prevState);

    return {
      ...midState,
      ...updatedData,
    };
  }
}

export const UpdateFiltersConfig = new UpdateFiltersConfigClass();
