import { isUndefined } from "lodash";
import { ChartState } from "..";
import { DataType } from "../../models/chart-description.model";
import { getBorderDashIndex } from "../../chart-generating/utils/getBorderDash";
import { getTotalRequestedDataTypesArray } from "../../chart-generating/utils/getTotalRequestedDataTypesArray";
import { TotalRequestedDataTypes } from "../../models/data-type-request.model";

/* 
null if no change needed
*/

export const calculateTotalRequestedDataTypesFromState = (
  state: ChartState
): TotalRequestedDataTypes | null => {
  const {
    totalRequestedDataTypes: prevTotalRequestedDataTypes,
    requestedDataTypes: requestedDataTypes,
    requestedDevices,
  } = state;
  const {totalRequestedDataTypes,changeFound} = calculateTotalRequestedDataTypes(
    requestedDevices,
    requestedDataTypes,
    prevTotalRequestedDataTypes
  );

  if(!changeFound){
    return null;
  }
  return totalRequestedDataTypes;
};

export const calculateTotalRequestedDataTypes = (
  requestedDevices: {
    [deviceName: string]: {
      enabled: boolean;
      requestedDataTypes?: Array<DataType> | undefined;
    };
  },
  requestedDataTypes: Array<DataType>,
  prevTotalRequestedDataTypes:TotalRequestedDataTypes={}
): {totalRequestedDataTypes:TotalRequestedDataTypes,changeFound:boolean} => {
  const totalRequestedDataTypesArray = [...requestedDataTypes];

  Object.values(requestedDevices).forEach((dev) => {
    const { requestedDataTypes,enabled } = dev;
    if (isUndefined(requestedDataTypes)||!enabled) {
      return;
    }
    requestedDataTypes.forEach((dataType) => {
      if (totalRequestedDataTypesArray.includes(dataType)) {
        return;
      }
      totalRequestedDataTypesArray.push(dataType);
    });
  });

  const totalRequestedDataTypes:TotalRequestedDataTypes={};

  const prevTotalRequestedDataTypesArray = getTotalRequestedDataTypesArray(prevTotalRequestedDataTypes);
  let toDeleteFound=false;
  let toAddFound=false;

  /* finding existing entries to rewrite to next object (to keep their dashes style) */
  prevTotalRequestedDataTypesArray.forEach(prevDT=>{

    /* no rewrite if is not included */
    if(!(totalRequestedDataTypesArray.includes(prevDT))){
        toDeleteFound=true;
        return;
    }

    /* rewrite if was included */
    totalRequestedDataTypes[prevDT]=prevTotalRequestedDataTypes[prevDT];
  })

  /* finding entries that was not existing in prev object and adding them with new dashes style */
  totalRequestedDataTypesArray.forEach(newDT=>{

    /* no add if was rewrited earlier */
    if(newDT in totalRequestedDataTypes){
      return;
    }

    /* add if wasnt */
    toAddFound=true;
    totalRequestedDataTypes[newDT]={
      enabled:true,
      deviceDashIndex:getBorderDashIndex(totalRequestedDataTypes)
    }
  })


  const changeFound = toDeleteFound || toAddFound;
  return {totalRequestedDataTypes,changeFound};
};
