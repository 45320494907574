import "reflect-metadata";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";

import "wicg-inert"
// import "bootstrap/dist/css/bootstrap.min.css";
import "./theme.scss"
// import "bootswatch/dist/cosmo/bootstrap.min.css"
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/tab";
import "bootstrap/js/dist/modal";
import { getContainer } from "./modules/app-container";
import { configAxiosBeforeServices } from "./utils/network/axios.config";


getContainer()

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
