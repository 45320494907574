const OffscreenContainer: React.FC<{ children: React.ReactNode }> = (props) => {
  const { children } = props;

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <div style={{ position: "absolute", right: "100%" }}>{children}</div>
    </div>
  );
};

export default OffscreenContainer;
