import IconTile from "./IconTile";
import { ReactComponent as ProfileIcon } from "bootstrap-icons/icons/person-fill.svg";
import { PROFILE_PAGE_PATH } from "../../../../skeleton/pages/profile";
import { useTranslate } from "react-translate";

const ProfileTile: React.FC = () => {

    const t= useTranslate("menu")

  return (
    <IconTile
      title={t("PAGE_PROFILE")}
      url={PROFILE_PAGE_PATH}
      Icon={
        <ProfileIcon
          style={{ top: "-10%", left: "-10%", position: "relative" }}
          width="120%"
          height="120%"
        />
      }
    />
  );
};

export default ProfileTile;
