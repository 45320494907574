import { useCallback, useMemo, useRef } from "react";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { isNull } from "lodash";
import { container } from "tsyringe";
import { DeviceGroupRequestsService } from "../../../request-services/device-group-requests.service";
import { useModPanelData } from "../../hooks/useModPanelData";
import { EmailConfigDto, IpConfigDto, SmsConfigDto } from "shared";
import { OrganisationRequestsService } from "../../../request-services/organisation-requests.service";
import { SystemRequestsService } from "../../../request-services/system-requests.service";

const DeleteIPModal: React.FC<{
  index: number | null;
  onClose: () => void;
  organisationId: string | null;
}> = ({ onClose, index, organisationId }) => {
  const t = useTranslate("user");
  const { ipConfigData } = useModPanelData(organisationId);
  const ipItem = useMemo(() => ipConfigData?.ipAddressList[index || 0], [ipConfigData, index]);

  const onSave = useCallback(() => {
    const dto: IpConfigDto = { ipAddressList:[...(ipConfigData.ipAddressList||[])],ipRestrictionEnabled:true };
    dto.ipAddressList.splice(index || 0, 1);
    if(dto.ipAddressList.length===0){
      dto.ipRestrictionEnabled=false;
    }

    if (isNull(organisationId)) {
  
    } else {
      container
        .resolve(OrganisationRequestsService)
        .sendUpdateIPConfig(dto, organisationId)
        .then(() => {
          onClose();
        });
    }
  }, [organisationId, ipConfigData, index]);

  return (
    <DefaultFormModal
      title={t("IP_CONFIG_MODAL_HEADER_DELETE")}
      show={!isNull(index)}
      onClose={onClose}
      onSave={onSave}
      submitType="delete"
    >
      <>
        {t("IP_CONFIG_MODAL_PARAGRAPH_DELETE_1")}
        <span className="fw-bold">{ipItem?.ipAddress}</span>
        {t("IP_CONFIG_MODAL_PARAGRAPH_DELETE_2")}
      </>
    </DefaultFormModal>
  );
};

export default DeleteIPModal;
