export default {
    "ALARMS_HEADER":"Alarmy",
    "ALARMS_PARAGRAPH":"Lista przekroczonych wartości alarmowych",
    
    "ALARM_CARD_HEADER_1":"{{deviceName}}, {{dataType}}: przekroczono wartość ",
    "ALARM_CARD_HEADER_VALUE_WARNING":"ostrzegawczą",
    "ALARM_CARD_HEADER_VALUE_CRITICAL":"krytyczną",
    "ALARM_CARD_HEADER_2":" (max, ostatnie 24h: {{maxValueLast24h}}).",
    // "ALARM_CARD_HEADER_2":" (maksymalnie dzisiaj: {{maxValueLast24h}}, ogólnie: {{maxValue}}.)",


    "GO_TO_DEVICE_PAGE":"Przejdź do urządzenia",
    "GO_TO_STATS_PAGE":"Przejdź do pomiarów",


    "TIME_SCALE_LABEL":"Przedział czasu",
    "FIELD_SINCE":"Od",
    "FIELD_UNTIL":"Do",

    "SORT_LABEL":"Sortowanie",
    "SORT_DATE_DESCENDING":"Data malejąco",
    "SORT_DATE_ASCENDING":"Data rosnąco",
    "SORT_DEVICE_NAME":"Alfabetycznie",
    "SORT_DEVICE_NAME_REVERTED":"Alfabetycznie odwrócone",
    "SORT_VALUE_DESCENDING":"Najwyższa wartość",

    "FILTER_LABEL":"Filtrowanie",
    "FILTER_DEVICE":"Urządzenia",
    "FILTER_DEVICE_ALL":"Wszystkie",
    "FILTER_DEVICE_GROUP":"Grupy",
    "FILTER_GROUP_ALL":"Wszystkie",

    "FILTER_DATA_TYPES":"Dane pomiarowe",
    "FILTER_DATA_TYPES_ALL":"Wszystkie",

    "FILTER_ALARM_TYPE":"Próg",
    "FILTER_ALARM_TYPE_ALL":"Wszystkie",
    "FILTER_ALARM_TYPE_WARNING":"Ostrzegawczy",
    "FILTER_ALARM_TYPE_CRITICAL":"Krytyczny",
}