export default {

  "CO2+ATMOSPHERIC_PRESSURE_LABEL":"Poziom CO2, Ciśnienie atmosferyczne (przerywana linia)",

  "TEMPERATURE_LABEL":"Temperatura",
  "TMP_LABEL":"Temperatura",
  "TEMPERATURE_UNIT":" °C",
  "TMP_UNIT":" °C",

  "CO2_LEVEL_LABEL":"Poziom CO2",
  "CO2_LABEL":"Poziom CO2",
  "CO2_LEVEL_UNIT":" ppm",
  "CO2_UNIT":" ppm",
  
  "CO_LEVEL_LABEL":"Poziom CO",
  "CO_LABEL":"Poziom CO",
  "CO_LEVEL_UNIT":" ppm",
  "CO_UNIT":" ppm",
  
  "ATMOSPHERIC_PRESSURE_LABEL":"Ciśnienie atmosferyczne",
  "AMP_LABEL":"Ciśnienie atmosferyczne",
  "ATMOSPHERIC_PRESSURE_UNIT":" hPa",
  "AMP_UNIT":" hPa",
  
  "HUMIDITY_LABEL":"Wilgotność względna",
  "HMD_LABEL":"Wilgotność względna",
  "HUMIDITY_UNIT":"%",
  "HMD_UNIT":"%",

  "POWER_SUPPLY_LABEL":"Zasilanie",
  "PWS_LABEL":"Zasilanie",
  "POWER_SUPPLY_UNIT":"%",
  "PWS_UNIT":"%",

  "MOVE_BACKWARD":"Przesuń oś czasu w lewo",
  "MOVE_FORWARD":"Przesuń oś czasu w prawo",
  "DOWNLOAD_CHART":"Pobierz wykres",
  
  "MEASURES_DATA":"Dane pomiarowe",
  "AUTO_SCALE":"Skala automatyczna",
  "GROUPING_LABEL":"Uśrednianie",
  "GROUPING_NONE":"Brak",
  "GROUPING_HOUR":"Godzinowo",
  "GROUPING_DAILY":"Dziennie",

  "DEVICES_DATA":"Urządzenia",
  "SELECT_ALL_DEVICES":"Zaznacz wszystkie",
  "SET_DEFAULT":"Ustaw domyślnie",
  "SET_DEFAULT_ALL":"Ustaw domyślnie wszystkie",

  "ALARMS_DATA":"Alarmy",

  "TIME_RANGE_DAILY":"Dzienny",
  "TIME_RANGE_WEEKLY":"Tygodniowy",
  "TIME_RANGE_CUSTOM":"Niestandardowy",

  "SUBPAGE_CHART":"Wykres",
  "SUBPAGE_TABLE":"Tabela",

  "ANIMATIONS_ENABLED_LABEL":"Animacje włączone",
  "ANIMATIONS_DISABLED_LABEL":"Animacje wyłączone",

  "DEVICES_CONTROLLS_MOVE_TO_DEVICE_PAGE":"Przejdź do strony urządzenia"
};
