import { useCallback, useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import {
  EmailConfigDto,
  EmailItemDto,
  IpAddressItemDto,
  IpConfigDto,
  SmsConfigDto,
  SmsItemDto,
} from "shared";
import ErrorDisplayFieldError from "../../../../../utils/forms/ErrorDisplayFieldError";
import { isNull } from "lodash";
import { useModPanelData } from "../../hooks/useModPanelData";
import { container } from "tsyringe";
import { SystemRequestsService } from "../../../request-services/system-requests.service";
import { OrganisationRequestsService } from "../../../request-services/organisation-requests.service";

const resolver = classValidatorResolver(IpAddressItemDto);

const EditIPModal: React.FC<{
  organisationId: string | null;
  onClose: () => void;
  index: number | null;
}> = ({ onClose, organisationId, index }) => {
  const t = useTranslate("user");

  const { ipConfigData } = useModPanelData(organisationId);
  const ipItem = useMemo(
    () => ipConfigData?.ipAddressList[index || 0],
    [ipConfigData, index]
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm<IpAddressItemDto>({ resolver, mode: "onBlur" });

  const submitRef = useRef<HTMLInputElement>(null);
  const onSave = useCallback(() => {
    if (!submitRef.current) {
      return;
    }
    submitRef.current.click();
  }, [submitRef]);

  useEffect(() => {
    if (!ipItem) {
      return;
    }
    setValue("ipAddress", ipItem.ipAddress);
    setValue("comment", ipItem.comment);
    trigger();
  }, [ipItem]);

  return (
    <DefaultFormModal
      title={t("IP_CONFIG_MODAL_HEADER_EDIT")}
      show={!isNull(index)}
      onClose={onClose}
      onSave={onSave}
      saveDisabled={!isValid}
    >
      <>
        {t("IP_CONFIG_MODAL_PARAGRAPH_EDIT_1")}
        <span className="fw-bold">{ipItem?.ipAddress}</span>
        {t("IP_CONFIG_MODAL_PARAGRAPH_EDIT_2")}
      </>
      <form
        onSubmit={handleSubmit((data) => {
          const { comment, ipAddress } = data;
          // const dto: SmsConfigDto = { items: [...smsData.items] };
          // dto.items[index || 0] = {
          //   comment,
          //   phoneNumber,
          // };

          const dto: IpConfigDto = {
            ipAddressList: [...(ipConfigData.ipAddressList || [])],
            ipRestrictionEnabled: true,
          };
          dto.ipAddressList.splice(index || 0, 1, { comment, ipAddress });

          if (isNull(organisationId)) {
          } else {
            container
              .resolve(OrganisationRequestsService)
              .sendUpdateIPConfig(dto, organisationId)
              .then(() => {
                onClose();
              });
          }
        })}
      >
        <input ref={submitRef} hidden type="submit" />
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <label htmlFor="edit_ip_address" className="col-form-label">
                {t("IP_CONFIG_TABLE_IP")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="edit_ip_address"
                {...register("ipAddress")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.ipAddress} />
        </div>
        <div className="mb-3 mt-4">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div>
              <label htmlFor="edit_ip_comment" className="col-form-label">
                {t("IP_CONFIG_TABLE_COMMENT")}
              </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                id="edit_ip_comment"
                {...register("comment")}
              />
            </div>
          </div>
          <ErrorDisplayFieldError error={errors.comment} />
        </div>
      </form>
    </DefaultFormModal>
  );
};

export default EditIPModal;
