import Decimal from "decimal.js";
import { isNull, isUndefined } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslate } from "react-translate";
import { getDisplayDefaultDecimalPoints } from "../chart-generating/utils/getDisplayDefaultDecimalPoints";
import { findMinimumAlarmThreshold } from "../chart-generating/utils/findMinimumAlarmThreshold";
import { MeasureSetup } from "../models/measure-dataset-setup.model";
import { getDeviceTableTimeGroups } from "./getDeviceTableTimeGroups";
import { getRequestedTableTimeGroups } from "./getRequestedTableTimeGroups";
import { useMainChartSelector } from "../hooks/chart.context";
import { getContainer } from "../../app-container";
import { DataProviderService } from "../../data-provider/data-provider.service";
import { ChartsService } from "../charts.service";

export type DevicesTableTimeGroups = {
  [deviceName: string]: {
    [dataType: string]: { [grouping: string]: TableTimeGroups };
  };
};
export type TableTimeGroups = { [timeStamp_s: number]: Array<MeasureSetup> };

const TableView: React.FC<{ pdfMode?: boolean }> = (props) => {
  const chartsService = getContainer().resolve<ChartsService>("charts");

  const { pdfMode } = props;

  const measuresDatasetsSetup = useMainChartSelector(
    (state) => state.measuresDatasetsSetup
  );
  const requestedDevices = useMainChartSelector(
    (state) => state.requestedDevices
  );
  const requestedDataTypes = useMainChartSelector(
    (state) => state.requestedDataTypes
  );
  const measuresGrouping = useMainChartSelector(
    (state) => state.measuresGrouping
  );
  const timeScaleState = useMainChartSelector((state) => state.timeScaleState);
  const devicesTableTimeGroups: DevicesTableTimeGroups = useMemo(
    () => getDeviceTableTimeGroups(measuresDatasetsSetup),
    [measuresDatasetsSetup]
  );
  const { requestedTimestampGroups_s } = useMemo(
    () =>
      getRequestedTableTimeGroups(
        devicesTableTimeGroups,
        requestedDevices,
        requestedDataTypes,
        measuresGrouping,
        timeScaleState
      ),
    [
      devicesTableTimeGroups,
      requestedDevices,
      requestedDataTypes,
      measuresGrouping,
      timeScaleState,
    ]
  );

  const t = useTranslate("chart");

  const chartState = useMainChartSelector((state) => state);

  const [firstHeaderHeight, setFirstHeaderHeight] = useState<
    number | undefined
  >(undefined);
  const tableFirstHeaderRef = useRef<HTMLTableRowElement>(null);
  useEffect(() => {
    if (!tableFirstHeaderRef.current) {
      return;
    }
    setFirstHeaderHeight(tableFirstHeaderRef.current.clientHeight);
  }, [tableFirstHeaderRef]);

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <div
        style={{
          position: pdfMode ? "absolute" : undefined,
          width: "100%",
          height: "100%",
        }}
      />
      <div
        style={{
          position: pdfMode ? undefined : "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          overflow: pdfMode ? "visible" : "auto",
        }}
      >
        <table className="table table-striped" style={{ position: "relative" }}>
          <thead className="text-white" style={{ zIndex: 1 }}>
            <tr ref={tableFirstHeaderRef}>
              <th
                className="table-secondary"
                style={{
                  position: "sticky",
                  top: 0,
                  left: 0,
                  zIndex: 2,
                  borderBottomWidth: 0,
                }}
              ></th>
              {Object.keys(requestedDevices).map((deviceId, i) => {
                if (!requestedDevices[deviceId].enabled) {
                  return null;
                }

                const requestedDeviceDataTypes = !isUndefined(
                  requestedDevices[deviceId].requestedDataTypes
                )
                  ? requestedDevices[deviceId].requestedDataTypes!
                  : requestedDataTypes;

                if (requestedDeviceDataTypes.length === 0) {
                  return null;
                }

                const color = requestedDevices[deviceId].color;
                const colorString = `rgb(${color})`;
                const devices = getContainer()
                  .resolve<DataProviderService>("data-provider")
                  .getStore()
                  .getState().devices;

                const deviceName =
                  devices.find((dev) => dev.device._id.toString() === deviceId)
                    ?.device.name || "";

                return (
                  <th
                    style={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: colorString,
                      borderBottomWidth: 0,
                    }}
                    key={i}
                    colSpan={requestedDeviceDataTypes.length}
                  >
                    {deviceName}
                  </th>
                );
              })}
            </tr>
            <tr>
              <th
                className="table-secondary"
                style={{
                  position: "sticky",
                  top: firstHeaderHeight && firstHeaderHeight - 0.5,
                  left: 0,
                  zIndex: 2,
                }}
              ></th>
              {Object.keys(requestedDevices).map((deviceName, i) => {
                if (!requestedDevices[deviceName].enabled) {
                  return null;
                }

                const requestedDeviceDataTypes = !isUndefined(
                  requestedDevices[deviceName].requestedDataTypes
                )
                  ? requestedDevices[deviceName].requestedDataTypes!
                  : requestedDataTypes;

                const color = requestedDevices[deviceName].color;
                const colorString = `rgb(${color})`;
                return (
                  <>
                    {requestedDeviceDataTypes.map((dataType, j) => (
                      <th
                        style={{
                          position: "sticky",
                          top: firstHeaderHeight && firstHeaderHeight - 0.5,
                          backgroundColor: colorString,
                        }}
                        key={`${i}-${j}`}
                      >
                        {t(`${dataType}_LABEL`)}
                      </th>
                    ))}
                  </>
                );
              })}
            </tr>
          </thead>
          <tbody
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {requestedTimestampGroups_s.map((timestampGroup_s, i) => (
              <tr key={i}>
                <th
                  style={{
                    position: "sticky",
                    left: 0,
                  }}
                  className="table-secondary"
                >
                  {chartsService.getMeasureTimestampDisplay(
                    timestampGroup_s,
                    measuresGrouping
                  )}
                </th>

                {Object.keys(requestedDevices).map((deviceName, i) => {
                  if (!requestedDevices[deviceName].enabled) {
                    return null;
                  }

                  const requestedDeviceDataTypes = !isUndefined(
                    requestedDevices[deviceName].requestedDataTypes
                  )
                    ? requestedDevices[deviceName].requestedDataTypes!
                    : requestedDataTypes;

                  return (
                    <>
                      {requestedDeviceDataTypes.map((dataType, j) => {
                        /* return if data wasnt downloaded yet */
                        if (
                          !devicesTableTimeGroups[deviceName]?.[dataType]?.[
                            measuresGrouping
                          ]?.[timestampGroup_s]
                        ) {
                          return <td key={`${i}-${j}`} />;
                        }

                        const values =
                          devicesTableTimeGroups[deviceName][dataType][
                            measuresGrouping
                          ][timestampGroup_s];
                        const value = values[0].value;

                        const valueStrings = values.map((item) =>
                          new Decimal(item.value).toFixed(
                            getDisplayDefaultDecimalPoints(dataType)
                          )
                        );

                        // let valueString: string = new Decimal(value).toFixed(
                        //   getDisplayDefaultDecimalPoints(dataType)
                        // );

                        const { minimumAlarmThreshold, maximumAlarmThreshold } =
                          findMinimumAlarmThreshold(chartState, dataType);

                        return (
                          <td
                            className={
                              (!isNull(minimumAlarmThreshold) &&
                                minimumAlarmThreshold < value) ||
                              (!isNull(maximumAlarmThreshold) &&
                                maximumAlarmThreshold >= value)
                                ? "fw-bolder"
                                : undefined
                            }
                            key={`${i}-${j}`}
                          >
                            {valueStrings.map((item, i) => (
                              <span key={i}>
                                {`${item}${t(`${dataType}_UNIT`)}`}
                                <br />
                              </span>
                            ))}
                          </td>
                        );
                      })}
                    </>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableView;
