import { ChartState } from "../../store";
import { DataType } from "../../models/chart-description.model";

export const findMinimumAlarmThreshold = (
  chartState: ChartState,
  dataType: DataType
): {
  minimumAlarmThreshold: number | null;
  maximumAlarmThreshold: number | null;
} => {
  const { alarmsDatasetsSetup, requestedAlarms } = chartState;

  let minimumAlarmFound: boolean = false;
  let minimumAlarmThresholdVal: number = Number.NEGATIVE_INFINITY;
  let maximumAlarmFound: boolean = false;
  let maximumAlarmThresholdVal: number = Number.POSITIVE_INFINITY;
  requestedAlarms.forEach((alarmName) => {
    if (!(alarmName in alarmsDatasetsSetup)) {
      return;
    }

    const {
      dataType: alarmDataType,
      value,
      exceedBelowThreshold,
    } = alarmsDatasetsSetup[alarmName];

    if (dataType !== alarmDataType) {
      return;
    }

    if (exceedBelowThreshold) {
        if(maximumAlarmFound){

            if (value > maximumAlarmThresholdVal) {
                maximumAlarmThresholdVal = value;
              }
        }else{
            maximumAlarmFound = true;
            maximumAlarmThresholdVal = alarmsDatasetsSetup[alarmName].value;
        }

    } else {
      if (minimumAlarmFound) {
        if (value < minimumAlarmThresholdVal) {
          minimumAlarmThresholdVal = value;
        }
      } else {
        minimumAlarmFound = true;
        minimumAlarmThresholdVal = alarmsDatasetsSetup[alarmName].value;
      }
    }
  });

  const minimumAlarmThreshold = minimumAlarmFound
    ? minimumAlarmThresholdVal
    : null;
  const maximumAlarmThreshold = maximumAlarmFound ? maximumAlarmThresholdVal : null;
  return { minimumAlarmThreshold, maximumAlarmThreshold };
};
