import { ReactComponent as PrevIcon } from "bootstrap-icons/icons/arrow-left-square-fill.svg";
import { ReactComponent as NextIcon } from "bootstrap-icons/icons/arrow-right-square-fill.svg";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useTranslate } from "react-translate";
import PaginationBlock from "../../../utils/components/PaginationBlock";
import { ALARMS_CONFIG } from "../config/config";
import { SetPage } from "../store/setPage";
import { useAlarmsDispatch, useAlarmsSelector } from "./AlarmsView";

const AlarmsPagination: React.FC = () => {

  const page = useAlarmsSelector((state) => state.page);
  const requestedAlarmExceeds = useAlarmsSelector(
    (state) => state.requestedAlarmExceeds
  );
  const totalPages = useMemo(
    () => Math.ceil(requestedAlarmExceeds.length / ALARMS_CONFIG.itemsOnPage),
    [requestedAlarmExceeds]
  );
  const dispatch = useAlarmsDispatch();
  const setPage = useCallback(
    (page: number) => {
      dispatch(SetPage.callAction({ page }));
    },
    [dispatch]
  );

  return(
    <PaginationBlock currentPage={page} totalPages={totalPages} onPageChange={setPage} />
  )
}

export default AlarmsPagination;
