import { createContext, useMemo } from "react";
import { createDispatchHook, createSelectorHook, Provider } from "react-redux";
import { Action, createStore, Dispatch, Store } from "redux";
import { DataExporterReducer, DataExporterState } from "./store";

export const DataExporterContext = createContext({});

export const useDataExporterDispatch: <
  AppDispatch extends Dispatch<Action<DataExporterState>> = Dispatch<
    Action<DataExporterState>
  >
>() => AppDispatch = createDispatchHook(
  //@ts-ignore
  DataExporterContext
);
export const useDataExporterSelector: <S>(
  selector: (state: DataExporterState) => S
  //@ts-ignore
) => S = createSelectorHook(DataExporterContext);

export const useCreateDataExporterStore = ()=>{
  const store = useMemo(()=>{
    const reducer = new DataExporterReducer();

    //@ts-ignore
    return createStore(reducer.changeState.bind(reducer)) as Store<DataExporterState>;
  },[])

  return store;
}

const DataExporterProvider: React.FC<{
  children: React.ReactNode;
  store: Store<DataExporterState>;
}> = ({ store, children }) => {
  return (
    <Provider context={DataExporterContext as any} store={store}>
      {children}
    </Provider>
  );
};

export default DataExporterProvider;
