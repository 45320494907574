import { useTranslate } from "react-translate";
import { ReactComponent as PasswordIcon } from "bootstrap-icons/icons/key-fill.svg";
import { ReactComponent as EditIcon } from "bootstrap-icons/icons/pencil-fill.svg";
import { ReactComponent as DeleteIcon } from "bootstrap-icons/icons/trash-fill.svg";
import { ReactComponent as AddIcon } from "bootstrap-icons/icons/plus-square-fill.svg";
import { Tooltip } from "bootstrap";
import { useLayoutEffect, useMemo, useState } from "react";
import { useTable } from "react-table";
import ResetPasswordModal from "../../modals/UsersTab/ResetPasswordModal";
import { UserOrganisationRole } from "shared";
import ChangeRoleModal from "../../modals/UsersTab/ChangeRoleModal";
import CreateUserModal from "../../modals/UsersTab/CreateUserModal";
import DeleteUserModal from "../../modals/UsersTab/DeleteUserModal";
import { useModDataSelector } from "../../../mod-data.provider";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { useUserSelector } from "../../../../user/user.provider";
import { useCanAccessAdminPanel } from "../../../hooks/useCanAccessAdminPanel";

export const exampleUsers: Array<{
  user: string;
  _id: string;
  role: UserOrganisationRole;
}> = [
  { user: "Zdzislaw", role: "OWNER", _id: "0" },
  { user: "Antoni", role: "MOD", _id: "1" },
  { user: "Bronisław", role: "MOD", _id: "2" },
  { user: "Czesław", role: "USER", _id: "3" },
  { user: "Daria", role: "USER", _id: "4" },
  { user: "Edward", role: "USER", _id: "5" },
];

const UsersTab: React.FC<{organisationId:string}> = ({organisationId}) => {
  const t = useTranslate("mod");

  const organisations = useModDataSelector(state=>state.organisations)
  const organisation = useMemo(()=>organisations[organisationId],[organisations,organisationId])


  const primaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-primary-rgb");

  const dangerColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--bs-danger-rgb");

  const columns = useMemo(
    () => [
      {
        Header: t("USERS_COLUMN_NAME"),
        accessor: "name",
      },
      {
        Header: t("USERS_COLUMN_LOGIN"),
        accessor: "login",
      },
      {
        Header: t("USERS_COLUMN_ROLE"),
        accessor: "role",
        Cell: (obj: any) => t(`USERS_ROLE_${obj.value}`),
      },
    ],
    [t]
  );

  const user = useUserSelector(state=>state.user)
  //@ts-ignore
  const orgRole = useMemo(()=>user?.roles.find(role=>role.target===organisationId)?.role,[user,organisationId]) as UserOrganisationRole|undefined
  const {access: adminAccess} = useCanAccessAdminPanel()

  const data = useMemo(
    () =>
    (organisation?.users||[]).map((user) => ({
        _id: user._id,
        name: user.name,
        login: user.username,
        //@ts-ignore
        role: user.roles.find(role=>role.target===organisationId)?.role,
      })),
    [organisationId,organisation]
  );

  useLayoutEffect(() => {
    const tooltipElements = Array.from(
      document.querySelectorAll(".UsersTabTooltip")
    );
    const tooltips = tooltipElements.map((el) => new Tooltip(el));
    return () => tooltips.forEach((tooltip) => tooltip.dispose());
  }, [data]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      //@ts-ignore
      columns,
      data,
    });

  const [resetingPassword, setResetingPassword] = useState<string | null>(null);
  const [changingRole, setChangingRole] = useState<string | null>(null);
  const [creatingUser, setCreatingUser] = useState<boolean>(false);
  const [deletingUser, setDeletingUser] = useState<string | null>(null);

  return (
    <>
      <h4>{t("TAB_users")}</h4>
      <p>{t("USERS_PARAGRAPH")}</p>
      <div style={{ maxWidth: 1100 }}>
        <table {...getTableProps()} className="table table-striped mb-2">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}

                <th />
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {

              const allowActions = row.original.role==="OWNER" ? false : row.original.role==="MANAGER" ? orgRole==="OWNER"||adminAccess : true

              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                  <td className="ps-4 pe-0" style={{ width: "1%" }}>
                    {!allowActions ? null : (
                    <div className="d-flex justify-content-end">
                      <div className="me-3">
                        <div
                          className="UsersTabTooltip"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={t("USERS_TOOLTIP_RESET_PASSWORD")}
                        >
                          <a
                            className="text-decoration-none text-reset"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setResetingPassword(row.original._id);
                            }}
                          >
                            <PasswordIcon style={{ cursor: "pointer" }} />
                          </a>
                        </div>
                      </div>
                      {!((orgRole==="OWNER")||adminAccess) ? null : (
                      <div className="me-3">
                        <div
                          className="UsersTabTooltip"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={t("USERS_TOOLTIP_EDIT_ROLE")}
                        >
                          <a
                            className="text-decoration-none text-reset"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setChangingRole(row.original._id);
                            }}
                          >
                            <EditIcon style={{ cursor: "pointer" }} />
                          </a>
                        </div>
                      </div>
                      )}
                      <div className="me-4">
                        <div
                          className="UsersTabTooltip"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title={t("USERS_TOOLTIP_DELETE_USER")}
                        >
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              setDeletingUser(row.original._id);
                            }}
                          >
                            <DeleteIcon
                              fill={`rgb(${dangerColor})`}
                              style={{ cursor: "pointer" }}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="d-flex justify-content-end">
          <div className="pe-4" style={{ marginRight: -2 }}>
            <div
              className="UsersTabTooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={t("USERS_TOOLTIP_ADD_USER")}
            >
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setCreatingUser(true);
                }}
              >
                <AddIcon
                  style={{ cursor: "pointer" }}
                  fill={`rgb(${primaryColor})`}
                  height="20"
                  width="20"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <ResetPasswordModal
        userId={resetingPassword}
        onClose={() => setResetingPassword(null)}
        organisationId={organisationId}
      />
      <ChangeRoleModal
        userId={changingRole}
        onClose={() => setChangingRole(null)}
        organisationId={organisationId}

      />
      <CreateUserModal
        show={creatingUser}
        onClose={() => setCreatingUser(false)}
        organisationId={organisationId}

      />
      <DeleteUserModal
        userId={deletingUser}
        onClose={() => setDeletingUser(null)}
        organisationId={organisationId}

      />
    </>
  );
};

export default UsersTab;
