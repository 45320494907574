import { ReactComponent as ProfileIcon } from "bootstrap-icons/icons/person-square.svg";
import { useMemo, useState } from "react";
import { useTranslate } from "react-translate";
import DefaultModal from "../../../../utils/components/DefaultModal";
import ChangePasswordModal from "./ChangePasswordModal";
import { useUserSelector } from "../../user.provider";
import { useSelector } from "react-redux";

const UserProfileHeader: React.FC = () => {
  const t = useTranslate("user");

  const roles = useUserSelector(state=>state.user?.roles);
  const name = useUserSelector(state=>state.user?.username);
  const organisationNames = useUserSelector(state=>state.organisationNames)

  return (
    <>
      <ChangePasswordModal />
      <div>
        <div className="mb-5 text-center text-sm-start">
          <h1>{t("PROFILE_HEADER")}</h1>
        </div>
        <div className="d-flex align-items-center align-items-sm-stretch flex-column flex-sm-row justify-content-start">
          <div style={{ width: 250, height: 250 }}>
            <ProfileIcon width="250" height="250" />
          </div>
          <div
            className="d-flex align-items-center justify-content-center justify-content-lg-start ms-5 mt-4 mt-sm-2 m-2"
          >
            <div>
              <div className="mb-3">
                <label className="form-label">{t("USERNAME")}</label>
                <input
                  readOnly
                  className="form-control-plaintext ms-3"
                  value={name}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">{t("USER_ROLE")}</label>
                {
                  (roles||[]).map((role,i)=>{

                    //@ts-ignore
                    const orgId = role.targer as string
                    const orgName = organisationNames[orgId]
                    
                    return(               
                    <input
                    key={i}
                      readOnly
                      className="form-control-plaintext ms-3"
                      value={`${orgName ? `${orgName} - ` : ""}${t(`USER_ROLE_${role.role}`)}`}
                    />
                  )})
                } 
              </div>
              <div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#changePasswordModal"
                  className="btn btn-primary ms-2 mt-2"
                >
                  {t("CHANGE_PASSWORD")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfileHeader;
