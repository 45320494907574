import Decimal from "decimal.js";
import { isUndefined } from "lodash";
import { useMemo } from "react";
import { useTranslate } from "react-translate";
import { getContainer } from "../../../app-container";
import { getCorrectGroupingsForTimeRange } from "../../../charts/chart-generating/utils/getCorrectGroupingsForTimeRange";
import { ChartRequests } from "../../../charts/models/chart-requests.model";
import { DataProviderService } from "../../../data-provider/data-provider.service";
import { getStatsPageURLWithSearchParamsValue } from "../../../../skeleton/pages/stats/index";
import { useHistory } from "react-router";
import { getColorForDeviceGroup } from "../../../charts/chart-generating/utils/getColor";
import {
  AlarmFullRequests,
} from "../../../alarms/models/alarm-requests.model";
import {
  ALARMS_PAGE_URL,
  ALARMS_REQUESTS_SEARCH_PARAM,
} from "../../../../skeleton/pages/alarms";
import { Alarm, AlarmExceedStatistic, Device } from "shared";
import { DateTime } from "luxon";

const AlarmExceedsStatisticDisplay: React.FC<{
  alarmExceedStatistic: AlarmExceedStatistic;
  device: Device;
  alarm: Alarm;
  onChartRequestChange?: (chartRequests: ChartRequests) => void;
  requestedColor?: string;
}> = ({
  alarmExceedStatistic,
  onChartRequestChange,
  alarm,
  device,
  requestedColor,
}) => {
  const valuePercentage = new Decimal(
    alarmExceedStatistic.exceededPercentage * 100
  ).toFixed(1);

  const t = useTranslate("devices");
  const history = useHistory();
  const { timeRangeStart_s: timeRangeStart, timeRangeEnd_s: timeRangeEnd } = alarmExceedStatistic;

  const dataProviderService =
    getContainer().resolve<DataProviderService>("data-provider");

  const dataType = useMemo(
    () => dataProviderService.getDataType(alarm.dtAlias),
    [alarm]
  );

    const startTimestamp_s = DateTime.fromJSDate(timeRangeStart,{zone:"utc"}).toSeconds();
    const endTimestamp_s =  DateTime.fromJSDate(timeRangeEnd,{zone:"utc"}).toSeconds()

  const chartRequests: ChartRequests = useMemo(
    () => ({
      requestedAlarms: [alarm.name],
      requestedDataTypes: [dataType],
      requestedDevices: {
        [device._id]: {
          enabled: true,
          color: requestedColor || getColorForDeviceGroup(device.groupIds[0]),
        },
      },
      timeScaleState: {
        startTimestamp_s,
        endTimestamp_s,
      },
      measuresGrouping: getCorrectGroupingsForTimeRange({
        startTimestamp_s  ,
        endTimestamp_s,
      })[0],
    }),
    [alarmExceedStatistic, device, alarm, requestedColor, dataType]
  );

  const statsPageURI = useMemo(
    () => getStatsPageURLWithSearchParamsValue(JSON.stringify(chartRequests)),
    [chartRequests]
  );

  const alarmsFullRequests: AlarmFullRequests = useMemo(
    () => ({
      filterDataType: [dataType],
      filterAlarmType: [alarm.type],
      filterDeviceName: [device.name],
      timeScaleState: {
        startTimestamp_s,
        endTimestamp_s,
      },
      sortMode: "DATE_DESCENDING",
      page: 0,
    }),
    [dataType, alarm, device, alarmExceedStatistic]
  );

  const alarmsPageUrl = useMemo(
    () =>
      `${ALARMS_PAGE_URL}?${ALARMS_REQUESTS_SEARCH_PARAM}=${JSON.stringify(
        alarmsFullRequests
      )}`,
    [alarmsFullRequests]
  );

  return (
    <>
      {valuePercentage}%{" "}
      <span
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.nativeEvent.key === "Enter") {
            e.stopPropagation();
            //@ts-ignore
            e.target.click();
          }
        }}
        style={{ cursor: "pointer" }}
        className="dropdown-toggle"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
      />
      <ul
        className="dropdown-menu-end dropdown-menu ae-statistic-dropdown"
        aria-labelledby="dropdownMenuLink"
      >
        {onChartRequestChange && (
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onChartRequestChange(chartRequests);
              }}
              onKeyDown={(e) => {
                if (e.nativeEvent.key === "Enter") {
                  e.stopPropagation();
                  //@ts-ignore
                  e.target.click();
                }
              }} 
            >
              {t("ALARM_EXCEEDS_STATISTICS_SHOW_IN_STATS")}
            </a>
          </li>
        )}
        <li>
          <a
            href={statsPageURI}
            onClick={(e) => {
              e.preventDefault();
              history.push(statsPageURI);
            }}
            className="dropdown-item"
            onKeyDown={(e) => {
              if (e.nativeEvent.key === "Enter") {
                e.stopPropagation();
                //@ts-ignore
                e.target.click();
              }
            }}
          >
            {t("ALARM_EXCEEDS_STATISTICS_GO_TO_STATS")}
          </a>
        </li>
        <li>
          <a
            href={alarmsPageUrl}
            onClick={(e) => {
              e.preventDefault();
              history.push(alarmsPageUrl);
            }}
            onKeyDown={(e) => {
              if (e.nativeEvent.key === "Enter") {
                e.stopPropagation();
                //@ts-ignore
                e.target.click();
              }
            }}
            className="dropdown-item"
          >
            {t("ALARM_EXCEEDS_STATISTICS_GO_TO_ALARMS")}
          </a>
        </li>
      </ul>
    </>
  );
};

export default AlarmExceedsStatisticDisplay;
