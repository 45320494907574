import React, { Ref, RefObject, useEffect, useState } from "react";
import EditorJS from "@editorjs/editorjs";
//@ts-ignore
import paragraph from "@editorjs/paragraph";
//@ts-ignore
import header from "@editorjs/header";
import { NotificationBlock } from "shared";

export const useEditor = (ref:RefObject<HTMLDivElement>,blocks:Array<NotificationBlock>=[])=>{
    const [editor, setEditor] = useState<EditorJS | null>(null);

  useEffect(() => {
    if(!ref.current){
        return
    }
    const container=ref.current;
    const el = document.createElement("div");
    container.appendChild(el);
    const editor = new EditorJS({
        data:{
            blocks
        },
      holder: el,
      tools: {
        header: {
          class: header,
          inlineToolbar: ["bold", "italic", "link"],
          config: {
            levels: [1, 2, 3, 4, 5, 6],
            defaultLevel: 2,
          },
        },
        paragraph: {
          class: paragraph,
          inlineToolbar: ["bold", "italic", "link"],
        },
      },
      i18n: {
        /**
         * @type {I18nDictionary}
         */
        messages: {
          toolNames: {
            Text: "Paragraf",
            Heading: "Nagłówek",
          },
        },
      },
    });
    editor.isReady.then(() => {
      setEditor(editor);
    });
    return () => {
      editor.isReady.then(() => {
        editor.destroy();
        setEditor((curr) => (curr === editor ? null : curr));
      });
    };
  }, [setEditor,ref,blocks]);

  return {editor};
}