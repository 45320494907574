import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslate } from "react-translate";
import DefaultFormModal from "../../../../../utils/forms/modals/DefaultFormModal";
import { useDataProviderSelector } from "../../../../data-provider/data-provider.provider";
import { exampleOrganisations } from "../../tabs/Admin/OrganisationsTab";
import { useModDataSelector } from "../../../mod-data.provider";
import { container } from "tsyringe";
import { DeviceRequestsService } from "../../../request-services/device-requests.service";

const EditDevicesOrganisationModal: React.FC<{
  deviceId: string | null;
  onClose: () => void;
}> = ({ onClose, deviceId }) => {
  const t = useTranslate("mod");

  const devices = useModDataSelector(state=>state.system?.devices)

  // const devices = useDataProviderSelector((state) => state.devices);
  const device = useMemo(
    () => devices&&devices.find((dev) => dev._id === deviceId),
    [deviceId, devices]
  );
  const organisations = useModDataSelector(state=>state.system?.organisations);

  const [newOrganisation, setNewOrganisation] = useState<string|null>(null);

  const onSave = useCallback(() => {
    if(!deviceId||!newOrganisation){
      return
    }
    container.resolve(DeviceRequestsService).editDevicesOrganisations(deviceId,newOrganisation).then(()=>onClose())

  }, [deviceId,newOrganisation]);

  useEffect(() => {
    if(!organisations){
      return setNewOrganisation(null);
    }
    setNewOrganisation(organisations.find(org=>org.organisation._id===device?.organisation)?.organisation._id||"");
  }, [device,organisations]);


  return (
    <DefaultFormModal
      title={t("DEVICES_TOOLTIP_EDIT_ORGANISATION")}
      show={!!deviceId}
      onClose={onClose}
      onSave={onSave}
    >
      <>{t("EDIT_DEVICE_ORGANISATION_PARAGRAPH", { groupName: device?.name || "" })}</>
      <div className="mb-3 mt-4">
      {organisations&&organisations.map((org) =>{
        const checked = newOrganisation===org.organisation._id
        return (
        <div key={org.organisation._id} className="form-check">
          <input
            className="form-check-input"
            type="radio"
            checked={checked}
            onChange={(e)=>{
              setNewOrganisation(org.organisation._id)
            }}
            id={`device-org-radio-${org.organisation._id}`}
          />
          <label className="form-check-label" htmlFor={`device-org-radio-${org.organisation._id}`}>
            {org.organisation.name}
          </label>
        </div>
      )})}
      </div>
    </DefaultFormModal>
  );
};

export default EditDevicesOrganisationModal;
