import axios from "axios";
import { DeviceDto, DeviceMqttConfigurationDto, RunningHistory, SmsConfigDto } from "shared";
import { injectable, Lifecycle, scoped } from "tsyringe";
import { getConstants } from "../../../config/constants";
import { getContainer } from "../../app-container";
import { ModDataService } from "../mod-data.service";
import { DateTime } from "luxon";

@injectable()
@scoped(Lifecycle.ContainerScoped)
export class DeviceRequestsService {
  async addDevice(dto:DeviceDto) {
    await axios({
      method: "post",
      url: `${getConstants().SERVER_URL}/devices/system`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()

  }

  async deleteDevice(id:string) {
    await axios({
      method: "delete",
      url: `${getConstants().SERVER_URL}/devices/system/${id}`,
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()

  }


  async editDevicesOrganisations(devId:string,orgId:string) {
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/devices/system/${devId}/organisation`,
      data:{organisation:orgId}
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()
  }

  async editDevicesMqttConfiguration(devId:string,dto:DeviceMqttConfigurationDto) {
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/devices/system/${devId}/mqtt-configuration`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()
  }
  
  async editDevicesGlobalGroups(devId:string,dto:{groups:Array<string>}) {
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/devices/system/${devId}/groups`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleSystemDataChanges()
  }


  async editDevicesGroups(devId:string,organisationId:string,dto:{groups:Array<string>}) {
    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/devices/organisations/${organisationId}/${devId}/groups`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleOrganisationDataChanges(organisationId)
  }

  async editDevice(dto:DeviceDto,devId:string,organisationId:string){

    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/devices/organisations/${organisationId}/${devId}`,
      data:dto
    });

    await getContainer().resolve<ModDataService>("mod-data").handleOrganisationDataChanges(organisationId)
  }

  async editDevicesRunningHistory(runningHistory:RunningHistory,devId:string,organisationId:string){
    const items = runningHistory.map((rhi)=>({
      timeStart_s:DateTime.fromJSDate(rhi.timeStart_s, {
          zone: "utc",
        }).toSQL(),
      timeEnd_s:rhi.timeEnd_s&&DateTime.fromJSDate(rhi.timeEnd_s, {
        zone: "utc",
      }).toSQL(),
      location:rhi.location
    }))

    await axios({
      method: "patch",
      url: `${getConstants().SERVER_URL}/devices/organisations/${organisationId}/${devId}/history`,
      data:{items}
    });

    await getContainer().resolve<ModDataService>("mod-data").handleOrganisationDataChanges(organisationId)


  }
}
