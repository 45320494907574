import { PdfGeneratorState } from ".";
import { ReducerActionClass } from "../../../utils/store/core";
import { checkIfStateIsDownloadable } from "./utils/checkIfStateIsDownloadable";

type Payload = {
  requestedDevice: string;
};

class PutRequestedDeviceClass extends ReducerActionClass<
  PdfGeneratorState,
  Payload
> {
  getActionName() {
    return "PUT_REQUESTED_DEVICE";
  }
  handleDispatch(
    prevState: PdfGeneratorState,
    payload: Payload
  ): PdfGeneratorState {
    const { requestedDevice } = payload;

    const curr = {
      ...prevState,
      requestedDevice,
    };

    const downloadable = checkIfStateIsDownloadable(curr);

    return {
      ...curr,
      downloadable,
    };
  }
}

export const PutRequestedDevice = new PutRequestedDeviceClass();
