import {
  Alarm,
  Device,
  DeviceGroup,
  DeviceWMqttConfWOPassword,
  EmailConfig,
  IpConfig,
  MissingMeasuresAlertsConfig,
  Notification,
  Organisation,
  SmsConfig,
  User,
} from "shared";
import { useModDataSelector } from "../../mod-data.provider";
import { useMemo } from "react";
import { isNull } from "lodash";

export const useModPanelData = (organisationId: string | null) => {
  const modState = useModDataSelector((state) => state);
  const devices: Array<{
    device: DeviceWMqttConfWOPassword;
  }> = useMemo(() => {
    if (isNull(organisationId)) {
      //system data
      return modState.system?.devices.map((dev) => ({ device: dev })) || [];
    } else {
      //organisation data
      return modState.organisations[organisationId]?.viewData.devices || [];
    }
  }, [organisationId, modState]);

  const deviceGroups: Array<DeviceGroup> = useMemo(() => {
    if (isNull(organisationId)) {
      //system data
      return modState.system?.device_groups || [];
    } else {
      //organisation data
      return (
        modState.organisations[organisationId]?.viewData.deviceGroups || []
      );
    }
  }, [organisationId, modState]);

  const smsData: SmsConfig = useMemo(() => {
    if (isNull(organisationId)) {
      //system data
      return modState.system?.system.smsConfig || { items: [] };
    } else {
      //organisation data
      return (
        modState.organisations[organisationId]?.organisation.smsConfig || {
          items: [],
        }
      );
    }
  }, [organisationId, modState]);

  const emailData: EmailConfig = useMemo(() => {
    if (isNull(organisationId)) {
      //system data
      return modState.system?.system.emailConfig || { items: [] };
    } else {
      //organisation data
      return (
        modState.organisations[organisationId]?.organisation.emailConfig || {
          items: [],
        }
      );
    }
  }, [organisationId, modState]);

  const alarms: Array<Alarm> = useMemo(() => {
    if (isNull(organisationId)) {
      //system data
      return modState.system?.alarms || [];
    } else {
      //organisation data
      return modState.organisations[organisationId]?.viewData.alarms || [];
    }
  }, [organisationId, modState]);

  const ipConfigData: IpConfig = useMemo(() => {
    if (isNull(organisationId)) {
      return { ipRestrictionEnabled: false, ipAddressList: [] };
    } else {
      return (
        modState.organisations[organisationId]?.organisation.ipConfig || {
          ipRestrictionEnabled: false,
          ipAddressList: [],
        }
      );
    }
  }, [organisationId, modState]);

  const notifications: Array<Notification> = useMemo(() => {
    if (isNull(organisationId)) {
      return modState.system?.notifications || [];
    } else {
      return [];
    }
  }, [organisationId, modState]);

  const organisations: Array<{
    organisation: Organisation<string, string>;
    owner: User<string, string>;
  }> = useMemo(() => {
    if (isNull(organisationId)) {
      return modState.system?.organisations || [];
    } else {
      return [];
    }
  }, [organisationId, modState]);

  const missingMeasuresAlertConfig: MissingMeasuresAlertsConfig | undefined =
    useMemo(() => {
      if (isNull(organisationId)) {
        return modState.system?.system.missingMeasuresAlertsConfig;
      } else {
        return undefined;
      }
    }, [organisationId, modState]);

  return {
    devices,
    deviceGroups,
    smsData,
    alarms,
    emailData,
    ipConfigData,
    notifications,
    organisations,
    missingMeasuresAlertConfig,
  };
};
