import Decimal from "decimal.js";
import { isNull, isUndefined } from "lodash";
import { inject, injectable } from "tsyringe";
import type { DependencyContainer} from "tsyringe";
import translations from "../../../../translations/translations";
import { DataProviderService } from "../../../data-provider/data-provider.service";
import { getDisplayDefaultDecimalPoints } from "../../chart-generating/utils/getDisplayDefaultDecimalPoints";
import { ChartsService } from "../../charts.service";
import { ChartData } from "../../models/chart-data";
import { DataType } from "../../models/chart-description.model";
import { ChartRequests } from "../../models/chart-requests.model";
import { getDeviceTableTimeGroups } from "../../table/getDeviceTableTimeGroups";
import { getRequestedTableTimeGroups } from "../../table/getRequestedTableTimeGroups";
import { TableTimeGroups } from "../../table/TableView";
import { TableItem, TableItemCell } from "./model/table-item.model";
import { AlarmExceedStatistic } from "shared";

/* translations should be imported from translations service */
const translation = translations.PL;
const tCharts = translation.chart as { [key: string]: string };

type ItemTableCreationOptions = {
  singleRowHeader: boolean;
  alarmThreshold?:{[dataType:string]:number}
  alarmExceededBelow?:{[dataType:string]:boolean}
};

const defaultOptions: ItemTableCreationOptions = {
  singleRowHeader: false,
};

@injectable()
export class ChartsTableService {
  constructor(
    @inject("data-provider") private dataProviderService: DataProviderService,
    @inject("depedency-container") private chartsContainer:DependencyContainer,
  ) {}

  getItemTableAEStatistics(alarmExceedStatistics:Array<AlarmExceedStatistic>):TableItem{
      
    const {alarms} = this.dataProviderService.getStore().getState();
    const head: Array<Array<TableItemCell>> =[["Próg alarmowy","Limit progu","Czas przekroczenia alarmu"]]

    const body:Array<Array<TableItemCell>> = []

    alarmExceedStatistics.forEach((aes)=>{
      const alarm = alarms.find(alarm=>alarm._id===aes.alarm)


      const dt = this.dataProviderService.getDataType(alarm?.dtAlias||"CO2")
      const decimalPoint = isUndefined(alarm) ? 0 :getDisplayDefaultDecimalPoints(dt);
      const valueDisplay = (isUndefined(alarm))
      ? ""
      : `${new Decimal(alarm!.threshold).toFixed(decimalPoint)}${
          tCharts[`${dt}_UNIT`]
        }`;

      body.push([alarm?.name||"",valueDisplay,`${new Decimal(aes.exceededPercentage*100).toFixed(1)}%`])
    })

    console.log(head,body,alarmExceedStatistics)

    return {head,body}
  }

  getItemTableData(
    chartData: ChartData,
    chartRequests: ChartRequests,
    options: Partial<ItemTableCreationOptions> = defaultOptions
  ): TableItem {
    const mergedOptions: ItemTableCreationOptions = {
      ...defaultOptions,
      ...options,
    };
    const { singleRowHeader,alarmThreshold={},alarmExceededBelow={} } = mergedOptions;

    const { measuresDatasetsSetup } = chartData;
    const {
      requestedDevices,
      requestedDataTypes: requestedDataTypes,
      timeScaleState,
      measuresGrouping,
    } = chartRequests;

    const devicesTableTimeGroups = getDeviceTableTimeGroups(
      measuresDatasetsSetup
    );

    const { requestedTableTimeGroups, requestedTimestampGroups_s } =
      getRequestedTableTimeGroups(
        devicesTableTimeGroups,
        requestedDevices,
        requestedDataTypes,
        measuresGrouping,
        timeScaleState
      );

    const head: Array<Array<TableItemCell>> = singleRowHeader
      ? [[""]]
      : [[""], [""]];
    const tableTimeGroups: Array<TableTimeGroups> = [];
    const tableTimeGroupsDt: Array<DataType> = [];
    const devices = this.dataProviderService.getStore().getState().devices.map(dev=>dev.device)
    const deviceIds = Object.keys(requestedTableTimeGroups)
    const deviceNames = Object.keys(requestedTableTimeGroups).map(id=>devices.find(dev=>dev._id===id)!.name);
    deviceIds.forEach((deviceId,i) => {
      const deviceTableTimeGroups = requestedTableTimeGroups[deviceId];
      const deviceDataTypes = Object.keys(
        deviceTableTimeGroups
      ) as Array<DataType>;
      if (!singleRowHeader) {
        head[0].push({ content: deviceNames[i], colSpan: deviceDataTypes.length });
      }

      const sortedDeviceDataTypes = [...deviceDataTypes].sort((dtA,dtB)=>requestedDataTypes.findIndex(dt=>dt===dtA)-requestedDataTypes.findIndex(dt=>dt===dtB))

      sortedDeviceDataTypes.forEach((dt) => {
        if (!singleRowHeader) {
          head[1].push(tCharts[`${dt}_LABEL`]);
        } else {
          head[0].push(`${deviceNames[i]} - ${tCharts[`${dt}_LABEL`]}`);
        }
        const dtTTG = deviceTableTimeGroups[dt];
        const groupingTTG = dtTTG[measuresGrouping];
        tableTimeGroups.push(groupingTTG);
        tableTimeGroupsDt.push(dt);
      });
    });

    /* delete row 0, if there is only 1 device (and there is no single row header mode enabled) */
    if (deviceNames.length === 1&&!singleRowHeader) {
      head.splice(0, 1);
    }

    const body: Array<Array<TableItemCell>> = [];
    requestedTimestampGroups_s.forEach((group_s) => {
      const dateTime = this.chartsContainer.resolve<ChartsService>("charts").getMeasureTimestampDisplay(group_s,chartRequests.measuresGrouping)
      const row: Array<TableItemCell> = [dateTime];
      tableTimeGroups.forEach((ttg, j) => {
        const dt = tableTimeGroupsDt[j];
        const decimalPoint = getDisplayDefaultDecimalPoints(dt);
        const value = ttg[group_s]?.[0]?.value;

        const dtAlarmThreshold = alarmThreshold[dt] 
        const dtAlarmExceededBelow = alarmExceededBelow[dt] 

        const valueDisplay = (isUndefined(value)||isNull(value))
          ? ""
          : `${new Decimal(value).toFixed(decimalPoint)}${
              tCharts[`${dt}_UNIT`]
            }`;
        if(!isUndefined(dtAlarmThreshold)){
          const exceeded = dtAlarmExceededBelow ? value<dtAlarmThreshold : value>dtAlarmThreshold
          row.push({content:valueDisplay,bold:exceeded})
        }else{
          row.push(valueDisplay);

        }
      });
      body.push(row);
    });

    return {
      body,
      head,
    };
  }
}
